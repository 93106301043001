import { IFilterData } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'
import { ITopic } from 'pages/modules/ModulesList.interface'

export const filterTopics = (filterOptions: IFilterData, topics: ITopic[]): ITopic[] => {
  const filteredTopics = topics
    .map((topicRaw: ITopic) => {
      const topic: ITopic = { ...topicRaw }

      // Filter by name
      if (filterOptions.name) {
        const searchName = filterOptions.name.toLowerCase()
        if (topic?.name?.toLowerCase().includes(searchName)) {
          return topic
        }

        if (topic.lessons?.some(lesson => lesson.name.toLowerCase().includes(searchName))) {
          topic.lessons = topic.lessons.filter(lesson =>
            lesson.name.toLowerCase().includes(searchName),
          )
          return topic
        }
        return null
      }

      // Filter by number of lessons
      if (filterOptions.numberOfLessons !== '0-0') {
        const [from, to] = (filterOptions.numberOfLessons as string).split('-').map(Number)
        const topicLessonsLength = topic.lessons?.length || 0

        if (topicLessonsLength < from || topicLessonsLength > to) {
          return null
        }
      }

      // Filter by topic duration
      if (filterOptions.topicDuration && filterOptions.topicDuration[1] !== 0) {
        const [from, to] = filterOptions.topicDuration
        const topicDuration =
          (topic.lessons?.reduce((acc, lesson) => acc + (lesson.duration || 0), 0) || 0) / 60

        if (topicDuration < from || topicDuration > to) {
          return null
        }
      }

      return topic
    })
    .filter(Boolean)

  return filteredTopics as ITopic[]
}
