import gql from 'graphql-tag'

export const LOGIN = gql`
  mutation Login($input: LoginInput) {
    authAdmin(input: $input) {
      accessToken
      user {
        id
        firstName
        lastName
        email
        role
        leaderboard {
          companyPosition
          globalPosition
        }
      }
    }
  }
`
