import React, { useState, useEffect, useRef, ReactElement } from 'react'
import { EditorState, convertToRaw, convertFromRaw, ContentState } from 'draft-js'
import Editor from 'draft-js-plugins-editor'
import createToolbarPlugin from 'draft-js-static-toolbar-plugin'
import { useTranslation } from 'react-i18next'

import {
  Container,
  ContainerHeader,
  EditorContainer,
  ScrollView,
  EditorActions,
  EditorActionsEditButton,
  EditorActionsEditText,
  EditorEditActions,
  DefaultText,
  ContentContainer,
} from './styled-components'
import { Button } from 'components/common/Button'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'

import Controls, { IControlsProps } from './Controls'
import { linkPlugin, linkifyPlugin } from './Plugins'

interface ITextEditorProps {
  text?: string
  isVisible?: boolean
  hasControls?: boolean
  editorTitle?: string
  closeEditMode?: () => void
  openEditMode?: () => void
  onSave?: (editorText: string, plainText: string) => void
  canEdit?: boolean
  defaultText?: string
  handleChange?: (currentValue: string, plainText: string) => void
  contentHeight?: string
}

const TextEditor = ({
  text = '',
  isVisible = true,
  hasControls = false,
  editorTitle = '',
  closeEditMode = (): void => undefined,
  openEditMode = (): void => undefined,
  onSave = (): void => undefined,
  canEdit = false,
  defaultText = '',
  handleChange,
  contentHeight = 'auto',
}: ITextEditorProps): ReactElement => {
  const { t } = useTranslation()
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const [scrollViewVisible, setScrollViewVisible] = useState(false)

  const [{ plugins, Toolbar }] = useState(() => {
    const createToolbar = createToolbarPlugin()
    const { Toolbar } = createToolbar
    const plugins = [createToolbar, linkPlugin, linkifyPlugin]
    return {
      plugins,
      Toolbar,
    }
  })

  const editorRef = useRef<Editor | null>(null)

  const convertToString = (currentState: ContentState): string => {
    const convertedRaw = convertToRaw(currentState)
    const stringified = JSON.stringify(convertedRaw)
    return stringified
  }

  const convertToEditorState = (jsonState: string): EditorState => {
    const rawContent = JSON.parse(jsonState)
    const contentState = convertFromRaw(rawContent)
    const newState = EditorState.createWithContent(contentState)
    return newState
  }

  const scrollToTop = (): void => {
    const editorDiv = document.querySelector('.DraftEditor-root')
    editorDiv?.scrollTo(0, 0)
  }

  const handleCancelClick = (): void => {
    const newState = convertToEditorState(text)
    setEditorState(newState)
    scrollToTop()
    closeEditMode()
  }

  const handleSaveClick = async (): Promise<void> => {
    const editorText = convertToString(editorState.getCurrentContent())
    const plainText = editorState.getCurrentContent().getPlainText('\u0001')
    onSave(editorText, plainText)
  }

  const handleEditorScroll = (): void => {
    const editorDiv = document.querySelector('.DraftEditor-root')
    if (!editorDiv) return
    const editorHeight = editorDiv.scrollHeight
    const extraHeight = editorHeight - 250
    const editorScrollTop = editorDiv.scrollTop
    if (editorScrollTop > extraHeight / 2) {
      setScrollViewVisible(false)
    } else {
      setScrollViewVisible(true)
    }
  }

  useEffect(() => {
    const editorDiv = document.querySelector('.DraftEditor-root')
    if (!editorDiv) return
    const editorHeight = editorDiv.scrollHeight
    const editorScrollTop = editorDiv.scrollTop

    if (editorHeight > 250 && editorScrollTop < 20) {
      setScrollViewVisible(true)
    } else {
      setScrollViewVisible(false)
    }

    editorDiv.addEventListener('scroll', handleEditorScroll)

    return (): void => editorDiv.removeEventListener('scroll', handleEditorScroll)
  }, [editorState])

  useEffect(() => {
    if (text) {
      const newState = convertToEditorState(text)
      setEditorState(newState)
    }
  }, [text])

  const isEmpty = !editorState.getCurrentContent().hasText()

  return (
    <Container>
      {hasControls && (
        <ContainerHeader>
          <div>{editorTitle && editorTitle}</div>
          <EditorActions>
            <EditorEditActions className={isVisible ? 'show' : ''}>
              <Button
                text={t('actions.cancel')}
                size='small'
                onClick={handleCancelClick}
                background='#E0E1E2'
                textColor='#414141'
              />
              <Button
                text={t('actions.save')}
                size='small'
                color='secondary'
                onClick={handleSaveClick}
                background='#06C68F'
              />
            </EditorEditActions>
            {canEdit && (
              <EditorActionsEditButton>
                <IconButton
                  disabled={isVisible}
                  onClick={(): void => {
                    openEditMode()
                    editorRef.current && editorRef.current.focus()
                  }}
                >
                  <EditIcon />
                </IconButton>

                <EditorActionsEditText>{t('actions.edit')}</EditorActionsEditText>
              </EditorActionsEditButton>
            )}
          </EditorActions>
        </ContainerHeader>
      )}
      <EditorContainer className={isVisible ? 'show' : ''}>
        {isVisible && editorRef.current && (
          <Toolbar>
            {(externalProps: IControlsProps): ReactElement => (
              <Controls externalProps={externalProps} />
            )}
          </Toolbar>
        )}
        <ContentContainer
          $contentHeight={contentHeight}
          onClick={(): void => {
            editorRef.current && editorRef.current.focus()
          }}
        >
          {isEmpty && !isVisible && (
            <DefaultText style={{ position: 'absolute', top: 10, left: 10 }}>
              {defaultText || t('general.nothing_display')}
            </DefaultText>
          )}
          <Editor
            editorState={editorState}
            onChange={(value): void => {
              setEditorState(value)
              if (handleChange) {
                const currentState = value.getCurrentContent()
                const currentValue = convertToString(currentState)
                const plainText = currentState.getPlainText('\u0001')
                handleChange(currentValue, plainText)
              }
            }}
            readOnly={!isVisible}
            plugins={plugins}
            ref={(editor): void => {
              editorRef.current = editor
            }}
          />
        </ContentContainer>
      </EditorContainer>
      {scrollViewVisible && !isVisible && (
        <ScrollView>
          <p style={{ color: '#06c68f' }}>{t('general.scroll_down_to_view')}</p>
        </ScrollView>
      )}
    </Container>
  )
}

export default TextEditor
