import { gql } from 'graphql.macro'

export const GET_ASSISTANT_FILES = gql`
  query GetAssistantFiles($assistantId: String!) {
    getAssistantFiles(assistantId: $assistantId) {
      _id
      description
      contentDocument {
        name
        fileType
        size
        link
        thumbnail
      }
    }
  }
`
