import React, { RefObject } from 'react'
import {
  Label,
  ActivityHeader,
  UserDetailsContainer,
  Tracker,
  LoaderContainer,
  ActivitiesContainer,
  ActivityWrapper,
  UserName,
  LeaderBoardContainer,
  LeaderBoardHeader,
  LeaderBoardItem,
} from './styled-components'
import { useParams } from 'react-router-dom'
import { ReactComponent as CourseLevelSvg } from 'pages/courses/CourseDetail/Svg/CourseLevel.svg'
import { GET_USER_ACTIVITIES } from 'gql/activity/activity.query'
import { useQuery } from '@apollo/client'
import TimeLine from './Timeline'
import MyLoader from 'loading/loading'
import Breadcrumb from 'components/common/Breadcrumb'
import { EditableAvatar } from 'components/common/EditableAvatar/EditableAvatar'
import { useUpdateProfileAvatar } from 'hooks/users/useEditProfileService'
import { ReactCropperElement } from 'react-cropper'
import { ActivityIndicator } from './activity.interface'
import { useTranslation } from 'react-i18next'

const ActivityTracker = (): React.JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const { data, loading } = useQuery(GET_USER_ACTIVITIES, {
    variables: {
      userId: id,
    },
  })
  const userActivities = (data && data.getUserActivities) || []
  const user = userActivities && userActivities.user

  const breadCrumbData = [
    {
      label: `${user ? user.firstName + "'s Profile" : 'User Profile'}`,
      link: `/profile/${id}`,
    },
    {
      label: 'Activity Tracker',
      link: '',
    },
  ]

  const { updateAvatar, hasResponseReceived } = useUpdateProfileAvatar()

  const finishCrop = (
    action: string,
    crudFunction: (isFinished: boolean) => void,
    cropper: RefObject<ReactCropperElement>,
  ): void => {
    let croppedFile = null
    if (action === 'update') {
      croppedFile = cropper.current?.cropper.getCroppedCanvas().toDataURL()
    }
    if (id) updateAvatar(croppedFile as string, id, action, crudFunction)
  }

  const renderIndicator = (indicator: string): React.JSX.Element => {
    switch (indicator) {
      case ActivityIndicator.PROGRESS:
        return <span className='progress'>{t('activity.progress')} ↑</span>
      case ActivityIndicator.STABLE:
        return <span className='stable'>{t('activity.stable')} - </span>
      case ActivityIndicator.REGRESS:
        return <span className='regress'>{t('activity.regress')} ↓</span>
      default:
        return <span className='stable'>{t('activity.stable')} - </span>
    }
  }

  if (loading) {
    return (
      <LoaderContainer>
        <MyLoader speed={0.8} />
      </LoaderContainer>
    )
  }

  return (
    <>
      <Breadcrumb data={breadCrumbData} backBtn={false} />
      <ActivitiesContainer>
        <UserDetailsContainer>
          <EditableAvatar
            img={user.avatar}
            finishCrop={finishCrop}
            hasResponseReceived={hasResponseReceived}
          />
          <UserName>
            {user.firstName} {user.lastName}
          </UserName>
          <LeaderBoardContainer>
            <LeaderBoardHeader>LeaderBoard {<CourseLevelSvg />}</LeaderBoardHeader>
            <LeaderBoardItem>
              <span>Position in Company LeaderBoard:</span>
              <span>{user.leaderboard.companyPosition || t('n/a')}</span>
            </LeaderBoardItem>
            <LeaderBoardItem>
              <span>Position in Global LeaderBoard:</span>
              <span>{user.leaderboard.globalPosition || t('n/a')}</span>
            </LeaderBoardItem>
            <LeaderBoardItem>
              <span>Current LeaderBoard Status:</span>
              {renderIndicator(user.leaderboard.indicator)}
            </LeaderBoardItem>
          </LeaderBoardContainer>
        </UserDetailsContainer>
        <ActivityWrapper>
          <ActivityHeader>
            <Label>Activity Tracker</Label>
          </ActivityHeader>
          <Tracker>
            <TimeLine list={userActivities.activity} />
          </Tracker>
        </ActivityWrapper>
      </ActivitiesContainer>
    </>
  )
}
export default ActivityTracker
