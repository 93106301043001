import { DrawerEventEmitter } from 'helpers/drawer'
import { IUseBookDetails } from './useBookDetails.interface'
import { useSwal } from 'hooks/useSwal'
import { useDeleteBookService } from 'services/books/deleteBookService'
import { useTranslation } from 'react-i18next'
import { useRemoveBooksFromGroupService } from 'services/books/removeBookFromGroupService'

const useBookDetails = (companyId: string, groupId?: string): IUseBookDetails => {
  const { fireSwal } = useSwal()
  const { t } = useTranslation()

  const { deleteBook } = useDeleteBookService({ companyId })

  const { removeBooksFromGroup } = useRemoveBooksFromGroupService({ groupId })

  const handleDeleteBookFromGroup = (bookId: string): void => {
    if (!groupId) return
    fireSwal({
      title: t('popups.delete_book_from_group'),
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => removeBooksFromGroup({ bookIds: [bookId], groupId }),
    })
  }

  const handleEditBook = (bookId: string): void => {
    DrawerEventEmitter.emit('openDrawer', 'editBook', true, {
      bookId,
      companyId,
    })
  }

  const handleDeleteBook = (bookId: string): void => {
    fireSwal({
      title: t('popups.delete_book'),
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => deleteBook(bookId),
    })
  }

  return {
    handleEditBook,
    handleDeleteBook,
    handleDeleteBookFromGroup,
  }
}

export default useBookDetails
