import { useQuery } from '@apollo/client'

import { GET_STUDENTS_QUIZ_RESULTS } from 'gql/group/group.query'
import { IStudent } from 'pages/courses/CourseQuizzes/courseQuizzes.interface'
interface IGroupProps {
  groupId: string | undefined
  groupsIds: string[] | undefined
  courseId: string
  type: 'quiz' | 'iq_test'
  filter: object
  skip: number
  limit: number
}

interface IUseGetStudentsQuizResultsRefetch {
  groupId: string
  courseId: string
  skip: number
  limit: number
  type: 'quiz' | 'iq_test'
  filter: object
}

export interface IUseGetStudentsQuizResultsReturnType {
  data: {
    data: IStudent[]
    currentPage: number
    totalCount: number
  }
  loading: boolean
  error: string | undefined
  refetch: (variables?: IUseGetStudentsQuizResultsRefetch) => void
}

export const useGetStudentsQuizResults = ({
  groupId,
  groupsIds,
  courseId,
  type,
  filter,
  skip = 1,
  limit = 10,
}: IGroupProps): IUseGetStudentsQuizResultsReturnType => {
  const { data, loading, error, refetch } = useQuery(GET_STUDENTS_QUIZ_RESULTS, {
    variables: {
      // pass groupId only if it is a single group, otherwise don't pass anything to query all groups
      ...(groupId ? { groupsIds: [groupId] } : { groupsIds }),
      courseId,
      skip,
      limit,
      type,
      filter: { ...filter },
    },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    data: data && data.getStudentsQuizResults,
    loading,
    error: errorMessage,
    refetch,
  }
}
