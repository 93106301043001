import styled from 'styled-components'

export const StyledHLSButton = styled.button`
  background: #08c694;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 24px 10px;
  transition: all 0.3s;
  font-size: 16px;

  &:hover {
    background: #12a37a;
  }

  &:disabled {
    background: #b3b3b8;
    cursor: not-allowed;
  }
`
