import React from 'react'
import {
  SelectContainer,
  Label,
  StyledSelectField,
  Entries,
  StyledSelectFieldContainer,
  IconContainer,
} from './styled-component'
import { ISelectFieldProps } from './selectDropDown.interface'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const SelectField: React.FC<ISelectFieldProps> = ({ value, onChange, options }) => (
  <SelectContainer>
    <Label>Show</Label>
    <StyledSelectFieldContainer>
      <StyledSelectField value={value} onChange={onChange}>
        {options.map((option, index) => (
          <option key={index} value={option.value as string}>
            {option.label}
          </option>
        ))}
      </StyledSelectField>
      <IconContainer>
        <ArrowDropDownIcon />
      </IconContainer>
    </StyledSelectFieldContainer>
    <Entries>Entries</Entries>
  </SelectContainer>
)

export default SelectField
