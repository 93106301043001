import { Dispatch, ReactElement, SetStateAction, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { TFunction, useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'

import config from 'config'
import { useAssistantMessages } from 'services/assistant/assistant'
import { useGetCurrentUser } from 'services/users/getCurrentUser'
import { IFile } from 'services/assistant/file/getAssistantFiles'
import { GET_COURSE } from 'gql/course/course.query'
import { useSwal } from 'hooks/useSwal'
import { useUpdateUserAssistant } from 'hooks/course/useEditCourse'

interface IKeyValuePair {
  [key: string]: string
}

export interface IAIAssistantReturnType {
  files: IFile[]
  createButton: () => ReactElement
  t: TFunction<'translation', undefined>
  setAddFileDrawerOpened: Dispatch<SetStateAction<boolean>>
  addFileDrawerOpened: boolean
  assistantId: string
  handleDelete: (_id: string) => void
}

export interface IMessage {
  _id: string
  content: string
  role: string
  createdAt: number
  __typename: string
}

interface IAIAssistantChatReturnType {
  response: string
  responseLoading: boolean
  message: string
  messagesLoading: boolean
  setMessage: (message: string) => void
  messages: IMessage[]
  userMessageStyles: IKeyValuePair
  assistantMessageStyles: IKeyValuePair
  scrollRef: React.RefObject<HTMLDivElement>
  handleFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void
  selectedFiles: FileList | null
  removeFile: (index: number) => void
  assistant:
    | {
        assistantId: string
        threadId: string
        isUserNotifiedAboutReTraining: boolean
      }
    | undefined
  handleConfirmUpdate: () => Promise<void>
}

const useAIAssistantChat = (): IAIAssistantChatReturnType => {
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null)
  const [message, setMessage] = useState('')
  const [response, setResponse] = useState('')
  const [responseLoading, setResponseLoading] = useState(false)
  const { id: courseId } = useParams()
  const { data: courseData } = useQuery(GET_COURSE, {
    variables: { courseId },
  })
  const { currentUser, refetch } = useGetCurrentUser()
  const { fireSwal } = useSwal()
  const { t } = useTranslation()
  const { updateUserAssistant } = useUpdateUserAssistant()

  const course = courseData?.getCourseById
  const assistant = currentUser?.assistants?.find(
    assistant => assistant.assistantId === course?.assistantId,
  )

  const {
    messages,
    loading: messagesLoading,
    refetch: refetchMessages,
  } = useAssistantMessages({
    assistantId: assistant?.assistantId || '',
    filter: {
      limit: 10,
    },
  })

  const scrollRef = useRef<HTMLDivElement>(null)

  const handleConfirmUpdate = async (): Promise<void> => {
    fireSwal({
      title: t('AI_assistant.update_assistant_data'),
      text: t('AI_assistant.update_assistant_data_description'),
      icon: 'info',
      showCancelButton: true,
      showConfirmButton: true,
      confirmText: t('actions.confirm_update'),
      cancelText: t('actions.cancel'),
      onConfirm: () =>
        updateUserAssistant({ assistantId: assistant?.assistantId || '', callback: refetch }),
    })
  }

  const scrollToBottom = (behavior: 'auto' | 'instant' | 'smooth' | undefined = 'smooth'): void => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: behavior,
      })
    }
    window.scroll({ top: document.body.scrollHeight, behavior: behavior })
  }

  function fileArrayToFileList(files: File[]): FileList {
    const dataTransfer = new DataTransfer()
    files.forEach(file => dataTransfer.items.add(file))
    return dataTransfer.files
  }

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>): void => {
    // move out assigning event.target.files to a variable to avoid stale closure
    const newFiles = event.target.files ? Array.from(event.target.files) : []
    event.target.value = '' // Clear the input field to allow selecting the same file again
    setSelectedFiles(prevState => {
      const existingFiles = prevState ? Array.from(prevState) : []
      return fileArrayToFileList([...existingFiles, ...newFiles])
    })
  }

  const removeFile = (index: number): void => {
    const updatedFiles = Array.from(selectedFiles || [])
    updatedFiles.splice(index, 1)
    setSelectedFiles(fileArrayToFileList(updatedFiles))
  }

  // Scroll to bottom when new messages are loaded, or when a new response is received in chunks
  useEffect(() => {
    if (!response && messages.length > 0) scrollToBottom()
    if (response && !message) scrollToBottom('instant')
  }, [messages, response, message, messagesLoading])

  useEffect(() => {
    scrollToBottom()
  }, [selectedFiles])

  const handleSendMessage = async (): Promise<void> => {
    setResponseLoading(true)

    try {
      const formData = new FormData()
      formData.append('prompt', message)
      formData.append('assistantId', course?.assistantId || '')
      formData.append('userId', String(currentUser.id))

      if (selectedFiles) {
        // Append each file to the formData object
        Array.from(selectedFiles).forEach(file => {
          formData.append('files', file) // 'files' should match the backend field name
        })
      }

      const responseStream = await fetch(`${config.APIURL}/assistant/create-run`, {
        method: 'POST',
        headers: {
          // Do not set 'Content-Type' manually
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
      })

      setMessage('') // Clear input field
      setSelectedFiles(null) // Clear selected files

      if (!responseStream.body) {
        throw new Error('ReadableStream not supported in this browser.')
      }

      const reader = responseStream.body.getReader()
      const decoder = new TextDecoder()

      let firstIteration = true
      let done = false
      setResponse('') // Clear previous responses
      while (!done) {
        if (firstIteration) {
          refetchMessages({
            assistantId: assistant?.assistantId || '',
            filter: {
              limit: 10,
            },
          })
          scrollToBottom()
          firstIteration = false
        }
        const { value, done: streamDone } = await reader.read()
        done = streamDone
        // refetch the current user to update the assistant thread if the stream is done and thread id was not provided
        if (streamDone && !assistant?.threadId && refetch) {
          refetch()
        }
        const chunk = decoder.decode(value, { stream: true })
        setResponseLoading(false)
        setResponse(prev => prev + chunk)
      }
    } catch (error) {
      console.error('Error:', error)
      setResponseLoading(false)
      setResponse('Error processing your request')
    }
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent): void => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault() // Prevent default behavior such as form submission
        if (message.trim()) {
          handleSendMessage()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return (): void => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [message]) // Add message as a dependency

  const userMessageStyles = {
    alignSelf: 'flex-end',
    backgroundColor: '#009CE5',
    color: '#fff',
  }

  const assistantMessageStyles = {
    alignSelf: 'flex-start',
    backgroundColor: '#E9E9E9',
    color: '#000',
  }

  return {
    messages,
    response,
    responseLoading,
    message,
    messagesLoading,
    setMessage,
    userMessageStyles,
    assistantMessageStyles,
    scrollRef,
    handleFileSelect,
    selectedFiles,
    removeFile,
    assistant,
    handleConfirmUpdate,
  }
}

export default useAIAssistantChat
