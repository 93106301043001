import React, { ReactElement } from 'react'
import styled from 'styled-components'

interface IProfilePictureProps {
  imgSource?: string
  width: number
  shadow?: boolean
  height: number
}

const ProfilePicture = ({
  imgSource,
  shadow,
  width,
  height,
}: IProfilePictureProps): ReactElement => (
  <ProfilePictureWrapper shadow={shadow} width={width} height={height}>
    <img src={imgSource} alt={imgSource} />
  </ProfilePictureWrapper>
)

export default ProfilePicture

const ProfilePictureWrapper = styled.figure<IProfilePictureProps>`
  overflow: hidden;
  border-radius: 50%;
  width: ${({ width }): string => `${width}px;`}
  height: ${({ height }): string => `${height}px;`}
  box-shadow: ${({ shadow }): string => (shadow ? `0px 10px 10px rgba(0,0,0,0.2);` : `none`)}

`
