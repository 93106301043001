import React, { ReactElement } from 'react'

/**
 * Cuts a string to a specified length and optionally adds ellipsis.
 * @param {string} str - The string to cut.
 * @param {number} len - The length to cut the string to.
 * @param {boolean} r - Whether to add ellipsis or not.
 * @returns {string} - The cut string.
 */
const strCutter = (str = '', len = 20, r = false): ReactElement => (
  <>
    {typeof str === 'string' && str.length > len
      ? `${str.substring(0, len)}${r ? '' : '...'}`
      : str}
  </>
)

export default strCutter
