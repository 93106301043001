import React, { ReactElement } from 'react'

import { useFetchRejection, useSetRejectionState } from 'hooks/rejections/useRejections'
import { useSwal } from 'hooks/useSwal'

import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { DrawerHeader, DrawerTitle, DrawerContent } from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import { CapitalizeWrapper, RejectionContent, RejectionStateWrapper } from './styled-components'
import Loader from 'components/common/Loader'
import RejectionDetailContent from './RejectionDetailContent'
import { IRejection } from 'interfaces/rejections'

interface Params {
  onClose: () => void
  data: IRejection
}

const RejectionDetailsDrawer = ({ onClose, data }: Params): ReactElement => {
  const { fireSwal } = useSwal()
  const {
    rejection,
    error: fetchRejectionError,
    refetch,
  } = useFetchRejection(data.actionId as string)
  const { setRejectionState, loading } = useSetRejectionState()

  const handleApproveRejectRejectionClick = (action: string): void => {
    console.log('action', action)
    let title = ''
    let confirmText = ''

    if (action === 'APPROVE') {
      title = 'Are You Sure you want to Approve ?'
      confirmText = 'Approve'
    }

    if (action === 'REJECT') {
      title = 'Are You Sure you want to Reject?'
      confirmText = 'Reject'
    }

    fireSwal({
      title,
      text: 'text',
      confirmText: `Yes, ${confirmText}`,
      onConfirm: () => {
        if (!rejection) return
        setRejectionState(rejection.id as string, action, refetch)
      },
    })
  }

  const rejectionType = data.message?.split(' ').slice(-1)[0]

  const renderTitle = (actionName: string): string => {
    let title = ''

    if (actionName === 'Post') {
      title = `${data.actionName} Marked as ${rejectionType}`
    }
    if (actionName === 'User') {
      title = `User Reported`
    }

    return title
  }

  return (
    <>
      {loading && <Loader />}
      <DrawerHeader>
        <DrawerTitle>{renderTitle(data.actionName as string)}</DrawerTitle>
        <IconButton onClick={onClose}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent headerHeight={68}>
        {rejection && (
          <FormContainer>
            <FormGroupWrapper>
              <RejectionContent>
                <RejectionDetailContent data={rejection} />
              </RejectionContent>
            </FormGroupWrapper>
            <FormFooter>
              <FormButtons>
                {rejection.state === 'PENDING' ? (
                  <>
                    <Button
                      variant='outlined'
                      size='small'
                      style={{
                        color: '#06C68F',
                        border: '1px solid #06C68F',
                        marginRight: '10px',
                      }}
                      onClick={(): void => handleApproveRejectRejectionClick('APPROVE')}
                    >
                      Accept
                    </Button>
                    <Button
                      variant='outlined'
                      color='secondary'
                      onClick={(): void => handleApproveRejectRejectionClick('REJECT')}
                    >
                      Reject
                    </Button>
                  </>
                ) : (
                  <RejectionStateWrapper
                    color={rejection.state === 'APPROVED' ? '#06C68F' : '#f50057'}
                  >
                    This Report is Marked as{' '}
                    <CapitalizeWrapper>{rejection.state?.toLowerCase()}</CapitalizeWrapper>
                  </RejectionStateWrapper>
                )}
              </FormButtons>
            </FormFooter>
          </FormContainer>
        )}
        {fetchRejectionError && <div style={{ padding: 10 }}>{fetchRejectionError}</div>}
      </DrawerContent>
    </>
  )
}

export default RejectionDetailsDrawer
