import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react'
import {
  Header,
  Title,
  Toolbar,
  FormButtons,
  FormTitle,
  FormFooter,
  FormFieldsWrapper,
  ModulesList,
} from './styled-components'
import { useQuery } from '@apollo/client'
import { useSwal } from 'hooks/useSwal'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'

import { TOGGLE_CLOSE_DRAWER } from 'store/types'
import { GET_MODULES } from 'gql/modules.query'
import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import FilterListIcon from '@mui/icons-material/FilterList'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { DrawerHeader, DrawerContent } from 'components/DrawerLayout/styled-components'
import { MultipleAction, MultipleActionTooltip } from 'components/common/Grid/styled-components'

import Drawer from 'components/common/Drawer'
import FilterDrawer from 'pages/modules/FilterDrawer/FilterDrawer'
import { Button } from 'components/common/Button'
import TextInput from 'components/common/TextInput/TextInput'
import { CardsLayout } from 'components/common/Cards'
import Modal from 'components/common/Modal'
import VideoPlayer from 'components/common/VideoPlayer'
import { IModule } from 'pages/modules/ModulesList.interface'
import { IVideo } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'
import { IFilterData } from 'pages/modules/FilterDrawer/filterDrawer.interface'

interface IAddModuleToQuestionDrawerProps {
  title: string
  closeDrawer: () => void
  selectedModules: IModule[]
  setSelectedModules: Dispatch<SetStateAction<IModule[]>>
}

interface IModuleItem {
  id: string
  name: string
}

const filterOption: IFilterData = {
  name: '',
  coverImage: { label: 'All', id: 0, value: 'all' },
  video: { label: 'All', id: 0, value: 'all' },
  attachment: { label: 'All', id: 0, value: 'all' },
  range: [0, 5000],
}

const AddModuleToQuestionDrawer = ({
  title,
  closeDrawer,
  selectedModules,
  setSelectedModules,
}: IAddModuleToQuestionDrawerProps): ReactElement => {
  const { t } = useTranslation()
  const { data: modulesData, loading, refetch } = useQuery(GET_MODULES)

  const { fireSwal } = useSwal()
  const [, dispatch] = useUserValue()
  const [filterData, setFilterData] = useState<IFilterData>(filterOption)
  const [filtered, setFiltered] = useState<boolean>(false)
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [videoModal, setVideoModal] = useState({ modal: false, data: '' })
  const [selectedItems, setSelectedItems] = useState<string[]>([])

  const selectItem = (id: string): void => {
    const selectedIndex = selectedItems.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1))
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1),
      )
    }

    if (newSelected.length > 0) {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: t('popups.close_popup'),
          confirm: true,
        },
      })
    } else {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: '',
          confirm: false,
        },
      })
    }

    setSelectedItems(newSelected)
  }

  const handleCloseDrawer = (): void => {
    if (selectedItems.length === 0) {
      closeDrawer()
    } else {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        confirmText: 'Yes, Cancel!',
        onConfirm: () => closeDrawer(),
      })
    }
  }

  const handleSubmit = (): void => {
    const moduleList = modulesData.allModules.reduce(
      (modules: { moduleId: string; name: string }[], item: IModuleItem) => {
        if (selectedItems.includes(item.id)) {
          modules.push({
            moduleId: item.id,
            name: item.name,
          })
        }
        return modules
      },
      [] as { moduleId: string; name: string }[],
    )

    setSelectedModules([...selectedModules, ...moduleList])
    closeDrawer()
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value)

    if (e.target.value === '') {
      refetch({
        filter: {
          name: {
            type: 'match',
            value: '',
          },
        },
      })
    }
  }

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    refetch({
      filter: {
        name: {
          type: 'match',
          value: searchValue,
        },
      },
    })
  }

  const handleResetClick = (): void => {
    refetch({
      filter: {
        name: { type: 'match', value: '' },
      },
    })
    setFiltered(false)
    setSearchValue('')
  }

  const handleVideoModal = (item?: IModule): void => {
    setVideoModal({
      ...videoModal,
      modal: !videoModal.modal,
      data:
        !videoModal.modal && item && item.video
          ? ((item.video as IVideo[])[0] as IVideo).links[2].url
          : '',
    })
  }

  const selectedModuleIds = selectedModules.map(module => module.moduleId) as string[]

  return (
    <>
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={(): void => setFilterDrawerOpened(false)}
        totalWidth='500px'
      >
        <FilterDrawer
          filterData={filterData}
          setFilterData={setFilterData}
          refetchData={refetch}
          onClose={(): void => setFilterDrawerOpened(false)}
          setFiltered={setFiltered}
          type='nestedArrayNotIn'
          setFilterQuery={setFiltered}
          perPage={12}
          t={t}
        />
      </Drawer>
      <DrawerHeader>
        <FormTitle>{title}</FormTitle>
        <IconButton onClick={handleCloseDrawer}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <ModulesList>
          <FormFieldsWrapper>
            <Header
              style={{
                marginTop: 20,
                borderBottom: '1px solid #ccc',
                paddingBottom: 8,
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title>Module list</Title>
              </div>
              <Toolbar>
                <div style={{ display: 'flex' }}>
                  <MultipleAction>
                    <IconButton
                      disabled={filtered || searchValue ? false : true}
                      onClick={handleResetClick}
                    >
                      <SyncIcon fontSize='small' />
                    </IconButton>
                    <MultipleActionTooltip>Reset</MultipleActionTooltip>
                  </MultipleAction>
                  <MultipleAction>
                    <IconButton onClick={(): void => setFilterDrawerOpened(true)}>
                      <FilterListIcon fontSize='small' />
                    </IconButton>
                    <MultipleActionTooltip>Filter</MultipleActionTooltip>
                  </MultipleAction>
                  <form onSubmit={handleSearchSubmit} style={{ marginRight: 10 }}>
                    <TextInput
                      label='Search'
                      type='text'
                      size='small'
                      value={searchValue}
                      onChange={handleSearchChange}
                      icon={
                        <IconButton type='submit'>
                          <SearchRoundedIcon />
                        </IconButton>
                      }
                    />
                  </form>
                </div>
              </Toolbar>
            </Header>
            {loading && <div>Loading...</div>}
            {modulesData && (
              <div style={{ padding: 10 }}>
                <CardsLayout
                  gridStyle={'card'}
                  data={modulesData.allModules}
                  noRecordsText='No Records found'
                  handleVideoModal={handleVideoModal}
                  selectItem={selectItem}
                  selectedModuleIds={selectedModuleIds}
                  selectedItems={selectedItems}
                  refetch={refetch}
                  cols={2}
                  t={t}
                />
              </div>
            )}
          </FormFieldsWrapper>
          <FormFooter>
            <FormButtons>
              <Button text='Cancel' type='small' onClick={handleCloseDrawer}></Button>
              <Button
                text='Add'
                type='small'
                color='secondary'
                onClick={handleSubmit}
                isDisabled={selectedItems.length === 0}
              ></Button>
            </FormButtons>
          </FormFooter>
        </ModulesList>
      </DrawerContent>
      <Modal isOpened={videoModal.modal} onClose={handleVideoModal as () => void}>
        <VideoPlayer url={videoModal.data} />
      </Modal>
    </>
  )
}

export default AddModuleToQuestionDrawer
