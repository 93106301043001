import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { SET_REJECTION_STATE } from 'gql/rejections/rejections.query'
import { IRejection } from '../../interfaces/rejections'

interface ISetRejectionStateServiceResponse {
  setRejectionStateMutation: MutationTuple<ISetRejectionStateResponse, ISetRejectionStateParams>[0]
  loading: boolean
  error?: ApolloError
}

interface ISetRejectionStateResponse {
  setRejectionState: IRejection
}

interface ISetRejectionStateParams {
  id: string
  action: string
}

export const setRejectionStateService = (): ISetRejectionStateServiceResponse => {
  const [setRejectionStateMutation, { loading, error }] = useMutation<
    ISetRejectionStateResponse,
    ISetRejectionStateParams
  >(SET_REJECTION_STATE)

  return {
    setRejectionStateMutation,
    loading,
    error,
  }
}
