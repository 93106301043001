import deleteCompanyService from 'services/companies/deleteCompanyService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import { ApolloError } from '@apollo/client'

const useDeleteCompanyService = (): {
  deleteCompany: (ids: string[], onSuccess: () => void) => Promise<void>
  deleteCompanyLoading: boolean
  error: ApolloError | undefined
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const { deleteCompanyMutation, loading, error } = deleteCompanyService()

  const deleteCompany = async (ids: string[], onSuccess: () => void): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }
    const { errors } = await deleteCompanyMutation({
      variables: { ids },
    })
    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      onSuccess()
      message = `${t('general.company')} ${t('messages.deleted')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    deleteCompany,
    deleteCompanyLoading: loading,
    error,
  }
}

export default useDeleteCompanyService
