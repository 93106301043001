// @deprecated
/* eslint-disable */

import React from 'react'
import { Container, ButtonItem, ButtonContainer } from './styled-components'
import { FormFields, FormField } from 'components/common/Form/Form'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import { SelectField } from 'components/common/SelectField'
import useGroupForm from 'hooks/group/useGroupForm/useGroupForm'
import { IEditGroupDrawerProps } from './editGroup.interface'

const EditGroup = ({
  item,
  onCancel,
  companyId,
  adminList,
}: IEditGroupDrawerProps): JSX.Element => {
  const {
    errors,
    touched,
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    loading,
    t,
    courseData,
    adminList: admins,
  } = useGroupForm({
    companyId,
    onClose: () => undefined,
    afterSave: () => undefined,
    item,
    groupId: item?.id,
    adminList,
  })

  return (
    <Container>
      <FormFields>
        <FormField>
          <TextInput
            error={!!errors.name}
            errorMessage={errors.name}
            touched={touched.name}
            label={`${t('form_fields.name')}*`}
            name='name'
            size='small'
            type='text'
            value={values.name}
            onChange={handleChange}
          />
        </FormField>

        <FormField>
          <TextInput
            label={t('form_fields.location')}
            name='location'
            size='small'
            type='text'
            value={values.location}
            onChange={handleChange}
          />
        </FormField>

        <FormField style={{ position: 'relative' }}>
          <SelectField
            error={!!errors.admin}
            errorMessage={errors.admin}
            touched={touched.admin}
            placeholder={`${t('general.admin')}*`}
            name='admin'
            options={admins}
            value={values.admin}
            onChange={(e: string): void => setFieldValue('admin', e)}
            isClearable
          />
        </FormField>

        <FormField style={{ position: 'relative' }}>
          <SelectField
            error={!!errors.courses}
            errorMessage={errors.courses}
            touched={touched.courses}
            placeholder={`${t('general.courses')}*`}
            name='courses'
            options={courseData}
            value={values.courses}
            onChange={(e: string): void => setFieldValue('courses', e)}
            isClearable
            isMulti
          />
        </FormField>

        <FormField>
          <TextInput
            label={t('form_fields.note')}
            size='small'
            name='note'
            type='text'
            multiline
            rows='3'
            value={values.note}
            onChange={handleChange}
            fullWidth={true}
          />
        </FormField>

        <FormField>
          <ButtonContainer>
            <ButtonItem style={{ marginRight: '10px' }}>
              <Button
                text={t('actions.cancel')}
                type='small'
                onClick={onCancel}
                background='#E0E1E2'
                textColor='#414141'
              />
            </ButtonItem>

            <ButtonItem>
              <Button
                text={t('actions.save')}
                type='small'
                color='secondary'
                onClick={handleSubmit}
                isDisabled={loading}
                background='#06C68F'
              />
            </ButtonItem>
          </ButtonContainer>
        </FormField>
      </FormFields>
    </Container>
  )
}

export default EditGroup
