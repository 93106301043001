import styled from 'styled-components'

// AddSkillTestVideoDrawer

export const SkilTestDetailsFieldTitle = styled.label`
  display: block;
  width: 100%;
  font-size: 16px;
  margin-bottom: 10px;
`
