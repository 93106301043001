import React, { ReactElement } from 'react'
import { CssBaseline } from '@mui/material'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'

import { DataProvider } from './context/DataContext'
import { UserProvider } from './context/UserContext'
import { dataInitialState, dataReducer } from 'store/reducers/dataReducer'
import { userInitialState, userReducer } from 'store/reducers/userReducer'
import { MainRoutes } from 'routes'
import './i18n/index.js'

const App = (): ReactElement => (
  <DataProvider initialState={dataInitialState} reducer={dataReducer}>
    <UserProvider initialState={userInitialState} reducer={userReducer}>
      <CssBaseline />
      <SnackbarProvider>
        <BrowserRouter>
          <MainRoutes />
        </BrowserRouter>
      </SnackbarProvider>
    </UserProvider>
  </DataProvider>
)

export default App
