import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ItemContainer,
  CheckboxContainer,
  ColorContainer,
  AvatarContainer,
  TitleContainer,
  ContentContainer,
  ToTalCountBlock,
  ListContainer,
  ListItemContainer,
  CourseInfo,
  ButtonContainer,
  PlatformServiceIndicator,
} from './styled-components'

import BusinessIcon from '@mui/icons-material/Business'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import CheckBox from 'components/common/Checkbox'
import { useTranslation } from 'react-i18next'
import { SET_SELECTED_COMPANY } from 'store/types'
import { setSelectedCompanyInLocalStorage } from 'helpers/localStorage'
import { useUserValue } from 'context/UserContext'
import { ICompanyItemProps } from './companyItem.interface'
import { CompanyStatusIndicator } from 'components/common/StatusIndicator/StatusIndicator'
import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'

const CompanyItem = ({
  company,
  selectItem,
  selectedItem,
  actions,
  selectedCompanyId,
  setSelectedCompanyId,
}: ICompanyItemProps): ReactElement => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [, dispatch] = useUserValue()

  return (
    <ItemContainer
      className='company-container'
      key={company.id}
      onClick={(): void => {
        company.finished === 7
          ? navigate(`/companies/${company.id}`)
          : navigate(`/create-company/${company.id}`)
      }}
    >
      <ColorContainer>
        <CheckboxContainer>
          <CheckBox
            onChange={(): void => selectItem(company.id)}
            checked={selectedItem.includes(company.id)}
          />
          <CompanyStatusIndicator $isActive={company.status === GridStatusEnums.ACTIVE} />
        </CheckboxContainer>
        <div>
          <GridActionMenu actionConfig={actions} row={company} />
        </div>
      </ColorContainer>

      <ContentContainer>
        <ToTalCountBlock>
          <AvatarContainer>
            {company.avatar || company.avatarThumbnail ? (
              <img src={company.avatarThumbnail || company.avatar} />
            ) : (
              <BusinessIcon
                style={{
                  fontSize: '5em',
                  color: '#06C68F',
                }}
              />
            )}
          </AvatarContainer>
          <TitleContainer>
            <div>{company.name}</div>
            <PlatformServiceIndicator $isActive={company.platformService}>
              {t('companies_layout.platform_service')}{' '}
              {company.platformService ? t('general.enabled') : t('general.disabled')}
            </PlatformServiceIndicator>
          </TitleContainer>
        </ToTalCountBlock>

        <ToTalCountBlock>
          <CourseInfo>{`${t('general.suada_courses')}: ${company.suadaCoursesCount}`}</CourseInfo>
          <CourseInfo>
            {`${t('general.company_courses')}: ${company.internalCoursesCount}`}
          </CourseInfo>
        </ToTalCountBlock>

        <ToTalCountBlock>
          <ListContainer style={{ width: '60%' }}>
            <ListItemContainer>
              <span>{t('general.groups')}:</span>
              <span className='count'>{company.groupsCount}</span>
            </ListItemContainer>
            <ListItemContainer>
              <span>{t('general.students')}:</span>
              <span className='count'>{company.studentsCount}</span>
            </ListItemContainer>
            <ListItemContainer>
              <span>{t('general.admins')}:</span>
              <span className='count'>{company.groupAdminsCount}</span>
            </ListItemContainer>
            <ListItemContainer>
              <span>{t('general.company_coaches')}:</span>
              <span className='count'>{company.internalCoachesCount}</span>
            </ListItemContainer>
            <ListItemContainer>
              <span>{t('general.assigned_coaches')}:</span>
              <span className='count'>{company.assignedCoachesCount}</span>
            </ListItemContainer>
          </ListContainer>
        </ToTalCountBlock>

        {company.id !== selectedCompanyId ? (
          <ButtonContainer
            onClick={(e): void => {
              e.stopPropagation()
              dispatch({
                type: SET_SELECTED_COMPANY,
                payload: {
                  id: company.id,
                  name: company.name,
                },
              })
              setSelectedCompanyId(company.id)
              setSelectedCompanyInLocalStorage({
                id: company.id,
                name: company.name,
              })
            }}
          >
            <div>{t('general.switch')}</div>
          </ButtonContainer>
        ) : (
          <ButtonContainer
            $switched
            onClick={(e): void => {
              e.stopPropagation()
              dispatch({
                type: SET_SELECTED_COMPANY,
                payload: null,
              })
              setSelectedCompanyId('')
              setSelectedCompanyInLocalStorage(null)
            }}
          >
            <div>{t('general.switched')}</div>
          </ButtonContainer>
        )}
      </ContentContainer>
    </ItemContainer>
  )
}

export default CompanyItem
