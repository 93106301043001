import React, { useState, useEffect, SyntheticEvent } from 'react'
import axios, { AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import { FlashCardSchema } from 'helpers/validationSchemas'
import { useData } from 'context/DataContext'
import { useTranslation } from 'react-i18next'
import { images } from 'pages/modules/FlashCards/images'
import { useUploadImage, useUploadAudioService } from 'hooks/helpers/useHelpersService'
import { useFetchFlashCard } from 'hooks/modules/useModules'
import useEditFlashCardService from 'hooks/modules/useEditFlashCardService'
import { IFlashCardDrawerParams } from 'pages/modules/FlashCards/interfaces'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import {
  IEditFlashCard,
  IUseEditFlashCardDrawerReturn,
  formData,
} from './useEditFlashCardDrawer.interface'
import { IAudio } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'
import { IFlashCard } from '../FlashCardsLayout/useModuleDetailContent.interface'

const useEditFlashCardDrawer = ({
  onClose,
  data,
}: IFlashCardDrawerParams): IUseEditFlashCardDrawerReturn => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const { editFlashCard, editFlashCardLoading } = useEditFlashCardService()

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue, setValues } =
    useFormik({
      initialValues: formData,
      validationSchema: FlashCardSchema,
      onSubmit(values) {
        values.audio = (values.audio as IAudio)?.link || null
        editFlashCard(data.id as string, values, () => {
          if (data.refetch) data.refetch()
          onClose()
        })
      },
    })

  const [state, dispatch] = useData()
  const [openedModal, setOpenedModal] = useState<string>('')
  const [color, setColor] = useState<string>('')
  const [image, setImage] = useState<string>('')
  const [uploadedImage, setUploadedImage] = useState<string>('')
  const [audioProgress, setAudioProgress] = useState<number | null>(null)
  const { uploadImage, imageLoading } = useUploadImage()
  const { uploadAudio } = useUploadAudioService()
  const { flashCard, loading } = useFetchFlashCard(data.id as string)
  const [currentFlashCard, setCurrentFlashCard] = useState<IEditFlashCard | null>(null)
  const [currentTab, setCurrentTab] = useState<string>('bussiness')

  const uploadVideoService = (url: string, file: File): Promise<AxiosResponse> => {
    return axios.put(url, file, {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: ({ total, loaded }) => {
        setAudioProgress((loaded / total) * 100)
      },
    })
  }

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files = e.target.files
    if (!files) return
    const reader: FileReader = new FileReader()
    const file = files[0]
    reader.readAsDataURL(file)
    reader.onload = (): void => {
      if (typeof reader.result === 'string') {
        uploadImage(file, `flashcards/${file.name}`, (link: string) => {
          setFieldValue('image', link)
          setUploadedImage(link)
        }).catch(() => {
          setSnackbar({ message: 'Error uploading image', variant: 'error' })
        })
      }
    }
  }

  const handleAudioChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files ? e.target.files[0] : null
    let errorMessage: ISnackbarProps = { message: '', variant: 'error' }

    if (!file || !file.type.startsWith('audio/')) {
      errorMessage.message = !file ? 'No file selected' : 'Selected file is not an audio file'
      setSnackbar(errorMessage)
      return
    }
    const audioFile = { fileName: file.name, type: file.type }
    uploadAudio(
      audioFile,
      (arg: string) => uploadVideoService(arg, file),
      (link: string) => {
        setFieldValue('audio', {
          name: file.name,
          fileType: file.name.split('.').pop(),
          link,
        })
        setAudioProgress(0)
      },
    ).catch(() => {
      setSnackbar({ message: 'Error uploading audio', variant: 'error' })
    })
  }

  const handleCloseDrawer = (): void => {
    dispatch({
      type: 'SET_FORMDATA',
      payload: {
        type: 'edit',
        drawer: 'editFlashCard',
        values,
        compareTo: currentFlashCard,
      },
    })
  }

  const handleModalClose = (): void => {
    setOpenedModal('')
    if (openedModal === 'color') {
      setColor('')
    }

    if (openedModal === 'image') {
      setImage('')
    }
  }

  const handleModalSubmit = (): void => {
    if (openedModal === 'color') {
      setFieldValue('color', color)
      handleModalClose()
    }

    if (openedModal === 'image') {
      setFieldValue('image', image)
      handleModalClose()
    }
  }

  const handleTabChange = (_: SyntheticEvent, newValue: string): void => {
    setCurrentTab(newValue)
  }

  useEffect(() => {
    if (flashCard) {
      const newFlashCard: IFlashCard = {
        question: flashCard.question,
        answer: flashCard.answer,
        image: flashCard.image,
        color: flashCard.color,
        audio: flashCard.audio || null,
      }
      setCurrentFlashCard(newFlashCard as IEditFlashCard)
      setValues(newFlashCard)
    }
  }, [flashCard])

  useEffect(() => {
    if (state.formData.closeDrawerClick === true) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  return {
    loading: !!loading,
    editFlashCardLoading,
    t,
    handleCloseDrawer,
    handleSubmit,
    errors,
    touched,
    values,
    handleChange,
    setFieldValue,
    uploadedImage,
    setOpenedModal,
    imageLoading: !!imageLoading,
    setImage,
    setUploadedImage,
    handleImageChange,
    handleAudioChange,
    setAudioProgress,
    audioProgress,
    openedModal,
    handleModalClose,
    color,
    setColor,
    currentTab,
    images,
    handleTabChange,
    image,
    handleModalSubmit,
  }
}

export default useEditFlashCardDrawer
