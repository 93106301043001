import { useMutation, MutationTuple, ApolloError } from '@apollo/client'
import { GET_COMPANY, UPDATE_COMPANY_AVATAR } from 'gql/companies.query'
import { ICompany } from 'pages/companies/CompanyDetails/types'
import { updateCache } from 'services/updateCache'

interface IUpdateProfileAvatarServiceResponse {
  updateCompanyAvatar: MutationTuple<IUpdateCompanyAvatarResponse, IUpdateCompanyAvatarVariables>[0]
  loading: boolean
  error?: ApolloError
}

interface IUpdateCompanyAvatarResponse {
  updateCompanyAvatar: ICompany
}

interface IUpdateCompanyAvatarVariables {
  id: string
  avatar: string
  action: string
}

export const updateProfileAvatarService = (): IUpdateProfileAvatarServiceResponse => {
  const [updateCompanyAvatar, { loading, error }] = useMutation<
    IUpdateCompanyAvatarResponse,
    IUpdateCompanyAvatarVariables
  >(UPDATE_COMPANY_AVATAR, {
    update(cache, { data }) {
      if (data?.updateCompanyAvatar) {
        const document = data.updateCompanyAvatar
        updateCache({
          objectName: 'company',
          query: GET_COMPANY,
          cache,
          document,
          action: 'update',
        })
      }
    },
  })

  return {
    updateCompanyAvatar,
    loading,
    error,
  }
}
