import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import { useQuery, useMutation, FetchResult } from '@apollo/client'
import { useData } from 'context/DataContext'
import { useUserValue } from 'context/UserContext'
import { SET_FORMDATA, CLEAR_FORMDATA } from 'store/types'
import { useUploadImage } from 'hooks/helpers/useHelpersService'
import Badge from 'components/common/Badge/Badge'
import { ModuleSchema } from 'helpers/validationSchemas'
import { DrawerEventEmitter } from 'helpers/drawer'
import { GENERATE_BADGE_DESIGN, GET_MODULE } from 'gql/modules.query'
import CheckSVG from 'assets/checked.svg'
import useAddModuleService from 'hooks/modules/useAddModuleService'
import useEditModuleService from 'hooks/modules/useEditModuleService'
import removeTypeNameFromObject from 'utils/removeTypeNameFromObject'
import { useTranslation } from 'react-i18next'
import {
  IFormDataInterface,
  IEditFormDataInterface,
  IVideoState,
  IBadgeDesignResponse,
  ICourseData,
  ICourse,
  IVariables,
  IDrawerState,
  ICloneModuleData,
  ICurrentModuleType,
} from 'components/modules/AddModule.interface'
import { IModuleData, IModuleProps, IUseModuleFormReturnType } from './useModuleForm.interface'
import { CropperResultType } from 'interfaces/common'
import removeKeys from 'utils/removeKeys'

const defaultTips = [
  {
    icon: CheckSVG,
    text: 'Keep it concise',
  },
  {
    icon: CheckSVG,
    text: 'Use your own stories',
  },
  {
    icon: CheckSVG,
    text: 'Get the message across',
  },
]

const useModuleForm = ({
  data,
  drawerName,
  id,
  onClose,
}: IModuleProps): IUseModuleFormReturnType => {
  const { t } = useTranslation()
  const [state, dispatchData] = useData()
  const [userState] = useUserValue()
  const [drawer, setDrawer] = useState<IDrawerState>({
    badgeEditor: false,
    args: {},
  })
  const [cropperOpen, setCropperOpened] = useState(false)
  const [file, setFile] = useState<CropperResultType>({ name: '', file: '' })
  const [introVideo, setIntroVideo] = useState<IVideoState>()
  const [imageType, setImageType] = useState('')
  const [progress, setProgress] = useState(0)
  const [currentModule, setCurrentModule] = useState<ICurrentModuleType>()
  const [generateBadgeDesign, { loading: generatingBadge }] = useMutation(GENERATE_BADGE_DESIGN)
  const { uploadImage, imageLoading } = useUploadImage()
  const { addModule, newModule, addModuleLoading } = useAddModuleService()
  const { editModule } = useEditModuleService()

  const variables: IVariables = data?.courseId
    ? {
        filter: {
          name: { type: 'match', value: '' },
          course: { type: 'nestedArrayIn', value: data.courseId },
        },
      }
    : {}

  const formData: IFormDataInterface = {
    name: '',
    courses: [],
    coverImage: null,
    attachment: null,
    subtitle: null,
    video: null,
    skillTestVideoEnabled: false,
    skillTestVideoCriteriaEnabled: false,
    skillTestVideoDetails: true,
    badgeEnabled: false,
    badgeData: null,
  }

  const editFormData: IEditFormDataInterface = {
    name: '',
    coverImage: { name: '', link: '' },
    attachment: null,
    video: null,
    subtitle: null,
    skillTestVideoEnabled: true,
    skillTestVideoCriteriaEnabled: true,
    skillTestVideoDetails: true,
    badgeEnabled: false,
    badgeData: null,
  }

  const { data: moduleData } = useQuery(GET_MODULE, {
    variables: { moduleId: id },
    skip: !id,
  })

  const { handleSubmit, handleChange, values, errors, touched, setValues, setFieldValue } =
    useFormik({
      initialValues: id ? editFormData : formData,
      validationSchema: ModuleSchema,
      onSubmit(values) {
        const courseData: ICourseData = { ...values, courses: [] }

        if (id) {
          const data = removeTypeNameFromObject({ ...values })
          data.skillTestVideoDetails = values.skillTestVideoDetails

          if (values.skillTestVideoEnabled) {
            data.skillTestVideoDetails = values.skillTestVideoDetails ? 'default' : 'custom'
          } else {
            data.skillTestVideoDetails = null
          }

          editModule(id, data, onClose)
        } else {
          if (addModuleLoading) return

          courseData.courses = (courseData.courses as ICourse[]).flatMap(
            (course: ICourse) => course.value as ICourse[],
          )

          if (userState.selectedCompany) courseData.companyId = userState.selectedCompany.id

          if (!userState.selectedCompany && data?.manualCompanyId) {
            courseData.companyId = data.manualCompanyId
          }

          if (values.skillTestVideoEnabled) {
            courseData.skillTestVideoDetails = values.skillTestVideoDetails ? 'default' : 'custom'
          } else {
            courseData.skillTestVideoDetails = null
          }

          const dataParams = removeKeys(data as IModuleData, ['manualCompanyId']) as IModuleData
          addModule(courseData, dataParams)
        }
      },
    })

  useEffect(() => {
    if (moduleData && moduleData.getModule) {
      const dataClone: ICloneModuleData = {} as ICloneModuleData
      for (const [key] of Object.entries(editFormData)) {
        dataClone[key] = moduleData.getModule[key]
      }
      dataClone.skillTestVideoDetails = dataClone.skillTestVideoDetails === 'default' ? true : false

      if (moduleData.getModule?.badgeData?.id) {
        dataClone.badgeData = moduleData?.getModule?.badgeData
      }
      setValues(dataClone as unknown as IEditFormDataInterface)
      setCurrentModule(dataClone)
    }
  }, [moduleData])

  const uploadVideoService = (url: string): Promise<string> => {
    return axios.put(url, introVideo, {
      headers: {
        'Content-Type': introVideo?.type || 'application/octet-stream',
      },
      onUploadProgress: ({ total, loaded }) => {
        setProgress((loaded / total) * 100)
      },
    })
  }

  const closeDrawer = (): void => {
    DrawerEventEmitter.emit('openDrawer', { drawerName }, false)
  }

  const cropperModalToggle = (): void => {
    setCropperOpened(!cropperOpen)
  }

  const handleCropSave = (field: string, croppedFile: CropperResultType): void => {
    uploadImage(croppedFile as string, `module/${field}`, (link: string) =>
      setFieldValue(field, {
        name: Date.now().toString(),
        link,
        fileType: 'png',
      }),
    )
  }

  const handleBadgeEnabled = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFieldValue('badgeEnabled', e.target.checked)
    generateBadgeDesign().then((fetchResult: FetchResult<IBadgeDesignResponse>) => {
      const badgeDesignResponse = fetchResult
      if (badgeDesignResponse.data) {
        const { id, url } = badgeDesignResponse.data.generateBadgeDesign
        setFieldValue('badgeData', {
          id,
          url,
        })
      }
    })
  }

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>, field: string): void => {
    const { files, name } = e.target

    if (!files || !files.length) return
    const reader: FileReader = new FileReader()

    reader.onload = (): void => {
      const img: HTMLImageElement = new Image()
      img.src = reader.result as string
      setFile({ name, file: reader.result as string })
    }
    reader.readAsDataURL(files[0])
    if (field !== 'certificateImage') {
      setCropperOpened(true)
    }
    setImageType(field)
  }

  const deleteImage = (field: string): void => {
    setFieldValue(field, '')
  }

  const handleCloseDrawer = (): void => {
    dispatchData({
      type: SET_FORMDATA,
      payload: {
        type: id ? 'edit' : 'add',
        drawer: id ? 'editModuleDrawer' : 'addModule',
        values,
        compareTo: id ? currentModule : {},
      },
    })
  }

  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  useEffect(() => {
    if (newModule) {
      closeDrawer()
      dispatchData({ type: CLEAR_FORMDATA })
      data?.onSuccess()
    }
  }, [newModule])

  const disabledButton = !!((progress > 0 && progress < 100) || imageLoading || addModuleLoading)

  const defaultOverview = `Knowing what you know about ${values.name}, it's your turn to become the teacher! Please create a video, no longer than 3 minutes, teaching back the key learning.`

  const handleBadgeEditorOpen = (): void => {
    setDrawer({ badgeEditor: true, args: { id: values?.badgeData?.id } })
  }

  const handleBadgeEditorClose = (badgeData: { id: number; url: string }): void => {
    setFieldValue('badgeData', badgeData)
    setDrawer({ badgeEditor: false, args: {} })
    setFieldValue('badgeEnabled', false)
    setTimeout(() => {
      setFieldValue('badgeEnabled', true)
    }, 500)
  }

  const renderImage = useCallback(() => {
    return <Badge src={values.badgeData?.url || ''} onEditClick={handleBadgeEditorOpen} />
  }, [values.badgeData?.url])

  const finishCrop = (field: string, croppedFile: CropperResultType): void => {
    uploadImage(croppedFile as string, `module/${field}`, (link: string) =>
      setFieldValue(field, {
        name: Date.now().toString(),
        link,
        fileType: 'png',
      }),
    )
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, field: string): void => {
    const { files } = e.target
    const reader: FileReader = new FileReader()
    reader.onload = (): void => {
      const img: HTMLImageElement = new Image()
      img.src = reader.result as string
      setFile({ name: field, file: reader.result as string })
    }
    if (files) {
      reader.readAsDataURL(files[0])
      if (field !== 'certificateImage') {
        setCropperOpened(true)
      }
    }
  }

  return {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    drawer,
    setDrawer,
    cropperOpen,
    finishCrop,
    handleFileChange,
    setCropperOpened,
    file,
    setFile,
    introVideo,
    setIntroVideo,
    imageType,
    setImageType,
    progress,
    setProgress,
    generatingBadge,
    imageLoading,
    addModuleLoading,
    newModule,
    uploadVideoService,
    handleCropSave,
    handleBadgeEnabled,
    handleImageChange,
    deleteImage,
    handleCloseDrawer,
    closeDrawer,
    handleBadgeEditorOpen,
    handleBadgeEditorClose,
    renderImage,
    disabledButton,
    defaultOverview,
    defaultTips,
    formData,
    cropperModalToggle,
    variables,
    t,
  }
}

export default useModuleForm
