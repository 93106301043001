import { useMutation } from '@apollo/client'
import { ADD_TEST_ASSESSMENT_TO_GROUPS } from 'gql/tests/tests.query'
import useSnackbarAlert from 'hooks/useSnackbar'
import { SnackbarVariants } from 'interfaces/common'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

interface IAddTestAssessmentToGroupsServiceResponse {
  addTestAssessmentToGroup: (variables: IAddTestAssessmentToGroupsParams) => Promise<void>
  data?: string
  loading: boolean
  error?: string
}

interface IAddTestAssessmentToGroupsResponse {
  addTestAssessmentToGroupsById: string
}

interface IAddTestAssessmentToGroupsParams {
  testAssessmentId: string
  groupIds: string[]
}

const useAddTestAssessmentToGroupsService = (): IAddTestAssessmentToGroupsServiceResponse => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()

  // mutation initialization
  const [addTestAssessmentToGroupMutation, { data, loading, error }] = useMutation<
    IAddTestAssessmentToGroupsResponse,
    IAddTestAssessmentToGroupsParams
  >(ADD_TEST_ASSESSMENT_TO_GROUPS)

  const errorMessage = error ? error.message.split(':')[1] : ''

  const addTestAssessmentToGroup = async (
    variables: IAddTestAssessmentToGroupsParams,
  ): Promise<void> => {
    try {
      let { message, variant }: ISnackbarProps = {
        message: '',
        variant: undefined,
      }

      const { errors } = await addTestAssessmentToGroupMutation({ variables })
      if (errors) {
        message = errors[0].message
        variant = SnackbarVariants.ERROR
      } else {
        message =
          variables.groupIds.length > 1
            ? t('books_layout.groups_added')
            : t('books_layout.group_added')
        variant = SnackbarVariants.SUCCESS
      }

      setSnackbar({ message, variant })
    } catch (error) {
      console.log(SnackbarVariants.ERROR, error)
    }
  }

  return {
    addTestAssessmentToGroup,
    data: data?.addTestAssessmentToGroupsById,
    loading,
    error: errorMessage,
  }
}

export default useAddTestAssessmentToGroupsService
