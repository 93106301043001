import editModuleService from 'services/modules/editModuleService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import { IModule } from 'pages/modules/ModulesList.interface'

const useEditModuleService = (): {
  editModule: (moduleId: string, input: IModule, action?: () => void) => Promise<void>
  loading: boolean
} => {
  const { t } = useTranslation()
  const { editModuleMutation, loading } = editModuleService()
  const { setSnackbar } = useSnackbarAlert()

  const editModule = async (
    moduleId: string,
    input: IModule,
    action?: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await editModuleMutation({
      variables: {
        moduleId,
        input,
      },
    })

    if (errors && errors.length) {
      message = errors[0]?.message ? errors[0].message : t('messages.something_wrong')
      variant = 'error'
    } else {
      message = t('messages.changes_saved')
      variant = 'success'
    }

    setSnackbar({ message, variant })
    if (action) action()
  }

  return {
    editModule,
    loading,
  }
}

export default useEditModuleService
