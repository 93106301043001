import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import DashboardIcon from '@mui/icons-material/Dashboard'
import EditIcon from '@mui/icons-material/Edit'
import HeadsetIcon from '@mui/icons-material/Headset'
import ImageIcon from '@mui/icons-material/Image'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { Grid } from 'components/common/Cards/styled-components'
import CheckBox from 'components/common/Checkbox'
import DndItem from 'components/common/Grid/DndGrid/DndItem'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { DefaultText } from 'components/common/TextEditor/styled-components'
import React, { ReactElement } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import strCutter from 'utils/strCutter'
import Tags from 'components/common/Tags/Tags'
import {
  Card,
  CheckBoxWrapper,
  Content,
  Edited,
  FilesWrapper,
  FileWrapper,
  Footer,
  Header,
  ModuleItem,
  ModuleItemWrapper,
  Modules,
  Question,
  TagItem,
  TagsContainer,
  Type,
  Wrapper,
  WrapperStyle,
} from '../styled-components'
import { TFunction } from 'interfaces/TFunction'
import { IQuestion } from 'interfaces/questions'
import { IAction } from 'components/common/GridV2'
import { IRouterState } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'

interface Params {
  gridStyle: string
  cols: number
  data: IQuestion[]
  actions?: IAction[]
  currentModuleId?: string
  selectItem: (id: string) => void
  selectedItems: string[]
  linkDrawer?: boolean
  questionIds?: string[]
  isDraggable?: boolean
  moveCard?: (dragIndex: number, hoverIndex: number) => void
  handleChangeRequest?: (draggedId: string, droppedId: string) => void
  t: TFunction
  openInExternalTab?: boolean
  routerState?: IRouterState
  canModify?: boolean
}

const QuestionsGrid = ({
  gridStyle,
  cols,
  data,
  actions,
  currentModuleId,
  canModify = true,
  selectItem,
  selectedItems,
  linkDrawer = false,
  questionIds,
  isDraggable = false,
  moveCard,
  handleChangeRequest,
  t,
  openInExternalTab,
  routerState,
}: Params): ReactElement => {
  const navigate = useNavigate()

  const redirectToQuestionDetails = (quistionId: string): void => {
    if (openInExternalTab) {
      window.open(`/questions/${quistionId}`)
    } else {
      navigate(`/questions/${quistionId}`, {
        state: routerState,
      })
    }
  }

  return (
    <Grid className={gridStyle} cols={cols} noPadding={true}>
      {data.map((item, index: number) => {
        const moduleIds = item.modules.map(moduleItem => moduleItem.moduleId)
        const visibleModulesQuantity = gridStyle === 'card' ? 2 : 10
        const visibleModules = item.modules.slice(0, visibleModulesQuantity)
        const exModules = item.modules.slice(visibleModulesQuantity, item.modules.length)

        return isDraggable && canModify && moveCard ? (
          <DndItem
            key={item.id}
            id={item.id}
            index={index}
            moveCard={moveCard}
            cardIds={data.map(card => card.id)}
            onEnd={handleChangeRequest}
            isNested
          >
            <Card
              onClick={(): void => redirectToQuestionDetails(item.id)}
              isSelected={
                selectedItems.includes(item.id) ||
                (questionIds && questionIds.includes(item.id)) ||
                (linkDrawer && moduleIds.includes(currentModuleId))
              }
            >
              <Header>
                <Wrapper>
                  <WrapperStyle>
                    <Link to={`${`/questions/${item.id}`}`}>
                      <Type>{t(`question_details.${item.type.value}`)}</Type>
                    </Link>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {item.edited && (
                        <Edited>
                          <EditIcon fontSize='small' />
                          edited
                        </Edited>
                      )}
                      {actions && <GridActionMenu actionConfig={actions} row={item} />}
                    </div>
                    <CheckBox
                      onChange={(): void => selectItem(item.id)}
                      checked={
                        selectedItems.includes(item.id) ||
                        (questionIds && questionIds.includes(item.id)) ||
                        (linkDrawer && moduleIds.includes(currentModuleId))
                      }
                      disabled={
                        (questionIds && questionIds.includes(item.id)) ||
                        (linkDrawer && moduleIds.includes(currentModuleId))
                      }
                    />
                  </WrapperStyle>
                </Wrapper>
              </Header>
              <Content>
                <div>
                  <Question>{strCutter(item.question, 130)}</Question>
                  <FilesWrapper>
                    {item.documents.length > 0 && (
                      <FileWrapper>
                        <InsertDriveFileIcon style={{ color: '#06C68F' }} />
                        {item.documents.length}
                      </FileWrapper>
                    )}
                    {item.videos.length > 0 && (
                      <FileWrapper>
                        <PlayCircleOutlineIcon style={{ color: '#06C68F' }} />
                        {item.videos.length}
                      </FileWrapper>
                    )}
                    {item.images.length > 0 && (
                      <FileWrapper>
                        <ImageIcon style={{ color: '#06C68F' }} />
                        {item.images.length}
                      </FileWrapper>
                    )}
                    {item.audios.length > 0 && (
                      <FileWrapper>
                        <HeadsetIcon style={{ color: '#06C68F' }} />
                        {item.audios.length}
                      </FileWrapper>
                    )}
                  </FilesWrapper>
                </div>
              </Content>
              <TagsContainer>
                {item.tags.map((tag, index: number) => (
                  <TagItem key={index}>#{tag.label}</TagItem>
                ))}

                {item.tags.length === 0 && <div>{t('general.no_tags')}</div>}
              </TagsContainer>
              <Footer>
                <Modules>
                  <IconButton
                    size='small'
                    style={{ marginRight: 10 }}
                    className={`${item.modules.length > 0 ? 'with-modules' : ''}`}
                  >
                    <DashboardIcon fontSize='small' style={{ color: '#06C68F' }} />
                  </IconButton>
                  {/* <div
                    style={{ display: 'flex', flexWrap: 'wrap', width: '80%' }}
                  > */}
                  {item.modules.length > 0 ? (
                    visibleModules.map(moduleItem => (
                      <ModuleItemWrapper
                        key={moduleItem.moduleId}
                        className={`${currentModuleId === moduleItem.moduleId ? 'disabled' : ''}`}
                      >
                        <Tooltip title={moduleItem.name}>
                          <ModuleItem>
                            {strCutter(moduleItem.name, gridStyle === 'card' ? 15 : 30)}
                          </ModuleItem>
                        </Tooltip>
                        {/* {currentModuleId !== moduleItem.moduleId && (
                        <HighlightOffIcon />
                      )} */}
                      </ModuleItemWrapper>
                    ))
                  ) : (
                    <div>{t('general.no_assigned_modules')}</div>
                  )}
                  {exModules.length > 0 && (
                    <Tooltip
                      title={
                        <>
                          {exModules.map(exModule => (
                            <div key={exModule.moduleId}>{exModule.name}</div>
                          ))}
                        </>
                      }
                    >
                      <MoreHorizIcon fontSize='small' />
                    </Tooltip>
                  )}
                  {/* </div> */}
                </Modules>
              </Footer>
            </Card>
          </DndItem>
        ) : (
          <Card
            onClick={(): void => redirectToQuestionDetails(item.id)}
            isSelected={
              selectedItems.includes(item.id) ||
              (questionIds && questionIds.includes(item.id)) ||
              (linkDrawer && moduleIds.includes(currentModuleId))
            }
          >
            <Header>
              <Wrapper>
                <WrapperStyle>
                  <Link to={`${`/questions/${item.id}`}`}>
                    <Type>{t(`question_details.${item.type.value}`)}</Type>
                  </Link>
                  {canModify && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {item.edited && (
                        <Edited>
                          <EditIcon fontSize='small' />
                          edited
                        </Edited>
                      )}
                      {actions && <GridActionMenu actionConfig={actions} row={item} />}
                    </div>
                  )}
                  {canModify && (
                    <CheckBoxWrapper>
                      <CheckBox
                        onChange={(): void => selectItem(item.id)}
                        checked={
                          selectedItems.includes(item.id) ||
                          (questionIds && questionIds.includes(item.id)) ||
                          (linkDrawer && moduleIds.includes(currentModuleId))
                        }
                        disabled={
                          (questionIds && questionIds.includes(item.id)) ||
                          (linkDrawer && moduleIds.includes(currentModuleId))
                        }
                      />
                    </CheckBoxWrapper>
                  )}
                </WrapperStyle>
              </Wrapper>
            </Header>
            <Content>
              <div>
                <Question>{strCutter(item.question, 130)}</Question>
                <FilesWrapper>
                  {item.documents && item.documents.length > 0 && (
                    <FileWrapper>
                      <InsertDriveFileIcon style={{ color: '#06C68F' }} />
                      {item.documents.length}
                    </FileWrapper>
                  )}
                  {item.videos && item.videos.length > 0 && (
                    <FileWrapper>
                      <PlayCircleOutlineIcon style={{ color: '#06C68F' }} />
                      {item.videos.length}
                    </FileWrapper>
                  )}
                  {item.images.length > 0 && (
                    <FileWrapper>
                      <ImageIcon style={{ color: '#06C68F' }} />
                      {item.images.length}
                    </FileWrapper>
                  )}
                  {item.audios.length > 0 && (
                    <FileWrapper>
                      <HeadsetIcon style={{ color: '#06C68F' }} />
                      {item.audios.length}
                    </FileWrapper>
                  )}
                </FilesWrapper>
              </div>
            </Content>
            <Tags tags={item.tags} type='question' gridStyle={gridStyle} />
            <Footer>
              <Modules>
                <IconButton
                  size='small'
                  style={{ marginRight: 10 }}
                  className={`${item.modules.length > 0 ? 'with-modules' : ''}`}
                >
                  <DashboardIcon fontSize='small' style={{ color: '#06C68F' }} />
                </IconButton>
                {/* <div
                    style={{ display: 'flex', flexWrap: 'wrap', width: '80%' }}
                  > */}
                {item.modules.length > 0 ? (
                  visibleModules.map(moduleItem => (
                    <ModuleItemWrapper
                      key={moduleItem.moduleId}
                      className={`${currentModuleId === moduleItem.moduleId ? 'disabled' : ''}`}
                    >
                      <Tooltip title={moduleItem.name}>
                        <ModuleItem>
                          {strCutter(moduleItem.name, gridStyle === 'card' ? 15 : 30)}
                        </ModuleItem>
                      </Tooltip>
                      {/* {currentModuleId !== moduleItem.moduleId && (
                        <HighlightOffIcon />
                      )} */}
                    </ModuleItemWrapper>
                  ))
                ) : (
                  <div>{t('general.no_assigned_modules')}</div>
                )}
                {exModules.length > 0 && (
                  <Tooltip
                    title={
                      <>
                        {exModules.map(exModule => (
                          <div key={exModule.moduleId}>{exModule.name}</div>
                        ))}
                      </>
                    }
                  >
                    <MoreHorizIcon fontSize='small' />
                  </Tooltip>
                )}
                {/* </div> */}
              </Modules>
            </Footer>
          </Card>
        )
      })}

      {data.length === 0 && (
        <DefaultText style={{ width: '100vw' }}>{t('general.no_questions')}</DefaultText>
      )}
    </Grid>
  )
}

export default QuestionsGrid
