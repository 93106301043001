import React from 'react'
import strCutter from 'utils/strCutter'
import CheckBox from 'components/common/Checkbox'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import {
  Wrapper,
  FlashCardItemContainer,
  FlashCardItemContent,
  AudioIcon,
  FlashCardItemTitle,
  FlashCardItemImageBox,
  FlashCardItemAnswer,
  ActionsContainer,
} from '../styled-components'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { Params } from './FlashCardLayout.interface'
import { IAudio } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'

const FlashCardItem = ({
  flashCard,
  actions,
  selectedItems,
  selectItem,
  handleAudioClick,
}: Params): React.JSX.Element => {
  const audioColor: 'primary' | 'disabled' =
    flashCard.audio && flashCard.audio.link ? 'primary' : 'disabled'

  return (
    <FlashCardItemContainer background={flashCard.color}>
      <Wrapper>
        <CheckBox
          onChange={(e: React.SyntheticEvent<EventTarget>): void =>
            selectItem(flashCard.id as string, e)
          }
          checked={selectedItems.includes(flashCard.id as string)}
        />
        <ActionsContainer>
          <AudioIcon className={`${flashCard.audio && !flashCard.audio.link ? 'disabled' : ''}`}>
            <VolumeUpIcon
              color={audioColor}
              onClick={(): void =>
                handleAudioClick((flashCard.audio as IAudio) && (flashCard.audio as IAudio).link)
              }
            />
          </AudioIcon>
          <GridActionMenu actionConfig={actions} row={flashCard} />
        </ActionsContainer>
      </Wrapper>
      <FlashCardItemContent>
        <FlashCardItemTitle>{flashCard.question}</FlashCardItemTitle>
        <FlashCardItemImageBox>
          <img src={flashCard.image} />
        </FlashCardItemImageBox>
        <FlashCardItemAnswer>{strCutter(flashCard.answer, 250)}</FlashCardItemAnswer>
      </FlashCardItemContent>
    </FlashCardItemContainer>
  )
}

export default FlashCardItem
