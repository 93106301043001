import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const Content = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SelectorContainer = styled.form`
  display: flex;
  gap: 16px;
  align-items: center;
`
export const SelectFieldWrapper = styled.div`
  width: 372px !important;
  height: 40px !important;

  .selectField {
    width: 372px !important;
  }
`

export const CenterParagraph = styled.p`
  display: flex;
  text-align: center;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.5);
`

export const InputContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: space-between;
`

// Statistic
export const TileContainer = styled.div`
  display: flex;
  gap: 20px;
  transform: translateY(50%);
`

export const StatisticList = styled.div`
  background-color: white;
  display: flex;
  padding: 15px 25px;
  gap: 20px;
  justify-content: space-around;
  flex-direction: column;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 7%), 0px 4px 5px 0px rgb(0 0 0 / 7%);
`
export const StatisticFlexBox = styled.div`
  display: flex;
  width: 100%;
  gap: 35px;
  flex-wrap: nowrap;
`

export const SubLabel = styled.p`
  font-size: 16px;
  text-align: left;
  border-bottom: 1px solid rgba(89, 199, 144, 0.8);
  margin: 10px;
  padding-bottom: 3px;
  margin-bottom: 5px;
  color: #343b43;
`
export const Quantity = styled.p`
  font-size: 28px;
  text-align: right;
  color: rgba(89, 199, 144, 1);
  margin: 0px 15px 5px 0;
`

export const StatisticTitle = styled.div`
  text-align: left;
  gap: 20px;
  margin-top: -8px;
  width: 100%;

  @media (max-width: 1050px) {
    width: 100%;
  }
`
export const StatisticLabel = styled.h1`
  text-align: left;
  padding-bottom: 5px;
  margin-bottom: 8px;
  @media (max-width: 1350px) {
    margin-top: 75px;
  }
`
export const StatisticFlex = styled.div`
  text-align: left;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
`

export const datasetBackgroundColors = [
  'rgba(89, 199, 144, 0.8)',
  'rgba(64, 173, 243, 0.8)',
  'rgba(251, 222, 75, 0.8)',
]

export const datasetBorderColors = [
  'rgba(89, 199, 144, 1)',
  'rgba(64, 173, 243, 1)',
  'rgba(251, 222, 75, 1)',
]

export const TileIconStyles = {
  color: '#fff',
  fontSize: '1.2em',
}
