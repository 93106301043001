import { ApolloQueryResult, useQuery } from '@apollo/client'
import { GET_ASSISTANT_MESSAGES } from 'gql/assistant/assistant.query'
import { IMessage } from 'hooks/assistant/useAIAssistantChat'

interface IGetAssistantMessagesResponse {
  getAssistantMessages2: IMessage[]
}

interface IUseAssistantMessagesResponse {
  messages: IMessage[]
  loading: boolean
  error: string
  refetch: (
    variables?: Partial<IAssistantMessagesProps> | undefined,
  ) => Promise<ApolloQueryResult<IGetAssistantMessagesResponse>>
}

interface IAssistantMessagesProps {
  assistantId: string
  filter?: {
    limit?: number
  }
}

export const useAssistantMessages = (
  variables: IAssistantMessagesProps,
): IUseAssistantMessagesResponse => {
  const { data, error, loading, refetch } = useQuery<
    IGetAssistantMessagesResponse,
    IAssistantMessagesProps
  >(GET_ASSISTANT_MESSAGES, {
    variables,
  })
  const errorMessage = error ? error.message.split(':')[1] : ''

  return {
    messages: data?.getAssistantMessages2 || [],
    loading,
    error: errorMessage,
    refetch,
  }
}
