export const images = {
  bussiness: [
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/basket.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/binoculars.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/brain.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/calculator.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/calendar.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/chat.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/clock.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/coffee-1.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/coffee.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/cogwheel.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/coins.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/compass.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/diagram-1.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/diagram.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/documentation.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/dollar-bill.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/employee.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/employees.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/flag.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/flask.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/form.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/glasses.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/horse.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/idea.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/laptop.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/lifebuoy.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/magnifying-glass.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/medal.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/megaphone.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/microscope.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/money-bag.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/mortarboard.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/mountain.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/organization.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/paper-plane.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/piggy-bank.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/pointer.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/rocket.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/smartphone-1.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/smartphone.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/speedometer.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/store.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/suitcase.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/target.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/team.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/telescope.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/tie.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/trophy.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/ufo.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/default/website.svg',
  ],
  everyday: [
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/001-bike.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/002-mail.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/003-key.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/004-umbrella.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/005-newspaper.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/006-notebook.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/007-toilet paper.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/008-glasses.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/009-sanitary pad.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/010-razor.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/011-spray.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/012-coffee cup.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/013-grocery bag.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/014-hammer.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/015-flowers.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/016-headphones.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/017-tv.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/018-deodorant.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/019-iron.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/020-water bottle.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/021-pet food.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/022-car key.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/023-pressure cooker.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/024-cosmetics.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/025-book.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/026-pill box.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/027-tea cup.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/028-computer.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/029-portfolio.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/030-dumbbell.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/031-shower.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/032-pen.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/033-joystick.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/034-soap.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/035-desk chair.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/036-parking.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/037-calendar.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/038-bed.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/039-soda.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/040-washing machine.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/041-backpack.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/042-wallet.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/043-alarm clock.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/044-underwear.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/045-check list.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/046-breakfast.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/047-phone call.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/048-Tooth Brush.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/049-tshirt.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Everyday+SVG/050-hair comb.svg',
  ],
  influence: [
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/001-activation.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/002-affiliate marketing.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/003-aida.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/004-ambassador.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/005-megaphone.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/006-analytics.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/007-authentic.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/008-backlink.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/009-bant.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/010-benchmark.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/011-best practice.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/012-brand awareness.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/013-brand.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/014-briefing.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/015-call to action.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/016-campaign.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/017-cost per click.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/018-click through rate.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/019-comment.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/020-commission.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/021-community.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/022-content.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/023-content marketing.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/024-content.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/025-contra strategy.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/026-conversion.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/027-conversion.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/028-cost per acquisition.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/029-cost per click.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/030-cost per engagement.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/031-cost per lead.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/032-cost per mille.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/033-creator.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/034-credibility.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/035-cross platform.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/036-data driven.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/037-deliverable.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/038-disclosure.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/039-dislike.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/040-online shopping.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/041-email.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/042-fake followers.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/043-favorite.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/044-blogger.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/045-celebrity.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/046-influencer.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/047-vlogger.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/048-filter.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/049-flat lay.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/050-followers.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/051-guest post.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/052-hashtag.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/053-impressions.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/054-influencer.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/055-influencer.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/056-influencer.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/057-influencer.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/058-influencer.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/059-influencer.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/060-influencer.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/061-sponsorship.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/062-instameet.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/063-like.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/064-macro influencer.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/065-blogger.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/066-celebrity.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/067-influencer.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/068-vlogger.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/069-media kit.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/070-mega influencer.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/071-macro influencer.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/072-nano influencer.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/073-organic.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/074-pitch.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/075-podcast.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/076-qualify.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/077-relevancy.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/078-repost.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/079-reputation.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/080-return on influencer.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/081-return on investment.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/082-seo.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/083-instagram.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/084-contest.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/085-social reach.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/086-stories.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/087-target audience.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/088-link.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/089-trend.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/090-trend jacking.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/091-content.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/092-video marketing.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/093-views.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/094-viral.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Influence+Icons+SVG/095-word of mouth.svg',
  ],
  social: [
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/001-network.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/002-chat group.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/003-thumbs up.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/004-notification.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/005-internet.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/006-thumbs up.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/007-heart.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/008-happy face.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/009-surprised.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/010-sad face.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/011-angry face.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/012-chat box.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/013-love.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/014-viral.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/015-contact list.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/016-camera.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/017-news.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/018-comments.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/019-laptop.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/020-podcast.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/021-user.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/022-user.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/023-subscribe.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/024-email.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/025-drone.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/026-selfie.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/027-video player.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/028-smartphone.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/029-smartphone.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/030-smartphone.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/031-laptop.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/032-internet.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/033-profile.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/034-profile.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/035-video player.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/036-network.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/037-smartphone.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/038-review.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/039-bubble speech.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/040-notification.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/041-text.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/042-video player.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/043-profile.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/044-laptop.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/045-link.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/046-love.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/047-network.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/048-smartphone.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/049-monitor.svg',
    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/flashcards/Social+Media+SVG/050-smartphone.svg',
  ],
}
