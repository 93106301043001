import { useQuery } from '@apollo/client'
import { GET_BOOKS_BY_COMPANY_ID } from 'gql/books/books.query'
import { IUseFetchBooksProps, IUseFetchBooksResponse } from './bookServices.interface'

export const useFetchBooks = ({
  searchValue,
  currentPage = 1,
  perPage = 12,
  companyId,
}: IUseFetchBooksProps): IUseFetchBooksResponse => {
  const { data, loading, error, refetch, fetchMore } = useQuery(GET_BOOKS_BY_COMPANY_ID, {
    variables: {
      searchValue,
      currentPage,
      perPage,
      companyId,
    },
    skip: !companyId,
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''

  return {
    books: (data && data.getBooksByCompanyId) || [],
    loading,
    error: errorMessage,
    refetch,
    fetchMore: variables => fetchMore({ variables }),
  }
}
