import styled from 'styled-components'

export const StyledWrapper = styled.div`
  height: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`

export const StyledInnerWrapper = styled.div`
  max-width: 100%;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  gap: 1rem;
  padding: 16px 0;
`

export const StyledResponseText = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 1rem;
  background-color: #fff;
  border-radius: 4px;
  max-width: 1000px;
  color: #000;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 1rem;
`

export const StyledPlaceholder = styled.div`
  text-align: center;
  color: #aaa;
  font-style: italic;
  margin: 20px 0;

  p {
    margin: 10px 0;
  }
`

export const StyledFileUploadButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
`

export const StyledAssistantUpdateMsgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledAssistantUpdateMsg = styled.p`
  width: 100%;
  max-width: 1000px;
  color: red;
  text-align: center;
`

export const StyledAssistantUpdateButton = styled.button`
  background-color: #08c694;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`

export const StyledFileList = styled.ul`
  width: 1200px;
  max-width: 100%;
  padding: 0;
  /* margin: 10px 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
`

export const StyledFileItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f0f0f0; /* Grey background */
  color: #333;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 8px;
`

export const FileIcon = styled.div`
  background-color: #ff4b5c;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`

export const FileName = styled.span`
  flex-grow: 1;
  font-size: 14px;
`

export const RemoveButton = styled.button`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #dc3545;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
