export default [
  '#b3ecda',
  '#b4e0fb',
  '#f0bec4',
  '#fddbb6',
  '#fef4c4',
  '#f4d2fa',
  '#d0d0d0',
  '#b2b5ff',
  '#e4ffb4',
  '#b4fffc',
  '#ffb6e3',
]
