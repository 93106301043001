import { useState } from 'react'
import editUserService from 'services/users/editUserService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import formatRoleForTranslation from 'helpers/formatRole'
import { IUser } from 'interfaces/users'

const useEditUserService = (): {
  updateUser: (id: string, input: IUser, callback?: () => void) => Promise<void>
  user: IUser | null
  editUserLoading: boolean
  error: string
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [user, setUser] = useState<IUser | null>(null)
  const [error, setError] = useState('')
  const { updateUserMutation, loading } = editUserService()

  const updateUser = async (id: string, input: IUser, callback?: () => void): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }
    const { data, errors } = await updateUserMutation({
      variables: {
        id,
        input,
      },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      setError(message)
      variant = 'error'
    } else if (data) {
      setUser(data.updateUser)
      const role = data?.updateUser?.role?.toLowerCase()
      if (!role) return
      message = `${t(formatRoleForTranslation(role))} ${t('messages.edited')}`
      variant = 'success'
      if (callback) {
        callback()
      }
    }

    setSnackbar({ message, variant })
  }

  return {
    updateUser,
    user,
    editUserLoading: loading,
    error,
  }
}

export default useEditUserService
