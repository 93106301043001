import { useMutation, MutationTuple } from '@apollo/client'

import {
  GENERATE_VIDEO_UPLOAD_LINKS,
  UPLOAD_IMAGE,
  UPLOAD_FILE,
  UPLOAD_AUDIO,
  EXPORT_DATA,
  EXTRACT_TEXT,
  UPLOAD_CAPTIONS,
} from 'gql/helper/helper.query'
import { IVideo } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'

export interface ILinkResponse {
  link: string
  fileId?: string
  text?: string
}

// Interfaces for generateUploadVideoLinksService
interface IGenerateUploadVideoLinksResponse {
  generateVideoUploadLinks: IGenerateUploadVideoLinksMutationResponse
}

export interface IGenerateUploadVideoLinksMutationResponse {
  uploadLinks: string[]
  video: IVideo
}

interface IGenerateUploadVideoLinksVariables {
  videos: IVideo[]
  modelName: string
  isNewVideo: boolean
}

interface IGenerateUploadVideoLinksServiceResponse {
  generateUploadVideoLinksMutation: MutationTuple<
    IGenerateUploadVideoLinksResponse,
    IGenerateUploadVideoLinksVariables
  >[0]
}

export const generateUploadVideoLinksService = (): IGenerateUploadVideoLinksServiceResponse => {
  const [generateUploadVideoLinksMutation] = useMutation<
    IGenerateUploadVideoLinksResponse,
    IGenerateUploadVideoLinksVariables
  >(GENERATE_VIDEO_UPLOAD_LINKS)

  return {
    generateUploadVideoLinksMutation,
  }
}

// Interfaces for uploadImageService
interface IUploadImageResponse {
  uploadImageLink: ILinkResponse
}

interface IUploadImageVariables {
  image: string
  path: string
}

interface IUploadImageServiceResponse {
  uploadImageMutation: MutationTuple<IUploadImageResponse, IUploadImageVariables>[0]
  loading: boolean
}

export const uploadImageService = (): IUploadImageServiceResponse => {
  const [uploadImageMutation, { loading }] = useMutation<
    IUploadImageResponse,
    IUploadImageVariables
  >(UPLOAD_IMAGE)

  return {
    uploadImageMutation,
    loading,
  }
}

// Interfaces for uploadFileService
interface IUploadFileResponse {
  uploadFileLink: ILinkResponse
}

interface IUploadFileVariables {
  file: File
  path: string
}

interface IUploadFileServiceResponse {
  uploadFileMutation: MutationTuple<IUploadFileResponse, IUploadFileVariables>[0]
  loading: boolean
}

export const uploadFileService = (): IUploadFileServiceResponse => {
  const [uploadFileMutation, { loading }] = useMutation<IUploadFileResponse, IUploadFileVariables>(
    UPLOAD_FILE,
  )

  return {
    uploadFileMutation,
    loading,
  }
}

// Interfaces for uploadCaptionsService
interface IUploadCaptionsResponse {
  uploadCaptions: ILinkResponse
}

interface IUploadCaptionsVariables {
  file: File
  path: string
}

interface IUploadCaptionsServiceResponse {
  uploadFileMutation: MutationTuple<IUploadCaptionsResponse, IUploadCaptionsVariables>[0]
  loading: boolean
}

export const uploadCaptionsService = (): IUploadCaptionsServiceResponse => {
  const [uploadFileMutation, { loading }] = useMutation<
    IUploadCaptionsResponse,
    IUploadCaptionsVariables
  >(UPLOAD_CAPTIONS)

  return {
    uploadFileMutation,
    loading,
  }
}

// Interfaces for uploadAudioService
interface IUploadAudioResponse {
  uploadAudio: ILinkResponse
}

interface IUploadAudioVariables {
  file: File
}

interface IUploadAudioServiceResponse {
  uploadAudioMutation: MutationTuple<IUploadAudioResponse, IUploadAudioVariables>[0]
  loading: boolean
}

export const uploadAudioService = (): IUploadAudioServiceResponse => {
  const [uploadAudioMutation, { loading }] = useMutation<
    IUploadAudioResponse,
    IUploadAudioVariables
  >(UPLOAD_AUDIO)

  return {
    uploadAudioMutation,
    loading,
  }
}

// Interfaces for exportDataService
interface IExportDataResponse {
  exportCsv: ILinkResponse
}

interface IExportDataVariables {
  action: string
  ids: string[]
  companyId: string | null
}

interface IExportDataServiceResponse {
  exportDataMutation: MutationTuple<IExportDataResponse, IExportDataVariables>[0]
  loading: boolean
}

export const exportDataService = (): IExportDataServiceResponse => {
  const [exportDataMutation, { loading }] = useMutation<IExportDataResponse, IExportDataVariables>(
    EXPORT_DATA,
  )

  return {
    exportDataMutation,
    loading,
  }
}

// Interfaces for extractTextService
interface IExtractTextResponse {
  extractText: ILinkResponse
}

interface IExtractTextVariables {
  link: string
}

interface IExtractTextServiceResponse {
  extractTextMutation: MutationTuple<IExtractTextResponse, IExtractTextVariables>[0]
  loading: boolean
}

export const extractTextService = (): IExtractTextServiceResponse => {
  const [extractTextMutation, { loading }] = useMutation<
    IExtractTextResponse,
    IExtractTextVariables
  >(EXTRACT_TEXT)

  return {
    extractTextMutation,
    loading,
  }
}
