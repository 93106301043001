import styled from 'styled-components'

// AddQuizDrawer

export const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Header = styled.header`
  padding: 10px 20px;
  background: #ffffff;
  position: sticky;
  z-index: 2;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 13px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
`

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
`

// Step1

export const StyledFromGroupContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-direction: column;
`

export const StyledFormGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledFormGroupTitle = styled.div`
  display: flex;
  gap: 10px;
`

export const ErrorMessage = styled.div`
  position: absolute;
  bottom: -10px;
  left: 0;
  font-size: 12px;
  color: red;
`

export const ItemWrapper = styled.div`
  width: 125px;
  .MuiRadio-colorSecondary.Mui-checked {
    color: #06c68f;
  }
`

export const HelpButtonContainer = styled.div`
  width: 32px;
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
  }
`

export const Label = styled.label`
  &.error {
    color: red;
  }
`

export const InputLabel = styled.div`
  padding-bottom: 10px;
`

// Step2

export const StepHeader = styled.header`
  margin-bottom: 20px;

  &.withBorder {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
  }

  button.makeStyles-button-247 {
    padding: 0;
  }
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  label {
    margin-right: 0;

    span {
      font-size: 14px;
    }
  }
`

export const StepTitle = styled.h2`
  font-size: 18px;
  font-weight: 500;
`
