import useSnackbarAlert from 'hooks/useSnackbar'
import { IInsertGroupsWithTestsInput } from 'interfaces/groups'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

import importGroupsWithTestsService from 'services/group/importGroupsWithTestsService'
import { IExtractGroupsWithTestsMutationResponse } from 'services/group/uploadGroupsWithCoursesService'

export interface IUseImportGroupsWithTestsReturnType {
  importGroupsWithTests: (
    values: IInsertGroupsWithTestsInput[],
    companyId: string,
    callBack?: (groupsData: IExtractGroupsWithTestsMutationResponse[] | null) => void,
  ) => Promise<void>
  loading: boolean
}

const useImportGroupsWithTests = (): IUseImportGroupsWithTestsReturnType => {
  const { t } = useTranslation()
  const { insertGroupsWithTests, loading } = importGroupsWithTestsService()
  const { setSnackbar } = useSnackbarAlert()

  const importGroupsWithTests = async (
    values: IInsertGroupsWithTestsInput[],
    companyId: string,
    callBack?: (groupsData: IExtractGroupsWithTestsMutationResponse[] | null) => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }
    const { errors, data } = await insertGroupsWithTests({
      variables: {
        groups: values,
        companyId,
      },
    })

    let groupsData = null

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else if (data) {
      message = `${t('general.groups')} ${t('messages.edited')}`
      variant = 'success'
      groupsData = data.insertGroupsWithTests
    }

    if (callBack) callBack(groupsData)

    setSnackbar({ message, variant })
  }

  return {
    importGroupsWithTests,
    loading,
  }
}

export default useImportGroupsWithTests
