import { useState } from 'react'
import addSkillTestVideoService, {
  IAddSkillTestVideoTemplateParams,
} from 'services/modules/addSkillTestVideoTemplateService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { ISkillTestVideo } from 'pages/modules/ModuleDetail/SkillTestVideos/SkillTestVideos.interface'

const useAddSkillTestVideoService = (
  params: IAddSkillTestVideoTemplateParams,
): {
  addSkillTestVideoTemplate: (
    moduleId: string,
    input: ISkillTestVideo,
    onSuccess?: () => void,
  ) => Promise<void>
  addSkillTestVideoTemplateLoading: boolean
  error: string
} => {
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { addSkillTestVideoTemplateMutation } = addSkillTestVideoService(params)

  const addSkillTestVideoTemplate = async (
    moduleId: string,
    input: ISkillTestVideo,
    onSuccess?: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    try {
      const { errors } = await addSkillTestVideoTemplateMutation({
        variables: {
          moduleId,
          input,
        },
      })

      if (errors) {
        message = errors[0].message
        if (message === 'User already registered') {
          message = message
          setError(message)
          return
        }
      } else {
        message = 'Skill Test Video has been created'
        variant = 'success'

        if (onSuccess) {
          onSuccess()
        }
      }
      setSnackbar({ message, variant })
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  return {
    addSkillTestVideoTemplate,
    addSkillTestVideoTemplateLoading: loading,
    error,
  }
}

export default useAddSkillTestVideoService
