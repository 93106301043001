import styled from 'styled-components'

export const Container = styled.div`
  padding-block: 20px;
  border-bottom: 1px solid #e0e0e0;
  .MuiStepIcon-root.MuiStepIcon-active {
    color: #06c68f;
  }
  .MuiStepIcon-root.MuiStepIcon-completed {
    color: #06c68f;
  }
`
