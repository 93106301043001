import { useState } from 'react'
import editFlashCardService from 'services/modules/editFlashCardService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import { IFlashCard } from './FlashCardsLayout/useModuleDetailContent.interface'

const useEditFlashCardService = (): {
  editFlashCard: (id: string, input: IFlashCard, onSuccess: () => void) => Promise<void>
  editFlashCardLoading: boolean
  error: string
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { editFlashCardMutation } = editFlashCardService()

  const editFlashCard = async (
    id: string,
    input: IFlashCard,
    onSuccess: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { data, errors } = await editFlashCardMutation({
      variables: {
        id,
        input,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        message = `${t('general.user')} ${t('messages.already_registered')}`
        setError(message)
        return
      }
      variant = 'error'
    } else {
      console.log('data', data)
      onSuccess()
      message = `${t('general.flash_card')} ${t('messages.edited')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return {
    editFlashCard,
    editFlashCardLoading: loading,
    error,
  }
}

export default useEditFlashCardService
