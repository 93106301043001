import { useMutation, MutationTuple, ApolloError } from '@apollo/client'
import { updateCache } from 'services/updateCache'
import {
  GET_SKILL_TEST_VIDEOS_TEMPLATES,
  DELETE_SKILL_TEST_VIDEO_TEMPLATE,
} from 'gql/skillTestVideos.query'

interface IDeleteSkillTestVideoTemplateResponse {
  deleteSkillTestVideoTemplate: string
}

interface IDeleteSkillTestVideoTemplateVariables {
  ids: string[]
}

interface IDeleteSkillTestVideoTemplateServiceResponse {
  deleteSkillTestVideoTemplateMutation: MutationTuple<
    IDeleteSkillTestVideoTemplateResponse,
    IDeleteSkillTestVideoTemplateVariables
  >[0]
  loading: boolean
  error?: ApolloError
}

export interface IDeleteSkillTestVideoTemplateServiceParams {
  moduleId: string
}

const deleteSkillTestVideoTemplateService = (
  params: IDeleteSkillTestVideoTemplateServiceParams,
): IDeleteSkillTestVideoTemplateServiceResponse => {
  const [deleteSkillTestVideoTemplateMutation, { loading, error }] = useMutation<
    IDeleteSkillTestVideoTemplateResponse,
    IDeleteSkillTestVideoTemplateVariables
  >(DELETE_SKILL_TEST_VIDEO_TEMPLATE, {
    update(cache, { data }) {
      if (data?.deleteSkillTestVideoTemplate) {
        const document = data.deleteSkillTestVideoTemplate
        updateCache({
          objectName: 'getSkillTestVideosTemplates',
          query: GET_SKILL_TEST_VIDEOS_TEMPLATES,
          cache,
          document,
          action: 'deleteMany',
          params,
        })
      }
    },
  })

  return {
    deleteSkillTestVideoTemplateMutation,
    loading,
    error,
  }
}

export default deleteSkillTestVideoTemplateService
