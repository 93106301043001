import React, { useState, useEffect, ChangeEvent, ReactElement } from 'react'
import {
  PriceContainer,
  StyledTextContainer,
  InputFieldContainer,
  FieldItemContainer,
  FormItemWrapper,
  FullWidthContainer,
} from './styled-components'
import CreatableSelect from 'react-select/creatable'

import Checkbox from 'components/common/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Uploader from 'components/common/FileUploader/Uploader'

import { FormFields, FormField } from 'components/common/Form'
import TextInput from 'components/common/TextInput/TextInput'
import Select from 'components/common/Select'
import Modal from 'components/common/Modal'
import ImageCropper from 'components/common/ImageCropper'
import { Button } from 'components/common/Button'
import { SelectField } from 'components/common/SelectField'
import { FormGroupWrapper, FormFooter, FormButtons } from 'components/common/Form'
import { completeCriterias, courseLevels, currencyList } from 'pages/courses/staticData'
import { Input } from 'components/common/TextInput'
import { customStyles } from 'components/common/SelectField/SelectField'
import { StyledFlexColumn } from '../AddCourseDrawer/styled-components'
import { Title } from 'components/DrawerLayout/styled-components'
import TextEditor from 'components/common/TextEditor/TextEditor'
import { FileTypes } from 'components/common/FileUploaderV2/uploader.interface'
import { CropperResultType } from 'interfaces/common'
import { Tab1Props } from 'interfaces/courses/useCourseForm/useCourseForm.interface'
import { ISelectDropDownOption } from 'components/common/SelectDropDown/selectDropDown.interface'

const Tab1 = ({
  values,
  errors,
  touched,
  handleChange,
  handleVideoChange,
  fileLoading,
  videoUploadProgress,
  handleUploadFile,
  setFieldValue,
  skillList,
  uploadImage,
  imageLoading,
  courseData,
  handleButtonClick,
  handleDeleteCourse,
  t,
}: Tab1Props): ReactElement => {
  const [file, setFile] = useState<
    | {
        name: string
        file: string
      }
    | string
  >('')
  const [cropperOpen, setCropperOpen] = useState<boolean>(false)
  const [imageType, setImageType] = useState('')
  const [courseOverview, setCourseOverview] = useState<string>('')

  useEffect(() => {
    if (courseData?.editorText) {
      setCourseOverview(courseData.editorText)
    }
  }, [])

  const deleteImage = (field: string): void => {
    setFile('')
    setFieldValue(field, null)
    setImageType('')
  }

  const cropperModalToggle = (): void => {
    setCropperOpen(!cropperOpen)
  }

  const handleCropSave = (field: string, croppedFile: CropperResultType): void => {
    uploadImage(croppedFile as File, `course/${imageType}`, (link: string) =>
      setFieldValue(field, {
        name: Date.now().toString(),
        link,
        fileType: 'png',
      }),
    )
  }

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>, field: string): void => {
    const { files, name } = e.target
    const reader = new FileReader()

    reader.onload = (): void => {
      if (files && files[0]) {
        const img = new Image()
        img.src = reader.result as string
        setFile({ name, file: reader.result as string })
        if (field === 'certificate') {
          handleCropSave('certificate', reader.result as string)
        }

        reader.readAsDataURL(files[0])
        if (field !== 'certificate') {
          setCropperOpen(true)
        }
        setImageType(field)
      }
    }
  }

  return (
    <>
      <FormGroupWrapper padding={10}>
        <FormFields>
          <FormField>
            <Input
              error={!!errors.name}
              errorMessage={errors.name ? errors.name : ''}
              touched={touched.name}
              title={t('form_fields.name')}
              required
              name='name'
              size='small'
              type='text'
              fullWidth
              value={values.name}
              onChange={handleChange}
            />
          </FormField>
          <FullWidthContainer>
            <Title>{t('form_fields.course_overview')}</Title>
            <TextEditor
              isVisible={true}
              hasControls={false}
              contentHeight='200px'
              text={courseOverview}
              handleChange={(editorState: string, plainText: string): void => {
                setFieldValue('editorText', editorState)
                setFieldValue('description', plainText)
              }}
              canEdit={true}
              defaultText={t('form_fields.course_overview')}
            />
          </FullWidthContainer>
          <FormField>
            <SelectField
              label={t('course_details.level')}
              error={!!errors.level}
              errorMessage={errors.level ? errors.level : ''}
              touched={touched.level}
              placeholder={t('course_details.level')}
              options={courseLevels}
              value={values.level}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => setFieldValue('level', e)}
              isClearable
            />
          </FormField>

          {values.coursePrivacy === 'public' && (
            <FormField>
              <PriceContainer>
                <StyledTextContainer>{t('form_fields.price_description')}</StyledTextContainer>
                <InputFieldContainer>
                  <FieldItemContainer width='160px'>
                    <TextInput
                      label={t('form_fields.price')}
                      name='price'
                      size='small'
                      type='number'
                      fullWidth
                      value={values.price}
                      onChange={handleChange}
                    />
                  </FieldItemContainer>
                  <FieldItemContainer width='120px'>
                    <Select
                      error={!!errors.currency}
                      touched={touched.currency}
                      label={t('form_fields.currency')}
                      name='currency'
                      items={currencyList}
                      value={values.currency}
                      onChange={handleChange}
                    />
                  </FieldItemContainer>
                </InputFieldContainer>
              </PriceContainer>
            </FormField>
          )}
          <FormField>
            <Title>{t('general.skills')}</Title>
            <CreatableSelect
              isMulti
              onChange={(e): void => {
                setFieldValue('skills', e as object)
              }}
              options={
                (skillList &&
                  skillList.sort((a, b) => {
                    const textA = a.label?.toUpperCase() || ''
                    const textB = b.label?.toUpperCase() || ''
                    return textA < textA ? -1 : textA > textB ? 1 : 0
                  })) ||
                []
              }
              value={values.skills as ISelectDropDownOption[]}
              styles={customStyles}
              isValidNewOption={(inputValue, _, selectOptions): boolean => {
                const exactValueExists = selectOptions.find(el => el.label === inputValue)
                // Check if the value has a valid length.
                // Without this, it will show create option for empty values.
                const valueIsNotEmpty = inputValue.trim().length
                // If true show create option.
                return !exactValueExists && !!valueIsNotEmpty
              }}
            />
          </FormField>
          <FormField width='48%'>
            <StyledFlexColumn>
              <Title $required>{t('actions.upload_cover_image')}</Title>
              <Uploader
                accept='image/x-png,image/gif,image/jpeg'
                onFileChange={(e): void => handleImageChange(e, 'avatar')}
                deleteFile={(): void => deleteImage('avatar')}
                type={FileTypes.IMAGE}
                inputValue={values.avatar ? values.avatar.name : ''}
                onInputChange={(e): void => setFieldValue('avatar.name', e.target.value)}
                disabled={!values.avatar}
                uploadedFile={values.avatar}
                fileExtension={(values.avatar && `(${values.avatar.link.split('.').pop()})`) || ''}
                loading={imageLoading === 'avatar'}
                id='avatar'
              />
              {errors.avatar && <span style={{ color: '#f44336' }}>{errors.avatar}</span>}
            </StyledFlexColumn>
          </FormField>
          <FormField width='48%'>
            <Title>{t('actions.upload_intro_video')}</Title>
            <Uploader
              accept='video/*'
              onFileChange={handleVideoChange}
              deleteFile={(): void => handleVideoChange()}
              type={FileTypes.VIDEO}
              inputValue={values.video ? (values.video.title as string) : ''}
              onInputChange={(e): void => setFieldValue('video.title', e.target.value)}
              disabled={!values.video}
              uploadedFile={values.video}
              fileExtension={
                (values.video && `(${values.video?.links && values.video.links[2]?.type})`) || ''
              }
              loading={videoUploadProgress > 0 ? videoUploadProgress : false}
              id='video'
            />
          </FormField>

          {values.video && (
            <FormField>
              <FormItemWrapper>
                <Uploader
                  label='Upload video subtitle'
                  accept='.vtt, .srt'
                  onFileChange={(e): void => handleUploadFile(e, 'subtitle')}
                  deleteFile={(): void => deleteImage('subtitle')}
                  type={FileTypes.FILE}
                  inputValue={values.subtitle ? values.subtitle.name : ''}
                  onInputChange={(e): void => setFieldValue('subtitle.name', e.target.value)}
                  disabled={!values.subtitle}
                  uploadedFile={values.subtitle}
                  fileExtension={values.subtitle ? '(vtt)' : ''}
                  loading={fileLoading === 'subtitle'}
                  id='subtitle'
                />
              </FormItemWrapper>
            </FormField>
          )}
          <FormField>
            <SelectField
              label={t('course_details.complete_criteria')}
              error={!!errors.completeCriteria}
              errorMessage={errors.completeCriteria ? errors.completeCriteria : ''}
              touched={touched.completeCriteria}
              placeholder={t('course_details.complete_criteria')}
              options={completeCriterias}
              value={values.completeCriteria}
              onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                setFieldValue('completeCriteria', e)
              }
            />
          </FormField>
          <FormField width='48%' style={{ height: 100 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.certificateIncluded}
                  onChange={(): void =>
                    setFieldValue('certificateIncluded', !values.certificateIncluded)
                  }
                  name='certificateIncluded'
                  color='primary'
                />
              }
              label={t('general.certificate_included')}
            />
          </FormField>

          <Modal isOpened={cropperOpen} onClose={cropperModalToggle}>
            <ImageCropper
              file={file}
              hideCropper={cropperModalToggle}
              getCroppedFile={(_, file): void => handleCropSave(imageType, file)}
            />
          </Modal>
        </FormFields>
      </FormGroupWrapper>

      <FormFooter>
        <FormButtons multiCols={true}>
          <Button
            text={t('actions.cancel')}
            type='small'
            onClick={handleDeleteCourse}
            background='#E0E1E2'
            textColor='#414141'
          />

          {((courseData && courseData.finished) || 0) < 2 && (
            <Button
              text={t('actions.save_as_draft')}
              type='small'
              color='secondary'
              onClick={(): void => handleButtonClick('draft')}
              textColor='#06C68F'
              background='#f5f5f5'
              outline='1px solid #06C68F'
            />
          )}

          <Button
            text={t('actions.save')}
            type='small'
            color='secondary'
            onClick={(): void => handleButtonClick('save')}
            background='#06C68F'
          />
        </FormButtons>
      </FormFooter>
    </>
  )
}

export default Tab1
