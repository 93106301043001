import React from 'react'
import { useQuery } from '@apollo/client'
import { useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  ActionsWrapper,
  Content,
  ContextMenu,
  QuestionTitle,
  StyledQuestionDetailContainer,
  StyledTableContainer,
} from './styled-components'
import { GET_QUESTION } from 'gql/questions.query'
import QuestionInfo from './QuestionInfo'
import QuestionFiles from './QuestionFiles'
import QuestionAnswers from './QuestionAnswers'
import Breadcrumb from 'components/common/BreadCrumbV2'
import { ReactComponent as Learning } from 'components/common/Button/icons/list-checked.svg'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { ReactComponent as MoreIcon } from 'components/common/Button/icons/drop.svg'
import { actionConfig } from 'pages/questions/QuestionDetails/actionConfig'
import { useUserValue } from 'context/UserContext'
import useQuestionsLayout from 'hooks/questions/useQuestionsLayout'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useSwal } from 'hooks/useSwal'
import { IModule } from 'pages/modules/ModulesList.interface'
import { IUseQuestionsLayoutReturn } from 'hooks/questions/useQuestionsLayout.interface'

type BreadcrumbItem = {
  label: string
  link: string
  state?: object
  icon?: React.ReactNode
}

interface Props {
  currentModule: IModule
}

const QuestionDetails = ({ currentModule }: Props): React.JSX.Element => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const { fireSwal } = useSwal()
  const { id = '' } = useParams<{ id: string }>()
  const hasModule = id.includes('module')
  const { state: routerState } = useLocation()

  const { data, loading, error } = useQuery(GET_QUESTION, {
    variables: {
      questionId: id,
      type: hasModule ? 'module' : '',
    },
  })
  const handleEditClick = (id: string): void => {
    const title: string = question?.currentModule
      ? t('popups.edit_question_in_module')
      : t('popups.edit_question_gerenal')
    fireSwal({
      title,
      confirmText: t('popups.confirm_edit'),
      onConfirm: () => {
        DrawerEventEmitter.emit('openDrawer', 'editQuestion', true, {
          id,
          data: {
            moduleId: question?.currentModule ? question?.currentModule?.moduleId : '',
            name: question?.currentModule ? question?.currentModule?.name : '',
          },
        })
      },
    })
  }

  const { handleDeleteClick } = useQuestionsLayout({
    currentModule,
  }) as IUseQuestionsLayoutReturn

  const actions = actionConfig(handleEditClick, handleDeleteClick, t)

  if (loading) return <div>Loading...</div>

  if (error) return <div>Something went wrong</div>

  const question = (data && data.question) || {}

  const canModify =
    !state.selectedCompany?.id || question.companyId === (state.selectedCompany?.id || null)

  let breadCrumbData: BreadcrumbItem[] = []
  if (data && question) {
    if (question.currentModule) {
      breadCrumbData = [
        {
          label:
            (routerState?.from && `${t('general.course')} ${t('general.modules')}`) ||
            t('general.modules'),
          link: routerState?.from || '/modules',
          state: routerState?.state || {},
          icon: <Learning />,
        },
        {
          label: question.currentModule.name,
          link: `/modules/${question.currentModule.moduleId}`,
          state: routerState,
        },
        { label: t(`question_details.${question.type.value}`), link: '' },
      ]
    } else {
      breadCrumbData = [
        {
          label: t('general.questions'),
          link: '/questions',
          icon: <Learning />,
        },
        { label: t(`question_details.${question.type.value}`), link: '' },
      ]
    }
  }

  return (
    <>
      <Breadcrumb data={breadCrumbData} />

      <StyledQuestionDetailContainer>
        <ContextMenu>
          {canModify && (
            <ActionsWrapper>
              <GridActionMenu actionConfig={actions} row={question} button={<MoreIcon />} />
            </ActionsWrapper>
          )}
        </ContextMenu>
        <Content>
          <QuestionFiles
            images={question.images}
            documents={question.documents}
            videos={question.videos}
            audios={question.audios}
            t={t}
          />
          <QuestionTitle>{question.question}</QuestionTitle>
          <QuestionAnswers
            type={question.type.value}
            boolAnswer={question.boolAnswer !== null ? question.boolAnswer.toString() : ''}
            answers={question.answers}
            t={t}
          />
        </Content>
      </StyledQuestionDetailContainer>
      <StyledTableContainer>
        <QuestionInfo
          type={question.type.label}
          tags={question.tags}
          modules={question.modules}
          currentModule={question.currentModule}
          documents={question.documents}
          audios={question.audios}
          question={question}
          t={t}
        />
      </StyledTableContainer>
    </>
  )
}

export default QuestionDetails
