import { useState, useEffect, useRef } from 'react'
import {
  IConditionalTooltipProps,
  IConditionalTooltipPropsReturn,
} from './useConditionalTooltip.interface'

const useConditionalTooltip = ({
  title,
  maxWidth,
}: IConditionalTooltipProps): IConditionalTooltipPropsReturn => {
  const [showTooltip, setShowTooltip] = useState(false)
  const contentRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const checkWidth = (): void => {
      const width = contentRef.current?.offsetWidth ?? 0
      setShowTooltip(width >= maxWidth)
    }

    checkWidth()

    window.addEventListener('resize', checkWidth)
    return (): void => window.removeEventListener('resize', checkWidth)
  }, [maxWidth])

  return { showTooltip, contentRef, title }
}

export default useConditionalTooltip
