import moment, { MomentInput } from 'moment'
import { IUser } from 'utils/utils.interface'

export const generateFullName = (user: IUser): string => {
  if (!user) return ''
  if (!user.firstName && !user.lastName) return user.email || ''
  return `${user.firstName || ''} ${user.lastName || ''}`.trim()
}

export const formatDateAgo = (dateable: MomentInput): string => {
  const str = moment(dateable).fromNow()

  if (str.includes('few')) {
    return '1m'
  }

  const split = str.split(' ')

  const numb = split[0] === 'a' || split[0] === 'an' ? 1 : split[0]

  let char = split[1][0]

  if (split[1].includes('month')) {
    char = 'M'
  }

  return `${numb}${char}`
}

export const getUserRole = (role: string): string => {
  if (!role) return ''
  const text = role.toLowerCase().split('_')

  if (text && text.length > 1) {
    return `${text[0]} ${text[1]}`
  }

  return text[0]
}
