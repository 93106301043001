import { IUseHelperModalCloseProps } from './useHelperModalClose.interface'

export const useHelperModalClose = ({
  setPushNotificationModal,
  setMessageData,
}: IUseHelperModalCloseProps): (() => void) => {
  const handleModalClose = (): void => {
    setPushNotificationModal(false)
    setTimeout(() => {
      setMessageData({
        heading: '',
        body: '',
      })
    }, 500)
  }
  return handleModalClose
}
