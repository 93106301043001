import React from 'react'
import DndItem from 'components/common/Grid/DndGrid'
import { IModule, IModulesList } from './ModulesList.interface'
import useModulesList from 'hooks/modules/useModuleList'

const ModulesList = ({
  data,
  isDraggable = false,
  gridStyle,
  actions,
  selectedItem,
  handleVideoModal,
}: IModulesList): React.JSX.Element => {
  const { cards, moveCard, handleChangeRequest, renderContent } = useModulesList({
    data,
    isDraggable: false,
    gridStyle,
    actions,
    selectedItem,
    handleVideoModal,
  })

  return (
    <>
      {cards.map((item: IModule, index: number) =>
        isDraggable ? (
          <DndItem
            key={item.id}
            id={item.id}
            index={index}
            moveCard={moveCard}
            cardIds={cards.map((item: { id: string }) => item.id)}
            onEnd={handleChangeRequest}
          >
            <div className='module-wrapper'>{renderContent(item)}</div>
          </DndItem>
        ) : (
          <React.Fragment key={item.id}>
            <div className='module-wrapper'>{renderContent(item)}</div>
          </React.Fragment>
        ),
      )}
    </>
  )
}

export default ModulesList
