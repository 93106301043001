import { styled } from 'styled-components'

export const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const TagContainer = styled.div`
  border: 1px solid #e7e9ed;
  width: fit-content;
  white-space: nowrap;
  border-radius: 5px;
  color: #68707a;
  margin: 5px;
  padding: 4px 10px;
  cursor: pointer;
`

export const CenteredParagraphContainer = styled.div`
  text-align: center;
  align-items: center;
  width: 100%;
`

export const CenteredParagraph = styled.p`
  color: #68707a;
  font-size: 16px;
  line-height: 24px;
`
