import 'cropperjs/dist/cropper.css'
import useOutsideClick from 'helpers/useOutsideClick'
import { CompanyImage } from 'pages/companies/CompanyDetails/styled-components'
import React, { useRef, useState, useEffect, ReactElement } from 'react'
import { AvatarSettingContainer, StyledAvatarWrapper } from './styled-components'
import ProfileIcon from '../../../pages/profile/AdminProfile/icons/profile-picture.svg'
import { IEditableAvatarV2Props } from './EditableAvatarV2.interface'

export const EditableAvatarV2 = ({
  img,
  isRectangular,
  hasResponseReceived,
}: IEditableAvatarV2Props): ReactElement => {
  const [avatarMenu, setAvatarMenu] = useState(false)
  const ref: React.RefObject<HTMLDivElement> = useRef() as React.RefObject<HTMLDivElement>

  const [loading] = useState(false)
  const [loadingForDelete, setLoadingForDelete] = useState(false)

  useEffect(() => {
    // loading && setLoading(!hasResponseReceived)
    loadingForDelete && setLoadingForDelete(!hasResponseReceived)
  }, [hasResponseReceived, loading, loadingForDelete])

  useOutsideClick(ref, () => {
    if (avatarMenu) setAvatarMenu(false)
  })

  const toggleAvatarMenu = (): void => {
    setAvatarMenu(!avatarMenu)
  }

  return (
    <div>
      <AvatarSettingContainer ref={ref}>
        {isRectangular ? (
          <CompanyImage onClick={toggleAvatarMenu}>
            <img src={img} alt='' />
          </CompanyImage>
        ) : (
          <StyledAvatarWrapper>
            <img src={img || ProfileIcon} alt='' />
          </StyledAvatarWrapper>
        )}
      </AvatarSettingContainer>
    </div>
  )
}
