import styled from 'styled-components'

export const StyledUserContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledCommentContainer = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  align-self: stretch;
  padding-left: 32px;
`
export const AttachmentContainer = styled.div`
  display: flex;
  padding-left: 32px;
  gap: 4px;
`

export const styles = {
  avatar: {
    width: '26px',
    height: '26px',
    borderRadius: '4px',
  },
  fileIcon: {
    width: '16px',
    height: '16px',
  },

  time: {
    fontSize: '12px',
    color: '#9CA1AB',
    fontWeight: 400,
    lineHeight: '150%',
  },
  name: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '150%',
    color: '#020210',
    fontFamily: 'Open Sans',
  },

  fileTitle: {
    color: '#68707A',
    fontSize: '14px',
    fontStyle: ' normal',
    fontWeight: 400,
    lineHeight: '20px',
  },

  data: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '150%',
    margin: '0',
  },
}

export const TextWithLine = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  &:after {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: #020210;
    margin-left: 10px;
  }
`

export const StyledRoleContainer = styled.div`
  display: flex;
  height: 24px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  color: #20a8c6;
  background: #f2f7fb;
`
export const StyledDrawerContainer = styled.div`
  height: 100%;
  /* box-sizing: inherit; */
  /* position: relative; */
  .MuiContainer-root {
    padding-left: 0 !important;
  }
  /* overflow-y: scroll; */

  /* padding: 16px; */
`

export const StyledCommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  /* margin-bottom: 20px; */
  height: fit-content;
  gap: 16px;
`

export const StyledUserDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const FormFooter = styled.footer`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  background: #fff;
  height: 60px;
  position: fixed;
  bottom: 0;
`

export const StyledDataContainer = styled.div`
  display: flex;
`

export const Container = styled.div`
  height: fit-content;
`

export const FormButtons = styled.div`
  align-self: flex-end;
  display: flex;
  width: 100%;
  padding-bottom: 4px;

  div:not(:last-child) {
    margin-right: 10px;
  }
`
