import React from 'react'
import UsersLayout from 'components/UsersLayout'
import useAdminsLayout from 'hooks/admins/useAdminsLayout'

interface AdminsLayoutProps {
  showBreadCrumb?: boolean
  groupId?: string
}

const AdminsLayout = ({ showBreadCrumb = true, groupId = '' }: AdminsLayoutProps): JSX.Element => {
  const { handleGroupClick } = useAdminsLayout()

  return (
    <div>
      <UsersLayout
        groupId={groupId}
        showBreadCrumb={showBreadCrumb}
        role='ADMIN'
        addText='Add Admin'
        addUserDrawer='addAdmin'
        editUserDrawer='editAdmin'
        onGroupClick={handleGroupClick}
      />
    </div>
  )
}

export default AdminsLayout
