import React, { ReactElement, useState, MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { Wrapper, Title, PopoverContent } from './styled-components'
import Popover from '@mui/material/Popover'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ProfilePicture from '../common/ProfilePicture/ProfilePicture'
import useAuth from 'hooks/useAuth'
import { ReactComponent as LogoutIcon } from 'assets/logout.svg'
import { ReactComponent as MyProfileIcon } from 'assets/my-profile.svg'
import { IUser } from 'interfaces/users'
import { useTranslation } from 'react-i18next'

const ProfileDropdown = ({ user }: { user: IUser }): ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { logout } = useAuth()
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const handleClick = (event: MouseEvent<HTMLSpanElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleProfileClick = (): void => {
    handleClose()
    navigate(`/user-profile/${user.id}`)
  }

  const onLogoutClick = (): void => {
    handleClose()
    logout()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <Wrapper role='button' open={open} aria-describedby={id} onClick={handleClick}>
        <ProfilePicture
          imgSource={
            user.avatarThumbnail ||
            user.avatar ||
            'https://ib-assets-public.s3.eu-west-2.amazonaws.com/profilePicture.svg'
          }
          width={25}
          height={25}
        />
        <Title>{`${user.firstName} ${user.lastName}`}</Title>
        <ExpandMoreIcon
          style={{
            color: '#02020f',
            transition: 'transform 0.3s',
            transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        />
      </Wrapper>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: {
            style: {
              borderRadius: 8,
            },
          },
        }}
      >
        <PopoverContent>
          <div onClick={handleProfileClick}>
            <div>
              <MyProfileIcon />
            </div>
            <span>{`${t('general.my')} ${t('general.profile')}`}</span>
          </div>
          <div onClick={onLogoutClick}>
            <div>
              <LogoutIcon />
            </div>
            <span>{t('actions.log_out')}</span>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default ProfileDropdown
