import { FormikErrors, FormikTouched } from 'formik'
import { Images } from 'pages/modules/FlashCards/interfaces'
import { TFunction } from 'interfaces/TFunction'
import { IFlashCard } from '../FlashCardsLayout/useModuleDetailContent.interface'
import { SyntheticEvent } from 'react'

export interface IUseEditFlashCardDrawerReturn {
  loading: boolean
  editFlashCardLoading: boolean
  t: TFunction<'translation', undefined>
  handleCloseDrawer: () => void
  handleSubmit: () => void
  errors: FormikErrors<IFlashCard>
  touched: FormikTouched<IFlashCard>
  values: IFlashCard
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  setFieldValue: (field: string, value: string | number | boolean, shouldValidate?: boolean) => void
  uploadedImage: string
  setOpenedModal: (value: string) => void
  imageLoading: boolean
  setImage: (value: string) => void
  setUploadedImage: (value: string) => void
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleAudioChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  setAudioProgress: (value: number | null) => void
  audioProgress: number | null
  openedModal: string
  handleModalClose: () => void
  color: string
  setColor: (value: string) => void
  currentTab: string
  images: Images
  handleTabChange: (event: SyntheticEvent, newValue: string) => void
  image: string
  handleModalSubmit: () => void
}

export interface IEditFlashCard {
  question: string
  answer: string
  image: string
  audio: { name: string; fileType: string; link: string }
  color: string
}

export const formData: IFlashCard = {
  question: '',
  answer: '',
  image: '',
  color: '',
}
