import React from 'react'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { ReactComponent as MoreIcon } from 'components/common/Button/icons/drop.svg'
import Modal from 'components/common/Modal'
import Player from 'components/common/VideoPlayer/Player'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { formatDuration } from 'helpers/formatDuration'
import {
  CourseContentWrapper,
  CourseContentRight,
  CourseContentVideoCover,
  CourseContentPlayIcon,
} from 'components/courseDetail/styled-component'

import {
  ModuleContentDetailsContainer,
  ModuleContentDetailsHeader,
  ModuleContentDetails,
  ModuleContentDetail,
  ModulesContentWrapper,
  StyledModuleDetailsFlex,
  ModuleContentDetailsContainerFlex,
  ModuleContentDetailsRight,
  styles,
  ActionsWrapper,
  StyledModuleDetailsFlexBottom,
} from './styled-components'
import { TagsGrid } from 'pages/tests/testDetails/styled-components'
import Tags from 'components/common/Tags/Tags'
import { CenteredText } from 'pages/tests/testLayout/styled-components'
import useModuleDetailsContent from 'hooks/modules/FlashCardsLayout/useModuleDetailContent'
import { ModuleDetailsContentProps } from '../ModuleDetailContent.interface'

const ModuleDetailsContent = ({
  moduleItem,
  t,
  canRedirect,
  refetch,
  topicsLength,
}: ModuleDetailsContentProps): React.JSX.Element => {
  const { video, setVideoModal, state, actions, handleCourseClick, videoModal } =
    useModuleDetailsContent({
      moduleItem,
      t,
      canRedirect,
      refetch,
      topicsLength,
    })

  return (
    <ModulesContentWrapper>
      <CourseContentWrapper>
        <CourseContentRight style={{ textAlign: 'right' }} className={!video ? 'noVideo' : ''}>
          {video ? (
            <CourseContentVideoCover>
              <img src={video.thumbnail} />
              <CourseContentPlayIcon onClick={(): void => setVideoModal(true)}>
                <PlayCircleOutlineIcon fontSize='large' style={{ color: '#06C68F' }} />
              </CourseContentPlayIcon>
            </CourseContentVideoCover>
          ) : null}
        </CourseContentRight>
      </CourseContentWrapper>
      <ModuleContentDetailsContainer>
        <ModuleContentDetailsHeader>
          <h3>
            {' '}
            <strong>{moduleItem.name}</strong>{' '}
          </h3>
          <div style={{ display: 'inline-flex' }}>
            {!moduleItem.companyId && state.currentUser.companyId ? null : (
              <ActionsWrapper>
                <GridActionMenu actionConfig={actions} button={<MoreIcon />} />
              </ActionsWrapper>
            )}
          </div>
        </ModuleContentDetailsHeader>
        <ModuleContentDetailsContainerFlex>
          <ModuleContentDetails>
            <h4 style={styles.detailHeader}>{t('general.details')}</h4>

            <StyledModuleDetailsFlex>
              <ModuleContentDetail>
                <p style={styles.detailTitle}>{t('course_details.duration')}:</p>{' '}
                {formatDuration(moduleItem.duration)}
              </ModuleContentDetail>
              <ModuleContentDetail>
                <p style={styles.detailTitle}>{t('general.topics')}:</p>
                {moduleItem.numberOfTopics}
              </ModuleContentDetail>

              <ModuleContentDetail>
                <p style={styles.detailTitle}>{t('general.lessons')}:</p>
                {moduleItem.numberOfLessons}
              </ModuleContentDetail>
            </StyledModuleDetailsFlex>
            <StyledModuleDetailsFlexBottom>
              <ModuleContentDetail>
                <p style={styles.detailTitle}>{t('general.flashCards')}:</p>
                {moduleItem.numberOfFlashCards}
              </ModuleContentDetail>
              <ModuleContentDetail>
                <p style={styles.detailTitle}>{t('general.quizzes')}:</p>
                {moduleItem.numberOfQuizzes}
              </ModuleContentDetail>
            </StyledModuleDetailsFlexBottom>
          </ModuleContentDetails>
          <ModuleContentDetailsRight>
            <h4 style={styles.detailHeader}>{t('modules_layout.linked_courses')}</h4>
            {moduleItem.courses ? (
              <TagsGrid>
                <Tags
                  tags={moduleItem?.courses}
                  type='courses'
                  redirectInDetails={(index: number): false | void =>
                    canRedirect && handleCourseClick(index)
                  }
                />
              </TagsGrid>
            ) : (
              <CenteredText>{t('modules_layout.no_courses')}</CenteredText>
            )}
          </ModuleContentDetailsRight>
        </ModuleContentDetailsContainerFlex>
      </ModuleContentDetailsContainer>

      <Modal isOpened={videoModal} onClose={(): void => setVideoModal(false)}>
        <Player url={video && video.links[0].url} subtitle={moduleItem.subtitle} />
      </Modal>
    </ModulesContentWrapper>
  )
}

export default ModuleDetailsContent
