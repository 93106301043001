import React from 'react'
import { ReactComponent as EditIcon } from 'assets/edit.svg'
import { ReactComponent as TrashCanRed } from 'assets/trash-can-red.svg'
import { TFunction } from 'interfaces/TFunction'
import { Action } from '../AddBookDrawer/styled-components'
import { DeleteSpan } from './styled-components'
import { IAction } from 'components/common/GridV2'

export type Handler = (bookId: string) => void

export const actionConfig = (
  handleEditClick: Handler,
  handleDeleteClick: Handler,
  handleDeleteBookFromGroup: Handler,
  t: TFunction<'translation', undefined>,
  enableGroupActions: boolean,
): IAction[] => {
  const actions: IAction[] = [
    {
      hide: !enableGroupActions,
      render: item => (
        <Action
          className='remove'
          onClick={(): void => {
            handleDeleteBookFromGroup(item.id)
          }}
        >
          <TrashCanRed />
          <DeleteSpan>{t('actions.remove_book')}</DeleteSpan>
        </Action>
      ),
    },
    {
      hide: enableGroupActions,
      render: item => (
        <Action className='edit' onClick={(): void => handleEditClick(item.id)}>
          <EditIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: enableGroupActions,
      render: item => (
        <Action
          className='delete'
          onClick={(): void => {
            handleDeleteClick(item.id)
          }}
        >
          <TrashCanRed />
          <DeleteSpan>{t('actions.delete')}</DeleteSpan>
        </Action>
      ),
    },
  ]

  return actions.filter(action => !action.hide)
}
