import { useState } from 'react'
import addQuestionsToModuleService from 'services/questions/addQuestionsToModuleService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import { IModule } from 'pages/modules/ModulesList.interface'

export const useAddQuestionsToModule = (): {
  addQuestionsToModule: (
    module: IModule,
    questionIds: string[],
    successCallback: () => void,
  ) => Promise<void>
  addQuestionsToModuleLoading: boolean
  error: string
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const { addQuestionsToModuleMutation, loading } = addQuestionsToModuleService()

  const addQuestionsToModule = async (
    module: IModule,
    questionIds: string[],
    successCallback: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await addQuestionsToModuleMutation({
      variables: {
        module,
        questionIds,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        message = `${t('general.user')} ${t('messages.already_registered')}`
        setError(message)
        return
      }
      variant = 'error'
    } else {
      successCallback()
      message = t('messages.added_questions_to_module')
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    addQuestionsToModule,
    addQuestionsToModuleLoading: loading,
    error,
  }
}
