import React, { useState, useEffect, ReactElement } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { Container, InputContainer, Input, Label, ErrorMessage } from './styled-components'
import countryList from './countryCode'
import ReactSelect, { components, OptionProps, ValueType } from 'react-select'
import { ISelectFilterOption } from 'interfaces/common'
import { ComponentProps, PhoneCountryCodeProps } from '../PhoneCountyCodeV2/PhoneCountyCode'

const PhoneCountryCode = (props: PhoneCountryCodeProps): ReactElement => {
  const [value, setValue] = useState<ISelectFilterOption | null>(null)

  const {
    label,
    selectLabel,
    selectValue,
    selectChange,
    inputLabel,
    inputValue,
    inputChange,
    touched,
    error,
    errorMessage,
  } = props

  const { Option } = components
  const CustomSelectFilterOption = (
    props: OptionProps<ISelectFilterOption, false>,
  ): ReactElement => (
    <Option {...props}>
      <ReactCountryFlag countryCode={props.data.value} /> {props.data.label}
    </Option>
  )

  const country = countryList().map(i => ({
    label: i.dial_code,
    value: i.code,
  })) as ISelectFilterOption[]
  const Component = ({ dialCode, code }: ComponentProps): ReactElement => {
    return (
      <div>
        <ReactCountryFlag countryCode={code} /> {dialCode}
      </div>
    )
  }

  useEffect(() => {
    if (selectValue && selectValue.value) {
      setValue({
        label: <Component dialCode={selectValue.label as string} code={selectValue.value} />,
        value: selectValue.value,
      })
    }
  }, [selectValue])

  const handleChange = (e: ValueType<ISelectFilterOption, false>): void => {
    if (!e) {
      setValue(null)
      selectChange(null)
      return
    }
    setValue({
      label: <Component dialCode={e.label as string} code={e.value} />,
      value: e.value,
    })
    selectChange(e)
  }

  const errorField = touched && error && errorMessage

  return (
    <Container error={!!errorField}>
      <Label error={!!errorField}>{label}</Label>
      <div style={{ width: '150px' }}>
        <ReactSelect
          placeholder={selectLabel}
          options={country}
          value={value}
          onChange={handleChange}
          components={{ Option: CustomSelectFilterOption }}
          isClearable
        />
      </div>
      <InputContainer>
        <Input
          name='phone'
          placeholder={inputLabel}
          onChange={inputChange}
          value={inputValue}
          // type="number"
        />
      </InputContainer>

      {touched && error && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  )
}

export default PhoneCountryCode
