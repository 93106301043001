import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'
import { IUser } from 'interfaces/users'
import { ICourse } from '../courseList.interface'

export enum ActionTypes {
  EDIT = 'edit',
  MODIFY_STUDENTS = 'modifyStudents',
  ASSIGN_TUTOR = 'assignTutor',
  DELETE = 'delete',
}

export interface itemProps {
  name: string
  description: string
  location: string
  isChecked?: boolean
  id: string
  last?: string
  numberOfStudents?: number | string
  admin: IUser[]
  created: string
  status: GridStatusEnums
  companyId: string
  courses: ICourse[]
  company: Company
}

export interface Company {
  id: string
  name: string
}
