import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { getToken } from 'helpers/localStorage'
import { useUserValue } from 'context/UserContext'
import { checkRole } from 'helpers/checkRole'
import { IUserCompany } from 'interfaces/users'

interface IPrivateRouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>
  exact?: boolean
  protectedFromConsultants?: boolean
  roleAccess?: string[]
  protectedWithCompanyId?: boolean
  path?: string
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({
  component: Component,
  roleAccess = [],
  protectedWithCompanyId = false,
  ...rest
}) => {
  const [state] = useUserValue()
  const token = getToken()
  const isAuthenticated = token ? true : false
  const { id: paramId } = useParams()

  if (!isAuthenticated) {
    return <Navigate to='/login' />
  }

  if (roleAccess.length === 0) {
    return <Component {...rest} />
  }

  if (state.currentUser && state.currentUser.role) {
    const userCompany = state.currentUser?.companyId as IUserCompany
    const currentUserCompanyId = userCompany?.id

    const role = checkRole(state.currentUser.role, !!userCompany)

    if (roleAccess.includes(role)) {
      if (!protectedWithCompanyId || !currentUserCompanyId) {
        return <Component {...rest} />
      }

      if (currentUserCompanyId === paramId) {
        return <Component {...rest} />
      }

      return <Navigate to='/' />
    } else if (currentUserCompanyId) {
      return <Navigate to={`/Companies/${currentUserCompanyId}`} />
    }
  }

  return <Navigate to='/' />
}

export default PrivateRoute
