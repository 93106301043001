import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Header = styled.header`
  padding: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const FormFooter = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.3);

  & > :first-child {
    width: 30%;
  }

  & nav {
    margin-top: 0;
    display: flex;
    justify-content: flex-end;
  }

  & > :last-child {
    width: 70%;
  }
`
export const FormButtons = styled.div`
  display: flex;
  padding: 20px;
  gap: 10px;
`
export const StyledFlex = styled.div`
  display: flex;
  gap: 8px;

  .css-yk16xz-control,
  .css-2b097c-container {
    height: 100%;
  }
`
export const InputWrapper = styled.div`
  width: 100%;
`
export const GridHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 12px;
`
export const QuestionsContainer = styled.div`
  overflow-y: auto;
  max-height: 73vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
