import { updateProfileAvatarService } from 'services/companies/editCompanyAvatarService'
import { useTranslation } from 'react-i18next'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'

export interface IUseUpdateCompanyAvatar {
  updateAvatar: (
    id: string,
    action: string,
    avatar?: string | null,
    crudFunction?: (hasResponseReceived: boolean) => void,
  ) => Promise<void>
  hasResponseReceived: boolean
}

export const useUpdateCompanyAvatar = (): IUseUpdateCompanyAvatar => {
  const { t } = useTranslation()
  const { updateCompanyAvatar } = updateProfileAvatarService()
  const { setSnackbar } = useSnackbarAlert()

  const updateAvatar = async (
    id: string,
    action: string,
    avatar?: string | null,
    crudFunction?: (hasResponseReceived: boolean) => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    let hasResponseReceived = false

    const { errors } = await updateCompanyAvatar({
      variables: {
        id,
        action,
        avatar: avatar || '',
      },
    })

    if (errors) {
      hasResponseReceived = true
      message = errors[0].message
      variant = 'error'
    } else {
      hasResponseReceived = true
      if (action === 'ADD') {
        message = t('messages.changes_saved')
      } else {
        message = `${t('general.avatar')} ${t('messages.successfully')} ${t('general.deleted')}`
      }

      variant = 'success'
    }

    setSnackbar({ message, variant })

    if (crudFunction) crudFunction(hasResponseReceived)
  }

  return {
    updateAvatar,
    hasResponseReceived: false,
  }
}
