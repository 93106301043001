import { useState } from 'react'
import editGroupService, { IEditGroupServiceParams } from 'services/group/editGroupService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import { IGroupDrawerFormData } from './useGroupDrawers/useGroupDrawer.interface'

const useEditGroup = (
  params?: IEditGroupServiceParams,
): {
  loading: boolean
  editGroup: (payload: IGroupDrawerFormData, id: string, refetch: () => void) => Promise<void>
  assignCoach: (
    groupId: string,
    tutorId?: string,
    courseId?: string,
    cb?: () => void,
  ) => Promise<void>
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [loading, setLoading] = useState(false)
  const { editGroupMutation, assignTutorMutation } = editGroupService(params)

  const editGroup = async (
    payload: IGroupDrawerFormData,
    id: string,
    refetch: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)
    const { errors } = await editGroupMutation({
      variables: { id, input: payload },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
      setLoading(false)
    } else {
      message = `${t('general.group')} ${t('messages.edited')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
    setLoading(false)
    refetch()
  }

  const assignCoach = async (
    groupId: string,
    tutorId?: string,
    courseId?: string,
    cb?: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)
    const { errors } = await assignTutorMutation({
      variables: {
        groupId,
        tutorId,
        courseId,
      },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = t('messages.success')
      variant = 'success'

      if (cb) cb()
    }

    setSnackbar({ message, variant })
    setLoading(false)
  }

  return {
    loading,
    editGroup,
    assignCoach,
  }
}

export default useEditGroup
