import { gql } from 'graphql.macro'

export const GET_MODULES_OPTIONS = gql`
  query GetModulesOptions($filter: CourseFilter, $currentPage: Int, $perPage: Int) {
    allModules(filter: $filter, currentPage: $currentPage, perPage: $perPage) {
      currentPage
      totalPages
      data {
        id
        name
      }
    }
  }
`

export const CHANGE_ORDER = gql`
  mutation ($draggedId: String!, $droppedId: String!) {
    changeModulesOrder(draggedId: $draggedId, droppedId: $droppedId)
  }
`

export const GET_MODULES = gql`
  query GetModules($filter: CourseFilter, $currentPage: Int, $perPage: Int) {
    allModules(filter: $filter, currentPage: $currentPage, perPage: $perPage) {
      currentPage
      totalPages
      data {
        id
        orderIndex
        name
        coverImage {
          name
          fileType
          link
        }
        description
        editorText
        createDate
        numberOfTopics
        numberOfLessons
        numberOfFlashCards
        numberOfQuizzes
        duration
        subtitle {
          name
          fileType
          link
        }
        courses {
          courseId
          name
        }
        attachment {
          name
          fileType
          link
        }
        video {
          name
          thumbnail
          title
          links {
            url
            fileType
            converted
          }
        }
        skillTestVideoDetails
      }
    }
  }
`

export const GET_MODULE = gql`
  query GetModule($moduleId: String!) {
    getModule(moduleId: $moduleId) {
      id
      name
      coverImage {
        name
        fileType
        link
      }
      description
      editorText
      createDate
      badgeData {
        id
        url
      }
      badgeEnabled
      numberOfLessons
      numberOfFlashCards
      numberOfQuizzes
      subtitle {
        name
        fileType
        link
      }
      courses {
        courseId
        name
      }
      attachment {
        name
        fileType
        link
      }
      companyId
      # video {
      #   name
      #   thumbnail
      #   title
      #   links {
      #     url
      #     fileType
      #     converted
      #   }
      # }
      certificateImage {
        name
        fileType
        link
      }
      topics {
        id
        name
        description
        attachment {
          name
          fileType
          size
          link
        }
        lessons {
          name
          id
          video {
            id
            name
            thumbnail
            title
            duration
            links {
              url
              fileType
              converted
            }
          }
          subtitle {
            name
            fileType
            link
          }

          attachment {
            name
            fileType
            link
          }
          duration
          audio {
            name
            duration
            link
            fileType
          }
          contentDocument {
            name
            fileType
            link
          }
          transcriptFile {
            name
            fileType
            link
          }
        }
      }
      numberOfQuestions
      numberOfTopics
      duration
      companyId
      skillTestVideoEnabled
      skillTestVideoDetails
      skillTestVideoCriteriaEnabled
      skillTestVideos {
        id
        status
        title
        description
        tips
        version
        coaches
        students
        isTemplate
      }
    }
  }
`

export const GET_COURSE_MODULES = gql`
  query GetCourseModules($courseId: String!, $type: String, $filter: CourseFilter) {
    getModulesByCourseId(courseId: $courseId, type: $type, filter: $filter) {
      id
      orderIndex
      name
      coverImage {
        name
        fileType
        link
      }
      description
      editorText
      createDate
      subtitle {
        name
        fileType
        link
      }
      topics {
        id
        name
        # lessons {
        #   id
        #   name
        # }
      }
      attachment {
        name
        fileType
        link
      }
      video {
        name
        thumbnail
        title
        links {
          url
          fileType
          converted
        }
      }
      skillTestVideoDetails
    }
  }
`

export const ADD_MODULE = gql`
  mutation AddModule($input: ModuleInput, $courseData: CourseData) {
    addModule(input: $input, courseData: $courseData) {
      id
      orderIndex
      name
      description
      editorText
      createDate
      numberOfTopics
      numberOfLessons
      duration
      skillTestVideoEnabled
      skillTestVideoCriteriaEnabled
      badgeEnabled
      badgeData {
        id
        url
      }
      skillTestVideoDetails
      coverImage {
        name
        fileType
        link
      }
      subtitle {
        name
        fileType
        link
      }
      courses {
        courseId
        name
      }
      video {
        name
        thumbnail
        title
        links {
          url
          fileType
          converted
        }
      }
      attachment {
        name
        fileType
        link
      }
      certificateImage {
        name
        fileType
        link
      }
    }
  }
`

export const ADD_COURSE_MODULE = gql`
  mutation AddCourseModule($courseData: CourseData, $moduleIds: [String!]) {
    addModulesToCourse(courseData: $courseData, moduleIds: $moduleIds) {
      id
      name
      coverImage {
        name
        fileType
        link
      }
      description
      editorText
      numberOfTopics
      numberOfLessons
      duration
      createDate
      subtitle {
        name
        fileType
        link
      }
      courses {
        courseId
        name
      }
      topics {
        id
        name
        # lessons {
        #   id
        #   name
        # }
      }
      attachment {
        name
        fileType
        link
      }
      video {
        name
        thumbnail
        links {
          url
          fileType
          converted
        }
      }
      skillTestVideoDetails
    }
  }
`

export const GENERATE_BADGE_DESIGN = gql`
  mutation generateBadgeDesign {
    generateBadgeDesign {
      id
      name
      kind
      url
    }
  }
`

export const DELETE_DESIGN = gql`
  mutation DeleteDesign($designId: Int) {
    deleteDesign(designId: $designId) {
      message
    }
  }
`

export const EDIT_MODULE = gql`
  mutation EditModule($moduleId: String!, $input: ModuleInput) {
    editModule(moduleId: $moduleId, input: $input) {
      id
      name
      description
      editorText
      createDate
      numberOfTopics
      numberOfLessons
      duration
      badgeData {
        id
        url
      }
      badgeEnabled
      skillTestVideoEnabled
      skillTestVideoCriteriaEnabled
      coverImage {
        name
        fileType
        link
      }
      subtitle {
        name
        fileType
        link
      }
      video {
        name
        thumbnail
        title
        links {
          url
          fileType
          converted
        }
      }
      attachment {
        name
        fileType
        link
      }
      certificateImage {
        name
        fileType
        link
      }
      skillTestVideoDetails
    }
  }
`

export const DELETE_MODULE = gql`
  mutation DeleteModule($ids: [String]) {
    deleteModule(ids: $ids) {
      id
      name
      description
      createDate
      numberOfTopics
      numberOfLessons
      duration
      subtitle {
        name
        fileType
        link
      }
      coverImage {
        name
        fileType
        link
      }
      video {
        name
        thumbnail
        title
        links {
          url
          fileType
          converted
        }
      }
      attachment {
        name
        fileType
        link
      }
      certificateImage {
        name
        fileType
        link
      }
      skillTestVideoDetails
    }
  }
`
