import { Props } from 'pages/modules/ModuleDetail/SkillTestVideos/SkillTestVideoItem/SkillTestVideoItem.interface'
import { IReturnType } from './useSkillTestVideoItem.interface'
import { useTranslation } from 'react-i18next'

const useSkillTestVideoItem = ({ skillTestVideo }: Props): IReturnType => {
  const { title, description = '', videosCount = 0 } = skillTestVideo
  const { t } = useTranslation()

  const renderText = (videosCount: number): string => {
    let text = ''

    if (videosCount === 0) {
      text = t('renderText.no_student')
    }

    if (videosCount === 1) {
      text = `${videosCount} ${t('renderText.student_added')}`
    }

    if (videosCount > 1) {
      text = `${videosCount} ${t('renderText.students_added')}`
    }

    return text
  }

  return {
    title,
    description,
    videosCount,
    renderText,
  }
}

export default useSkillTestVideoItem
