import config from '../config'
import { AxiosError, AxiosResponse } from 'axios'
import * as Sentry from '@sentry/react'
import { IDataPayload, IErrorDetails } from './index.interface'
const axios = require('axios').default

// Handle API errors and return structured error details
const handleError = (error: AxiosError): IErrorDetails => {
  const errorDetails: IErrorDetails = {
    message: error.message,
  }

  if (error.response) {
    // Error response from server
    errorDetails.responseData = error.response.data
    errorDetails.responseStatus = error.response.status
    errorDetails.responseHeaders = error.response.headers
  } else if (error.request) {
    // No response received
    errorDetails.requestData = error.request
  }

  console.error('API Error:', errorDetails)
  return errorDetails
}

// Make an API request with the specified method, URL, and data
export const Api = async (
  method: string,
  url: string,
  data: IDataPayload,
): Promise<AxiosResponse> => {
  try {
    const response = await axios({
      method,
      url: `${config.APIURL}/${url}`,
      data,
      timeout: 5000,
    })
    return response
  } catch (error) {
    const errorDetails = handleError(error as AxiosError)
    Sentry.captureException(errorDetails)
    return Promise.reject(error)
  }
}
