import { MutationTuple, useMutation } from '@apollo/client'

import { INSERT_STUDENTS } from 'gql/users.query'
import { IStudentValues } from 'hooks/users/services.interface'

interface IImportStudentsServiceResponse {
  insertStudents: MutationTuple<IImportStudentsResponse, IInsertStudentsParams>[0]
}

interface IInsertStudentsParams {
  students: IStudentValues[]
  companyId: string
  groupId: string
  groupName: string
}

interface IImportStudentsResponse {
  insertStudents: IStudentValues[]
}

const importStudentsService = (): IImportStudentsServiceResponse => {
  const [insertStudents] = useMutation<IImportStudentsResponse, IInsertStudentsParams>(
    INSERT_STUDENTS,
  )

  return {
    insertStudents,
  }
}

export default importStudentsService
