// @deprecated
/* eslint-disable */

import React from 'react'
import moment from 'moment'
import {
  Action,
  GroupsWrapper,
  GroupItem,
  GroupTitle,
  CheckBoxAndAvatarContainer,
} from './styled-components'
import strCutter from 'utils/strCutter'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import SchoolIcon from '@mui/icons-material/School'
import Checkbox from 'components/common/Checkbox'
import { NoValueWrapper } from 'components/common/Grid/styled-components'
import AvatarIcon from 'assets/profile.svg'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'
import { TFunction } from 'interfaces/TFunction'

interface ICompanyStudentsItemProps {
  id: string
  isChecked: boolean
  firstName: string
  lastName: string
  email: string
  status: GridStatusEnums
  phone: string
  avatarThumbnail: string
  phoneFields: {
    dialCode: string
    code: string
  }
  gender: string
  birthDate: string
  age: string
  biography: string
  note: string
  group: any
  isQualified: boolean
  avatar: string
  courses: any
  createDate: any
  companyId: any
}

export const columnConfig = (
  selectItem: any,
  t: TFunction,
  onGroupClick?: any,
  checkbox?: boolean,
) => {
  const grid = [
    {
      id: 'isChecked',
      render: (item: ICompanyStudentsItemProps) => (
        <CheckBoxAndAvatarContainer>
          <div style={{ visibility: !checkbox ? 'hidden' : 'visible' }}>
            <Checkbox checked={item.isChecked} onChange={() => selectItem(item.id)} />
          </div>
          <img src={item.avatarThumbnail || item.avatar || AvatarIcon} />
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'firstName',
      numeric: false,
      disablePadding: true,
      label: t('user_details.first_name'),
      render: (item: ICompanyStudentsItemProps) => (
        <div style={{ cursor: 'pointer' }}>{item.firstName}</div>
      ),
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: false,
      label: t('user_details.last_name'),
      render: (item: ICompanyStudentsItemProps) => <div>{item.lastName}</div>,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: t('user_details.email'),
      render: (item: ICompanyStudentsItemProps) => <div>{item.email}</div>,
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: t('user_details.phone'),
      render: (item: ICompanyStudentsItemProps) => {
        return (
          <div>
            {item.phoneFields && item.phoneFields.code && `(${item.phoneFields.code})`}
            {item.phone}
          </div>
        )
      },
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: t('status'),
      render: (item: ICompanyStudentsItemProps) => {
        return <StatusIndicator status={item.status} />
      },
    },
    {
      id: 'group',
      numeric: false,
      disablePadding: false,
      label: t('general.groups'),
      render: (item: ICompanyStudentsItemProps) => {
        const groups = item.group || []
        const firstTwoGroups = groups.slice(0, 2)
        const exGroups = groups.slice(2, groups.length)
        return (
          <div>
            {firstTwoGroups.length === 0 && <NoValueWrapper>N/A</NoValueWrapper>}
            <GroupsWrapper>
              {firstTwoGroups.map(
                (group: any, index: number) =>
                  index < 3 && (
                    <GroupItem key={group.groupId}>
                      <IconButton>
                        <span className='material-icons'>groups</span>
                      </IconButton>
                      <GroupTitle className='group-item-title'>{group.name}</GroupTitle>
                    </GroupItem>
                  ),
              )}

              {exGroups.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {exGroups.map((exGroup: any) => (
                        <div key={exGroup.groupId}>{exGroup.name}</div>
                      ))}
                    </>
                  }
                >
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                  >
                    ...
                  </span>
                </Tooltip>
              )}
            </GroupsWrapper>
          </div>
        )
      },
    },
    {
      id: 'courses',
      numeric: false,
      disablePadding: false,
      label: `${t('general.courses')}`,
      render: (item: ICompanyStudentsItemProps) => {
        const courses = item.courses || []
        const firstTwoCourses = courses.slice(0, 2)
        const exCourse = courses.slice(2, courses.length)
        return (
          <div>
            {firstTwoCourses.length === 0 && <NoValueWrapper>N/A</NoValueWrapper>}
            <GroupsWrapper>
              {courses.map(
                (course: any, index: number) =>
                  index < 3 && (
                    <GroupItem key={course.courseId}>
                      <IconButton onClick={() => onGroupClick(course.courseId)}>
                        <SchoolIcon fontSize='small' />
                      </IconButton>
                      <GroupTitle className='group-item-title'>{course.name}</GroupTitle>
                    </GroupItem>
                  ),
              )}

              {exCourse.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {exCourse.map((exGroup: any) => (
                        <div key={exCourse.courseId}>{exGroup.name}</div>
                      ))}
                    </>
                  }
                >
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                  >
                    ...
                  </span>
                </Tooltip>
              )}
            </GroupsWrapper>
          </div>
        )
      },
    },
    {
      id: 'companyId',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.company')}`,
      render: (item: ICompanyStudentsItemProps) => (
        <div>{item.companyId ? strCutter(item.companyId.name, 25) : <>N/A</>}</div>
      ),
    },
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: `${t('general.create_date')}`,
      render: (item: ICompanyStudentsItemProps) => (
        <>{moment(parseInt(item.createDate)).format('DD-MM-YYYY')}</>
      ),
    },
  ]

  return grid
}

export const actionConfig = (handleClick: any, t: TFunction) => {
  const actions: any = [
    {
      render: (item: any) => (
        <Action onClick={() => handleClick('edit', item)}>
          <EditRoundedIcon />
          <Typography>{t('actions.edit')}</Typography>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action onClick={() => handleClick('delete', item.id)}>
          <DeleteForeverRoundedIcon />
          <Typography>{t('actions.delete')}</Typography>
        </Action>
      ),
    },
  ]
  return actions
}
