import styled from 'styled-components'

export const AvatarContainer = styled.div`
  width: 100%;

  // outline: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .input-button {
    position: absolute;
    top: 10px;
    left: 9px;
    input {
      opacity: 0;
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      overflow: hidden;
    }
  }
  img {
    width: 156px;
    height: 156px;
    box-shadow: rgb(0 0 0 / 20%) 0px 10px 10px;
    border-radius: 100%;
  }
`

export const EditIconContainer = styled.div`
  width: 28px;
  height: 28px;
  background: #f0f0f0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  position: absolute;
  bottom: 4px;
  right: 15px;
  cursor: pointer;
`

export const InputLabel = styled.label`
  cursor: pointer;
  color: #fff;
  background: #06c68f;
  font-size: 11px;
  padding: 4px 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  span {
    margin-left: 7px;
  }
`

export const AvatarSettingContainer = styled.div`
  position: relative;
`

export const AvatarMenu = styled.div`
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 130px;
  box-sizing: border-box;
  padding: 5px 7px;
  background: #fff;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.22);
  border-radius: 3px;
  z-index: 1;
`

export const StyledAvatarWrapper = styled.div`
  overflow: hidden;
  border-radius: 50%;
  width: 156px;
  height: 156px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 10px;
`

export const AvatarMenuItem = styled.div`
  padding: 5px 3px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  label {
    cursor: pointer;
  }
  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    overflow: hidden;
  }
`
