import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client'
import { GET_CURRENT_USER } from 'gql/users.query'
import { IUser } from 'interfaces/users'

interface useGetCurrentUserReturn {
  currentUser: IUser
  loading: boolean
  error?: ApolloError
  refetch?: (
    variables?: Partial<Record<string, never>> | undefined,
  ) => Promise<ApolloQueryResult<IGetCurrentUserResponse>>
}

interface IGetCurrentUserResponse {
  currentUserForAdmin: IUser
}

export const useGetCurrentUser = (): useGetCurrentUserReturn => {
  const { data, loading, error, refetch } = useQuery<
    IGetCurrentUserResponse,
    Record<string, never>
  >(GET_CURRENT_USER)
  return {
    currentUser: data?.currentUserForAdmin || ({} as IUser),
    loading,
    error,
    refetch,
  }
}
