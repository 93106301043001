import { useState } from 'react'
import { useMutation } from '@apollo/client'

import { ILogin, IUseAuthReturn } from 'interfaces/auth'
import { useUserValue } from 'context/UserContext'
import { setToken } from 'helpers/localStorage'
import { useNavigate } from 'react-router-dom'
import { LOGIN } from 'gql/auth/login.query'

const useAuth = (): IUseAuthReturn => {
  const [, dispatch] = useUserValue()
  const navigate = useNavigate()
  const [authenticate] = useMutation(LOGIN)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const login = async (loginValues: ILogin): Promise<boolean> => {
    try {
      setLoading(true)
      const { data, errors } = await authenticate({
        variables: { input: loginValues },
      })

      if (errors) {
        const errorMessage = errors[0].message
        setError(errorMessage)
        setTimeout(() => {
          setError('')
        }, 3000)
        return false
      }
      const { accessToken } = data.authAdmin

      setToken(accessToken)
      dispatch({
        type: 'LOGIN',
        payload: {},
      })
      navigate('/')
      return true
    } catch (err) {
      console.log('Error from login request', err)
      return false
    } finally {
      setLoading(false)
    }
  }

  const logout = (): void => {
    dispatch({
      type: 'LOGOUT',
      payload: {},
    })
    localStorage.clear()
    window.location.href = '/login'
  }

  return {
    loading,
    login,
    logout,
    error,
  }
}

export default useAuth
