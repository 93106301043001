import useUpateStatusUserService from 'hooks/users/useUpdateStatusUserService'
import { IUseHelperChangeUserStatusProps } from './useHelperUserChangeStatus.interface'

export const useHelperChangeUserStatus = ({
  defaultFilter,
  ROLE,
  allSelectedItems,
  setSelectedItem,
}: IUseHelperChangeUserStatusProps): {
  confirmChangeStatus: (userId: string, status?: 'ACTIVE' | 'SUSPENDED') => void
  confirmChangeStatusMany: (newStatus: string) => void
} => {
  const { updateUserStatus } = useUpateStatusUserService({
    role: ROLE,
    defaultFilter,
  })
  const confirmChangeStatus = (userId: string, status?: 'ACTIVE' | 'SUSPENDED'): void => {
    if (!userId || !status) return
    updateUserStatus([userId], status)
  }

  const confirmChangeStatusMany = (newStatus: string): void => {
    updateUserStatus(allSelectedItems, newStatus)
    setSelectedItem([])
  }

  return { confirmChangeStatus, confirmChangeStatusMany }
}
