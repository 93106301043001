import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { DELETE_QUIZ_BY_IDS } from 'gql/quiz/quiz.query'

interface IDeleteQuizServiceResponse {
  deleteQuizMutation: MutationTuple<IDeleteQuizResponse, IDeleteQuizParams>[0]
  loading: boolean
  error?: ApolloError
}

interface IDeleteQuizResponse {
  deleteQuiz: {
    ids: string[]
  }
}

interface IDeleteQuizParams {
  ids: string[]
}

const deleteQuizService = (): IDeleteQuizServiceResponse => {
  const [deleteQuizMutation, { loading, error }] = useMutation<
    IDeleteQuizResponse,
    IDeleteQuizParams
  >(DELETE_QUIZ_BY_IDS)

  return {
    deleteQuizMutation,
    loading,
    error,
  }
}

export default deleteQuizService
