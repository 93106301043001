import { gql } from 'graphql.macro'

export const ADD_FILE = gql`
  mutation CreateFile($createFileDto: FileInput!) {
    createFile(createFileDto: $createFileDto)
  }
`

export const DELETE_FILE = gql`
  mutation DeleteFile($assistantId: String!, $fileId: String!) {
    deleteFile(assistantId: $assistantId, fileId: $fileId)
  }
`
