import { useMutation, MutationTuple } from '@apollo/client'
import { EDIT_GROUP, ASSIGN_TUTOR, GET_GROUP } from 'gql/group/group.query'
import { updateCache } from 'services/updateCache'
import { IGroup } from 'interfaces/groups'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { IGroupDrawerFormData } from 'hooks/group/useGroupDrawers/useGroupDrawer.interface'

interface IEditGroupResponse {
  editGroup: IGroup
}

interface IEditGroupVariables {
  id: string
  input?: IGroupDrawerFormData
}

interface IAssignTutorResponse {
  assignTutor: IGroup
}

interface IAssignTutorVariables {
  groupId: string
  tutorId?: string
  courseId?: string
}

interface IEditGroupServiceResponse {
  editGroupMutation: MutationTuple<IEditGroupResponse, IEditGroupVariables>[0]
  assignTutorMutation: MutationTuple<IAssignTutorResponse, IAssignTutorVariables>[0]
}

export interface IEditGroupServiceParams {
  filter: IFilterQueryType
  currentPage?: number
  perPage?: number
}

const editGroupService = (params?: IEditGroupServiceParams): IEditGroupServiceResponse => {
  const [editGroupMutation] = useMutation<IEditGroupResponse, IEditGroupVariables>(EDIT_GROUP, {
    update(cache, { data }) {
      if (data?.editGroup) {
        updateCache({
          objectName: 'getAllGroups',
          query: GET_GROUP,
          cache,
          document,
          action: 'add',
          params,
        })
      }
    },
  })

  const [assignTutorMutation] = useMutation<IAssignTutorResponse, IAssignTutorVariables>(
    ASSIGN_TUTOR,
  )

  return {
    editGroupMutation,
    assignTutorMutation,
  }
}

export default editGroupService
