import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'

import {
  SelectedItemsWrapper,
  Wrapper,
  MultipleAction,
  MultipleActionTooltip,
} from '../styled-components'

interface IGridMultipleActions {
  id: number
  disabled: boolean
  color?: string
  onClick: () => void
  component: React.ReactElement
  tooltipText?: string
}

interface IGridMultipleActionsProps {
  selectedItems?: number
  actions: IGridMultipleActions[]
}

const GridMultipleActions = ({
  selectedItems,
  actions,
}: IGridMultipleActionsProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Wrapper>
        {actions.map(({ id, disabled, color, onClick, component, tooltipText }) => (
          <MultipleAction key={id} className={`${disabled ? 'disabled' : ''}`}>
            <IconButton
              color={color as IconButtonProps['color']}
              disabled={disabled}
              onClick={onClick}
            >
              {component}
            </IconButton>
            {!!tooltipText && (
              <MultipleActionTooltip $wide={tooltipText.length > 15}>
                {tooltipText}
              </MultipleActionTooltip>
            )}
          </MultipleAction>
        ))}
      </Wrapper>
      {(selectedItems && (
        <SelectedItemsWrapper>
          <strong> {selectedItems}</strong>
          <span style={{ marginLeft: 6 }}>{t('selected')}</span>
        </SelectedItemsWrapper>
      )) || <></>}
    </div>
  )
}

export default GridMultipleActions
