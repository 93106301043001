import styled from 'styled-components'

export const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

export const StyledColumnFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const GapStyles = {
  gap: '10px',
}
