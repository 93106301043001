import { useState } from 'react'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import setSkillTestVideoCriteriaService, {
  ISetSkillTestVideoCriteriaParams,
} from 'services/modules/setSkillTestVideoCriteria'
import { ISkillTestVideoReviewCriteria } from 'pages/modules/SkillTestVideoCriterias/skillTestVideoCriteria.interface'

const useSetSkillTestVideoCriteriaService = (
  params: ISetSkillTestVideoCriteriaParams,
): {
  setSkillTestVideoCriteria: (
    moduleId: string,
    criterias: ISkillTestVideoReviewCriteria[],
    idsToRemain: string[],
    onSuccess?: () => void,
  ) => Promise<void>
  setSkillTestVideoCriteriaLoading: boolean
  error: string
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const { setSkillTestVideoCriteriaMutation } = setSkillTestVideoCriteriaService(params)

  const setSkillTestVideoCriteria = async (
    moduleId: string,
    criterias: ISkillTestVideoReviewCriteria[],
    idsToRemain: string[],
    onSuccess?: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { errors } = await setSkillTestVideoCriteriaMutation({
      variables: {
        moduleId,
        criterias,
        idsToRemain,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
      setError(message)
    } else {
      if (onSuccess) onSuccess()
      message = `${t('general.stvcriterias')} ${t('messages.added')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return {
    setSkillTestVideoCriteria,
    setSkillTestVideoCriteriaLoading: loading,
    error,
  }
}

export default useSetSkillTestVideoCriteriaService
