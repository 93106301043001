import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

import importStudentsService from 'services/users/importStudentsService'
import { IStudentValues } from './services.interface'

const useImportStudents = (): {
  importStudents: (
    values: IStudentValues[],
    groupId: string,
    groupName: string,
    companyId: string,
    callBack?: (usersData?: IStudentValues[] | null) => void,
  ) => Promise<void>
} => {
  const { t } = useTranslation()
  const { insertStudents } = importStudentsService()
  const { setSnackbar } = useSnackbarAlert()

  const importStudents = async (
    values: IStudentValues[],
    groupId: string,
    groupName: string,
    companyId: string,
    callBack?: (usersData?: IStudentValues[] | null) => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }
    const { errors, data } = await insertStudents({
      variables: {
        students: values,
        groupId,
        groupName,
        companyId,
      },
    })

    let usersData = null

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = `${t('general.user')} ${t('messages.edited')}`
      variant = 'success'
      usersData = data?.insertStudents
    }

    if (callBack) {
      callBack(usersData)
    }

    setSnackbar({ message, variant })
  }

  return {
    importStudents,
  }
}

export default useImportStudents
