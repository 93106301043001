import React, { ReactElement, useEffect, useState } from 'react'

import { useUserValue } from 'context/UserContext'
import CompanyItem from './CompanyItem/CompanyItem'
import { Container } from './styled-components'
import { ICompanyListProps } from './CompanyItem/companyItem.interface'
import { SplashScreen } from 'components/common'
import { useTranslation } from 'react-i18next'

const CompanyList = ({
  data,
  selectItem,
  selectedItem,
  actions,
}: ICompanyListProps): ReactElement => {
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>('')
  const [state] = useUserValue()
  const { t } = useTranslation()

  useEffect(() => {
    if (state) {
      setSelectedCompanyId(state.selectedCompany ? state.selectedCompany.id : null)
    }
  }, [state])

  return (
    <>
      {data.length ? (
        <Container>
          {data.map(company => (
            <CompanyItem
              company={company}
              key={company.id}
              selectItem={selectItem}
              selectedItem={selectedItem}
              actions={actions}
              selectedCompanyId={selectedCompanyId}
              setSelectedCompanyId={setSelectedCompanyId}
            />
          ))}
        </Container>
      ) : (
        <SplashScreen
          title={t('general.no_results_found')}
          subTitle={t('general.Try_adjusting_your_search_or_filter')}
          textMarginTop='40px'
          isSearchOrFilter={true}
        />
      )}
    </>
  )
}

export default CompanyList
