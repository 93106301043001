import styled from 'styled-components'

export const SkillTestVideoCriteriasContainer = styled.div`
  padding-top: 20px;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SkillTestVideoCriteriasGrid = styled.div`
  margin-top: 30px;
  padding: 20px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: space-between;
`

export const SkillTestVideoCriteriasGridHeader = styled.h6`
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`

export const CriteriasContainer = styled.div`
  background: #f7f7f7;
  border-radius: 10px;
  padding: 12px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
`

export const CriteriaItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
  }
`
