import React, { createContext, useContext, useState, ReactNode, ReactElement } from 'react'

interface IFilterContextType {
  createDate: [Date | undefined, Date | undefined]
  setCreateDate: React.Dispatch<React.SetStateAction<[Date | undefined, Date | undefined]>>
}

const FilterContext = createContext<IFilterContextType | undefined>(undefined)

export const FilterProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [createDate, setCreateDate] = useState<[Date | undefined, Date | undefined]>([
    undefined,
    undefined,
  ])

  return (
    <FilterContext.Provider value={{ createDate, setCreateDate }}>
      {children}
    </FilterContext.Provider>
  )
}

export const useFilterContext = (): IFilterContextType => {
  const context = useContext(FilterContext)
  if (context === undefined) {
    throw new Error('useFilterContext must be used within a FilterProvider')
  }
  return context
}
