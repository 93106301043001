import { GET_BOOKS_BY_GROUP, REMOVE_BOOKS_FROM_GROUP } from 'gql/books/books.query'
import {
  IBookToGroupDto,
  IFetchBooksByCompanyIdProps,
  GetBooksByGroupFiltersByEnums,
  IRemoveBookFromGroupResponse,
} from './bookServices.interface'
import { useTranslation } from 'react-i18next'
import useSnackbarAlert from 'hooks/useSnackbar'
import { useMutation } from '@apollo/client'
import { ISnackbarProps } from 'interfaces/snackbar'
import { SnackbarVariants } from 'interfaces/common'

export const useRemoveBooksFromGroupService = ({
  groupId,
}: IFetchBooksByCompanyIdProps): IRemoveBookFromGroupResponse => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()

  // mutation initialization
  const [removeBooksFromGroup, { data, error, loading }] = useMutation(REMOVE_BOOKS_FROM_GROUP, {
    refetchQueries: [
      {
        query: GET_BOOKS_BY_GROUP,
        variables: {
          groupId,
          searchValue: '',
          currentPage: 1,
          filterBy: GetBooksByGroupFiltersByEnums.IN_GROUP,
          perPage: 12,
        },
      },
    ],
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''

  // mutationUsage
  const handleRemoveBooksFromGroup = async ({
    bookIds,
    groupId,
  }: IBookToGroupDto): Promise<void> => {
    try {
      let { message, variant }: ISnackbarProps = {
        message: '',
        variant: undefined,
      }
      const { errors } = await removeBooksFromGroup({
        variables: {
          bookIds,
          groupId,
        },
      })
      if (errors) {
        message = errors[0].message
        variant = SnackbarVariants.ERROR
      } else {
        message = t('books_layout.book_removed')
        variant = SnackbarVariants.SUCCESS
      }

      setSnackbar({ message, variant })
    } catch (error) {
      console.log(SnackbarVariants.ERROR, error)
    }
  }
  return {
    removeBooksFromGroup: (removeBookDto: IBookToGroupDto) =>
      handleRemoveBooksFromGroup(removeBookDto),
    books: (data && data.removeBook) || [],
    loading,
    error: errorMessage,
  }
}
