import styled from 'styled-components'

// NotificationLayout

export const Container = styled.div``
export const ToolbarItemContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px 20px 20px 15px;
`

export const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

  .MuiCircularProgress-root {
    width: 30px !important;
    height: 30px !important;
  }
`

// NotificationList

export const NotificationContainer = styled.ul`
  margin: 0;
  padding: 0;
`

export const NotificationItem = styled.li<{ bcColor: string }>`
  box-sizing: border-box;
  padding: 0px 15px;
  background: ${({ bcColor }: { bcColor: string }): string => bcColor};
  transition: 0.2s;
  &:hover {
    background: #fff;
  }
`

export const ListWrapper = styled.div<{ align?: string }>`
  display: flex;
  align-items: ${({ align }: { align?: string }): string => (align ? align : 'stretch')};
  border-bottom: 1px solid #d9dce0;
  box-sizing: border-box;
  padding: 17px 0px;
  position: relative;
`

export const CheckboxWrapper = styled.div``

export const ListContentWrapper = styled.div`
  margin-left: 20px;
  margin-top: -2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
`

export const AvatarContainer = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    border-radius: 50%;
    width: 37px;
    height: 37px;
  }
`
export const NameContainer = styled.div`
  color: #06c68f;
  font-size: 15px;
  margin-left: 5px;
  white-space: nowrap;
`
export const EventContainer = styled.div`
  color: #333;
  font-size: 14px;
  opacity: 0.8;
  display: flex;
  align-items: center;
  span {
    white-space: nowrap;
  }
`
export const DateContainer = styled.div`
  position: absolute;
  right: 0;
  top: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.55);
`
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

export const UserRole = styled.div`
  text-transform: capitalize;
`
