import styled, { css, RuleSet } from 'styled-components'
import { IArgs } from './cardsLayout.interface'

export const ListItemContentCards = styled.section`
  /* margin-top: 10px; */
  position: relative;
  flex-direction: column;
  z-index: 100;
  font-size: 16px;
  color: #333;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
`

export const ListItemCards = styled.div<{
  checked?: boolean
}>`
  display: flex;
  position: relative;
  cursor: pointer;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  border: ${({ checked }): string => (checked ? '1px solid #00905E' : '1px solid #d6dae0')};
  transition: border-color 0.3s ease;
  background: #f9f9f9;
  height: fit-content;
  font-weight: 300;
  box-shadow: 0px 0px 7px 2px rgb(0 0 0 / 7%);
`

export const ListItemImageCards = styled.figure<{ variant: 'small' | 'large' }>`
  width: 110px;
  height: 70px;
  overflow: hidden;
  border-radius: 4px;
  margin-right: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
    transition: transform 0.3s ease;
  }
`
export const StyledCheckboxContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999; //without this checkbox is not clickable
`
export const GridItemCards = styled.div<{ isFromUser?: boolean }>`
  display: flex;
  overflow: hidden;
  /* padding: 16px; */
  padding-left: 16px;
  height: fit-content;
  align-items: center;

  &:hover {
    img {
      /* transform: scale(1.2); */
    }

    h3 {
      color: #06c68f;
    }
  }

  ${({ isFromUser }): string =>
    (isFromUser &&
      `
  figure {
    position: relative;
    &:after {
      content: 'View Analytics';
      position: absolute;
      top: 0;
      opacity: 0;
      color: #06c68f;
      left: 0;
      font-size: 24px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.9);
      width: 100%;
      height: 100%;
      transition: opacity 0.3s ease;
      z-index: 10;
    }
  }

  &:hover {
    figure {
      &:after {
        opacity: 1;
      }
    }
  }
  `) ||
    ''}
`

export const GridItemContentHeaderCards = styled.header`
  /* justify-content: space-between; */
  align-items: flex-start;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const GridItemLessonsCards = styled.div`
  margin-top: auto;
  display: flex;
  gap: 10px;
  /* padding-bottom: 16px; */
  align-self: flex-end;
  width: 100%;
  flex-direction: row !important;

  > div {
    color: #20a8c6;
    font-size: 14px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    background-color: #edf8fa;

    :not(:last-child) {
      margin-right: 10px;
    }
  }
`
export const StylesCardWrapper = styled.div`
  position: relative;
  &:hover {
    .action_block {
      background: rgba(255, 255, 255, 0.59) !important;
    }
  }
`

const defs = (args: IArgs): RuleSet<object> => css`
  ${args.switched
    ? `
    top: 0px;
    left: 0px;
    grid-template-columns: 1fr 35px;
  `
    : `
    bottom: 0px;
    right: 0px;
    justify-content: end;
    grid-template-columns: 35px 35px;
  `}
`

export const CardActionBar = styled.div<{ switched: boolean }>`
  width: 100%;
  position: absolute;
  z-index: 99;
  box-sizing: border-box;
  padding: 6px;
  display: grid;
  ${defs}
  .action_block {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`
