import { ChangeEvent, FormEvent } from 'react'
import { useTranslation, TFunction } from 'react-i18next'

import { useFormik, FormikErrors, FormikTouched, FormikHandlers } from 'formik'

import { useUploadFile } from 'hooks/helpers/useHelpersService'
import { useSwal } from 'hooks/useSwal'

import { IAttachment } from 'interfaces/common'
import { FileSchema } from 'helpers/validationSchemas'
import { useAddFileService } from 'services/assistant/file/addFileService'
import { IFileDto } from 'services/assistant/file/fileService.interface'

export interface IBookDrawerResponse {
  t: TFunction<'translation', undefined>
  handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void
  fileLoading: boolean
  setFieldValue: (
    field: string,
    value: string | boolean | ChangeEvent<HTMLInputElement> | null,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<IFileDto>>
  values: IFileDto
  errors: FormikErrors<IFileDto>
  touched: FormikTouched<IFileDto>
  handleChange: FormikHandlers['handleChange']
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void
  handleCloseDrawer: () => void
  loading: boolean
}

export interface IAIAssistantProps {
  onClose: () => void
  assistantId: string
}

const useFileDrawer = ({ onClose, assistantId }: IAIAssistantProps): IBookDrawerResponse => {
  const { t } = useTranslation()
  const { fireSwal } = useSwal()
  const { uploadFile, fileLoading } = useUploadFile()

  const formData: IFileDto = {
    contentDocument: null as IAttachment | null,
    description: '',
    assistantId: null,
  }

  const { addFile, loading } = useAddFileService({ assistantId })

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.target
    if (!files) return
    const file = files[0]
    const fileType = file.name.split('.').slice(-1)[0]
    uploadFile(file, `assistant-files/${file.name}`, (link: string) => {
      setFieldValue('contentDocument', {
        name: file.name,
        fileType,
        link,
      })
    })
  }

  const { values, errors, touched, setFieldValue, handleChange } = useFormik({
    initialValues: formData,
    validationSchema: FileSchema,
    onSubmit(values) {
      const fileDto = { ...values }
      fileDto.assistantId = assistantId

      addFile(fileDto)
    },
  })

  const handleSubmit = async (e?: FormEvent<HTMLFormElement>): Promise<void> => {
    try {
      e?.preventDefault()
      const fileDto = { ...values }
      fileDto.assistantId = assistantId

      await addFile(fileDto)
    } catch (error) {
      console.error(error)
    } finally {
      onClose()
    }
  }

  const handleCloseDrawer = (): void => {
    if (!values.contentDocument) {
      onClose()
    } else {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        confirmText: t('popups.confirm_cancel'),
        onConfirm: () => onClose(),
      })
    }
  }

  return {
    t,
    setFieldValue,
    values,
    errors,
    touched,
    handleChange,
    handleCloseDrawer,
    handleSubmit,
    handleFileChange,
    fileLoading: !!fileLoading,
    loading,
  }
}

export default useFileDrawer
