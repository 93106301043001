import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import {
  PushNotificationContainer,
  InputContainer,
  StyledInput,
  StyledTextArea,
  NotificationPreview,
  StyledLabel,
  NotificationContainer,
  Heading,
  Container,
} from './styled-components'
import { Button } from 'components/common/Button'
import { debounce } from 'lodash'

const SendPushNotification = ({
  messageData,
  setMessageData,
  onClose,
  onSend,
}: {
  messageData: {
    heading: string
    body: string
  }
  setMessageData: (data: { heading: string; body: string }) => void
  onClose: () => void
  onSend: () => void
}): ReactElement => {
  const { t } = useTranslation()

  const updateDebounceText = debounce((key: string, value: string) => {
    setMessageData({
      ...messageData,
      [key]: value,
    })
  })

  return (
    <PushNotificationContainer>
      <Heading>{t('actions.send_push_notification')}</Heading>
      <Container>
        <InputContainer
          onSubmit={(e): void => {
            e.preventDefault()
            onSend()
          }}
        >
          <div>
            <StyledLabel htmlFor='heading'>{t('form_fields.heading')}</StyledLabel>
            <StyledInput
              name='heading'
              id='heading'
              type='text'
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                updateDebounceText('heading', e.target.value)
              }
            />
          </div>
          <div>
            <StyledLabel htmlFor='body'>{t('form_fields.body')}</StyledLabel>
            <StyledTextArea
              name='body'
              id='body'
              rows={5}
              required
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>): void =>
                updateDebounceText('body', e.target.value)
              }
            />
          </div>
          <div className='actions'>
            <Button
              color='primary'
              onClick={onClose}
              background='#E0E1E2'
              textColor='#414141'
              text={t('actions.cancel')}
              type='small'
            />
            <Button
              //   variant="contained"
              color='primary'
              background='#06C68F'
              text={t('actions.send')}
              type='small'
              btnType='submit'
            />
          </div>
        </InputContainer>
        <NotificationPreview>
          <img
            src='https://ib-assets-public.s3.eu-west-2.amazonaws.com/iphone-lock-screen.png'
            alt='suadalogo'
            className='lock-screen'
          />
          <NotificationContainer>
            <div className='logo'>
              <img
                src='https://ib-assets-public.s3.eu-west-2.amazonaws.com/logo.png'
                alt='suadalogo'
              />
            </div>
            <div className='content'>
              <p className='heading'>{messageData.heading || 'Notification Heading'}</p>
              <p className='body'>{messageData.body || 'Notification Body'}</p>
            </div>
          </NotificationContainer>
        </NotificationPreview>
      </Container>
    </PushNotificationContainer>
  )
}

export default SendPushNotification
