type token = string

export const setToken = (token: token): void => localStorage.setItem('token', token)
export const setSelectedCompanyInLocalStorage = (
  selectedCompany: {
    id: string
    name: string
  } | null,
): void => {
  if (selectedCompany) localStorage.setItem('selectedCompany', JSON.stringify(selectedCompany))
  else localStorage.removeItem('selectedCompany')
}
export const getSelectedCompanyFromLocalStorage = (): {
  id: string
  name: string
} | null => {
  const selectedCompany = localStorage.getItem('selectedCompany')
  return selectedCompany ? JSON.parse(selectedCompany) : null
}
export const getSelectedCompanyIdFromLocalStorage = (): string | null => {
  const selectedCompany = localStorage.getItem('selectedCompany')
  return selectedCompany ? JSON.parse(selectedCompany).id : null
}

export const getToken = (): string | null => localStorage.getItem('token')
export const getCompanyMode = (): string | null => localStorage.getItem('selectedCompanyId')

export const clearToken = (): void => localStorage.removeItem('token')
export const clearCompanyModee = (): void => localStorage.removeItem('selectedCompanyId')
