import React, { useState, useEffect, ReactElement, MouseEvent, FormEvent } from 'react'
import _ from 'lodash'
import { useNavigate } from 'react-router'
import { DrawerEventEmitter } from 'helpers/drawer'
import { ApolloQueryResult, useQuery } from '@apollo/client'
import { GET_GROUP } from 'gql/group/group.query'
import { useUserValue } from 'context/UserContext'
import { useSwal } from 'hooks/useSwal'
import { columnConfig, actionConfig } from 'pages/group/gridConfigV2'
import { useExportDataService } from 'hooks/helpers/useHelpersService'
import useDeleteGroup from 'hooks/group/useDeleteGroup'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import DeleteIcon from '@mui/icons-material/Delete'
import ArchiveIcon from '@mui/icons-material/Archive'
import { Wrapper } from '../styled-components'
import Grid from 'components/common/GridV2'
import { Input } from 'components/common/TextInput'
import Drawer from 'components/common/Drawer'
import AddGroupDrawer from 'pages/group/AddGroupDrawer/AddGroupDrawer'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import TablePagination from 'components/common/Pagination/TablePagination'
import FilterDrawer from 'pages/group/FilterDrawer/FilterDrawer'
import { TFunction } from 'interfaces/TFunction'
import { ICompanyDetailsTabFilterVariables } from '../CompanyDetails'
import { ISelectAll, ISelectPage } from 'components/common/FilterInterface/filter.interface'
import { IGroup } from 'interfaces/groups'
import { IGroupFilterDrawerOptions } from 'pages/group/FilterDrawer/filterDrawer.interface'

interface ICompanyGroupsParams {
  variables: ICompanyDetailsTabFilterVariables
  t: TFunction
  companyId: string
}

type GroupData = {
  id: string
  name: string
}

const CompanyGroups = ({ variables, t, companyId }: ICompanyGroupsParams): ReactElement => {
  const navigate = useNavigate()
  const [state] = useUserValue()
  const { fireSwal } = useSwal()
  const { data, loading, refetch } = useQuery(GET_GROUP, {
    variables,
  })
  const { exportData } = useExportDataService()
  const { deleteGroupServ } = useDeleteGroup()
  const [searchValue, setSearchValue] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [drawerOpened, setDrawerOpened] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<ISelectPage>({})
  const [selectAll, setSelectAll] = useState<ISelectAll>({})
  const [formData, setFormData] = useState<string>('')
  const [filterDrawer, setFilterDrawer] = useState<IGroupFilterDrawerOptions>({
    filterOptions: null,
    filterValues: null,
    opened: false,
  })

  const selectItem = (id: string): void => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = (): void => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = (groups.data && groups.data.map((n: GroupData) => n.id)) || []
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const handlePaginationClick = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    setCurrentPage(newPage + 1)
    refetch({
      ...variables,
      currentPage: newPage + 1,
      perPage,
    })
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
    refetch({
      ...variables,
      currentPage,
      perPage: parseInt(event.target.value),
    })
  }

  const handleDeleteGroup = (id?: string): void => {
    let ids = selectedItem[currentPage]

    if (id) ids = [id]

    if (ids.length <= 0) return

    const params = {
      title: 'Are you sure you want to delete this group?',
      onConfirm: (): void => {
        deleteGroupServ(ids, refetch)
        setSelectedItem([])
      },
      confirmText: 'Yes, delete!',
    }

    fireSwal(params)
  }

  const handleExportData = (): void => {
    const selectedGroupIdsFromAllPage: string[] =
      (Object.values(selectedItem).flat() as string[]) || []

    if (selectedGroupIdsFromAllPage.length > 0) {
      exportData('group', selectedGroupIdsFromAllPage, null, (link: string) => {
        window.open(link, '_blank')
      })
    }
  }

  const closeDrawer = (): void => setDrawerOpened(false)

  const handleClick = (e: string | IGroup, field?: string): void => {
    if (field === 'delete') {
      handleDeleteGroup(e as string)
      return
    }
    const group = e as IGroup
    if (field === 'info') {
      DrawerEventEmitter.emit('openDrawer', 'groupInformation', true, {
        id: group.id,
        companyId: group.company ? group.company.id : null,
      })
    } else if (field === 'addStudentInGroup') {
      DrawerEventEmitter.emit('openDrawer', 'addStudentInGroup', true, {
        groupData: e,
      })
    } else navigate(`/group/${group.id}`)
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.currentTarget.value === '') {
      refetch({
        ...variables,
      })
    }
    setSearchValue(e.currentTarget.value)
  }

  const handleSearchSubmit = (
    e: MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>,
  ): void => {
    e.preventDefault()
    let filterOptions = {}
    if (searchValue !== '') {
      filterOptions = {
        ...variables.filter,
        name: { type: 'match', value: searchValue },
      }
    } else {
      filterOptions = { ...variables.filter }
    }

    refetch({
      ...variables,
      filter: filterOptions,
    })
  }

  useEffect(() => {
    if (formData) {
      const isEmpty = _.values(formData).every(_.isEmpty)
      if (!isEmpty) {
        fireSwal({
          title: t('popups.close_popup'),
          text: t('popups.sure'),
          onConfirm: () => {
            closeDrawer()
            setFormData('')
          },
          onClose: () => {
            setFormData('')
          },
          confirmText: 'Yes, Cancel!',
          cancelText: 'No',
        })
      }

      if (isEmpty) {
        closeDrawer()
        setFormData('')
      }
    }
  }, [formData])

  const config = columnConfig(handleClick, selectItem, t, true)
  const actions = actionConfig(handleClick, state.userPermission, t)
  const groups = (data && data.getAllGroups) || {}

  if (loading) return <div>Loading...</div>
  return (
    <div style={{ paddingTop: 20 }}>
      <div style={{ marginBottom: 20 }}>
        <Wrapper justify='between' align='center'>
          <Wrapper align='center'>
            <GridMultipleActions
              selectedItems={selectedItem[currentPage] ? selectedItem[currentPage].length : 0}
              actions={[
                {
                  id: 0,
                  color: 'secondary',
                  tooltipText: t('actions.delete'),
                  hide: !state.userPermission.deleteGroup,
                  disabled: selectedItem[currentPage] ? selectedItem[currentPage].length < 1 : true,
                  onClick: (): void => handleDeleteGroup(),
                  component: <DeleteIcon fontSize='small' />,
                },
                {
                  id: 0,
                  color: 'secondary',
                  tooltipText: t('actions.export'),
                  hide: !state.userPermission.deleteGroup,
                  disabled: selectedItem[currentPage] ? selectedItem[currentPage].length < 1 : true,
                  onClick: (): void => handleExportData(),
                  component: <ArchiveIcon fontSize='small' />,
                },
              ].filter(i => !i.hide)}
            />
          </Wrapper>
          <form onSubmit={handleSearchSubmit}>
            <Input
              label={t('general.search_placeholder')}
              type='text'
              size='small'
              icon={
                <IconButton>
                  <SearchRoundedIcon />
                </IconButton>
              }
              value={searchValue}
              onChange={handleSearchChange}
            />
          </form>
        </Wrapper>
      </div>
      <div>
        <Grid
          data={groups.data}
          config={config}
          redirectToDetailsHandler={handleClick}
          actionConfig={actions}
          actionTilesLength={3}
          selectItem={selectItem}
          selectAllItem={selectAllItem}
          selected={_.size(selectedItem[currentPage] || [])}
          selectedItems={selectedItem[currentPage] || []}
          resetVisible={!!searchValue || !!filterDrawer?.filterValues}
        />

        {groups && groups.data && (
          <TablePagination
            currentPage={groups ? groups.currentPage : 0}
            rowsPerPage={perPage}
            count={groups ? groups.totalCount : 0}
            handleChangePage={handlePaginationClick}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </div>
      <Drawer
        opened={drawerOpened}
        toggleDrawer={(): void => setDrawerOpened(false)}
        totalWidth='750px'
      >
        <AddGroupDrawer
          afterSave={(): Promise<ApolloQueryResult<GroupData>> => refetch()}
          onClose={(): void => setDrawerOpened(false)}
          companyId={variables.filter.company?.value as string}
        />
      </Drawer>

      <Drawer
        opened={filterDrawer?.opened}
        toggleDrawer={(): void =>
          setFilterDrawer({ ...filterDrawer, opened: !filterDrawer?.opened })
        }
        totalWidth='600px'
      >
        <FilterDrawer
          closeDrawer={(): void => setFilterDrawer({ ...filterDrawer, opened: false })}
          setFilterOptions={setFilterDrawer}
          refetchGroup={(args): void => {
            refetch({
              filter: {
                ...variables.filter,
                ...args.filter,
              },
            })
          }}
          companyId={companyId}
          useCompanyId
          hasPurchasedCourses={true}
        />
      </Drawer>
    </div>
  )
}

export default CompanyGroups
