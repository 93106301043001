import { FormGroup } from '@mui/material'
import styled from 'styled-components'

export const AddSkillTestVideoCriteriaContainer = styled.div`
  height: 100%;
`

export const StyledFormGroup = styled(FormGroup)`
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: flex-start;
`

export const ButtonStyles = {
  color: '#06C68F',
  cursor: 'pointer',
}

export const DeleteButtonStyles = {
  color: '#FF0000',
  cursor: 'pointer',
}

export const TextAreaStyles: React.CSSProperties = {
  width: '90%',
  font: '16px Roboto, Helvetica, Arial, sans-serif ',
  resize: 'none',
  padding: '10px',
  borderRadius: '5px',
}

export const FormGroupItemStyles = {
  width: '100%',
  alignItems: 'center',
  gap: '10px',
  display: 'flex',
}

export const FormContainerStyles = {
  padding: '20px',
  gap: '20px',
}

export const CriteriasHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  p {
    font-size: 16px;
    font-weight: 500;
  }
`

export const CriteriasContainer = styled.div`
  background: #f7f7f7;
  border-radius: 10px;
  padding: 12px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
`
