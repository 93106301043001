import React from 'react'

import { Tooltip } from '@mui/material'

import AddCircleRoundedIcon from '@mui/icons-material/Add'

import { Drawer, SplashScreen } from 'components/common'
import { Button } from 'components/common'

import strCutter from 'utils/strCutter'
import useAIAssistant from 'hooks/assistant/useAIAssistant'

import { ReactComponent as DeleteIcon } from 'assets/trash-can-red.svg'

import {
  StyledToolbarWrapper,
  StyledFileItem,
  StyledFilesWrapper,
  StyledWrapper,
  StyledSpan,
} from './AIAssistant.styles'
import AddFileDrawer from './AddFileDrawer/AddFileDrawer'

const AIAssistant = (): React.JSX.Element => {
  const {
    files,
    createButton,
    t,
    setAddFileDrawerOpened,
    addFileDrawerOpened,
    assistantId,
    handleDelete,
  } = useAIAssistant()

  if (!files.length) {
    return (
      <>
        <SplashScreen
          title={t('AI_assistant.no_files')}
          subTitle={t('AI_assistant.AI_assistant_subtitle')}
          createButton={createButton}
          contentMaxWidth='430px'
          textMarginTop='40px'
          isSearchOrFilter={true}
        />
        <Drawer opened={addFileDrawerOpened}>
          <AddFileDrawer
            onClose={(): void => setAddFileDrawerOpened(false)}
            assistantId={assistantId}
          />
        </Drawer>
      </>
    )
  }

  return (
    <StyledWrapper>
      <StyledToolbarWrapper>
        <Button
          text={t('AI_assistant.add_file')}
          size='small'
          color='secondary'
          onClick={(): void => {
            setAddFileDrawerOpened(true)
          }}
          icon={<AddCircleRoundedIcon />}
          background='#06C68F'
        />
      </StyledToolbarWrapper>
      <StyledFilesWrapper>
        {files.map(file => (
          <StyledFileItem key={file.id}>
            {(file.contentDocument.name?.length || 0) > 55 ? (
              <Tooltip title={file.contentDocument.name}>
                <span>{strCutter(file.contentDocument.name, 55)}</span>
              </Tooltip>
            ) : (
              <Tooltip title={file.contentDocument.name}>
                <span>{file.contentDocument.name}</span>
              </Tooltip>
            )}
            <StyledSpan onClick={(): void => handleDelete(file._id)}>
              <DeleteIcon />
            </StyledSpan>
          </StyledFileItem>
        ))}
      </StyledFilesWrapper>
      <Drawer opened={addFileDrawerOpened}>
        <AddFileDrawer
          onClose={(): void => setAddFileDrawerOpened(false)}
          assistantId={assistantId}
        />
      </Drawer>
    </StyledWrapper>
  )
}

export default AIAssistant
