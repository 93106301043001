import React, { ReactElement } from 'react'
import styled from 'styled-components'

export const Toolbar = (props: {
  children: React.ReactNode
  styles?: React.CSSProperties
}): ReactElement => {
  return <ToolbarContainer style={{ ...props.styles }}>{props.children}</ToolbarContainer>
}

export const ToolbarItem = (props: {
  ml?: string
  children: React.ReactNode
  style?: React.CSSProperties
  $noGap?: boolean
}): ReactElement => (
  <ToolbarItemContainer ml={props.ml} $noGap={props.$noGap} {...props}>
    {props.children}
  </ToolbarItemContainer>
)

const ToolbarContainer = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 20px 0;
`

const ToolbarItemContainer = styled.div<{ ml?: string; $noGap?: boolean }>`
  display: flex;
  align-items: center;
  ${({ ml }): string => (ml && `margin-left: ${ml};`) || ''}
  ${({ $noGap: $noGap }): string => (!$noGap && 'gap: 10px;') || ''}
`
