import { useState } from 'react'
import deleteTopicService from 'services/topics/deleteTopicService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import { IModule } from 'pages/modules/ModulesList.interface'

export const useDeleteTopic = (): {
  deleteTopic: (moduleId: string, topicIds: string[], onSuccess: () => void) => Promise<void>
  deleteTopicLoading: boolean
  deletedTopic: IModule | null
  error: string
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [deletedTopic, setDeletedTopic] = useState<IModule | null>(null)
  const [error, setError] = useState('')
  const { deleteTopicMutation, loading } = deleteTopicService()

  const deleteTopic = async (
    moduleId: string,
    topicIds: string[],
    onSuccess: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await deleteTopicMutation({
      variables: {
        moduleId,
        topicIds,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        message = `${t('general.user')} ${t('messages.already_registered')}`
        setError(message)
        return
      }
      variant = 'error'
    } else if (data) {
      onSuccess()
      setDeletedTopic(data.deleteTopics)
      const isMultiple = topicIds.length > 1 ? true : false
      message = isMultiple
        ? `${t('general.topics')} ${t('messages.deleted')}`
        : `${t('general.topic')} ${t('messages.deleted')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    deleteTopic,
    deleteTopicLoading: loading,
    deletedTopic,
    error,
  }
}
