import { User } from '@sentry/react'
import { IUseSelectAllItemProps, IUseSelectItemProps } from './useHelperSelect.interface'
import { useSwal } from 'hooks/useSwal'

export const useSelectItem = ({
  setSelectAll,
  setSelectedItem,
  selectedItem,
  selectAll,
  currentPage,
}: IUseSelectItemProps): ((id: string) => void) => {
  const { fireSwal } = useSwal()
  const selectItem = (id: string): void => {
    const selectedItemsOnPage = selectedItem[currentPage as number] || []
    if (!id) return fireSwal({ text: 'something went wrong', icon: 'error' })
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: string[] = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  return selectItem
}

export const useSelectAllItem = ({
  selectAll,
  currentPage,
  data,
  setSelectedItem,
  setSelectAll,
  selectedItem,
}: IUseSelectAllItemProps): (() => void) => {
  const selectAllItem = (): void => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = data.data.map((n: User) => n.id as string)
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }
  return selectAllItem
}
