import styled from 'styled-components'

export const ErrorMessageContainer = styled.div`
  color: #f44336;
  font-size: 0.75rem;
  margin-left: 4px;
  margin-top: 6px;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`
