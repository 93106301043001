import styled from 'styled-components'

export const ItemWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-right: 30px;
`
export const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 0px 50px;
`
export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const InputWrapper = styled.div`
  margin-top: 30px;
`
export const AvatarContainer = styled.div`
  width: 100%;
  height: 180px;
  outline: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .input-button {
    position: absolute;
    top: 10px;
    left: 9px;
    input {
      opacity: 0;
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      overflow: hidden;
    }
  }
  img {
    width: 135px;
    height: 135px;
  }
`
export const InputLabel = styled.label`
  cursor: pointer;
  color: #fff;
  background: #06c68f;
  font-size: 11px;
  padding: 4px 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  span {
    margin-left: 7px;
  }
`

export const FooterWrapper = styled.div`
  width: 70%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const TextContainer = styled.div`
  box-sizing: border-box;
  padding: 20px;
`
