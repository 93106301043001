import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { addRemoveCourseFromCompanyService } from 'services/companies/companySettingsService'
import { useTranslation } from 'react-i18next'

export const useAddRemoveCourseFromCompany = (): {
  addRemoveCourseFromCompany: (
    companyId: string,
    courseIds: string[],
    callBack: () => void,
  ) => Promise<void>
  loading: boolean
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const { addRemoveCourseFromCompanyMutation, loading } = addRemoveCourseFromCompanyService()

  const addRemoveCourseFromCompany = async (
    companyId: string,
    coursesIds: string[],
    callBack: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await addRemoveCourseFromCompanyMutation({
      variables: {
        companyId,
        coursesIds,
      },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = t('messages.success')
      variant = 'success'

      callBack()
    }

    setSnackbar({ message, variant })
  }

  return {
    addRemoveCourseFromCompany,
    loading,
  }
}
