import React, { ReactElement } from 'react'
import { default as MUITablePagination } from '@mui/material/TablePagination'
import { useTranslation } from 'react-i18next'
import { MUITablePaginationStyles, StyledTable } from './styled-components'

interface ITablePaginationProps {
  currentPage: number
  rowsPerPage: number
  count: number
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  rowsPerPageOptions?: number[]
}

const TablePagination = ({
  currentPage,
  rowsPerPage,
  count,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPageOptions,
}: ITablePaginationProps): ReactElement => {
  const { t } = useTranslation()
  return (
    <StyledTable>
      <tbody>
        <tr>
          <MUITablePagination
            count={count}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={`${t('grid.rows_per_page')}:`}
            rowsPerPageOptions={rowsPerPageOptions || [10, 25, 50, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={MUITablePaginationStyles}
          />
        </tr>
      </tbody>
    </StyledTable>
  )
}

export default TablePagination
