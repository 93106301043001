import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as MoreIcon } from 'components/common/Button/icons/drop.svg'
import moment from 'moment'
import {
  BoldDetail,
  GridContainer,
  StyledDetailHeaderSpan,
  StyledDetailSpan,
  StyledDetailSpanBetween,
  StyledDetailsContainer,
  StyledGroupDetailsContainer,
} from './styled-components'
import { NoValueWrapper } from 'pages/companies/CompanyDetails/styled-components'
import { generateFullName } from 'utils/utils'
import GridActionMenu from 'components/common/GridV2/GridActionMenu/GridActionMenu'
import { ActionsWrapper } from 'pages/tasks/TaskLayout/styled-component'
import { IAdmin } from './group.interface'
import { IAction } from 'components/common/GridV2'
import { IGroup } from 'interfaces/groups'

const GroupMainInfo: React.FC<{ group: IGroup; actions: IAction[] }> = ({ group, actions }) => {
  const { t } = useTranslation()
  return (
    <div>
      <StyledGroupDetailsContainer>
        <StyledDetailHeaderSpan>
          <h2> {group?.name} </h2>
          <ActionsWrapper>
            <GridActionMenu actionConfig={actions} button={<MoreIcon />} row={group} />
          </ActionsWrapper>{' '}
        </StyledDetailHeaderSpan>
        <GridContainer>
          <StyledDetailSpan>
            <StyledDetailsContainer>
              <span>{t('general.create_date')}:</span>
              <BoldDetail>
                {group?.createDate ? (
                  moment(new Date(group.createDate)).format('DD-MM-YYYY')
                ) : (
                  <NoValueWrapper>N/A</NoValueWrapper>
                )}
              </BoldDetail>
            </StyledDetailsContainer>
            <StyledDetailsContainer>
              <span>
                {t('general.group')} {t('general.admin')}:
              </span>
              <BoldDetail>
                {group.admin &&
                  group.admin.map((admin: IAdmin) => generateFullName(admin)).join(', ')}
              </BoldDetail>
            </StyledDetailsContainer>
          </StyledDetailSpan>
          <StyledDetailSpanBetween>
            <StyledDetailsContainer>
              <span>{t('general.students')}:</span>
              <BoldDetail>
                {' '}
                {group?.numberOfStudents || <NoValueWrapper>N/A</NoValueWrapper>}{' '}
              </BoldDetail>
            </StyledDetailsContainer>
            <StyledDetailsContainer>
              <span>{t('general.company')}:</span>
              <BoldDetail>{group?.company?.name || <>N/A</>}</BoldDetail>
            </StyledDetailsContainer>
          </StyledDetailSpanBetween>
          <StyledDetailSpan>
            <StyledDetailsContainer>
              <span>{t('form_fields.location')}:</span>
              <BoldDetail>{group?.location || <NoValueWrapper> N/A</NoValueWrapper>}</BoldDetail>
            </StyledDetailsContainer>
            <StyledDetailsContainer>
              <span>{t('user_details.note')}:</span>
              <BoldDetail>{group?.note || <NoValueWrapper> N/A</NoValueWrapper>}</BoldDetail>
            </StyledDetailsContainer>
          </StyledDetailSpan>
        </GridContainer>
      </StyledGroupDetailsContainer>
    </div>
  )
}

export default GroupMainInfo
