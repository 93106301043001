export enum ActivityAction {
  FIRST_LOGIN = 'First login',
  INVITATION_EMAIL_SENT = 'Invitation email sent',
  COURSE_START = 'Started Course:',
  COURSE_FINISH = 'Finished Course:',
  MODULE_START = 'Started Module:',
  MODULE_FINISH = 'Finished Module:',
  COMMENT_ADD = 'Comment added:',
  SKILL_TEST_VIDEO_ADD = 'Skill test video added:',
  QUIZ_START = 'Quiz started:',
  QUIZ_FINISH = 'Quiz finished:',
  POST_ADD = 'Post added:',
  LESSON_START = 'Watched lesson:',
  UNKNOWN = 'Unknown action',
}

export enum ActivityIndicator {
  PROGRESS = 'PROGRESS',
  STABLE = 'STABLE',
  REGRESS = 'REGRESS',
}
