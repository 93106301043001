import { LowerCaseFilterableFieldType, UpperCaseFilterableFieldType } from 'enums/filterEnum'
import { useFormik } from 'formik'
import { FilterCoachesSchema } from 'helpers/validationSchemas'
import { FilterOptions, filterOptions as initialFilterOptions } from 'pages/group/staticData'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getAllCoursesService } from 'services/course/getAllCourseService/getAllCoursesService'
import { getUsersByRole } from 'services/users/getUsersByRoleService/getUsersService'
import {
  IAdminFilter,
  ICourseFilter,
  IFilterVariables,
  IFormikValues,
  IUseGroupsFilterDrawerProps,
  IUseGroupsFilterDrawerReturnType,
} from './useGroupsFilter.interface'
import { useFilterContext } from 'context/FilterContext'
import { useFilterRoleContext } from 'context/FilterRoleContext'
import { IGroupsFilterOptions } from 'hooks/course/addCourseDrawer/addGroupInCourseDrawer/addGroupInCourseDrawer.interface'
import { IFilterValues } from 'components/common/FilterInterface/filter.interface'
import { IGroupFilterDrawerOptions } from 'pages/group/FilterDrawer/filterDrawer.interface'

export const useGroupsFilterDrawer = ({
  companyId,
  filterLoading,
  setFilterOptions,
  filterOptions,
  defaultFilter,
  useCompanyId,
  hasPurchasedCourses,
  setCurrentPage,
  setIsFiltered,
  refetchGroup,
}: IUseGroupsFilterDrawerProps): IUseGroupsFilterDrawerReturnType => {
  const { t } = useTranslation()
  const ref = useRef<NodeJS.Timeout | undefined>()
  const { createDate, setCreateDate } = useFilterContext()
  const { filterRole, setFilterRole } = useFilterRoleContext()
  setFilterRole('GROUPS')

  let variables: IFilterVariables = { role: 'ADMIN' }
  if (companyId) {
    variables = {
      ...variables,
      filter: {
        companyId: {
          type: LowerCaseFilterableFieldType.EXACT,
          value: companyId,
        },
      },
    }
  }

  const {
    fetchUsers: fetchAdmin,
    loading: adminLoading,
    data: adminData,
  } = getUsersByRole({ variables })

  const {
    fetchCourses: fetchCourse,
    loading: courseLoading,
    data: courseData,
  } = getAllCoursesService()

  const [error, setError] = useState(false)

  const { handleSubmit, handleChange, values, errors, touched, setValues, setFieldValue } =
    useFormik({
      initialValues: initialFilterOptions,
      validationSchema: FilterCoachesSchema,
      onSubmit(values) {
        const formValues: IFormikValues = {}
        if (values.name) {
          formValues.name = {
            type: LowerCaseFilterableFieldType.MATCH,
            value: values.name,
          }
        }

        if (values.note) {
          formValues.note = {
            type: LowerCaseFilterableFieldType.MATCH,
            value: values.note,
          }
        }

        if (values.location) {
          formValues.location = {
            type: LowerCaseFilterableFieldType.MATCH,
            value: values.location,
          }
        }

        if (values.status.value !== 'all') {
          formValues.status = {
            type: LowerCaseFilterableFieldType.EXACT,
            value: values.status.value as string,
          }
        }

        if (values.company) {
          const companyIds = values.company.map((i: { value: string | null }) =>
            i.value === '-1' ? (i.value = null) : i.value,
          )
          const query = { company: { $in: companyIds } }

          formValues.query = {
            type: LowerCaseFilterableFieldType.QUERY,
            value: JSON.stringify(query),
          }
        }

        if (values.admin) {
          const adminIds = values.admin.map(i => i.value)
          formValues.admin = {
            type: LowerCaseFilterableFieldType.ARRAY_IN,
            value: adminIds as string[],
          }
        }

        if (values.course) {
          formValues.courses = {
            type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
            value: values.course.map(i => i.value) as string[],
            nestedField: 'courseId',
          }
        }

        //if user wants to clear the date, we should send undefined
        const safeCreateDate = createDate || [undefined, undefined]

        safeCreateDate[0] = safeCreateDate[0] && new Date(safeCreateDate[0])
        safeCreateDate[1] = safeCreateDate[1] && new Date(safeCreateDate[1])

        const isSameDate =
          safeCreateDate[0] === safeCreateDate[1] && safeCreateDate[0] !== undefined

        if (!safeCreateDate[1] && safeCreateDate[0] !== undefined) safeCreateDate[1] = new Date()

        const formattedData = isSameDate
          ? safeCreateDate[0]
          : `${safeCreateDate[0] ? safeCreateDate[0].toISOString() : ''}${'-'}${
              safeCreateDate[1] ? safeCreateDate[1].toISOString() : ''
            }`

        if (safeCreateDate[0] !== undefined || safeCreateDate[1] !== undefined) {
          formValues.createDate = {
            type: isSameDate
              ? LowerCaseFilterableFieldType.EXACT
              : LowerCaseFilterableFieldType.DATE_RANGE,
            value: formattedData as string,
          }
        }

        refetchGroup({
          filter: {
            ...defaultFilter,
            ...formValues,
          } as IGroupsFilterOptions,
        })

        const defaultDrawer = defaultFilter || null

        setFilterOptions((prev: IGroupFilterDrawerOptions) => ({
          ...prev,
          filterOptions: {
            ...defaultDrawer,
            ...formValues,
          } as IGroupsFilterOptions,
          filterValues: values as IFilterValues,
          opened: false,
        }))
        if (setIsFiltered && setCurrentPage) {
          setIsFiltered(JSON.stringify(formValues) !== '{}')

          setCurrentPage(1)
        }
      },
    })

  //clear createDate value when we switch tabs
  useEffect(() => {
    if (createDate && filterRole != 'GROUPS') {
      setCreateDate([undefined, undefined])
    }
  }, [filterRole])

  useEffect(() => {
    if ((filterOptions as IGroupFilterDrawerOptions)?.filterValues) {
      setValues((filterOptions as IGroupFilterDrawerOptions).filterValues as FilterOptions)
    }
  }, [])

  const loadDataOptions = (e: string, field: string): NodeJS.Timeout =>
    setTimeout(() => {
      const value = e

      if (field === 'admin') {
        let adminFilter: IAdminFilter = {}
        if (useCompanyId) {
          adminFilter = {
            ...adminFilter,
            companyId: {
              type: LowerCaseFilterableFieldType.EXACT,
              value: companyId,
            },
            search: {
              type: LowerCaseFilterableFieldType.SEARCH,
              value,
              fields: ['firstName', 'lastName'],
            },
          }
        } else
          adminFilter = {
            ...adminFilter,
            search: {
              type: LowerCaseFilterableFieldType.SEARCH,
              value,
              fields: ['firstName', 'lastName'],
            },
          }
        fetchAdmin({
          variables: {
            ...variables,
            filter: { ...adminFilter },
            ...variables.filter,
          },
        })
      }

      if (field === 'course') {
        let filter: ICourseFilter = hasPurchasedCourses
          ? {}
          : {
              originalId: {
                type: UpperCaseFilterableFieldType.ARRAY_IN,
                value: [null],
              },
            }
        if (useCompanyId) {
          filter = {
            ...filter,
            companyId: {
              type: UpperCaseFilterableFieldType.EXACT,
              value: companyId,
            },
          }
        }

        fetchCourse({
          variables: {
            filter,
            perPage: 0,
          },
        })
      }
    }, 200)

  const handleSelectChange = (e: string, field: string): void => {
    clearTimeout(ref.current as NodeJS.Timeout)
    if (e) {
      ref.current = loadDataOptions(e, field)
    }
  }

  const onCancelClick = useCallback(() => {
    if (defaultFilter) {
      setFilterOptions((prev: IGroupFilterDrawerOptions) => ({
        ...prev,
        filterOptions: defaultFilter as IGroupsFilterOptions,
        filterValues: null,
        opened: false,
      }))
    } else {
      setFilterOptions({
        filterOptions: null,
        filterValues: null,
        opened: false,
      })
    }
  }, [defaultFilter, setFilterOptions, filterOptions])

  return {
    onCancelClick,
    filterLoading,
    handleChange,
    handleSubmit,
    handleSelectChange,
    values,
    errors,
    touched,
    adminData,
    adminLoading,
    courseData,
    courseLoading,
    error,
    setFieldValue,
    setError,
    createDate,
    setCreateDate,
    t,
  }
}
