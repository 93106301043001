import { styled } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px 16px 40px 16px;
  border-radius: 8px;
  background-color: white;
  position: relative;
  border: 1px solid #d6dae0;
  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 4px;
  }

  h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`
export const KebabMenuContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  z-index: 100;
  border-radius: 4px;
  cursor: pointer;
`

export const StyledNoImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebf6f2;
  width: 100%;
  height: 250px;
`

export const StyledNoImageDrawer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebf6f2;
  width: 87px;
  height: 160px;
  svg {
    width: 60px;
    height: 60px;
  }
`

export const DeleteSpan = styled.span`
  color: #ea382a;
`
export const CheckboxContainer = styled.div`
  display: flex;
`
export const ImageCheckboxContainer = styled.div`
  position: relative;
  display: inline-block;
`

export const PositionedCheckBox = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
`
