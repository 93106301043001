import { FormContainer, FormGroupWrapper, FormFooter, FormButtons } from 'components/common/Form'
import { DrawerHeader, DrawerTitle, DrawerContent } from 'components/DrawerLayout/styled-components'
import React, { useState } from 'react'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import IconButton from '@mui/material/IconButton'
import Drawer from 'components/common/Drawer'
import { useUserValue } from 'context/UserContext'
import { TOGGLE_CLOSE_DRAWER } from 'store/types'
import { useSwal } from 'hooks/useSwal'
import { useFormik } from 'formik'
import { Button } from 'components/common/Button'
import * as Yup from 'yup'
import { ChangePasswordFields } from './ChangePasswordFields'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { changePasswordService } from 'services/users/editProfileService'
import { useTranslation } from 'react-i18next'

interface IAdminProfileFormData {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

const formData: IAdminProfileFormData = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
}
export const isValidPassword = (str: string): boolean => {
  return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[.,@#$%^&*])(.{8,})/.test(str)
}

export const ChangePassword: React.FC<{
  setIsClickedOnChangePassword: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ setIsClickedOnChangePassword }) => {
  const { t } = useTranslation()
  const [isDrawerOpen, setOpenDrawer] = useState(true)
  const [state, dispatch] = useUserValue()
  const [currentPasswordError, setCurrentPasswordError] = useState('')
  const { changePassword } = changePasswordService()
  const { setSnackbar } = useSnackbarAlert()

  const useChangePassword = async (formData: IAdminProfileFormData): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }
    const { oldPassword, newPassword, confirmPassword } = formData
    if (isValidPassword(newPassword) && newPassword === confirmPassword) {
      const { errors } = await changePassword({
        variables: {
          oldPassword,
          newPassword,
          confirmPassword,
        },
      })

      if (errors && errors.length) {
        message = errors[0].message
        variant = 'error'
        setCurrentPasswordError(message)
      } else {
        message = `profile successful updated`
        variant = 'success'
        handleDrawerClose()
      }
      setSnackbar({ message, variant })
    }
  }

  const { handleSubmit, handleChange, values, touched } = useFormik({
    initialValues: formData,
    validationSchema: Yup.object({
      oldPassword: Yup.string().required('Current password is required').min(4, '4'),
      newPassword: Yup.string().required('New password is required').min(8, '8'),
      confirmPassword: Yup.string().required('Confirm password is required').min(8, '8'),
    }),
    onSubmit: (values: IAdminProfileFormData) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useChangePassword(values)
    },
  })

  const handleDrawerClose = (): void => {
    setIsClickedOnChangePassword(false)
    setOpenDrawer(false)
    dispatch({
      type: 'SET_CLOSE_DRAWER',
      payload: true,
    })
  }
  const { fireSwal } = useSwal()

  const handleCloseDrawer = (): void => {
    const { text } = state.closeDrawer
    if (!text) {
      handleDrawerClose()
    } else {
      fireSwal({
        title: 'If you cancel the operation the changes you have made will not be saved',
        text: t('popups.sure'),
        confirmText: 'Yes, Cancel!',
        cancelText: 'No',
        onConfirm: () => {
          handleDrawerClose()
          dispatch({
            type: TOGGLE_CLOSE_DRAWER,
            payload: {
              text: '',
              confirm: false,
            },
          })
        },
      })
    }
  }

  const handleValueChange = (e: string | React.FormEvent<HTMLInputElement>): void => {
    const { confirm } = state.closeDrawer
    if (!confirm) {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: 'If you cancel the operation the changes you have made will not be saved',
          confirm: true,
        },
      })
    }
    handleChange(e)
  }

  return (
    <>
      <Drawer totalWidth={'418px'} opened={isDrawerOpen} toggleDrawer={(): void => undefined}>
        <DrawerHeader>
          <DrawerTitle>{t('actions.change_password')}</DrawerTitle>
          <IconButton onClick={handleCloseDrawer}>
            <HighlightOffRoundedIcon />
          </IconButton>
        </DrawerHeader>
        <DrawerContent headerHeight={68}>
          <FormContainer
            onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            <FormGroupWrapper>
              <ChangePasswordFields
                values={values}
                touched={touched}
                handleChange={handleValueChange}
                currentPasswordError={currentPasswordError}
                t={t}
              />
            </FormGroupWrapper>
            <FormFooter>
              <FormButtons>
                <Button
                  onClick={handleCloseDrawer}
                  background='#E0E1E2'
                  text={t('actions.cancel')}
                  textColor={'#414141'}
                  type='small'
                />
                <Button
                  onClick={(): void => handleSubmit()}
                  background='#06C68F'
                  text={t('actions.save')}
                  type='small'
                />
              </FormButtons>
            </FormFooter>
          </FormContainer>
        </DrawerContent>
      </Drawer>
    </>
  )
}

// const ButtonsContainer = styled.div`
//   width: 210px;
//   display: flex;
//   justify-content: space-between;
//   margin: 14px;
//   margin-left: 22px;
// `
