import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

const SelectedItemIndicator = ({ count = 0 }: { count: number }): ReactElement => {
  if (!count) return <></>
  const { t } = useTranslation()

  return (
    <StyledIndicator>
      <strong>{count}</strong> {t('general.selected')}
    </StyledIndicator>
  )
}

export default SelectedItemIndicator

const StyledIndicator = styled.div`
  margin: 0 10px;
  opacity: 0.5;
`
