import styled from 'styled-components'

export const StyledFromGroupContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const CourseDetailsContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
`

export const SkilTestDetailsField = styled.div`
  margin-bottom: 16px;
  width: 100%;
  ${({ hidden }: { hidden?: boolean }): string => (hidden ? 'display: none' : '')}
`

export const SkillTestDetailsFieldTitle = styled.label`
  display: block;
  width: 100%;
  font-size: 16px;
  margin-bottom: 10px;
  opacity: 1;
  font-weight: 600;
`
export const BadgePlaceholder = styled.div`
  width: 150px;
  height: 150px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;

  &:hover {
    color: rgb(6, 198, 143);
  }
`

export const BadgeField = styled.div`
  height: 170px;
`
