// @deprecated
/* eslint-disable */

import { AvatarInput } from 'components/common/AvatarInput/AvatarInput'
import { Button } from 'components/common/Button'
import { FormField, FormFields } from 'components/common/Form/Form'
import DatePicker from 'components/common/DatePicker/DatePicker'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'
import { SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import { genderOptions } from 'components/UsersLayout/staticData'
import React, { useEffect } from 'react'
import styled from 'styled-components'

const CreateAdmin = ({
  handleSubmit,
  handleChange,
  values,
  errors,
  touched,
  setFieldValue,
  addUserLoading,
  t,
}: any) => {
  const onDateChange = (date: Date | null): void => {
    const birthDate = date
    setFieldValue('birthDate', date)

    if (birthDate) {
      values.birthDate = birthDate
    }
  }

  useEffect(() => {
    setFieldValue('phoneFieldValue', (values.phoneFields && values.phoneFields.label) || null)
    values.phoneFieldValue = values.phoneFields ? values.phoneFields.value : null
  }, [values.phoneFields])

  return (
    <Container>
      <FormFields>
        <FormField>
          <AvatarInput setFieldValue={setFieldValue} initialAvatar={values.avatar} />
        </FormField>

        <FormField width='48%'>
          <TextInput
            error={!!errors.firstName}
            errorMessage={errors.firstName}
            touched={touched.firstName}
            label={`${t('user_details.first_name')}*`}
            name='firstName'
            size='small'
            type='text'
            value={values.firstName}
            onChange={handleChange}
          />
        </FormField>

        <FormField width='48%'>
          <TextInput
            error={!!errors.lastName}
            errorMessage={errors.lastName}
            touched={touched.lastName}
            label={`${t('user_details.last_name')}*`}
            name='lastName'
            size='small'
            type='text'
            value={values.lastName}
            onChange={handleChange}
          />
        </FormField>

        <FormField width='48%'>
          <TextInput
            error={!!errors.email}
            errorMessage={errors.email}
            touched={touched.email}
            label={`${t('user_details.email')}*`}
            name='email'
            size='small'
            type='text'
            value={values.email}
            onChange={handleChange}
          />
        </FormField>

        <FormField width='48%'>
          <PhoneCountryCode
            label={t('user_details.phone')}
            selectLabel={t('form_fields.dial_code')}
            selectValue={values.phoneFields || null}
            selectChange={(e: any) => setFieldValue('phoneFields', e)}
            inputLabel={t('user_details.phone')}
            inputValue={values.phone}
            inputChange={handleChange}
            error={errors.phone || errors.phoneFields ? true : false}
            errorMessage={
              errors.phone || errors.phoneFields ? errors.phone || errors.phoneFields : ''
            }
            touched={touched.phone || touched.phoneFields}
          />
        </FormField>

        <FormField width='48%' style={{ position: 'relative' }}>
          <SelectField
            placeholder={t('user_details.gender')}
            options={genderOptions}
            value={values.gender}
            onChange={(e: any) => setFieldValue('gender', e)}
            isClearable
          />
        </FormField>

        <FormField width='48%'>
          <DatePicker
            label={t('user_details.birthday')}
            touched={touched.birthDate}
            error={errors.birthDate ? true : false}
            errorMessage={errors.birthDate ? errors.birthDate : ''}
            name='birthDate'
            value={values.birthDate}
            onChange={onDateChange}
          />
        </FormField>

        <FormField width='48%'>
          <TextInput
            label={t('form_fields.job_title')}
            size='small'
            name='jobTitle'
            type='text'
            value={values.jobTitle}
            onChange={handleChange}
          />
        </FormField>
        <FormField width='48%'>
          <TextInput
            label={t('form_fields.location')}
            size='small'
            name='location'
            type='text'
            value={values.location}
            onChange={handleChange}
          />
        </FormField>

        <FormField>
          <TextInput
            error={!!errors.note}
            errorMessage={errors.note}
            touched={touched.note}
            label={t('user_details.biography')}
            size='small'
            name='note'
            type='text'
            multiline
            rows='3'
            value={values.biography}
            onChange={handleChange}
            fullWidth={true}
          />
        </FormField>

        <FormField>
          <TextInput
            error={!!errors.note}
            errorMessage={errors.note}
            touched={touched.note}
            label={t('form_fields.note')}
            size='small'
            name='note'
            type='text'
            multiline
            rows='3'
            value={values.note}
            onChange={handleChange}
            fullWidth={true}
          />
        </FormField>

        <FormField>
          <ButtonContainer>
            <ButtonItem style={{ marginRight: '10px' }}>
              <Button
                text={t('company_wizard_layout.save_and_create_another')}
                type='small'
                onClick={() => handleSubmit('reset')}
                isDisabled={addUserLoading}
                background='#06C68F'
              />
            </ButtonItem>

            <ButtonItem>
              <Button
                text={t('company_wizard_layout.save_and_review')}
                type='small'
                onClick={() => handleSubmit('review')}
                isDisabled={addUserLoading}
                background='#06C68F'
              />
            </ButtonItem>
          </ButtonContainer>
        </FormField>
      </FormFields>
    </Container>
  )
}

export default CreateAdmin

const Container = styled.div`
  box-sizing: border-box;
  padding: 0px 30px;
`

export const AvatarContainer = styled.div`
  width: 100%;
  height: 110px;
  outline: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .input-button {
    position: absolute;
    top: 10px;
    left: 9px;
    input {
      opacity: 0;
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      overflow: hidden;
    }
  }
  img {
    width: 96px;
    height: 96px;
  }
`
export const InputLabel = styled.label`
  cursor: pointer;
  color: #fff;
  background: #06c68f;
  font-size: 11px;
  padding: 4px 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  span {
    margin-left: 7px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ButtonItem = styled.div``
