import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { EDIT_MODULE } from 'gql/modules.query'
import { IModule } from 'pages/modules/ModulesList.interface'

interface IEditModuleServiceResponse {
  editModuleMutation: MutationTuple<IEditModuleResponse, IEditModuleParams>[0]
  loading: boolean
  error?: ApolloError
}

interface IEditModuleResponse {
  editModule: IModule
}

interface IEditModuleParams {
  moduleId: string
  input: IModule
}

const editModuleService = (): IEditModuleServiceResponse => {
  const [editModuleMutation, { loading, error }] = useMutation<
    IEditModuleResponse,
    IEditModuleParams
  >(EDIT_MODULE, {})

  return {
    editModuleMutation,
    loading,
    error,
  }
}

export default editModuleService
