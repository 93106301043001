import styled from 'styled-components'

// AddPurchasedCourseDrawer

export const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

// Step1

export const FormFooter = styled.footer`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.3);

  nav {
    margin-top: 0;
  }
`
export const FormButtons = styled.div`
  align-self: flex-end;
  display: flex;

  div:not(:last-child) {
    margin-right: 10px;
  }
`
