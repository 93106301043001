import styled from 'styled-components'

export const Action = styled.div<{
  $hoverColor?: string
  hoverBackground?: string
  disabled?: boolean
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  transition: 0.3s ease;
  border-radius: 5px;

  ${({ disabled }): string =>
    (disabled &&
      `
  cursor: not-allowed;
  opacity: 0.5;
`) ||
    ''}

  &:hover {
    /* background: ${({ hoverBackground }): string => hoverBackground || 'rgba(0, 0, 0, 0.1)'}; */
    ${({ $hoverColor }): string =>
      ($hoverColor &&
        `svg path {
        fill: ${$hoverColor};
         }`) ||
      ''}
  }
  svg {
    font-size: 1rem;
  }
`
