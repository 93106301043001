import { useMutation, MutationTuple, ApolloError } from '@apollo/client'

import { DELETE_COMPANY } from 'gql/companies.query'
import { ICompany } from 'pages/companies/CompanyDetails/types'

interface IDeleteCompanyServiceResponse {
  deleteCompanyMutation: MutationTuple<IDeleteCompanyResponse, IDeleteCompanyVariables>[0]
  loading: boolean
  error?: ApolloError
}

interface IDeleteCompanyResponse {
  deleteCompany: ICompany
}

interface IDeleteCompanyVariables {
  ids: string[]
}

const deleteCompanyService = (): IDeleteCompanyServiceResponse => {
  const [deleteCompanyMutation, { loading, error }] = useMutation<
    IDeleteCompanyResponse,
    IDeleteCompanyVariables
  >(DELETE_COMPANY)

  return {
    deleteCompanyMutation,
    loading,
    error,
  }
}

export default deleteCompanyService
