import { ApolloError, useQuery } from '@apollo/client'
import { GET_TASK_COMMENT_BY_TASK_ID } from 'gql/tasks.query'
import { ITaskComment } from 'pages/tasks/CommentsDrawer/TaskComments.interface'

interface IUseFetchTaskCommentsServiceResponse {
  taskComments?: IGetTaskCommentsResponse | null
  loading: boolean
  error?: ApolloError
  taskCommentsRefetch: () => void
}

export interface IGetTaskCommentsResponse {
  data: ITaskComment[]
  offsetId: string
  count: number
}

export interface IGetTaskCommentsByTaskIdResponse {
  getTaskComments: IGetTaskCommentsResponse
}

export interface IGetTaskCommentsByTaskIdParams {
  filter: { taskId: string } | null
  skip: number
  count: number
}

export const useFetchTaskComments = (
  taskId: string,
  skip: number = 0,
  count: number = 10,
): IUseFetchTaskCommentsServiceResponse => {
  const filter = taskId ? { taskId } : null

  const { data, loading, error, refetch } = useQuery<
    IGetTaskCommentsByTaskIdResponse,
    IGetTaskCommentsByTaskIdParams
  >(GET_TASK_COMMENT_BY_TASK_ID, {
    variables: {
      filter,
      skip,
      count,
    },
    fetchPolicy: 'cache-first',
    skip: !taskId,
  })

  return {
    taskComments: data && data.getTaskComments,
    loading,
    error,
    taskCommentsRefetch: refetch,
  }
}
