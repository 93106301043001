import { ApolloError, useQuery } from '@apollo/client'
import { GET_TESTS_NAME } from 'gql/tests/tests.query'
import { ITestAssessmentsData, ITestAssessmentsVars } from './testAssessment.inteface'
import { IQuiz } from 'pages/quizzes/quiz.interface'

export const useTestAssessmentsSearch = (
  companyId: string,
): {
  testAssessments: IQuiz[]
  loading: boolean
  error?: ApolloError
} => {
  const { data, loading, error } = useQuery<ITestAssessmentsData, ITestAssessmentsVars>(
    GET_TESTS_NAME,
    {
      variables: {
        companyId,
        currentPage: 0,
        perPage: 0,
        filter: {},
      },
    },
  )

  return {
    testAssessments: data?.getTestAssessmentsByCompanyId.data || [],
    loading,
    error,
  }
}
