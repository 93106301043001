import styled, { CSSProperties } from 'styled-components'

const PREFIX = 'ModuleTopicsGrid'

export const classes = {
  modal: `${PREFIX}-modal`,
  paper: `${PREFIX}-paper`,
}

export const Root = styled.div`
  padding-top: 20px;
  background-color: #f5f6fa;
`

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ToolbarActions = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

// gridConfig

export const Action = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  gap: 6px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  svg path {
    fill: #8f959d;
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

// nested Grid

export const Container = styled.div`
  color: rgba(0, 0, 0, 0.87);
`

export const Header = styled.header`
  > div {
    font-weight: 500;
    background: #fff;
    margin-bottom: 30px;
    padding: 10px;
  }
  .MuiList-root {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    border-bottom: 0.5px solid #ccc;
  }
`

export const StyledListLastItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #020210;
  font-weight: 500;
  padding-right: 75px;
  font-size: 16px;
`
export const StyledHeaderContainer = styled.div`
  display: flex;
  background-color: #fff;
  width: 100%;
  margin-top: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
  /* padding-left: 35px; */
  li {
    color: #020210;
    font-weight: 500;
    font-size: 16px;
  }
`
export const StyledListContainer = styled.div`
  .MuiList-root {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
`

export const StyledLessonVideoContainer = styled.div`
  display: flex;
  svg path {
    fill: #808080;
    filter: drop-shadow(0 0 2px #ffffff);
    cursor: pointer;
  }
  svg path:hover {
    fill: #000;
    transition: fill 0.5s ease;
  }
`

export const StyledGridComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  color: #020210;
  font-weight: 500;
`

export const StyledGridComponentContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #020210;
  font-weight: 500;
`
export const StyledNestedComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  font-size: 15px;
  font-weight: 400;
`

export const Body = styled.div`
  background: #fafafa;
  position: relative;
  > div:not(:last-child) {
  }
`

export const StyledTopicColumn = styled.div`
  display: flex;
  height: 72px;
  align-items: center;
  /* margin-bottom: 20px;  */
  /* padding: 10px; */
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  /* padding: 0 10px; */
  position: relative;
  background: #fff;
  /* padding-left: 35px; */
  &:hover {
    background: #edeef2;
    transition: background 0.3s ease;
  }
`
export const CollapseIcon = styled.div`
  cursor: pointer;
  /* position: absolute; */
  transform: rotate(90deg);
  transition: transform 0.2s ease;
  display: flex;
  align-items: center;
  /* padding: 10px;  */

  padding-right: 5px;
  svg {
    font-size: 1.5rem;
  }
  svg path {
    fill: #8f959d;
  }

  &.collapsed {
    transform: translateY(-10%) rotate(-90deg);

    /* position: fixed; */
  }

  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
`

export const Empty = styled.div`
  width: 0;
`
export const StyledEditTopicContainer = styled.div`
  display: flex;
  align-items: center;
  width: 720px;
`
export const StyledEditTopicContainerInput = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  width: 720px;
`

export const StyledTooltipSpan = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const StyledTooltipNestedSpan = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.2em;
  max-height: 2.4em;
  width: 594px; //+ 38
  padding-bottom: 2px;
  font-size: 15px;
`
export const StyledParentContainer = styled.div`
  padding-left: 20px;
  cursor: pointer;
`

export const StyledCollapseContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 4px;
`

export const styles: Record<string, CSSProperties> = {
  thumbnail: {
    width: '72px',
    height: '48px',
    borderRadius: '4px',
    padding: '4px',
  },
  playButton: {
    position: 'absolute',
    color: 'black',
    fill: 'black',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  topicName: {
    fontSize: '16px',
  },
}
