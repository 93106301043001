import styled from 'styled-components'

export const ImageCropperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .actions {
    /* width: 500px; */
    /* background: #fff; */
    display: flex;
    gap: 10px;
    margin-top: 5px;
  }
`
export const FormButtons = styled.div`
  display: flex;
  gap: 15px;
  padding-top: 5px;
`
