import { useQuery } from '@apollo/client'
import { GET_GROUPED_STUDENTS_FOR_PROGRESS_QUERY } from 'gql/users.query'
import {
  GetGroupedStudentsByCourseIdParams,
  GetGroupedStudentsByCourseIdResponse,
  IGetGroupedStudentsByCourseIdReturn,
} from './testAnalytics.interface'

export const PER_PAGE = 12

export const getGroupedStudentsByCourseIdService = (
  variables: GetGroupedStudentsByCourseIdParams,
): IGetGroupedStudentsByCourseIdReturn => {
  const { data, loading, error, refetch } = useQuery<
    GetGroupedStudentsByCourseIdResponse,
    GetGroupedStudentsByCourseIdParams
  >(GET_GROUPED_STUDENTS_FOR_PROGRESS_QUERY, {
    variables,
  })

  return {
    data: (data && data.getGroupedStudentsByCourseIdV2) || null,
    loading,
    error: error?.message,
    refetch,
  }
}
