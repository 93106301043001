import { useMutation, MutationTuple } from '@apollo/client'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { ADD_STUDENTS_IN_GROUP, GET_GROUP, MOVE_STUDENTS_IN_GROUP } from 'gql/group/group.query'
import { IMessageResponse } from 'interfaces/common'
import { IGroup } from 'interfaces/groups'

interface IAddStudentsResponse {
  addStudentsInGroup: IGroup
}

interface IAddStudentsVariables {
  groupId: string
  groupName: string
  ids: string[]
}

interface IMoveStudentsResponse {
  moveStudentsInGroup: IMessageResponse
}

interface IMoveStudentsVariables {
  groupId: string
  oldGroupId: string
  userId: string
}

interface IAddStudentsServiceResponse {
  addStudents: MutationTuple<IAddStudentsResponse, IAddStudentsVariables>[0]
  moveStudents: MutationTuple<IMoveStudentsResponse, IMoveStudentsVariables>[0]
}

const addStudentsService = (companyId: string): IAddStudentsServiceResponse => {
  const refetchQueries = [
    {
      query: GET_GROUP,
      variables: {
        filter: {
          company: {
            type: LowerCaseFilterableFieldType.EXACT,
            value: companyId,
          },
        },
      },
    },
  ]
  const [addStudents] = useMutation<IAddStudentsResponse, IAddStudentsVariables>(
    ADD_STUDENTS_IN_GROUP,
    {
      refetchQueries,
    },
  )
  const [moveStudents] = useMutation<IMoveStudentsResponse, IMoveStudentsVariables>(
    MOVE_STUDENTS_IN_GROUP,
    {
      refetchQueries,
    },
  )

  return {
    addStudents,
    moveStudents,
  }
}

export default addStudentsService
