import styled from 'styled-components'

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FormHeader = styled.header`
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const FormTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
`

export const FormFields = styled.div`
  padding: 30px 20px 0 20px;
  display: flex;
  flex-direction: column;

  & > div {
    width: 48%;
    margin-bottom: 30px;
    div {
      width: 100%;
    }
  }
`

export const FormButtons = styled.div`
  padding: 0 20px;
  align-self: flex-end;
  display: flex;

  div:not(:last-child) {
    margin-right: 10px;
  }
`
