import { styled } from 'styled-components'

export const QuestionType = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 4px 12px;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  background: #f2f7fb;
  color: #20a8c6;

  div {
    background: #f2f7fb;
  }
`

export const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
