import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { DELETE_TEST } from 'gql/tests/tests.query'

interface IDeleteTestServiceResponse {
  deleteTestMutation: MutationTuple<IDeleteTestResponse, IDeleteTestParams>[0]
  loading: boolean
  error?: ApolloError
}

export interface IDeleteTestsResponse {
  ids: string[]
}

interface IDeleteTestResponse {
  deleteTestAssessments: IDeleteTestsResponse
}

interface IDeleteTestParams {
  ids: string[]
}

const deleteTestService = (): IDeleteTestServiceResponse => {
  const [deleteTestMutation, { loading, error }] = useMutation<
    IDeleteTestResponse,
    IDeleteTestParams
  >(DELETE_TEST)

  return {
    deleteTestMutation,
    loading,
    error,
  }
}

export default deleteTestService
