import React from 'react'
import CreatableSelect from 'react-select/creatable'
import { ErrorMessageContainer } from './styled-components'
import { ICreatableSelect } from './creatableSelect.interface'

const CreatableSelectWithError: React.FC<ICreatableSelect> = ({
  placeholder,
  options,
  errorMessage,
  touched,
  value,
  onChange,
  isLoading,
  isDisabled,
}) => {
  const customStyles = {
    control: (provided: React.CSSProperties) => ({
      ...provided,
      border: !!errorMessage && touched ? '1px solid #f4493c' : '1px solid rgba(0, 0, 0, 0.23)',
    }),
    placeholder: (provided: React.CSSProperties) => ({
      ...provided,
      color: !!errorMessage && touched ? '#f4493c' : '#757575',
    }),
  }

  return (
    <div>
      <CreatableSelect
        placeholder={placeholder}
        options={options}
        value={value}
        onChange={onChange}
        isLoading={isLoading}
        isDisabled={isDisabled}
        styles={customStyles}
      />
      {!!errorMessage && touched && (
        <ErrorMessageContainer>
          <>{errorMessage}</>
        </ErrorMessageContainer>
      )}
    </div>
  )
}

export default CreatableSelectWithError
