/* eslint-disable */

import { EventEmitter } from 'fbemitter'

export const DrawerEventEmitter = new EventEmitter()

export interface DrawerEvent {
  event: string
  arg?: any
}

export function showDrawer(event: string, arg?: any): void {
  DrawerEventEmitter.emit(event, arg)
}

export function dismissDrawer(event: string): void {
  DrawerEventEmitter.emit(event)
}
