import { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { GET_ALL_QUIZ } from 'gql/quiz/quiz.query'
import { useDeleteQuestion } from 'hooks/questions/useDeleteQuestion'
import { useFetchQuestions, PER_PAGE } from 'hooks/questions/useQuestions'
import { IQuestion } from 'interfaces/questions'
import { useTranslation } from 'react-i18next'
import { useUserValue } from 'context/UserContext'
import { useSwal } from 'hooks/useSwal'
import { DrawerEventEmitter } from 'helpers/drawer'
import { TOGGLE_CLOSE_DRAWER } from 'store/types'
import { actionConfig, columnConfig, questionActionsConfig } from 'pages/questions/gridConfig'
import { UseQuestionsListParams } from 'pages/modules/ModuleDetail/QuestionList/questionList.interface'
import { IQuiz, ISelectAll } from 'pages/quizzes/quiz.interface'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { ISelectPage } from 'components/common/FilterInterface/filter.interface'
import { IQuestionsFilterData, IQuestionsFilterOption } from './useQuestionsLayout'
import { ISelectDropDownOption } from 'components/common/SelectDropDown/selectDropDown.interface'
import removeKeys from 'utils/removeKeys'
import { IUseQuestionsGridReturn } from './useQuestionsGrid.interface'
import { debounce } from 'lodash'

const useQuestionsGrid = ({
  currentModule,
  manualCompanyId,
  topics,
  refetchModule,
  handleTabChange,
  setOpenCreateQuizDrawer,
}: UseQuestionsListParams): IUseQuestionsGridReturn => {
  const { t } = useTranslation()
  const [state, dispatch] = useUserValue()
  const { questionsType = '', actionId = '' } = currentModule
    ? { questionsType: 'module', actionId: currentModule.moduleId }
    : {}

  const { questions, loading, refetch } = useFetchQuestions(questionsType, actionId)

  let moduleId: string | null = null
  let courseIds: string[] = []
  const topicIds: string[] = []
  const lessonIds: string[] = []

  if (topics) {
    topics.forEach(topic => {
      topicIds.push(topic.id as string)
      topic.lessons?.forEach(lesson => {
        lessonIds.push(lesson.id as string)
      })
    })
  }

  if (currentModule) {
    if (currentModule.moduleId) moduleId = currentModule.moduleId
    if (currentModule.courses) courseIds = currentModule.courses.map(course => course.courseId)
  }

  const ids = [moduleId, ...topicIds, ...lessonIds, ...courseIds]

  const { data: quizList, refetch: refetchQuizzes } = useQuery(GET_ALL_QUIZ, {
    variables: {
      query: JSON.stringify({ actionId: { $in: ids } }),
    },
  })

  const [currentPage, setCurrentPage] = useState<number>(1)
  const navigate = useNavigate()
  const { deleteQuestion } = useDeleteQuestion()
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState<ISelectPage>([])
  const [selectAll, setSelectAll] = useState<ISelectAll>({})
  const [initialLoad, setInitialLoad] = useState(true)
  const [LinkQuestionDrawerOpened, setLinkQuestionDrawerOpened] = useState<boolean>(false)
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<IQuestionsFilterData>({} as IQuestionsFilterData)
  const [isFiltered, setIsFiltered] = useState(false)
  const [filterOptions, setFilterOptions] = useState<IQuestionsFilterOption>({})
  const [filtered, setFiltered] = useState<boolean>(false)

  const { fireSwal } = useSwal()

  useEffect(() => {
    if (!loading) {
      setInitialLoad(false)
    }
  }, [loading])

  const selectItem = (id: string): void => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = (): void => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = questions?.data.map((question: IQuestion) => question.id) || []
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const updatedFilterOptions: IQuestionsFilterOption = {
      ...filterOptions,
      question: {
        type: LowerCaseFilterableFieldType.MATCH,
        value: searchValue,
      } as ISelectDropDownOption,
    }
    refetch({
      type: questionsType,
      id: actionId,
      filter: updatedFilterOptions,
      currentPage: 1,
    })
    setFilterOptions(updatedFilterOptions as IQuestionsFilterOption)
    setFiltered(true)
  }
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      if (value === '') return
      const search = { type: LowerCaseFilterableFieldType.MATCH, value }
      refetch({
        type: questionsType,
        id: actionId,
        filter: {
          ...filterOptions,
          question: search,
        },
        currentPage: 1,
      })
    }, 500),
    [questionsType, actionId, filterOptions, refetch],
  )

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target

    setSearchValue(value)
    debouncedSearch(value)

    if (value === '') {
      const updatedFilterOptions = removeKeys(filterOptions, ['question']) as IQuestionsFilterOption
      refetch({
        type: questionsType,
        id: actionId,
        filter: updatedFilterOptions,
      })
      setFilterOptions(updatedFilterOptions)
      setIsFiltered(false)
    }
  }

  const handleCreateQuestionClick = (moduleId?: string, name?: string): void => {
    DrawerEventEmitter.emit('openDrawer', 'createQuestion', true, {
      data: {
        moduleId,
        name,
        manualCompanyId,
        refetch: () => {
          if (refetchModule) {
            refetchModule()
          }
          setSelectAll({})
          setSelectedItem({})
          refetch()
        },
      },
    })
  }

  const handleLinkQuestionToModuleClick = (): void => {
    setLinkQuestionDrawerOpened(true)
  }

  const handleViewClick = (questionId: string): void => {
    navigate(`/questions/${questionId}`)
  }

  const handleEditClick = (id: string): void => {
    const title: string = currentModule
      ? t('popups.edit_question_in_module')
      : t('popups.edit_question_gerenal')
    fireSwal({
      title,
      confirmText: t('popups.confirm_edit'),
      onConfirm: () => {
        DrawerEventEmitter.emit('openDrawer', 'editQuestion', true, {
          id,
          data: {
            moduleId: currentModule ? currentModule.moduleId : '',
            name: currentModule ? currentModule.name : '',
            manualCompanyId,
          },
        })
      },
    })
  }

  const handleDuplicateClick = (id: string): void => {
    fireSwal({
      title: t('popups.edit_duplicate_question'),
      confirmText: t('popups.confirm_edit_duplicate'),
      onConfirm: () => {
        DrawerEventEmitter.emit('openDrawer', 'editQuestion', true, {
          id,
          data: {
            moduleId: currentModule ? currentModule.moduleId : '',
            name: currentModule ? currentModule.name : '',
            duplicate: true,
            manualCompanyId,
            refetch: () => refetch(),
          },
        })
      },
    })
  }

  const fireConfirmDeleteQuestionsPopup = (
    ids: string[],
    edited: boolean,
    isMultiple: boolean,
  ): void => {
    const moduleId: string = currentModule ? (currentModule.moduleId as string) : ''
    let mainText = ''
    if (!moduleId) {
      mainText = isMultiple ? t('popups.delete_question_many') : t('popups.delete_question_single')
    } else {
      mainText = edited
        ? t('popups.delete_question_edited')
        : t('popups.delete_question_many_in_module')
    }

    fireSwal({
      title: `${mainText}`,
      confirmText: t('popups.confirm_remove'),
      onConfirm: () => {
        deleteQuestion(moduleId, ids, () => {
          if (refetchModule) {
            refetchModule()
          }
          refetch()
        })
        setSelectedItem([])
      },
    })
  }

  const fireMultipleQuestionsIsUsedInQuizPopup = (): void => {
    fireSwal({
      title: `${t('popups.questions_is_used_in_quizzes')} ${t('general.questions')}`,
      confirmText: t('actions.close'),
      onConfirm: () => undefined,
    })
  }
  const fireQuestionIsUsedInQuizPopup = (quizzes: IQuiz[], ids: string[]): void => {
    const moduleId: string = currentModule ? (currentModule.moduleId as string) : ''
    const quizNames = quizzes.map((quiz: IQuiz) => `• ${quiz.parentName} - ${quiz.name}`).join('\n')
    const alertText =
      quizNames.length > 1
        ? `${t('popups.question_is_used_in_quizzes')} \n${quizNames}.\n`
        : `${t('popups.question_is_used_in_quiz')} \n${quizNames}.\n`
    fireSwal({
      title: `${alertText} ${t('popups.confirm_deletion')}`,
      confirmText: t('popups.confirm_remove'),
      customClass: {
        title: 'fireQuestionIsUsedInQuizPopup',
      },
      onConfirm: () => {
        deleteQuestion(moduleId, ids, () => {
          if (refetchModule) {
            refetchModule()
          }
          refetch()
        })
        setSelectedItem([])
      },
      width: `50%`,
    })
  }

  const handleDeleteClick = (ids: string[], edited: boolean): void => {
    refetchQuizzes()

    const isMultiple = ids.length > 1

    const quizzesWhereQuestionIsUsed = quizList?.getAllQuizzes.filter(
      (quiz: IQuiz) =>
        quiz.IQTestType !== 'final' && quiz.questions?.some((id: string) => ids.includes(id)),
    )

    if (quizzesWhereQuestionIsUsed.length) {
      isMultiple
        ? fireMultipleQuestionsIsUsedInQuizPopup()
        : fireQuestionIsUsedInQuizPopup(quizzesWhereQuestionIsUsed, ids)
    } else {
      fireConfirmDeleteQuestionsPopup(ids, edited, isMultiple)
    }
  }

  // const questionActions = questionActionsConfig(
  //   handleCreateQuestionClick,
  //   handleLinkQuestionToModuleClick,
  //   t,
  // )
  const actionHandler = (event: string, field: IQuestion): void => {
    switch (event) {
      case 'view':
        return handleViewClick(field.id)
      case 'edit':
        return handleEditClick(field.id)
      case 'duplicate':
        return handleDuplicateClick(field.id)
      case 'delete':
        return handleDeleteClick([field.id], field.edited as boolean)
      default:
    }
  }

  const handleCloseDrawer = (): void => {
    const { text, confirm } = state.closeDrawer
    if (!text && !confirm) {
      setLinkQuestionDrawerOpened(false)
    } else {
      fireSwal({
        title: text,
        text: t('popups.sure'),
        onConfirm: () => {
          setLinkQuestionDrawerOpened(false)
          dispatch({
            type: TOGGLE_CLOSE_DRAWER,
            payload: {
              text: '',
              confirm: false,
            },
          })
        },
        confirmText: t('popups.confirm_cancel'),
        cancelText: t('general.no'),
      })
    }
  }

  const handlePaginationClick = (event: React.ChangeEvent<unknown>, value: number): void => {
    if (value !== currentPage) {
      refetch({
        type: questionsType,
        id: actionId,
        filter: filterOptions,
        currentPage: value,
        perPage: PER_PAGE,
      })
      setCurrentPage(value)
    }
  }

  const handleFilterClick = (filter: IQuestionsFilterOption): void => {
    setSelectedItem({})
    setSelectAll({})
    const updatedFilterOptions: IQuestionsFilterOption = {
      ...filterOptions,
      ...filter,
    }
    refetch({
      filter: updatedFilterOptions,
      currentPage: 1,
    })
    setFilterOptions(updatedFilterOptions)
  }

  const handleCreateQuizClick = (e: React.MouseEvent<HTMLElement>): void => {
    if (handleTabChange) {
      handleTabChange(e, 2)
    }
    if (setOpenCreateQuizDrawer) {
      setOpenCreateQuizDrawer(true)
    }
  }

  const actions = actionConfig(state.userPermission, actionHandler, t)
  const config = columnConfig(selectItem, t)

  const redirectToQuestion = (question: IQuestion): void => {
    navigate(`/questions/${question.id}`)
  }

  const handleResetClick = (): void => {
    setFilterData({})
    setSearchValue('')
    refetch({
      type: questionsType,
      id: actionId,
      filter: {},
      currentPage: 1,
      perPage: PER_PAGE,
    })
    setIsFiltered(false)
    setFiltered(false)
  }

  const questionActions = questionActionsConfig(
    handleCreateQuestionClick,
    handleLinkQuestionToModuleClick,
    t,
  )

  return {
    questions,
    loading,
    currentPage,
    perPage: PER_PAGE,
    searchValue,
    selectedItem,
    selectAll,
    setSearchValue,
    setSelectedItem,
    setSelectAll,
    handleViewClick,
    handleEditClick,
    handleDuplicateClick,
    handleDeleteClick,
    handleSearchSubmit,
    handleSearchChange,
    handlePaginationClick,
    handleFilterClick,
    handleCloseDrawer,
    LinkQuestionDrawerOpened,
    setLinkQuestionDrawerOpened,
    filterDrawerOpened,
    setFilterDrawerOpened,
    filterData,
    setFilterData,
    isFiltered,
    setIsFiltered,
    filterOptions,
    setFilterOptions,
    filtered,
    setFiltered,
    actions,
    config,
    redirectToQuestion,
    state,
    t,
    handleCreateQuestionClick,
    selectAllItem,
    selectItem,
    cards: questions?.data || [],
    refetch,
    setCurrentPage,
    questionActions,
    handleCreateQuizClick,
    initialLoad,
    handleResetClick,
  }
}
export default useQuestionsGrid
