import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const SelectorContainer = styled.form`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const CenterParagraph = styled.p`
  text-align: center;
  align-self: center;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const StudentsAnalyticsProfile = styled.div`
  display: flex;
  gap: 120px;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;

  @media (max-width: 1350px) {
    gap: 20px;
  }
`

export const StudentProfile = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 21px;
`
