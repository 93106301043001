import { OperationVariables, useLazyQuery, useQuery } from '@apollo/client'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'

import { GET_USERS } from 'gql/users.query'
import { IUser } from 'interfaces/users'

export const PER_PAGE = 10

export interface IGetUsersResponse {
  data: IUser[]
  totalPages: number
  totalCount: number
  currentPage: number
}
interface IUseLazyFetchUsersResponse {
  users: IGetUsersResponse
  loading: boolean
  error: string | undefined
  fetchUsers: (variables: OperationVariables) => void
}

export interface IUseFetchUsersResponse {
  users: IGetUsersResponse
  loading: boolean
  error: string | undefined
  refetch: (variables?: OperationVariables) => void
}

export const useFetchUsers = (
  role: string,
  filter: IFilterQueryType = {},
  currentPage = 1,
  perPage: number = PER_PAGE,
): IUseFetchUsersResponse => {
  const { data, loading, error, refetch } = useQuery(GET_USERS, {
    variables: {
      role,
      filter: { ...filter },
      currentPage,
      perPage,
    },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    users: data && data.usersByRole,
    loading,
    error: errorMessage,
    refetch,
  }
}

export const useLazyFetchUsers = (role: string): IUseLazyFetchUsersResponse => {
  const [fetchUsers, { data, loading, error }] = useLazyQuery(GET_USERS, {
    variables: {
      role,
    },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    users: data && data.usersByRole,
    loading,
    error: errorMessage,
    fetchUsers: variables => fetchUsers({ variables }),
  }
}
