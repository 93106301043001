import moment from 'moment'

/**
 * Helper Function that will convert seconds into formatted time
 * @param duration - duration in seconds
 * @returns Formatted Duration (HH:mm:ss)
 */
export const formatDuration = (seconds: number): string => {
  const duration = moment.duration(seconds, 'seconds')
  if (!duration) return 'N/A'

  const hours = Math.floor(duration.asHours()).toString().padStart(2, '0')
  const minutes = duration.minutes().toString().padStart(2, '0')
  const secondsRemaining = duration.seconds().toString().padStart(2, '0')
  const formattedTime = `${hours}:${minutes}:${secondsRemaining}`
  return formattedTime
}
