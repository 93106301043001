import { IUser, IUserCompany } from 'interfaces/users'
import { Role } from './rolesObject.interface'

const checkCompany = (user: IUser): boolean | undefined => {
  if (!user.companyId) return true
  const userCompany = user.companyId as IUserCompany
  return userCompany.platformService
}

export default (user: IUser): Role[] => [
  {
    role: 'SUPER_ADMIN',
    permissions: [
      // company
      {
        name: 'Create company',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Edit company',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Delete company',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'View company page',
        companyUser: { can: true },
        user: { can: true },
      },

      // admin
      {
        name: 'Create admin',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Edit admin',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Delete admin',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'View admin',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Activate admin',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Suspend admin',
        companyUser: { can: true },
        user: { can: true },
      },

      {
        name: 'Resend Admin Password',
        companyUser: { can: true },
        user: { can: true },
      },

      // reset password for everyone

      {
        name: 'Reset Password',
        companyUser: { can: true },
        user: { can: true },
      },

      // coach
      {
        name: 'View coach',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'Create coach',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Edit coach',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Delete coach',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Activate coach',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Suspend coach',
        companyUser: { can: true },
        user: { can: true },
      },

      {
        name: 'Resend Coach Password',
        companyUser: { can: true },
        user: { can: true },
      },

      // student
      {
        name: 'View student',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Create student',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Edit student',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Delete student',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Activate student',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Suspend student',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Resend Student Password',
        companyUser: { can: true },
        user: { can: true },
      },

      // groups
      {
        name: 'Create group',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Edit group',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Delete group',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'View group',
        companyUser: { can: true },
        user: { can: true },
      },

      // course
      {
        name: 'Create course',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'Edit course',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'Delete course',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'View course',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Publish course',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Un publish course',
        companyUser: { can: true },
        user: { can: true },
      },

      // module
      {
        name: 'Create module',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'Edit module',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'Delete module',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'View module',
        companyUser: { can: true },
        user: { can: true },
      },

      // topic
      {
        name: 'Create topic',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'Edit topic',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'Delete topic',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'View topic',
        companyUser: { can: true },
        user: { can: true },
      },

      // lesson
      {
        name: 'Create lesson',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'Edit lesson',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'Delete lesson',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'View lesson',
        companyUser: { can: true },
        user: { can: true },
      },

      // questions

      {
        name: 'View question',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Add question',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Edit question',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Delete question',
        companyUser: { can: true },
        user: { can: true },
      },

      // quiz page
      {
        name: 'View quiz',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Add quiz',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Edit quiz',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Delete quiz',
        companyUser: { can: true },
        user: { can: true },
      },

      // test page
      {
        name: 'View test',
        companyUser: { can: true },
        user: { can: true },
      },
      // task page
      {
        name: 'View task',
        companyUser: { can: true },
        user: { can: true },
      },

      // flashcards
      {
        name: 'Create flashcard',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Edit flashcard',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Delete flashcard',
        companyUser: { can: true },
        user: { can: true },
      },

      // settings page
      {
        name: 'View notifications page',
        companyUser: { can: false },
        user: { can: true },
      },
      // settings page
      {
        name: 'View settings page',
        companyUser: { can: true },
        user: { can: true },
      },

      // view dashboard page
      {
        name: 'View dashboard page',
        companyUser: { can: false },
        user: { can: true },
      },

      // super admin
      {
        name: 'View Super Admin page',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Edit Super Admin',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Delete Super Admin',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Suspend Super Admin',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Activate Super Admin',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Resend Super Admin Password',
        companyUser: { can: true },
        user: { can: true },
      },
    ],
  },

  // consultant
  {
    role: 'CONSULTANT',
    permissions: [
      // company
      {
        name: 'Create company',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Edit company',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Delete company',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'View company page',
        companyUser: { can: true },
        user: { can: true },
      },

      // admin
      {
        name: 'Create admin',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Edit admin',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Delete admin',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'View admin',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Activate admin',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Suspend admin',
        companyUser: { can: true },
        user: { can: true },
      },

      {
        name: 'Resend Admin Password',
        companyUser: { can: true },
        user: { can: true },
      },

      // reset password for everyone

      {
        name: 'Reset Password',
        companyUser: { can: true },
        user: { can: true },
      },

      // coach
      {
        name: 'View coach',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Create coach',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Edit coach',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Delete coach',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Activate coach',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Suspend coach',
        companyUser: { can: true },
        user: { can: true },
      },

      {
        name: 'Resend Coach Password',
        companyUser: { can: true },
        user: { can: true },
      },

      // student
      {
        name: 'View student',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Create student',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Edit student',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Delete student',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Activate student',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Suspend student',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Resend Student Password',
        companyUser: { can: true },
        user: { can: true },
      },

      // groups
      {
        name: 'Create group',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Edit group',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Delete group',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'View group',
        companyUser: { can: false },
        user: { can: true },
      },

      // course
      {
        name: 'Create course',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Edit course',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'Delete course',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'View course',
        companyUser: { can: false },
        user: { can: false },
      },
      {
        name: 'Publish course',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Un publish course',
        companyUser: { can: true },
        user: { can: true },
      },

      // module
      {
        name: 'Create module',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'Edit module',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'Delete module',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'View module',
        companyUser: { can: false },
        user: { can: false },
      },

      // topic
      {
        name: 'Create topic',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'Edit topic',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'Delete topic',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'View topic',
        companyUser: { can: false },
        user: { can: true },
      },

      // lesson
      {
        name: 'Create lesson',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'Edit lesson',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'Delete lesson',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'View lesson',
        companyUser: { can: true },
        user: { can: true },
      },

      // questions

      {
        name: 'View question',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Add question',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Edit question',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Delete question',
        companyUser: { can: true },
        user: { can: true },
      },

      // quiz page
      {
        name: 'View quiz',
        companyUser: { can: false },
        user: { can: false },
      },
      {
        name: 'Add quiz',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Edit quiz',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Delete quiz',
        companyUser: { can: true },
        user: { can: true },
      },

      // test page
      {
        name: 'View test',
        companyUser: { can: true },
        user: { can: true },
      },
      // task page
      {
        name: 'View task',
        companyUser: { can: true },
        user: { can: true },
      },

      // flashcards
      {
        name: 'Create flashcard',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Edit flashcard',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Delete flashcard',
        companyUser: { can: true },
        user: { can: true },
      },

      // settings page
      {
        name: 'View notifications page',
        companyUser: { can: false },
        user: { can: false },
      },
      // settings page
      {
        name: 'View settings page',
        companyUser: { can: true },
        user: { can: true },
      },

      // view dashboard page
      {
        name: 'View dashboard page',
        companyUser: { can: false },
        user: { can: true },
      },

      // super admin
      {
        name: 'View Super Admin page',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Edit Super Admin',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Delete Super Admin',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Suspend Super Admin',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Activate Super Admin',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Resend Super Admin Password',
        companyUser: { can: true },
        user: { can: true },
      },
    ],
  },

  // ADMIN
  {
    role: 'ADMIN',
    permissions: [
      // company
      {
        name: 'Create company',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'View company page',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Edit company',
        companyUser: { can: false },
        user: { can: false },
      },

      // super admin
      {
        name: 'View Super Admin page',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Edit Super Admin',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Delete Super Admin',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Suspend Super Admin',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Activate Super Admin',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Resend Super Admin Password',
        companyUser: { can: false },
        user: { can: true },
      },

      // group

      {
        name: 'Edit group',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Delete group',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'View group',
        companyUser: { can: true },
        user: { can: true },
      },

      // admin
      {
        name: 'Edit admin',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Delete admin',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'View admin',
        companyUser: { can: false },
        user: { can: true },
      },

      {
        name: 'Activate admin',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Suspend admin',
        companyUser: { can: false },
        user: { can: true },
      },

      {
        name: 'Resend Admin Password',
        companyUser: { can: false },
        user: { can: true },
      },

      // course
      {
        name: 'Create course',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Edit course',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Delete course',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'View course',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Publish course',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Un publish course',
        companyUser: { can: false },
        user: { can: true },
      },

      // module
      {
        name: 'Create module',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Edit module',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Delete module',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'View module',
        companyUser: { can: false },
        user: { can: true },
      },

      // coach
      {
        name: 'View coach',
        companyUser: { can: checkCompany(user) },
        user: { can: true },
      },
      {
        name: 'Create coach',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Edit coach',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Delete coach',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Activate coach',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Suspend coach',
        companyUser: { can: false },
        user: { can: true },
      },

      {
        name: 'Resend Coach Password',
        companyUser: { can: false },
        user: { can: true },
      },

      // student
      {
        name: 'View student',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Create student',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Edit student',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Delete student',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Activate student',
        companyUser: { can: true },
        user: { can: true },
      },
      {
        name: 'Suspend student',
        companyUser: { can: true },
        user: { can: true },
      },

      {
        name: 'Resend Student Password',
        companyUser: { can: true },
        user: { can: true },
      },

      // topic
      {
        name: 'Create topic',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Edit topic',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Delete topic',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'View topic',
        companyUser: { can: true },
        user: { can: true },
      },

      // lesson
      {
        name: 'Create lesson',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Edit lesson',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Delete lesson',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'View lesson',
        companyUser: { can: false },
        user: { can: true },
      },

      // settings page
      {
        name: 'View settings page',
        companyUser: { can: false },
        user: { can: true },
      },

      // question page
      {
        name: 'View question',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Add question',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Delete question',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Edit question',
        companyUser: { can: false },
        user: { can: true },
      },

      // quiz page
      {
        name: 'View quiz',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Add quiz',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Edit quiz',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Delete quiz',
        companyUser: { can: false },
        user: { can: true },
      },

      // test page
      {
        name: 'View test',
        companyUser: { can: false },
        user: { can: false },
      },
      // task page
      {
        name: 'View task',
        companyUser: { can: false },
        user: { can: false },
      },

      // flashcards
      {
        name: 'Create flashcard',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Edit flashcard',
        companyUser: { can: false },
        user: { can: true },
      },
      {
        name: 'Delete flashcard',
        companyUser: { can: false },
        user: { can: true },
      },

      // view dashboard page
      {
        name: 'View dashboard page',
        companyUser: { can: false },
        user: { can: true },
      },
    ],
  },

  // admin
  // {
  //   role: 'ADMIN',
  //   permissions: [

  //     //company
  //     {
  //       name: 'Create company',
  //       companyUser: { can: false },
  //       user: { can: true },
  //     },
  //     {
  //       name: 'Edit company',
  //       companyUser: { can: false },
  //       user: { can: true },
  //     },
  //     {
  //       name: 'Delete company',
  //       companyUser: { can: false },
  //       user: { can: true },
  //     },
  //     {
  //       name: 'View company page',
  //       companyUser: { can: false },
  //       user: { can: true },
  //     },
  //     // <---->

  //     //group page
  //     {
  //       name: 'Create group',
  //       companyUser: { can: false },
  //       user: { can: false }
  //     },
  //     {
  //       name: 'Edit group',
  //       companyUser: { can: false },
  //       user: { can: false }
  //     },
  //     {
  //       name: 'Delete group',
  //       companyUser: { can: false },
  //       user: { can: false }
  //     },
  //     {
  //       name: 'View group',
  //       companyUser: { can: false },
  //       user: { can: true }
  //     },
  //     // <---->

  //     {
  //       name: 'View moderator page',
  //       companyUser: { can: false },
  //       user: { can: true }
  //     },
  //     //admin page
  //     {
  //       name: 'View admin',
  //       companyUser: { can: true },
  //       user: { can: true }
  //     },
  //     {
  //       name: 'View settings page',
  //       viewSettingPage: { can: true },
  //       user: { can: true }
  //     },
  //     {
  //       name: 'View question',
  //       viewQuestion: { can: true },
  //       user: { can: true }
  //     },
  //     {
  //       name: 'View quiz',
  //       viewQuiz: { can: true },
  //       user: { can: true }
  //     },
  //     // <---->

  //     //coach page
  //     {
  //       name: 'View coach',
  //       companyUser: { can: true },
  //       user: { can: true }
  //     },
  //     {
  //       name: 'Create coach',
  //       companyUser: { can: true },
  //       user: { can: true }
  //     },
  //     {
  //       name: 'Edit coach',
  //       companyUser: { can: true },
  //       user: { can: true }
  //     },
  //     {
  //       name: 'Delete coach',
  //       companyUser: { can: true },
  //       user: { can: true }
  //     },
  //     {
  //       name: 'Activate user',
  //       companyUser: { can: true },
  //       user: { can: true }
  //     },
  //     {
  //       name: 'Deactivate user',
  //       companyUser: { can: true },
  //       user: { can: true }
  //     },
  //     {
  //       name: 'Resend user password',
  //       companyUser: { can: true },
  //       user: { can: true }
  //     },
  //     // <---->

  //     //course page
  //     {
  //       name: 'View course',
  //       companyUser: { can: true },
  //       user: { can: true }
  //     },
  //     // <---->

  //     //student
  //     {
  //       name: 'View student',
  //       companyUser: { can: true },
  //       user: { can: true }
  //     },
  //     {
  //       name: 'Create student',
  //       companyUser: { can: true },
  //       user: { can: true }
  //     },
  //     {
  //       name: 'Edit student',
  //       companyUser: { can: true },
  //       user: { can: true }
  //     },
  //     {
  //       name: 'Delete student',
  //       companyUser: { can: true },
  //       user: { can: true }
  //     },
  //     // <---->

  //   ]
  // },
]
