import React, { useState, useEffect, ReactElement } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { Container, InputContainer, Input, Label, ErrorMessage, Flex } from './styled-components'
import countryList from './countryCode'
import ReactSelect, { components, OptionProps, ValueType } from 'react-select'
import { IPhoneFields, ISelectFilterOption } from 'interfaces/common'

export interface PhoneCountryCodeProps {
  label?: string
  selectLabel: string
  selectValue: IPhoneFields | null
  selectChange: (option: ISelectFilterOption | null) => void
  inputLabel: string
  inputValue?: string
  inputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  touched?: boolean
  error?: boolean
  errorMessage?: string
}

export interface ComponentProps {
  dialCode: string
  code: string
}

const PhoneCountryCode = (props: PhoneCountryCodeProps): ReactElement => {
  const [value, setValue] = useState<ISelectFilterOption | null>(null)

  const {
    label,
    selectLabel,
    selectValue,
    selectChange,
    inputLabel,
    inputValue,
    inputChange,
    touched,
    error,
    errorMessage,
  } = props

  const { Option } = components
  const CustomSelectOption = (props: OptionProps<ISelectFilterOption, false>): ReactElement => (
    <Option {...props}>
      <ReactCountryFlag countryCode={props.data.value} /> {props.data.label}
    </Option>
  )

  const country = countryList().map(i => ({
    label: i.dial_code,
    value: i.code,
  })) as ISelectFilterOption[]
  const Component = ({ dialCode, code }: ComponentProps): ReactElement => {
    return (
      <div>
        <ReactCountryFlag countryCode={code} /> {dialCode}
      </div>
    )
  }

  useEffect(() => {
    if (selectValue && selectValue.value) {
      setValue({
        label: <Component dialCode={selectValue.label as string} code={selectValue.value} />,
        value: selectValue.value,
      })
    }
  }, [selectValue])

  const handleChange = (e: ValueType<ISelectFilterOption, false>): void => {
    if (!e) {
      setValue(null)
      selectChange(null)
      return
    }
    setValue({
      label: <Component dialCode={e.label as string} code={e.value} />,
      value: e.value,
    })
    selectChange(e)
  }

  const errorField = touched && error && errorMessage

  // using this to prevent non-numeric input
  // p.s not using type number because return type of input value has to be string type and not number
  const numberChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.value
    // update only if the value is a number
    if (/^\d*$/.test(newValue)) {
      inputChange(e)
    }
  }

  return (
    <Flex>
      <InputContainer>
        <ReactSelect
          placeholder={selectLabel}
          options={country}
          value={value}
          onChange={handleChange}
          components={{ Option: CustomSelectOption }}
          isClearable
        />
      </InputContainer>
      <Container error={!!errorField}>
        <Label error={!!errorField}>{label}</Label>
        <Input name='phone' placeholder={inputLabel} onChange={numberChange} value={inputValue} />
        {touched && error && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Container>
    </Flex>
  )
}

export default PhoneCountryCode
