import React from 'react'
import { useTranslation } from 'react-i18next'
import { collapseColumnConfig, columnConfig, getCollapsedData } from './gridConfig'
import GridV2 from 'components/common/GridV2'
import { StyledTableContainer } from './styled-component'

interface QuizResult {
  id: string
  score: number
}

const QuizzesByUser: React.FC<{
  quizResult: QuizResult[]
  firstName?: string
}> = ({ quizResult, firstName }) => {
  const { t } = useTranslation()
  const config = columnConfig(t)
  const collapseConfig = collapseColumnConfig(t)

  return (
    <StyledTableContainer>
      <GridV2
        title={firstName && `${firstName}'s Quiz Results`}
        data={quizResult}
        config={config}
        collapseConfig={collapseConfig}
        getCollapsedData={getCollapsedData}
      />
    </StyledTableContainer>
  )
}

export default QuizzesByUser
