import React from 'react'
import { Action } from 'components/common/GridV2'
import { ReactComponent as DeleteIcon } from 'assets/trash-can-red.svg'
import { ReactComponent as EditRoundedIcon } from 'assets/edit.svg'
import { DeleteSpan } from 'components/common/Styled/styled-components'
import { TFunction } from 'interfaces/TFunction'

interface ActionItem {
  render: () => JSX.Element
  hide?: boolean
}

export const actionConfig = (
  handleEditClick: () => void,
  handleDeleteClick: () => void,
  t: TFunction,
): ActionItem[] => {
  const action: ActionItem[] = [
    {
      render: () => (
        <Action onClick={handleEditClick}>
          <EditRoundedIcon /> <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      render: () => (
        <Action onClick={handleDeleteClick}>
          <DeleteIcon /> <DeleteSpan>{t('actions.delete')}</DeleteSpan>
        </Action>
      ),
    },
  ]

  return action.filter((i: ActionItem) => !i.hide)
}
