import { useState } from 'react'
import useSnackbarAlert from 'hooks/useSnackbar'
import { useTranslation } from 'react-i18next'
import { ISnackbarProps } from 'interfaces/snackbar'
import sendPushNotificationsService, {
  ICustomMessageData,
} from 'services/group/sendPushNotificationsService'

const useSendPushNotifications = (): {
  loading: boolean
  sendPushNotifications: (
    recipientIds: string[],
    messageData: ICustomMessageData,
  ) => Promise<string | void>
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [loading, setLoading] = useState(false)
  const { sendPushNotificationsMutation, error } = sendPushNotificationsService()

  const sendPushNotifications = async (
    recipientIds: string[],
    messageData: ICustomMessageData,
  ): Promise<string | void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    await sendPushNotificationsMutation({
      variables: { recipientIds, messageData },
    })

    if (error) {
      setLoading(false)
      message = t('messages.something_wrong')
      variant = 'error'
      return
    }
    message = `Notifications Successfully Sent`
    variant = 'success'

    setSnackbar({ message, variant })
    setLoading(false)
    return message
  }

  return {
    loading,
    sendPushNotifications,
  }
}

export default useSendPushNotifications
