import { useQuery } from '@apollo/client'
import { GET_COMPANY_OPTIONS } from 'gql/companies.query'
import useSnackbarAlert from 'hooks/useSnackbar'
import { SnackbarVariants } from 'interfaces/common'
import {
  ICompanyOptionsServiceVariables,
  IGetCompanyOptionsQuery,
  IUseCompanyOptionsServiceResponse,
} from './companyServices.interface'

export const useCompanyOptionsService = (
  searchValue?: string,
): IUseCompanyOptionsServiceResponse => {
  const { setSnackbar } = useSnackbarAlert()
  const { data, loading, error } = useQuery<
    IGetCompanyOptionsQuery,
    ICompanyOptionsServiceVariables
  >(GET_COMPANY_OPTIONS, {
    variables: { searchValue: searchValue || '' },
  })

  const errorMessage = error ? error?.message.split(':')[1] : ''

  if (error) {
    setSnackbar({
      message: errorMessage,
      variant: SnackbarVariants.ERROR,
    })
    return { data: [], loading, error: errorMessage }
  }

  return { data: data?.getCompanyOptions, loading, error: errorMessage }
}
