import { DrawerEventEmitter } from 'helpers/drawer'
import { IUseOpenDrawerProps, IUseOpenDrawerReturn } from './useOpenDrawer.interface'

export const useOpenDrawer = ({
  fetchData,
  perPage,
  defaultFilter,
  setSelectedItem,
  drawerName,
}: IUseOpenDrawerProps): IUseOpenDrawerReturn => {
  const handleEditClick = (userId: string): void => {
    DrawerEventEmitter.emit('openDrawer', drawerName, true, {
      id: userId,
    })
  }

  const openAddDrawer = (): void => {
    // add defaultfilter in here
    DrawerEventEmitter.emit('openDrawer', drawerName, true, {
      data: {
        onSuccess: () => {
          fetchData({
            filter: {
              ...defaultFilter,
            },
            currentPage: 1,
            perPage,
          })

          setSelectedItem({})
        },
      },
    })
  }
  return { openAddDrawer, handleEditClick }
}
