export enum RoleFormats {
  SINGULAR = 'SINGULAR',
  PLURAL = 'PLURAL',
}

const formatRoleForTranslation = (
  role: string,
  format: RoleFormats = RoleFormats.SINGULAR,
): string => {
  let roleTranslation = `roles.${role.toLowerCase()}`
  if (format === RoleFormats.PLURAL) roleTranslation += 's'

  return roleTranslation
}

export default formatRoleForTranslation
