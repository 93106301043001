import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import {
  GET_SKILL_TEST_VIDEOS_CRITERIAS,
  SET_SKILL_TEST_VIDEO_CRITERIA,
} from 'gql/skillTestVideos.query'
import { ISkillTestVideoReviewCriteria } from 'pages/modules/SkillTestVideoCriterias/skillTestVideoCriteria.interface'

import { updateCache } from 'services/updateCache'

interface ISetSkillTestVideoCriteriaServiceResponse {
  setSkillTestVideoCriteriaMutation: MutationTuple<
    ISetSkillTestVideoCriteriaResponse,
    ISetSkillTestVideoCriteriaParams
  >[0]
  loading: boolean
  error?: ApolloError
}

interface ISetSkillTestVideoCriteriaResponse {
  setSkillTestVideoCriteriasOnModule: {
    criterias: ISkillTestVideoReviewCriteria[]
  }
}

export interface ISetSkillTestVideoCriteriaParams {
  criterias?: ISkillTestVideoReviewCriteria[]
  moduleId: string
  idsToRemain?: string[]
}

const setSkillTestVideoCriteriaService = (
  params: ISetSkillTestVideoCriteriaParams,
): ISetSkillTestVideoCriteriaServiceResponse => {
  const [setSkillTestVideoCriteriaMutation, { loading, error }] = useMutation<
    ISetSkillTestVideoCriteriaResponse,
    ISetSkillTestVideoCriteriaParams
  >(SET_SKILL_TEST_VIDEO_CRITERIA, {
    update(cache, { data }) {
      if (data?.setSkillTestVideoCriteriasOnModule) {
        const document = data?.setSkillTestVideoCriteriasOnModule
        updateCache({
          objectName: 'getSkillTestVideoCriteriasByModuleId',
          query: GET_SKILL_TEST_VIDEOS_CRITERIAS,
          cache,
          document,
          action: 'addToTop',
          params,
          nestedFindObjectName: 'criterias',
        })
      }
    },
  })

  return {
    setSkillTestVideoCriteriaMutation,
    loading,
    error,
  }
}

export default setSkillTestVideoCriteriaService
