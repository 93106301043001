import styled from 'styled-components'

// GroupCourses

export const Wrapper = styled.div`
  margin-top: 20px;
`
export const ToolBarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`

// GroupDashboard
export const GroupNameAndActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  > span {
    font-size: 22px;
  }
`
export const UpperShortInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 90px;
`
export const ShortInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    margin-left: 45px;
    > b {
      font-size: 30px;
      color: #414141;
      font-weight: 500;
    }
    > div {
      font-size: 16px;
      color: #989898;
    }
  }

  > img {
    width: 156px;
    height: 156px;
    box-shadow: rgb(0 0 0 / 20%) 0px 10px 10px;
    border-radius: 100%;
  }

  > span {
    width: 156px;
    height: 156px;
    box-shadow: rgb(0 0 0 / 20%) 0px 10px 10px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      font-size: 113px;
      color: #06c68f;
    }
  }
`

export const NavigationWrapper = styled.div`
  margin-top: 20px;
`

// GroupMainInfo

export const GridContainer = styled.div`
  display: grid;
  /* background: #fff; */
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 15px;
  > span {
    display: flex;
    > b {
      margin: 0px 7px;
      color: #68707a;
    }
    > svg {
      color: #06c68f;
      font-size: 22px;
    }
  }
`

export const StyledGroupDetailsContainer = styled.div`
  background: #fff;
  padding: 16px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
`
// GridConfig

export const StyledDetailHeaderSpan = styled.span`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e7e9ed;
  padding-bottom: 10px;
  h2 {
    font-weight: 700;
  }
`

export const StyledDetailSpan = styled.span`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;
  white-space: nowrap;
  width: 100%;
  span {
    color: #68707a;
  }
`
export const StyledDetailSpanBetween = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space: nowrap;
  width: 100%;
  border-right: 1px solid #e7e9ed;
  border-left: 1px solid #e7e9ed;
  padding-left: 16px;
  padding-right: 16px;
  span {
    color: #68707a;
  }
`

export const BoldDetail = styled.span`
  font-weight: 700;
  color: #020210 !important;
`

export const StyledDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
`
export const GroupsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const GroupItem = styled.div`
  position: relative;
  &:hover > span {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
`
export const GroupTitle = styled.span`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  z-index: 2;
  text-align: center;
  padding: 5px 8px;
  bottom: -35px;
  left: 50%;
  color: #333;
  font-size: 12px;
  transform: translateX(-50%) scale(0);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0.5px 12px rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s ease, transform 0.2s ease;
`
export const ClickableDiv = styled.div`
  cursor: pointer;
`
