import { useQuery, useLazyQuery, OperationVariables } from '@apollo/client'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { GET_TESTS } from 'gql/tests/tests.query'
import { GetTestAssessmentsByCompanyIdResponse } from 'pages/tests/testLayout/testLayout.interface'

export const PER_PAGE = 12

const useGetTestAssessmentsByCompanyId = (
  currentPage: number = 1,
  perPage: number = PER_PAGE,
  companyId?: string,
  filter?: IFilterQueryType,
): {
  tests: GetTestAssessmentsByCompanyIdResponse['getTestAssessmentsByCompanyId'] | undefined
  loading: boolean
  error: string | undefined
  refetch: (variables?: OperationVariables) => void
} => {
  const { data, loading, error, refetch } = useQuery<GetTestAssessmentsByCompanyIdResponse>(
    GET_TESTS,
    {
      variables: { companyId, currentPage, perPage, filter },
      skip: !companyId,
    },
  )

  const errorMessage = error && error?.message.split(':')[1]

  return {
    tests: data && data.getTestAssessmentsByCompanyId,
    loading,
    error: errorMessage,
    refetch,
  }
}

export default useGetTestAssessmentsByCompanyId

export const useLazyGetTestAssessmentsByCompanyId = (): {
  getTestAssessments: (variables?: OperationVariables) => void
  tests: GetTestAssessmentsByCompanyIdResponse['getTestAssessmentsByCompanyId'] | undefined
  loading: boolean
  error: string | undefined
} => {
  const [getTestAssessments, { data, loading, error }] =
    useLazyQuery<GetTestAssessmentsByCompanyIdResponse>(GET_TESTS)

  const errorMessage = error && error?.message.split(':')[1]

  return {
    getTestAssessments: (variables?: OperationVariables) => getTestAssessments({ variables }),
    tests: data && data.getTestAssessmentsByCompanyId,
    loading,
    error: errorMessage,
  }
}
