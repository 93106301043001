import { useQuery } from '@apollo/client'
import { GET_ALL_COURSE } from 'gql/course/course.query'
import {
  GetAllCourseResponse,
  GetUseFetchCoursesResponse,
  IFilterArgs,
  IGetAllCourseResponse,
} from 'interfaces/courses/courses.interface'

export const PER_PAGE = 12

const defaultResponse: GetAllCourseResponse = {
  data: [],
  totalPages: 0,
  offsetId: '',
  currentPage: 0,
  count: 0,
  totalCount: 0,
}

export const useFetchCourses: GetUseFetchCoursesResponse = (filter, currentPage, perPage) => {
  const { data, loading, error, refetch, fetchMore } = useQuery<IGetAllCourseResponse, IFilterArgs>(
    GET_ALL_COURSE,
    {
      variables: {
        filter,
        currentPage,
        perPage,
      },
    },
  )

  return {
    courses: (data && data.getAllCourse) || defaultResponse,
    loading,
    error: error?.message,
    refetch,
    fetchMore,
  }
}
