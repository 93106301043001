import React, { ReactElement, useState } from 'react'
import moment from 'moment'
import IconButton from '@mui/material/IconButton'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import {
  UserProfileWrapper,
  UsernameWrapper,
  PostContentWrapper,
  RejectionListWrapper,
  RejectionListTitle,
  PostContentContainer,
  PostMediaContainer,
  PostMediaImage,
  DateWrapper,
  UserDetails,
} from './styled-components'
import Thumbnail from 'components/common/Thumbnail/Thumbnail'
import Player from 'components/common/VideoPlayer/Player'
import Modal from 'components/common/Modal'
import { formatDateAgo } from 'utils/utils'
import {
  IRejectionDetailsContentPostData,
  IRejectionDetailsDrawerUser,
} from './RejectionDetailDrawer.interface'
import { IPost, IRegectionsMedia, IUser } from 'interfaces/users'
import { IRejection } from 'interfaces/rejections'

const RejectionDetailContent = ({ data }: { data: IRejection }): ReactElement => {
  const [videoModalOpened, setVideoModalOpened] = useState<boolean>(false)

  const { rejectedPost, rejectedUser } = data
  const renderUserContent = (): ReactElement => {
    return (
      <div style={{ display: 'flex' }}>
        <Thumbnail width={100} height={100} imageSrc={(rejectedUser as IUser).avatar} />
        <UserDetails>
          <UsernameWrapper>
            {(rejectedUser as IUser).firstName} {(rejectedUser as IUser).lastName}
          </UsernameWrapper>
          {(rejectedUser as IUser).email}
        </UserDetails>
      </div>
    )
  }

  const renderPostContent = (postData: IRejectionDetailsContentPostData): ReactElement => {
    return (
      <>
        <UserProfileWrapper>
          <Thumbnail width={50} height={50} imageSrc={postData.author.avatar} />
          <UsernameWrapper>
            {postData.author.firstName} {postData.author.lastName}
          </UsernameWrapper>
        </UserProfileWrapper>
        <PostContentWrapper>
          <PostContentContainer>
            {postData.description && postData.description}
            {postData.media &&
              postData.media.map((media: IRegectionsMedia) => (
                <PostMediaContainer key={media.id}>
                  {media.fileType === 'video' && (
                    <PostMediaImage>
                      <img src={media.thumbnail} />
                      <IconButton onClick={(): void => setVideoModalOpened(true)}>
                        <PlayCircleOutlineIcon fontSize='small' color='secondary' />
                      </IconButton>
                      {media.links && (
                        <Modal
                          isOpened={videoModalOpened}
                          onClose={(): void => setVideoModalOpened(false)}
                        >
                          <Player url={media.links[0].url} />
                        </Modal>
                      )}
                    </PostMediaImage>
                  )}
                  {media.fileType === 'image' && (
                    <PostMediaImage>
                      <img src={media.link} />
                    </PostMediaImage>
                  )}
                </PostMediaContainer>
              ))}
            {!postData.description && postData.media.length === 0 && <div>No Content</div>}
          </PostContentContainer>
        </PostContentWrapper>
      </>
    )
  }

  const renderUsers = (userList: IRejectionDetailsDrawerUser[]): ReactElement[] =>
    userList.map((user: IRejectionDetailsDrawerUser) => (
      <div key={user.id}>
        <UserProfileWrapper>
          <Thumbnail width={50} height={50} imageSrc={user.avatar} />
          <UsernameWrapper>
            {user.firstName} {user.lastName}
          </UsernameWrapper>
          <DateWrapper>
            {formatDateAgo(moment(user.createDate).format('DD MMM YYYY h:mm A'))}
          </DateWrapper>
        </UserProfileWrapper>
      </div>
    ))

  return (
    <>
      {rejectedPost && renderPostContent(rejectedPost as IPost)}
      {rejectedUser && renderUserContent()}
      <RejectionListWrapper>
        <RejectionListTitle>
          {' '}
          {rejectedPost ? `marked as ${data.type} by` : `reported by`}
        </RejectionListTitle>
        {data.allRejections && renderUsers(data.allRejections as IUser[])}
      </RejectionListWrapper>
    </>
  )
}

export default RejectionDetailContent
