import React, { ReactElement, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'

import {
  StyledFormGroupWrapper,
  StyledFromGroupContainer,
  ItemWrapper,
  ErrorMessage,
  InputLabel,
} from './styled-components'
import { Input } from 'components/common/TextInput'
import CreatableSelect from 'react-select/creatable'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Tooltip from '@mui/material/Tooltip'
import FormControlLabel from '@mui/material/FormControlLabel'
import HelpIcon from '@mui/icons-material/Help'
import { FormGroupItem, FormGroupWrapper } from 'components/common/Form'
import { GET_TAGS } from 'gql/skilss.query'
import { useTranslation } from 'react-i18next'
import { StyledFormGroupTitle } from '../AddQuizDrawer/styled-components'
import { IEditStep1Props } from '../AddQuizDrawer/addQuizDrawer.interface'
import { ReactComponent as Information } from 'assets/information.svg'
import { StyledQuizOverview } from '../styled-components'
import TextEditor from 'components/common/TextEditor/TextEditor'

const Step1 = ({
  companyId,
  values,
  handleBlur,
  handleChange,
  questionListSize,
  touched,
  errors,
  handleRandomizationChange,
  setFieldValue,
  type,
  handleQuizParticipationTypeChange,
  IQTest,
  quizData,
}: IEditStep1Props): ReactElement => {
  const [description, setDescription] = useState<string>('')

  const { data } = useQuery(GET_TAGS, {
    variables: { companyId: companyId },
  })

  const tagList =
    (data &&
      data.getAllTags.map((i: { label: string; id: string }) => ({
        label: i.label,
        value: i.id,
      }))) ||
    []

  // const IQTestTypeValue = IQTestTypes.find(
  //   (item: any) => item.value === values.position,
  // )

  useEffect(() => {
    if (quizData && quizData.description) {
      setDescription(quizData.description)
    }
  }, [])

  const { t } = useTranslation()
  return (
    <>
      <FormGroupItem>
        <Input
          error={!!errors.name}
          errorMessage={errors.name ? errors.name : ''}
          touched={!!touched.name}
          label={`${t('general.name')}*`}
          name='name'
          size='small'
          type='text'
          fullWidth
          value={values.name}
          onChange={handleChange}
        />
      </FormGroupItem>

      <FormGroupItem>
        <CreatableSelect
          label={`${t('form_fields.select')} ${t('general.group')}`}
          name='group'
          isMulti
          onChange={(value): void => setFieldValue('tags', value ? [...value] : [])}
          options={tagList}
          value={values.tags}
          placeholder={t('general.tags')}
        />
      </FormGroupItem>
      <FormGroupItem>
        <StyledQuizOverview>{t('assessment_drawer.quiz_overview')}</StyledQuizOverview>
        <TextEditor
          text={description}
          isVisible={true}
          hasControls={false}
          contentHeight='200px'
          handleChange={(editorState: string): void => {
            setFieldValue('description', editorState)
          }}
          canEdit={true}
          defaultText={
            type === 'course' ? t('quiz_details.iq_test_overview') : t('quiz_details.quiz_overview')
          }
        />
      </FormGroupItem>

      <FormGroupItem>
        <Input
          error={!!errors.time}
          errorMessage={errors.time ? errors.time : ''}
          touched={!!touched.time}
          label={t('quiz_details.time_in_minutes')}
          name='time'
          size='small'
          type='number'
          fullWidth
          value={values.time === 0 ? '' : values.time}
          onChange={handleChange}
        />
      </FormGroupItem>

      {!IQTest && (
        <>
          <FormGroupItem>
            <RadioGroup
              name='quizParticipationType'
              value={values.quizParticipationType}
              onChange={(e): void => handleQuizParticipationTypeChange(e.target.value)}
            >
              <StyledFromGroupContainer>
                <StyledFormGroupTitle>
                  {type === 'course'
                    ? `${t('general.iq_test')} ${t('quiz_details.participation')} ${t(
                        'general.type',
                      )}*`
                    : `${t('general.quiz')} ${t('quiz_details.participation')} ${t(
                        'general.type',
                      )}*`}
                  <Tooltip
                    title={
                      values.quizParticipationType &&
                      (values.quizParticipationType === 'mandatory' ? (
                        <span style={{ fontSize: '14px' }}>{t('general.mandatory_helper')}</span>
                      ) : (
                        <span style={{ fontSize: '14px' }}>{t('general.optional_helper')}</span>
                      ))
                    }
                    arrow
                  >
                    <HelpIcon color='secondary' style={{ color: '#06C68F' }} />
                  </Tooltip>
                </StyledFormGroupTitle>

                <FormGroupWrapper>
                  <ItemWrapper>
                    <FormControlLabel
                      value='mandatory'
                      control={<Radio color='secondary' />}
                      label={t('general.mandatory')}
                      disabled={false}
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <FormControlLabel
                      value='optional'
                      control={<Radio color='secondary' />}
                      label={t('general.optional')}
                      disabled={false}
                    />
                  </ItemWrapper>
                </FormGroupWrapper>
              </StyledFromGroupContainer>
            </RadioGroup>
          </FormGroupItem>

          <FormGroupItem>
            <Input
              error={!!errors.passRate}
              errorMessage={errors.passRate ? errors.passRate : ''}
              touched={!!touched.passRate}
              label={`${t('quiz_details.pass_rate')} ( % )${
                values.quizParticipationType !== 'optional' ? '*' : ''
              }`}
              name='passRate'
              size='small'
              type='number'
              fullWidth
              value={values.passRate}
              onChange={handleChange}
              disabled={values.quizParticipationType === 'optional'}
            />
          </FormGroupItem>
        </>
      )}

      {/* isShowAnswersTurnedOn component */}
      <FormGroupItem>
        <RadioGroup
          name='isShowAnswersTurnedOn'
          value={values.isShowAnswersTurnedOn}
          onChange={(): void =>
            setFieldValue('isShowAnswersTurnedOn', !values.isShowAnswersTurnedOn)
          }
        >
          <StyledFromGroupContainer>
            <StyledFormGroupTitle>
              {t('quiz_details.isShowAnswersTurnedOn')}
              <Tooltip
                title={
                  <span style={{ fontSize: '14px' }}>
                    {t('quiz_details.isShowAnswersTurnedOn_helper')}
                  </span>
                }
                arrow
              >
                <HelpIcon color='secondary' style={{ color: '#06C68F' }} />
              </Tooltip>
            </StyledFormGroupTitle>

            <StyledFormGroupWrapper>
              <ItemWrapper>
                <FormControlLabel
                  value={true}
                  control={<Radio color='secondary' />}
                  label={t('general.yes')}
                />
              </ItemWrapper>

              <ItemWrapper>
                <FormControlLabel
                  value={false}
                  control={<Radio color='secondary' />}
                  label={t('general.no')}
                />
              </ItemWrapper>
            </StyledFormGroupWrapper>
          </StyledFromGroupContainer>
        </RadioGroup>
      </FormGroupItem>

      {/* Randomization component */}
      <FormGroupItem>
        <RadioGroup
          name='randomization'
          value={values.randomization}
          onChange={(): void => handleRandomizationChange(!values.randomization)}
        >
          <StyledFromGroupContainer>
            <StyledFormGroupTitle>
              {t('quiz_details.randomize')} {t('general.question')}
              <Tooltip
                title={
                  <span style={{ fontSize: '14px' }}>{t('quiz_details.randomize_helper')}</span>
                }
                arrow
              >
                <HelpIcon color='secondary' style={{ color: '#06C68F' }} />
              </Tooltip>
            </StyledFormGroupTitle>

            <StyledFormGroupWrapper>
              <ItemWrapper>
                <FormControlLabel
                  value={true}
                  control={<Radio color='secondary' />}
                  label={t('general.yes')}
                />
              </ItemWrapper>
              <ItemWrapper>
                <FormControlLabel
                  value={false}
                  control={<Radio color='secondary' />}
                  label={t('general.no')}
                />
              </ItemWrapper>
            </StyledFormGroupWrapper>
            {touched.randomization && errors.randomization && (
              <ErrorMessage>{errors.randomization}</ErrorMessage>
            )}
          </StyledFromGroupContainer>
        </RadioGroup>
      </FormGroupItem>

      <FormGroupItem>
        <Input
          error={!!errors.randomizationNumber}
          errorMessage={errors.randomizationNumber ? errors.randomizationNumber : ''}
          touched={!!touched.randomizationNumber}
          label={t('quiz_details.number_of_randomized_questions')}
          name='randomizationNumber'
          size='small'
          type='number'
          required
          min={1}
          max={questionListSize}
          fullWidth
          value={values.randomizationNumber}
          onChange={handleChange}
          disabled={!values.randomization}
          onBlur={handleBlur}
        />
        <InputLabel>
          <Information style={{ marginRight: '5px', marginLeft: '5px' }} />
          <span>
            {t('quiz_details.this_quiz_contains')} {questionListSize}{' '}
            {t('quiz_details.randomize_rule')}
          </span>
        </InputLabel>
      </FormGroupItem>
    </>
  )
}

export default Step1
