import { useState } from 'react'
import { IGridV2ServicesProps, IUseGridServicesResponse, Order } from './grid.interface'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// grid logic services
export const useGridServices = ({
  data,
  selectedItems,
  openInExternalTab,
}: IGridV2ServicesProps): IUseGridServicesResponse => {
  const { t } = useTranslation()

  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<string>('default')
  const [selected, setSelected] = useState<string[]>([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const navigate = useNavigate()

  const handleRequestSort = (event: React.MouseEvent, property: string): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      const newSelectedIds = data.map((n: { title: string }) => n.title)
      setSelected(newSelectedIds)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (id: string): boolean =>
    selectedItems ? selectedItems.indexOf(id) !== -1 : false

  const redirectToProfile = (id: string): void => {
    if (openInExternalTab) {
      window.open(`/profile/${id}`, '_blank')
    } else {
      navigate(`/profile/${id}`)
    }
  }

  return {
    t,
    order,
    orderBy,
    selected,
    page,
    rowsPerPage,
    handleRequestSort,
    handleSelectAllClick,
    handleChangePage,
    handleChangeRowsPerPage,
    isSelected,
    redirectToProfile,
  }
}

// helper functions
function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  if (orderBy === 'birthDate') {
    const dateA = new Date(parseInt(a[orderBy] as string))
    const dateB = new Date(parseInt(b[orderBy] as string))

    if (dateB < dateA) {
      return -1
    }
    if (dateB > dateA) {
      return 1
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
  }
  return 0
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

export function getComparator<T extends Record<string, number | string>>(
  order: Order,
  orderBy: keyof T,
): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b): number => descendingComparator(a, b, orderBy)
    : (a, b): number => -descendingComparator(a, b, orderBy)
}
