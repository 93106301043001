import React, { ReactElement } from 'react'
import { grid } from 'ldrs'

grid.register()

interface IUIBallLoadingProps {
  loaderName: 'grid' // we can add other loaders in the future
  size?: number
  speed?: number
  color?: string
}

const UIBallLoading = ({
  loaderName = 'grid',
  size = 60,
  speed = 1.5,
  color = '#B39E9E',
}: IUIBallLoadingProps): ReactElement => {
  if (loaderName === 'grid') {
    return <l-grid size={size} speed={speed} color={color}></l-grid>
  }
  return <></>
  /// other loaders, we can add in the future
}

export default UIBallLoading
