import { MutationTuple, useMutation } from '@apollo/client'
import { updateCache } from 'services/updateCache'
import { GET_USERS, UPDATE_USER_PASSWORD } from 'gql/users.query'
import { IUpdateStatusUserServiceParams } from 'hooks/users/useUpdateStatusUserService'
import { IUser } from 'interfaces/users'

interface IUpdateUserPasswordParams {
  id: string
}

interface IUpdateUserPasswordMutationResponse {
  updateUserPassword: IUser
}

interface IUpdateUserPasswordResponse {
  updateUserPasswordMutation: MutationTuple<
    IUpdateUserPasswordMutationResponse,
    IUpdateUserPasswordParams
  >[0]
  loading: boolean
  error?: Error
}

const updateUserPasswordService = (
  params?: IUpdateStatusUserServiceParams,
): IUpdateUserPasswordResponse => {
  const [updateUserPasswordMutation, { loading, error }] = useMutation<
    IUpdateUserPasswordMutationResponse,
    IUpdateUserPasswordParams
  >(UPDATE_USER_PASSWORD, {
    update(cache, { data }) {
      if (data?.updateUserPassword) {
        updateCache({
          objectName: 'usersByRole',
          query: GET_USERS,
          cache,
          document,
          action: 'update',
          params,
        })
      }
    },
  })

  return {
    updateUserPasswordMutation,
    loading,
    error,
  }
}

export default updateUserPasswordService
