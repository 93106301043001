import { styled } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  overflow: hidden;
  height: 100%;
`

export const TutorsListContainer = styled(Wrapper)`
  overflow-y: auto;
`
export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    color: #06c68f;
    cursor: pointer;
  }
`
