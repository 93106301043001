import { useMutation } from '@apollo/client'
import { IAddFileResponse, IFileDto, IFetchFilesByAssistantIdProps } from './fileService.interface'
import { ISnackbarProps } from 'interfaces/snackbar'
import useSnackbarAlert from 'hooks/useSnackbar'
import { useTranslation } from 'react-i18next'
import { SnackbarVariants } from 'interfaces/common'
import { ADD_FILE } from 'gql/assistant/file/file.mutation'
import { GET_ASSISTANT_FILES } from 'gql/assistant/file/file.query'

export const useAddFileService = ({
  assistantId,
}: IFetchFilesByAssistantIdProps): IAddFileResponse => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()

  // mutation initialization
  const [addFile, { data, error, loading }] = useMutation(ADD_FILE, {
    refetchQueries: [
      {
        query: GET_ASSISTANT_FILES,
        variables: { assistantId },
      },
    ],
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''

  // mutationUsage
  const handleAddFile = async (createFileDto: IFileDto): Promise<void> => {
    try {
      let { message, variant }: ISnackbarProps = {
        message: '',
        variant: undefined,
      }

      const { errors } = await addFile({ variables: { createFileDto } })
      if (errors) {
        message = errors[0].message
        variant = SnackbarVariants.ERROR
      } else {
        message = t('AI_assistant.file_added')
        variant = SnackbarVariants.SUCCESS
      }

      setSnackbar({ message, variant })
    } catch (error) {
      console.log(SnackbarVariants.ERROR, error)
    }
  }

  return {
    addFile: async (createFileDto: IFileDto) => handleAddFile(createFileDto),
    files: (data && data.createFile) || [],
    loading,
    error: errorMessage,
  }
}
