import { ISelectDropDownOption } from 'components/common/SelectDropDown/selectDropDown.interface'
import i18n from 'i18n/index'

export let QuestionTypes: ISelectDropDownOption[] = []
export let filterQuestionTypes: ISelectDropDownOption[] = []
export let editedOptions: ISelectDropDownOption[] = []

function translate(): void {
  QuestionTypes = [
    {
      label: i18n.t('question_details.boolean'),
      value: 'boolean',
    },
    {
      label: i18n.t('question_details.single'),
      value: 'single',
    },
    {
      label: i18n.t('question_details.multiple'),
      value: 'multiple',
    },
  ]

  filterQuestionTypes = [
    {
      label: i18n.t('general.all'),
      value: 'all',
    },
    {
      label: i18n.t('question_details.boolean'),
      value: 'boolean',
    },
    {
      label: i18n.t('question_details.single'),
      value: 'single',
    },
    {
      label: i18n.t('question_details.multiple'),
      value: 'multiple',
    },
  ]

  editedOptions = [
    {
      label: i18n.t('general.all'),
      value: 'all',
    },
    {
      label: i18n.t('general.original'),
      value: 'false',
    },
    {
      label: i18n.t('general.edited'),
      value: 'true',
    },
  ]
}

i18n.on('languageChanged init', () => {
  translate()
})
