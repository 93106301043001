import { useMutation, MutationTuple, ApolloError } from '@apollo/client'
import { ICourseData } from 'components/modules/AddModule.interface'
import { ADD_MODULE } from 'gql/modules.query'
import { ICourseBriefData } from 'pages/courses/courseList.interface'
import { IModule } from 'pages/modules/ModulesList.interface'

interface IAddModuleResponse {
  addModule: IModule
}

interface IAddModuleVariables {
  input: ICourseData
  courseData: ICourseBriefData
}

interface IAddModuleServiceResponse {
  addModuleMutation: MutationTuple<IAddModuleResponse, IAddModuleVariables>[0]
  loading: boolean
  error?: ApolloError
}

const addModuleService = (): IAddModuleServiceResponse => {
  const [addModuleMutation, { loading, error }] = useMutation<
    IAddModuleResponse,
    IAddModuleVariables
  >(ADD_MODULE)

  return {
    addModuleMutation,
    loading,
    error,
  }
}

export default addModuleService
