import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSwal } from 'hooks/useSwal'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useFetchSkillTestVideosTemplates } from 'hooks/modules/useSkillTestVideos'
import useDeleteSkillTestVideoTemplate from 'hooks/modules/useDeleteSkillTestVideoTemplate'
import { actionConfig } from 'pages/modules/ModuleDetail/SkillTestVideos/gridConfig'
import { ISkillTestVideo } from 'pages/modules/ModuleDetail/SkillTestVideos/SkillTestVideos.interface'
import { ISkillTestVideosProps, IUseSkillTestVideosReturn } from './useSkillTestVideos.interface'

const useSkillTestVideos = ({
  moduleId,
  moduleName,
  canModify,
}: ISkillTestVideosProps): IUseSkillTestVideosReturn => {
  const { t } = useTranslation()
  const { fireSwal } = useSwal()
  const { skillTestVideosTemplates, loading, refetch } = useFetchSkillTestVideosTemplates(
    moduleId as string,
  )
  const { deleteSkillTestVideoTemplate } = useDeleteSkillTestVideoTemplate({
    moduleId: moduleId as string,
  })
  const [searchValue, setSearchValue] = useState('')
  const [skillTestVideos, setSkillTestVideos] = useState<ISkillTestVideo[]>([])

  const handleEditClick = (id: string): void => {
    DrawerEventEmitter.emit('openDrawer', 'editSkillTestVideo', true, {
      data: {
        moduleId,
        skillTestVideo: skillTestVideos.find((item: ISkillTestVideo) => item.id === id),
        refetch,
      },
    })
  }

  const handleDeleteClick = (ids: string[], videosCount: number): void => {
    if (videosCount > 0) {
      fireSwal({
        title: t('general.skill_test_title'),
        text: t('general.skill_test_text'),
        confirmText: t('popups.ok'),
        onConfirm: () => undefined,
      })
    } else {
      const params = {
        title: t('general.skill_test_params_title'),
        onConfirm: (): void => {
          deleteSkillTestVideoTemplate(ids)
        },
        confirmText: t('popups.confirm_delete'),
      }
      fireSwal(params)
    }
  }

  const handleCreateSkillTestVideoClick = (): void => {
    DrawerEventEmitter.emit('openDrawer', 'addSkillTestVideo', true, {
      data: { moduleId },
    })
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value)
  }

  const handleSearchSubmit = (): void => undefined

  const actionHandler = (event: string, field: ISkillTestVideo): void | (() => void) => {
    switch (event) {
      case 'edit':
        return handleEditClick(field.id as string)
      case 'delete':
        return handleDeleteClick([field.id as string], field.videosCount as number)
      case 'pdf':
        return () => undefined
      default:
    }
  }

  const actions = actionConfig(t, actionHandler, canModify).map(action => ({
    ...action,
    hide: action.hide ?? false,
  }))

  const defaultOverview = `Knowing what you know about ${moduleName}, it's your turn to become the teacher! Please create a video, no longer than 3 minutes, teaching back the key learning.`

  useEffect(() => {
    if (skillTestVideosTemplates) setSkillTestVideos(skillTestVideosTemplates)
  }, [skillTestVideosTemplates])

  const filteredSkillTestVideos = skillTestVideos.filter((item: ISkillTestVideo) =>
    item.title.includes(searchValue),
  )

  return {
    handleCreateSkillTestVideoClick,
    handleSearchSubmit,
    searchValue,
    handleSearchChange,
    defaultOverview,
    loading,
    skillTestVideos,
    filteredSkillTestVideos,
    actions,
  }
}

export default useSkillTestVideos
