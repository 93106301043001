import styled from 'styled-components'

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .Mui-selected {
    color: #fff !important;
  }
`

export const GridITemWrapper = styled.div<{ fullHeight?: boolean }>`
  height: ${({ fullHeight = true }): string => (fullHeight ? 'auto' : '40px')};
  display: flex;
  align-items: center;
  & > img {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    margin-right: 5px;
  }
`

export const StyledProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
`

// Outer container that represents the progress background
export const ProgressBarContainer = styled.div`
  width: 200px;
  height: 20px;
  background-color: #e4e4e4;
  border-radius: 5px;
  overflow: hidden;
`

// Inner container that represents the progress
export const ProgressFill = styled.div`
  height: 100%;
  background-color: #08c68f;
  transition: width 0.5s ease-in-out;
  border-radius: 5px;
`

export const CenterParagraph = styled.p`
  display: flex;
  text-align: center;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.5);
`

export const Container = styled.div`
  padding-top: 20px;

  .MuiTableCell-root {
    max-width: 200px;
  }
`
