import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 80vw;
  height: 100vh;
`

export const IframeContainer = styled.div`
  height: 100%;
`

export const DesignerIframe = styled.iframe`
  width: 100%;
  border: 0;
  height: 100vh;
`
