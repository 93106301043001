import styled from 'styled-components'

export const SelectFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 43px;
  font-size: 14px;
  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: #98e5cf !important;
    }
  }
  .MuiFormLabel-root.Mui-focused {
    color: #05855f !important;
  }
`
