import styled from 'styled-components'

// gridConfig

export const Action = styled.div<{ textColor?: string }>`
  display: flex;
  align-items: center;
  width: 218px;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  ${({ textColor }: { textColor?: string }): string => (textColor ? `color: ${textColor};` : '')}
  border-radius: 5px;
  gap: 8px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    width: 24px;
    height: 24px;
    font-size: 1rem;
    margin-right: 6px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

// ModulesLayout
export const Container = styled.div``
export const ModalRef = styled.span``
export const ToolbarItemWrapper = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-column-gap: 25px;
  width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
`

export const Title = styled.div`
  font-size: 23px;
  font-weight: 500;
`

export const ContentWrapper = styled.div`
  margin-top: 30px;
`
export const GridTableWrapper = styled.div`
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  margin-top: 20px;
  padding: 16px;

  th:last-child {
    width: 10px;
  }
`

// ModulesList

interface Styles {
  [key: string]: string
}

const defs = ({ $switched }: { $switched: boolean }): Styles => {
  const styles: Styles = {}
  if ($switched) {
    styles.top = '0px'
    styles.left = '0px'
    styles.gridTemplateColumns = '1fr 35px'
  } else {
    styles.bottom = '0px'
    styles.right = '0px'
    styles.justifyContent = 'end'
    styles.gridTemplateColumns = '35px 35px'
  }
  return styles
}

export const CardActionBar = styled.div<{ $switched: boolean }>`
  width: 100%;
  position: absolute;
  z-index: 999;
  box-sizing: border-box;
  padding: 6px;
  display: grid;
  ${({ $switched }): Styles => defs({ $switched })}
  .action_block {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`

export const StylesCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
  position: relative;
  &:hover {
    .action_block {
      background: rgba(255, 255, 255, 0.59) !important;
    }
  }
`

export const AssignedCourses = styled.div`
  display: flex;
  font-size: 13px;
  .item {
    margin-left: 8px;
    &:first-child {
      margin-left: 0;
    }
  }
`

export const StyledModuleContent = styled.span`
  color: #20a8c6;
  font-size: 14px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  background-color: #edf8fa;
`

export const StyledSvg = styled.span`
  svg path {
    color: #090917;
    fill: #090917;
  }
  display: flex;
  align-items: center;
  /* gap: 6px; */
`
export const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

export const modalStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}
