import styled from 'styled-components'

export const CoursesContainer = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 1366px) {
    grid-template-columns: repeat(2, 1fr);
  }
  gap: 30px;
  & > .wrapper {
    box-shadow: 0 10px 20px -15px rgb(0 0 0 / 50%);
    & > div {
      display: flex;
      flex-direction: column;
      border-radius: 12px;

      & > section {
        background-color: white;
      }
    }
  }
`

export const BuilderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const WarningText = styled.p`
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #d3d3d3;
  font-size: 24px;
`
export const IframeContainer = styled.div`
  margin: -30px;
  height: 100%;
`

export const DesignerIframe = styled.iframe`
  width: 100%;
  border: 0;
  height: calc(100vh - 64px);
`
