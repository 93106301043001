import React from 'react'
import { Action } from './styled-components'
import AddCircleRoundedIcon from '@mui/icons-material/Add'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import { ReactComponent as EditRoundedIcon } from 'assets/editRounded.svg'
import { ReactComponent as DeleteIcon } from 'assets/trash-can-action.svg'
import Checkbox from 'components/common/Checkbox'
import Tags from 'components/common/Tags/Tags'
import { ITestsItemProps } from 'hooks/tests/TestDetails/QuestionsLayout/testQuestions.interface'
import { QuestionType } from 'hooks/tests/TestDetails/QuestionsLayout/styled-components'
import { ReactComponent as ViewIcon } from 'components/common/Button/icons/view.svg'
import { ReactComponent as FileCopyIcon } from 'components/common/Button/icons/syllabus.svg'
import Tooltip from '@mui/material/Tooltip'
import { IAction } from 'components/common/GridV2'
import { IPermissionsBool } from 'utils/rolesObject/rolesObject.interface'
import { IQuestion } from 'interfaces/questions'

interface IActionItem {
  hide?: boolean
  render: (item: IQuestion) => React.JSX.Element
}
interface IActionItemRender {
  hide?: boolean
  render: (item: { moduleId: string; name: string }) => React.JSX.Element
}
interface Permissions {
  editQuestion?: boolean
  deleteQuestion?: boolean
  editQuiz?: boolean
}

export const actionConfig = (
  permissions: IPermissionsBool,
  actionHandler: (event: string, field: IQuestion) => void,
  t: (key: string) => string = (): string => '',
  setIsSearchType?: (value: boolean) => void,
): IActionItem[] => {
  const action = [
    {
      hide: false,
      render: (item: IQuestion): React.JSX.Element => (
        <Action onClick={(): void => actionHandler('view', item)}>
          <Tooltip title={t('actions.view')}>
            <ViewIcon />
          </Tooltip>
        </Action>
      ),
    },
    {
      hide: !permissions.editQuestion,
      render: (item: IQuestion): React.JSX.Element => (
        <Action className='edit' onClick={(): void => actionHandler('edit', item)}>
          <Tooltip title={t('actions.edit')}>
            <EditRoundedIcon />
          </Tooltip>
        </Action>
      ),
    },
    {
      hide: !permissions.editQuestion,
      render: (item: IQuestion): React.JSX.Element => (
        <Action className='duplicate' onClick={(): void => actionHandler('duplicate', item)}>
          <Tooltip title={t('actions.edit_duplicate')}>
            <FileCopyIcon />
          </Tooltip>
        </Action>
      ),
    },
    {
      hide: !permissions.deleteQuestion,
      render: (item: IQuestion): React.JSX.Element => (
        <Action
          className='delete'
          onClick={(): void => {
            setIsSearchType && setIsSearchType(false)
            actionHandler('delete', item)
          }}
        >
          <Tooltip title={t('actions.delete')}>
            <DeleteIcon />
          </Tooltip>
        </Action>
      ),
    },
  ]

  return action.filter(i => !i.hide) as IAction[]
}

export const questionActionsConfig = (
  handleCreateQuestionClick: (moduleId: string, name: string) => void,
  handleLinkQuestionToModuleClick: (moduleId: string, name: string) => void,
  t: (key: string) => string = (): string => '',
): IActionItemRender[] => {
  const action = [
    {
      render: (item: { moduleId: string; name: string }): React.JSX.Element => (
        <Action onClick={(): void => handleCreateQuestionClick(item.moduleId, item.name)}>
          <AddCircleRoundedIcon />
          <span>{t('questions_layout.create_question')}</span>
        </Action>
      ),
    },
    {
      render: (item: { moduleId: string; name: string }): React.JSX.Element => (
        <Action onClick={(): void => handleLinkQuestionToModuleClick(item.moduleId, item.name)}>
          <PlaylistAddIcon />
          <span>{t('questions_layout.link_question_to_module')}</span>
        </Action>
      ),
    },
  ]

  return action
}

export const quizQuestionActionsConfig = (
  actionHandler?: (action: string, item: IQuestion) => void,
  permissions?: Permissions,
  t: (key: string) => string = (): string => '',
): IActionItem[] => {
  const action = [
    {
      hide: false,
      render: (item: IQuestion): React.JSX.Element => (
        <Action onClick={(): void => actionHandler && actionHandler('view', item)}>
          <Tooltip title={t('actions.view')}>
            <ViewIcon />
          </Tooltip>
        </Action>
      ),
    },
    {
      hide: !permissions?.deleteQuestion,
      render: (item: IQuestion): React.JSX.Element => (
        <Action
          onClick={(): void | undefined => actionHandler && actionHandler('deleteQuestion', item)}
        >
          <Tooltip title={t('actions.remove_question_from_quiz')}>
            <DeleteIcon />
          </Tooltip>
        </Action>
      ),
    },
  ].filter((i: IActionItem) => !i.hide)

  return action
}

export const columnConfig = (
  selectItem: (id: string) => void,
  t: (key: string) => string = (key): string => key,
  canModify?: boolean,
): IAction[] => {
  let grid: IAction[] = [
    {
      id: 'question',
      label: `${t('general.question')}`,
      render: (item: ITestsItemProps) => <div>{item.question}</div>,
      numeric: false,
      disablePadding: false,
      width: '65%',
    },
    {
      id: 'type',
      label: `${t('general.type')}`,
      numeric: false,
      render: (item: ITestsItemProps) => <QuestionType>{item.type.label}</QuestionType>,
      disablePadding: false,
    },
    {
      id: 'tags',
      label: `${t('general.tags')}`,
      numeric: false,
      disablePadding: false,
      render: (item: ITestsItemProps) => <Tags tags={item.tags} />,
      width: '40%',
    },
  ]

  if (canModify) {
    grid = [
      {
        id: 'isChecked',
        render: (item: ITestsItemProps) => (
          <div>
            <Checkbox checked={item.isChecked} onChange={(): void => selectItem(item.id)} />
          </div>
        ),
        numeric: false,
        disablePadding: true,
      },
      ...grid,
    ]
  }

  return grid
}
