import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Container,
  LoginTitle,
  LoginSubText,
  CheckboxContainer,
  LoginContent,
  ServerErrorBox,
  StyledLink,
  LoginContentRight,
  LoginButton,
} from './styled-components'
import { FormikErrors, useFormik } from 'formik'
import { Alert } from '@mui/lab'
import useAuth from 'hooks/useAuth'
import { LoginSchema } from 'helpers/validationSchemas'
import AuthLayout from 'components/AuthLayout/'
import { Input } from 'components/common/TextInput'
import { Button } from 'components/common/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from 'react-i18next'

interface IInitialValue {
  email: string
  password: string
  remember: boolean
}

const initialValues: IInitialValue = {
  email: '',
  password: '',
  remember: false,
}

interface ILoginData {
  email: string
  password: string
}

const Login = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { loading, login, error } = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  const { handleSubmit, handleChange, setFieldValue, values, errors, touched } = useFormik({
    initialValues,
    validationSchema: LoginSchema,

    async onSubmit(values) {
      const { email, password } = values
      const data: ILoginData = { email, password }
      login(data)
    },
  })

  const onSignInClick = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()
    handleSubmit()
  }

  return (
    <AuthLayout>
      <Container>
        <LoginTitle>{t('login.welcome')}</LoginTitle>
        <LoginSubText>{t('login.sign_in_description')}</LoginSubText>
        <LoginContent>
          <ServerErrorBox className={`${error ? 'show' : ''}`}>
            <Alert severity='error'>{error}</Alert>
          </ServerErrorBox>
          <form onSubmit={onSignInClick}>
            <Input
              label={t('form_fields.email')}
              name='email'
              type='email'
              value={values.email}
              onChange={handleChange}
              error={!!errors.email}
              errorMessage={errors.email}
              touched={touched.email}
              enableAutoComplete
            />
            <Input
              label={t('form_fields.password')}
              name='password'
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange}
              error={!!errors.password}
              errorMessage={errors.password}
              touched={touched.password}
              enableAutoComplete
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={(): void => setShowPassword(!showPassword)}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />

            <CheckboxContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.remember}
                    icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1em' }} />}
                    checkedIcon={<CheckBoxIcon style={{ fontSize: '1em' }} />}
                    name='checkedI'
                    onChange={(): Promise<void> | Promise<FormikErrors<IInitialValue>> =>
                      setFieldValue('remember', !values.remember)
                    }
                  />
                }
                label={
                  <span style={{ color: 'rgb(65 65 65 / 67%)' }}>{t('login.remember_me')}</span>
                }
              />
            </CheckboxContainer>

            <LoginButton>
              <Button
                btnType='submit'
                text={t('login.sign_in')}
                loading={loading}
                size='large'
                background='#06C68F'
              />
            </LoginButton>
            <LoginContentRight>
              <StyledLink>
                <Link to='/forgot-password'>{t('login.forgot_password')}?</Link>
              </StyledLink>
            </LoginContentRight>
          </form>
        </LoginContent>
      </Container>
    </AuthLayout>
  )
}

export default Login
