import React from 'react'
import { GridITemWrapper } from '../groups/styled-components'
import { StyledScoreSpan } from 'pages/courses/CourseQuizzes/styled.components'
import { IConfigItem } from './iqTests.interface'
import { IGridItem } from './iqTests.interface'

export const columnConfig = (t: (key: string) => string): IConfigItem[] => {
  const grid = [
    {
      id: 'assessmentType',
      numeric: false,
      disablePadding: true,
      label: `${t('user_tabs.iq_type')}`,
      render: (item: IGridItem) => (
        <GridITemWrapper>{`${item.IQTestType[0].toUpperCase()}${item.IQTestType.slice(
          1,
        ).toLowerCase()}`}</GridITemWrapper>
      ),
    },
    {
      id: 'assessmentName',
      numeric: false,
      disablePadding: false,
      label: `${t('user_tabs.iq_name')}`,
      render: (item: IGridItem) => <GridITemWrapper>{item.name}</GridITemWrapper>,
    },
    {
      id: 'score',
      numeric: false,
      disablePadding: false,
      label: `${t('user_tabs.iq_result')}`,
      render: (item: IGridItem) => (
        <GridITemWrapper>
          <StyledScoreSpan passed={item.passed}>{item.score}%</StyledScoreSpan>
        </GridITemWrapper>
      ),
    },
  ]

  return grid.filter((i: IConfigItem) => !i.hide)
}
