import gql from 'graphql-tag'

export const CREATE_QUIZ = gql`
  mutation CreateQuiz($type: String, $actionId: String, $input: QuizInput, $companyId: String) {
    createQuiz(type: $type, actionId: $actionId, input: $input, companyId: $companyId) {
      name
      id
      description
      timeType
      time
      quizParticipationType
      passRate
      quizType
      IQTestType
      position
      feedback
      tags {
        label
        id
      }
      questions
      parentName
      randomization
      randomizationNumber
      isShowAnswersTurnedOn
    }
  }
`

export const GET_QUIZ_BY_TYPE = gql`
  query GetQuizByType(
    $type: String
    $actionId: String
    $currentPage: Int
    $perPage: Int
    $filter: QuizesFilter
  ) {
    getQuizzesByType(
      type: $type
      actionId: $actionId
      currentPage: $currentPage
      perPage: $perPage
      filter: $filter
    ) {
      currentPage
      totalPages
      data {
        name
        description
        timeType
        time
        IQTestType
        position
        quizParticipationType
        passRate
        feedback
        id
        quizType
        tags {
          label
          id
        }
        questions
        parentName
        randomization
        randomizationNumber
        isShowAnswersTurnedOn
      }
    }
  }
`

export const GET_QUIZ_BY_ID = gql`
  query GetQuizById($quizId: String) {
    getQuizById(quizId: $quizId) {
      name
      description
      timeType
      time
      quizParticipationType
      passRate
      feedback
      IQTestType
      position
      id
      quizType
      tags {
        label
        id
      }
      actionId
      questions
      parentName
      companyId
      randomization
      randomizationNumber
      isShowAnswersTurnedOn
    }
  }
`

export const GET_ALL_QUIZ = gql`
  query GetAllQuiz($query: String) {
    getAllQuizzes(query: $query) {
      name
      description
      timeType
      time
      quizParticipationType
      passRate
      IQTestType
      position
      companyId
      feedback
      id
      quizType
      tags {
        label
        id
      }
      questions
      parentName
      randomization
      randomizationNumber
    }
  }
`

export const DELETE_QUIZ_BY_IDS = gql`
  mutation DeleteQuizByIds($ids: [String]) {
    deleteQuiz(ids: $ids) {
      ids
    }
  }
`

export const EDIT_QUIZ = gql`
  mutation EditQuiz($quizId: String, $input: QuizInput) {
    editQuiz(quizId: $quizId, input: $input) {
      name
      description
      timeType
      time
      quizParticipationType
      passRate
      feedback
      id
      quizType
      IQTestType
      position
      tags {
        label
        id
      }
      questions
      parentName
      randomization
      randomizationNumber
      isShowAnswersTurnedOn
    }
  }
`
export const ADD_REMOVE_QUESTIONS = gql`
  mutation AddRemoveQuestions($action: String, $questionIds: [String], $quizId: String) {
    addRemoveQuestion(action: $action, questionIds: $questionIds, quizId: $quizId) {
      name
      description
      timeType
      time
      quizParticipationType
      passRate
      feedback
      id
      quizType
      tags {
        label
        id
      }
      questions
      parentName
    }
  }
`
