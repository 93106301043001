import { useMutation } from '@apollo/client'
import { CHECK_USER_EMAIL } from 'gql/users.query'

const useCheckUserEmailService = (): {
  checkUserEmail: (email?: string) => Promise<string | void>
} => {
  const [checkEmail] = useMutation(CHECK_USER_EMAIL)

  const checkUserEmail = async (email?: string): Promise<string | void> => {
    if (!email) return
    const { errors } = await checkEmail({
      variables: {
        email,
      },
    })

    if (errors) return errors[0].message
  }

  return {
    checkUserEmail: (email?: string) => checkUserEmail(email),
  }
}

export default useCheckUserEmailService
