import React, { useState, useEffect, ReactElement } from 'react'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { Wrapper } from '../styled-components'
import Drawer from 'components/common/Drawer'
import Grid from 'components/common/GridV2'
import { columnConfig } from './config'
import TextInput from 'components/common/TextInput/TextInput'
import TablePagination from 'components/common/Pagination/TablePagination'
import { FilterCoaches } from 'pages/coaches'
import { useFetchCompanyAssignedTutors } from 'hooks/users/useCompanyAssignedTutors'
import { TFunction } from 'interfaces/TFunction'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { ICompanyVariablesFilterCompanyId } from '../types'

interface FilterCriteria {
  search?: {
    type: string
    value: string
    fields: string[]
  }
}
interface ICompanyDetailsParams {
  variables: ICompanyVariablesFilterCompanyId
  currentTab: number
  t: TFunction
}

const ROLE = 'COACH'

const CompanyAssignedTutors = ({
  variables,
  currentTab,
  t,
}: ICompanyDetailsParams): ReactElement => {
  const { companyId } = variables

  const { users, loading, refetch } = useFetchCompanyAssignedTutors(companyId, {})

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [filterQuery, setFilterQuery] = useState<IFilterQueryType>({})
  const [filterOptions, setFilterOptions] = useState<IFilterQueryType | null>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)

  const handlePaginationClick = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    refetch({
      companyId,
      filter: filterQuery,
      currentPage: newPage + 1,
      perPage,
    })
    setCurrentPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    refetch({
      companyId,
      filter: filterQuery,
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
  }

  const handleSearchSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    const formValue = {
      search: {
        type: 'search',
        value: searchValue,
        fields: ['firstName', 'lastName', 'email', 'phone'],
      },
    }
    let formData = { ...filterQuery }
    if (searchValue !== '') {
      formData = { ...filterQuery, ...formValue }
    } else {
      const { ...filters } = filterQuery
      formData = { ...filters }
    }
    refetch({
      companyId,
      filter: formData,
      currentPage: 1,
      perPage,
    })
    setFilterQuery(formData)
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.currentTarget.value)
  }

  useEffect(() => {
    if (currentTab) {
      setSearchValue('')
      setFilterQuery({})
    }
  }, [currentTab])

  if (loading) return <div>Loading...</div>

  const emptyFunc = (): void => undefined

  const handleResetClick = (): void => {
    refetch({
      companyId,
      currentPage,
      perPage,
    })
    setSearchValue('')
    setFilterQuery({})
    setFilterOptions(null)
  }

  const filterData = (filters: FilterCriteria): void => {
    let formValue = {}
    if (searchValue !== '') {
      formValue = {
        search: {
          type: 'search',
          value: searchValue,
          fields: ['firstName', 'lastName', 'email', 'phone'],
        },
      }
    }
    refetch({
      companyId,
      filter: { ...filters, ...formValue },
      currentPage: 1,
      perPage,
    })

    if (users) {
      setFilterDrawerOpened(false)
      setFilterQuery({ ...filters, ...formValue })
    }
  }

  const config = columnConfig(ROLE, emptyFunc, emptyFunc, emptyFunc, t, true)

  return (
    <div style={{ paddingTop: 20 }}>
      <Drawer opened={filterDrawerOpened} toggleDrawer={(): void => undefined} totalWidth='600px'>
        <FilterCoaches
          role='COACH'
          closeDrawer={(): void => setFilterDrawerOpened(false)}
          filter={filterData}
          filterLoading={loading}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          useCompanyId
          forAssignCoaches={currentTab === 1 ? true : false}
        />
      </Drawer>
      <div style={{ marginBottom: 20 }}>
        <Wrapper justify='between' align='center'>
          <Wrapper align='center'></Wrapper>
          <form onSubmit={handleSearchSubmit}>
            <TextInput
              label={t('general.search_placeholder')}
              type='text'
              size='small'
              value={searchValue}
              onChange={handleSearchChange}
              disabled={loading}
              icon={
                <IconButton type='submit'>
                  <SearchRoundedIcon onClick={handleSearchSubmit} />
                </IconButton>
              }
            />
          </form>
        </Wrapper>
      </div>
      <div>
        <Grid
          title={t('general.users')}
          onFiltersClick={(): void => setFilterDrawerOpened(true)}
          resetVisible={true}
          resetFilters={handleResetClick}
          config={config}
          data={users.data}
        />

        {users && users.totalCount > 0 && (
          <TablePagination
            currentPage={users ? users.currentPage : 0}
            rowsPerPage={perPage}
            count={users ? users.totalCount : 0}
            handleChangePage={handlePaginationClick}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </div>
    </div>
  )
}

export default CompanyAssignedTutors
