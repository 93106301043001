import { styled } from 'styled-components'

export const ItemContainer = styled.div`
  width: 100%;

  padding: 5px 5px 16px 5px;
  border: 1px solid #d6dae0;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  display: flex;
  font-family: 'Open Sans', sans-serif;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
`

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

export const ColorContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
`

export const AvatarContainer = styled.div`
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  img,
  svg {
    height: 54px;
    max-width: 100%;
    object-fit: cover;
    width: auto;
    border-radius: 50%;
  }
`
export const TitleContainer = styled.div`
  width: 80%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 5px;

  div {
    font-size: 14px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const ButtonContainer = styled.div<{
  $switched?: boolean
}>`
  border: 1px solid #08c694;
  border-radius: 5px;
  padding: 1px 10px;
  font-size: 14px;
  text-align: center;
  padding: 8px 10px;
  box-sizing: border-box;
  ${({ $switched }): string =>
    $switched ? 'color: white; background: #08C694;' : 'color: #08C694; background: transparent;'}

  cursor: pointer;
  transition: 0.3s;
`
export const ContentContainer = styled.div`
  box-sizing: border-box;
  padding-inline: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const ToTalCountBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
export const ListContainer = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 2px;
`
export const ListItemContainer = styled.li`
  display: flex;
  justify-content: space-between;
  span {
    font-size: 13px;
    color: #68707a;
  }

  .count {
    color: #020210;
    font-weight: 600;
  }
`

export const CourseInfo = styled.div`
  gap: 8px;
  padding: 4px 8px;
  background: #edf8fa;
  font-size: 13px;
  border-radius: 4px;
  color: #20a8c6;
`

export const PlatformServiceIndicator = styled.span<{
  $isActive: boolean
}>`
  color: ${({ $isActive }): string => ($isActive ? '#64b89a' : '#68707a')};
  font-size: 13px;
`
