import React, { useState } from 'react'
import strCutter from 'utils/strCutter'
import config from 'config'
import {
  FilesContainer,
  ImageContainer,
  VideoContainer,
  MediaContainer,
  MediaHeader,
  MediaContent,
  MediaContentWrapper,
  MediaTitle,
} from './styled-components'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import PermMediaIcon from '@mui/icons-material/PermMedia'
import VideoCallIcon from '@mui/icons-material/VideoCall'
import CircularProgress from '@mui/material/CircularProgress'
import Modal from 'components/common/Modal'
import Player from 'components/common/VideoPlayer/Player'
import Tooltip from '@mui/material/Tooltip'
import { Image, IQuestionMedia, Params } from './questionsLayout.interface'

const QuestionFiles = ({ images, videos, t }: Params): React.JSX.Element => {
  const [mediaModalOpened, setMediaModalOpened] = useState<boolean>(false)
  const [currentMedia, setCurrentMedia] = useState<IQuestionMedia | null>(null)

  const handlePlayVideoClick = (video: IQuestionMedia): void => {
    setMediaModalOpened(true)
    setCurrentMedia(video)
  }

  const handleOpenImageClick = (image: string): void => {
    setMediaModalOpened(true)
    setCurrentMedia({
      currentImage: image,
      images,
    } as IQuestionMedia)
  }

  const renderImages = (): React.JSX.Element => {
    return (
      <>
        {images.map((image: Image) => (
          <MediaContentWrapper key=''>
            <ImageContainer>
              <ZoomInIcon onClick={(): void => handleOpenImageClick(image.link)} />
              <img src={image.link} />
            </ImageContainer>
            {image.name &&
              (image.name.length > 80 ? (
                <Tooltip title={image.name}>
                  <MediaTitle>{strCutter(image.name, 80)}</MediaTitle>
                </Tooltip>
              ) : (
                <MediaTitle>{image.name}</MediaTitle>
              ))}
          </MediaContentWrapper>
        ))}
      </>
    )
  }

  const renderVideos = (): React.JSX.Element => {
    return (
      <>
        {videos.map((video: IQuestionMedia) => {
          const hasConverted = video.links.find((link: { converted: boolean }) => link.converted)
          return (
            <MediaContentWrapper key=''>
              <VideoContainer>
                {hasConverted || (!hasConverted && config.ENV === 'development') ? (
                  <img src={video.thumbnail} alt={video.name} />
                ) : null}
                {hasConverted || (!hasConverted && config.ENV === 'development') ? (
                  <PlayCircleOutlineIcon onClick={(): void => handlePlayVideoClick(video)} />
                ) : null}
                {!hasConverted && config.ENV === 'production' && <CircularProgress size={20} />}
              </VideoContainer>
              {video.title && (
                <Tooltip title={video.title}>
                  <>{strCutter(video.title, 20)}</>
                </Tooltip>
              )}
            </MediaContentWrapper>
          )
        })}
      </>
    )
  }

  return (
    <FilesContainer>
      {images.length !== 0 && (
        <MediaContainer>
          <MediaHeader>
            <PermMediaIcon />
            {t('general.images')}
          </MediaHeader>
          <MediaContent>{renderImages()}</MediaContent>
        </MediaContainer>
      )}
      {videos.length !== 0 && (
        <MediaContainer>
          <MediaHeader>
            <VideoCallIcon />
            {t('general.videos')}
          </MediaHeader>
          <MediaContent>{renderVideos()}</MediaContent>
        </MediaContainer>
      )}
      <Modal isOpened={mediaModalOpened} onClose={(): void => setMediaModalOpened(false)}>
        <>
          {currentMedia && currentMedia.images ? (
            <div>
              <img src={currentMedia.currentImage} />
            </div>
          ) : (
            <Player url={currentMedia && currentMedia.links[0].url} />
          )}
        </>
      </Modal>
    </FilesContainer>
  )
}

export default QuestionFiles
