import styled from 'styled-components'
import Drawer from '@mui/material/Drawer'

export const classes = {
  drawer: `CustomDrawer-drawer`,
}

export const StyledDrawer = styled(Drawer)<{
  totalWidth: string
}>`
  & > .${classes.drawer} {
    width: ${({ totalWidth }): string => totalWidth};
    flex-shrink: 0;
  }
`
