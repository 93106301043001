import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { DELETE_USER } from 'gql/users.query'

export interface IDeleteUserParams {
  ids: string[]
  role: string
}

export interface IDeleteUserResponse {
  deleteUser: string
}
export interface IDeleteUserServiceResponse {
  deleteUserMutation: MutationTuple<IDeleteUserResponse, IDeleteUserParams>[0]
  loading: boolean
  error?: ApolloError
}

const deleteUserService = (): IDeleteUserServiceResponse => {
  const [deleteUserMutation, { loading, error }] = useMutation<
    IDeleteUserResponse,
    IDeleteUserParams
  >(DELETE_USER)

  return {
    deleteUserMutation,
    loading,
    error,
  }
}

export default deleteUserService
