import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ApolloProvider } from '@apollo/client'
import { client } from './apollo'

import App from './App'

import 'react-daterange-picker/dist/css/react-calendar.css'
import './sass/main.scss'
import { FilterProvider } from 'context/FilterContext'
import { FilterRoleProvider } from 'context/FilterRoleContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

Sentry.init({
  dsn: 'https://c583bdbe395044bb9cdfac5b15ab1f54@o918338.ingest.sentry.io/5932105',
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_STAGE,
})

root.render(
  <ApolloProvider client={client}>
    <FilterRoleProvider>
      <FilterProvider>
        <App />
      </FilterProvider>
    </FilterRoleProvider>
  </ApolloProvider>,
)
