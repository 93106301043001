import { styled } from 'styled-components'

export const Label = styled.h4`
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.87);
  padding: 15px 10px;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0;
    transform: translateY(-50%);
    width: 144px;
    height: 2px;
    border-radius: 20px;
    background-color: rgba(2, 191, 131, 0.5);
  }
`

export const ResourceContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  span {
    padding-left: 10px;
    opacity: 0.5;
    font-style: italic;
    margin: 0px;
    font-size: 16px;
  }
`
