import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { XYCoord } from 'dnd-core'
import { IDndItemProps, IDragItem } from './types'
import TableRow from '@mui/material/TableRow'
import { IDraggedItem } from 'components/common/GridV2/DndGrid/dndItem.interface'

const DndItem: React.FC<IDndItemProps> = ({
  id,
  index,
  moveCard,
  cardIds = [],
  isTable = false,
  isNested = false,
  className = '',
  children,
  onEnd = (): void => undefined,
}) => {
  const ref = useRef<HTMLDivElement | HTMLTableRowElement>(null)

  const [, drop] = useDrop({
    accept: 'CARD',
    hover: (item: IDragItem, monitor) => {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
      item.hoverIndex = dragIndex
    },
  })

  const [{ isDragging }, drag] = useDrag<IDraggedItem, void, { isDragging: boolean }>({
    type: 'CARD',
    item: { id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: async (item: IDraggedItem) => {
      const diff: number = (item.hoverIndex ?? item.index) - item.index
      let keyIndex: number | null = null

      if (Math.abs(diff) === 1) keyIndex = item.hoverIndex ?? null
      else if (diff > 0) keyIndex = item.index + 1
      else if (diff < 0) keyIndex = item.index - 1

      const droppedId = cardIds.find((i: string, ind: number) => ind === keyIndex) || item.id

      if (droppedId !== item.id) {
        isNested ? onEnd(item.id, droppedId) : onEnd(item.id, droppedId || item.id)
      }
    },
  })
  const styles = {
    opacity: isDragging ? 0.4 : 1,
  }

  drag(drop(ref))

  return isTable ? (
    <TableRow
      hover
      role='checkbox'
      tabIndex={-1}
      key={id}
      ref={ref as React.RefObject<HTMLTableRowElement>}
      style={styles}
      className={className}
    >
      {children}
    </TableRow>
  ) : (
    <div style={styles} onDragStart={(): void => undefined} key={id} ref={ref}>
      {children}
    </div>
  )
}

export default DndItem
