import styled from 'styled-components'

// Step1

export const StyledFromGroupContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
`
export const StyledFormGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const ItemWrapper = styled.div`
  width: 125px;
  .MuiRadio-colorSecondary.Mui-checked {
    color: #06c68f;
  }
`

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`
export const HelpButtonContainer = styled.div`
  width: 32px;
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
  }
`
export const ErrorMessage = styled.div`
  position: absolute;
  bottom: -10px;
  left: 0;
  font-size: 12px;
  color: red;
`

export const Label = styled.label`
  &.error {
    color: red;
  }
`
export const InputLabel = styled.div`
  padding: 5px;
  background: #e7e9ed;
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-top: 10px;
`
