import deleteCourseService from 'services/course/deleteCourseService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

interface IUseDeleteCourseReturnType {
  deleteCourse: (ids: string[], refetch: () => void) => Promise<void>
}

const useDeleteCourse = (): IUseDeleteCourseReturnType => {
  const { t } = useTranslation()
  const { deleteCourseMutation } = deleteCourseService()
  const { setSnackbar } = useSnackbarAlert()

  const deleteCourse = async (ids: string[], refetch: () => void): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await deleteCourseMutation({
      variables: { ids },
    })

    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${t('general.course')} ${t('messages.deleted')}`
      variant = 'success'
      refetch()
    }

    setSnackbar({ message, variant })
  }

  return {
    deleteCourse,
  }
}

export default useDeleteCourse
