import { useQuery } from '@apollo/client'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { GET_GROUP } from 'gql/group/group.query'
import { ICompanyCoursesServiceReturn } from './companyCourses.interface'
import { ICompanyDetailsTabFilterVariables } from 'pages/companies/CompanyDetails/CompanyDetails'

export const companyCoursesService = ({
  variables,
}: {
  variables: ICompanyDetailsTabFilterVariables
}): ICompanyCoursesServiceReturn => {
  const { data, loading, error } = useQuery(GET_GROUP, {
    variables: {
      filter: {
        company: {
          type: LowerCaseFilterableFieldType.EXACT,
          value: variables.filter.companyId?.value,
        },
      },
      perPage: 0,
    },
  })

  return {
    groupsData: data || [],
    loading,
    error,
  }
}
