import { CSSProperties } from 'react'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const InputContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: space-between;
`
export const SelectorContainer = styled.form`
  display: flex;
  gap: 16px;
  align-items: center;
`
export const SelectFieldWrapper = styled.div`
  width: 372px !important;
  height: 40px !important;

  .selectField {
    width: 372px !important;
  }
`

export const StyledTableContainer = styled.div`
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  margin-top: 20px;
  margin-bottom: 24px;
  padding: 10px;
`
export const LoaderStyles: CSSProperties = {
  position: 'relative',
}
