import React from 'react'
import { useTranslation } from 'react-i18next'
import { columnConfig } from './gridConfig'
import GridV2 from 'components/common/GridV2'
import { Root, StyledTableContainer } from './styled-component'
import { collapseColumnConfig, getCollapsedData } from '../quizes/gridConfig'

const IQTestsResult: React.FC<{
  testResult: {
    IQTestType: string
    name: string
    score: number
    questionsCount: number
  }
  firstName?: string
}> = ({ testResult, firstName }) => {
  const { t } = useTranslation()
  const config = columnConfig(t)
  const collapseConfig = collapseColumnConfig(t)

  return (
    <Root>
      <StyledTableContainer>
        <GridV2
          title={firstName && `${firstName}'s Pre/Post Course Assess Results`}
          data={testResult}
          config={config}
          collapseConfig={collapseConfig}
          getCollapsedData={getCollapsedData}
        />
      </StyledTableContainer>
    </Root>
  )
}

export default IQTestsResult
