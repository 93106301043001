import { gql } from 'graphql.macro'

export const GET_FLASHCARDS = gql`
  query GetFlascards($moduleId: String!, $skip: Int!, $limit: Int!) {
    flashCards(moduleId: $moduleId, skip: $skip, limit: $limit) {
      id
      question
      answer
      image
      color
      audio {
        fileType
        name
        link
      }
    }
  }
`

export const GET_FLASHCARD = gql`
  query GetFlascard($id: String!) {
    flashCard(id: $id) {
      id
      question
      answer
      image
      color
      audio {
        fileType
        name
        id
        link
      }
    }
  }
`

export const ADD_FLASHCARD = gql`
  mutation ($moduleId: String!, $input: FlashCardInput) {
    addFlashCard(moduleId: $moduleId, input: $input) {
      id
      question
      answer
      image
      color
      audio {
        fileType
        name
        id
        link
      }
    }
  }
`

export const EDIT_FLASHCARD = gql`
  mutation ($id: String!, $input: FlashCardInput) {
    editFlashCard(id: $id, input: $input) {
      id
      question
      answer
      image
      color
      audio {
        fileType
        id
        name
        link
      }
    }
  }
`
export const DELETE_FLASHCARDS = gql`
  mutation ($ids: [String]) {
    deleteFlashCards(ids: $ids)
  }
`
