import React, { ChangeEvent } from 'react'
import TextInput from 'components/common/TextInput/TextInput'
import { FormFields, FormField } from 'components/common/Form'
import ReactSelect, { ValueType } from 'react-select'
import countries from 'countries'
import CreatableSelect from 'react-select/creatable'
import { PhoneCountryCode } from 'components/common/PhoneCountyCodeV2'
import CustomSwitcher from 'components/common/Switcher/CustomSwitcher'
import { ColumnContainer } from 'pages/main/styled-components'
import { IGeneralINformationProps } from './addCompanyDrawer.interface'
import { useCompaniesEdit } from 'hooks/companies/useEditCompany'
import Uploader from 'components/common/FileUploaderV2'
import { StyledUploadLabel } from 'components/modules/styled-components'
import { FileTypes } from 'components/common/FileUploaderV2/uploader.interface'
import Checkbox from 'components/common/CheckboxV2/Checkbox'
import { columnFormFieldStyles } from './styled-components'
import { ISelectFilterOption } from 'interfaces/common'

const GeneralInformation = ({
  values,
  errors,
  generalError,
  touched,
  handleChange,
  setFieldValue,
  handleImageChange,
  deleteImage,
  imageLoading,
  t,
}: IGeneralINformationProps): JSX.Element => {
  const { activityList } = useCompaniesEdit({})

  return (
    <FormFields>
      <FormField>
        <TextInput
          error={errors.name ? true : false}
          errorMessage={errors.name ? errors.name : ''}
          touched={touched.name}
          title={`${t('general.company')} ${t('form_fields.name')}`}
          placeholder={`${t('general.company')} ${t('form_fields.name')}`}
          required
          name='name'
          size='small'
          type='text'
          value={values.name}
          onChange={handleChange}
        />
      </FormField>
      <FormField>
        <Uploader
          label={t('actions.upload_image')}
          required
          accept='image/x-png,image/jpeg'
          onFileChange={(e: ChangeEvent<HTMLInputElement>): void =>
            handleImageChange(e, 'avatarAttachment')
          }
          deleteFile={(): void => deleteImage('avatarAttachment')}
          inputValue={values.avatarAttachment ? values.avatarAttachment.name : ''}
          onInputChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setFieldValue('avatarAttachment.name', e.target.value)
          }}
          type={FileTypes.IMAGE}
          disabled={!values.avatarAttachment}
          uploadedFile={values.avatarAttachment}
          loading={imageLoading === 'avatar'}
          id='image'
        />
      </FormField>
      <FormField>
        <TextInput
          error={Boolean(errors.keyContactName)}
          errorMessage={errors.keyContactName ? errors.keyContactName : ''}
          touched={touched.keyContactName}
          title={t('company_details.key_contact_name')}
          placeholder={t('company_details.key_contact_name')}
          required
          name='keyContactName'
          size='small'
          type='text'
          value={values.keyContactName}
          onChange={handleChange}
        />
      </FormField>
      <FormField style={columnFormFieldStyles}>
        <Checkbox
          checked={values.exploreCourses}
          onChange={(): void => {
            setFieldValue('exploreCourses', !values.exploreCourses)
          }}
          name='exploreCourses'
          label={t('companies_layout.explore_courses')}
          tooltip={t('company_details.explore_courses_helper')}
        />
        <Checkbox
          checked={values.platformService}
          onChange={(): void => {
            setFieldValue('platformService', !values.platformService)
          }}
          name='platformService'
          label={t('companies_layout.platform_service')}
          tooltip={t('company_details.platform_service_helper')}
        />
      </FormField>

      <FormField width='48%'>
        <TextInput
          error={Boolean(errors.companyEmail || generalError)}
          errorMessage={
            errors.companyEmail || generalError ? errors.companyEmail || generalError : ''
          }
          touched={touched.companyEmail}
          title={t('company_details.key_contact_email')}
          placeholder={t('company_details.key_contact_email')}
          required
          name='companyEmail'
          size='small'
          type='text'
          value={values.companyEmail}
          onChange={handleChange}
        />
      </FormField>
      <FormField width='48%'>
        <StyledUploadLabel>{t('user_details.phone')}</StyledUploadLabel>
        <PhoneCountryCode
          selectLabel={t('form_fields.dial_code')}
          selectValue={values.phoneFields || null}
          selectChange={(e: ISelectFilterOption | null): void => {
            setFieldValue('phoneFields', e)
          }}
          inputLabel='Phone'
          inputValue={values.phone}
          inputChange={handleChange}
          error={errors.phone || errors.phoneFields ? true : false}
          errorMessage={
            errors.phone || errors.phoneFields ? errors.phone || errors.phoneFields : ''
          }
          touched={touched.phone || touched.phoneFields}
        />
      </FormField>
      <FormField>
        <TextInput
          title={t('company_details.number_of_users')}
          placeholder={t('company_details.number_of_users')}
          name='numberOfUsers'
          size='small'
          type='number'
          value={values.numberOfUsers}
          onChange={handleChange}
        />
      </FormField>
      <FormField>
        <StyledUploadLabel>{t('company_details.select_industry')}</StyledUploadLabel>
        <CreatableSelect
          onChange={(value: ValueType<{ label: string; value: string }, false>): void => {
            setFieldValue('companyActivity', value)
          }}
          options={activityList}
          value={values.companyActivity}
          placeholder={t('company_details.select_industry')}
        />
      </FormField>
      <FormField>
        <StyledUploadLabel>{t('company_details.select_country')}</StyledUploadLabel>
        <ReactSelect
          placeholder={t('company_details.select_country')}
          name='country'
          options={countries}
          value={values.country}
          isClearable
          onChange={(e: ValueType<{ label: string; value: string }, false>): void => {
            setFieldValue('country', e)
          }}
        />
      </FormField>
      <FormField width='48%'>
        <TextInput
          title={t('company_details.city')}
          placeholder={t('company_details.city')}
          name='city'
          size='small'
          type='text'
          value={values.city}
          onChange={handleChange}
        />
      </FormField>
      <FormField width='48%'>
        <TextInput
          title={t('company_details.postal_code')}
          placeholder={t('company_details.postal_code')}
          size='small'
          type='text'
          name='postalCode'
          value={values.postalCode}
          onChange={handleChange}
        />
      </FormField>
      <FormField>
        <TextInput
          title={t('company_details.address')}
          placeholder={t('company_details.address')}
          name='address'
          size='small'
          type='text'
          value={values.address}
          onChange={handleChange}
        />
      </FormField>
      <FormField>
        <TextInput
          title={t('form_fields.note')}
          placeholder={t('form_fields.note')}
          name='note'
          size='small'
          type='text'
          fullWidth
          multiline
          rows='3'
          value={values.note}
          onChange={handleChange}
        />
      </FormField>
      <FormField
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
        }}
      >
        <ColumnContainer companyEdit>
          <CustomSwitcher
            onChange={(): void => {
              setFieldValue('showLeaderboardByGroup', !values.showLeaderboardByGroup)
            }}
            label={t('settings.show_leaderboard_by')}
            name='showLeaderboardBy'
            value={values.showLeaderboardByGroup}
            on={t('navigation.groups')}
            off={t('navigation.company')}
            tooltip={t('settings.show_leaderboard_by_helper')}
          />
          <CustomSwitcher
            onChange={(): void => {
              setFieldValue('showNewsFeedByGroup', !values.showNewsFeedByGroup)
            }}
            label={t('settings.news_feed_scope')}
            name='showNewsFeedBy'
            value={values.showNewsFeedByGroup}
            on={t('navigation.groups')}
            off={t('navigation.company')}
            tooltip={t('settings.news_feed_scope_helper')}
          />
        </ColumnContainer>
      </FormField>
    </FormFields>
  )
}

export default GeneralInformation
