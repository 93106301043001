import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { CREATE_QUIZ } from 'gql/quiz/quiz.query'
import { IQuiz } from 'pages/quizzes/quiz.interface'

interface ICreateQuizServiceResponse {
  createQuizMutation: MutationTuple<ICreateQuizResponse, ICreateQuizParams>[0]
  loading: boolean
  error?: ApolloError
}

interface ICreateQuizResponse {
  createQuiz: IQuiz
}

interface ICreateQuizParams {
  type: string
  actionId: string
  input: IQuiz
  companyId: string | null
}

const createQuizService = (): ICreateQuizServiceResponse => {
  const [createQuizMutation, { loading, error }] = useMutation<
    ICreateQuizResponse,
    ICreateQuizParams
  >(CREATE_QUIZ)

  return {
    createQuizMutation,
    loading,
    error,
  }
}

export default createQuizService
