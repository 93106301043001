import React from 'react'
import { SelectFieldWrapper } from './styled-component'
import SelectField from '../SelectDropDown/SelectDropDown'
import { IPerPageDropdownProps } from './dropDown.interface'

const PerPageDropdown: React.FC<IPerPageDropdownProps> = ({ value, onChange, options }) => {
  return (
    <SelectFieldWrapper>
      <SelectField
        value={value}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
          onChange(Number(e.target.value))
        }
        options={options.map(option => ({
          label: option.toString(),
          value: option,
        }))}
      />
    </SelectFieldWrapper>
  )
}

export default PerPageDropdown
