import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { REMOVE_QUESTIONS_FROM_TEST } from 'gql/questions.query'
import { GET_TEST } from 'gql/tests/tests.query'

interface IRemoveQuestionsFromTestAssessmentServiceResponse {
  removeQuestionsFromTestAssessmentMutation: MutationTuple<
    IRemoveQuestionsFromTestResponse,
    IRemoveQuestionsFromTestParams
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IRemoveQuestionsFromTestResponse {
  removeQuestionsFromTest: string
}

interface IRemoveQuestionsFromTestParams {
  testAssessmentId: string
  questionIds: string[]
}

const removeQuestionsFromTestAssessmentService = (
  testId: string,
): IRemoveQuestionsFromTestAssessmentServiceResponse => {
  const [removeQuestionsFromTestAssessmentMutation, { loading, error }] = useMutation<
    IRemoveQuestionsFromTestResponse,
    IRemoveQuestionsFromTestParams
  >(REMOVE_QUESTIONS_FROM_TEST, {
    refetchQueries: [
      {
        query: GET_TEST,
        variables: {
          testId,
        },
      },
    ],
  })

  return {
    removeQuestionsFromTestAssessmentMutation,
    loading,
    error,
  }
}

export default removeQuestionsFromTestAssessmentService
