import React from 'react'
import UsersLayout from 'components/UsersLayout'
import { useTranslation } from 'react-i18next'

const ModeratorsLayout = (): React.JSX.Element => {
  const { t } = useTranslation()
  return (
    <div>
      <UsersLayout
        role='SUPER_ADMIN'
        addText={t('users_layout.add_super_admin')}
        addUserDrawer='addModerator'
        editUserDrawer='editModerator'
      />
    </div>
  )
}

export default ModeratorsLayout
