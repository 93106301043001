import { useMutation, MutationTuple } from '@apollo/client'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'

import { ADD_GROUP, GET_GROUP } from 'gql/group/group.query'
import { IGroup } from 'interfaces/groups'
import { updateCache } from 'services/updateCache'

interface IAddGroupServiceResponse {
  addGroupMutation: MutationTuple<IAddGroupResponse, IAddGroupVariables>[0]
}

export interface IAddGroupServiceParams {
  filter?: IFilterQueryType
  currentPage?: number
  perPage?: number
}

interface IAddGroupResponse {
  addGroup: IGroup
}

interface IAddGroupVariables {
  input: IGroup
  students?: string[]
}

const addGroupService = (params: IAddGroupServiceParams): IAddGroupServiceResponse => {
  if (params?.filter?.company && !params?.filter?.company?.value) {
    delete params.filter.company
  }
  const [addGroupMutation] = useMutation<IAddGroupResponse, IAddGroupVariables>(ADD_GROUP, {
    update(cache, { data }) {
      if (data?.addGroup) {
        const document = data.addGroup
        updateCache({
          objectName: 'getAllGroups',
          query: GET_GROUP,
          cache,
          document,
          action: 'add',
          params,
        })
      }
    },
  })

  return {
    addGroupMutation,
  }
}

export default addGroupService
