import React, { Dispatch, SetStateAction } from 'react'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { DrawerHeader, DrawerTitle, DrawerContent } from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form'
import TextInput from 'components/common/TextInput/TextInput'
import RangeSlider from 'components/common/RangeSlider'
import { Button } from 'components/common/Button'
import { TFunction } from 'interfaces/TFunction'
import { IFilterData } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'

interface Params {
  closeDrawer: () => void
  filterData: IFilterData
  setFilterData: Dispatch<SetStateAction<IFilterData>>
  filter?: (options: IFilterData) => void
  setFiltered?: React.Dispatch<React.SetStateAction<boolean>>
  t: TFunction
  maxValues: { duration: number; lessons: number }
}

const FilterDrawer = ({
  closeDrawer,
  filterData,
  setFilterData,
  filter,
  setFiltered,
  t,
  maxValues,
}: Params): React.JSX.Element => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target

    setFilterData({
      ...filterData,
      [name]: value,
    })
  }

  const handleRangeChange = (range: number | number[], name: string): void => {
    setFilterData({
      ...filterData,
      [name]: range,
    })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    const { name, numberOfLessons, duration, attachment } = filterData
    const filterOptions: IFilterData = {
      name,
      numberOfLessons: numberOfLessons ? `${numberOfLessons[0]}-${numberOfLessons[1]}` : '',
      topicDuration: duration,
      attachment,
    }

    if (attachment) {
      filterOptions.attachment =
        attachment === 'yes' ? 'true' : attachment === 'no' ? 'false' : 'all'
    }

    if (filter) filter(filterOptions)
    closeDrawer()
    if (setFiltered) setFiltered(true)
  }

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>
          {t('general.advanced_filters')} - {t('actions.filter')} {t('general.lessons')}
        </DrawerTitle>
        <IconButton onClick={closeDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField>
                <TextInput
                  label={t('form_fields.name')}
                  size='small'
                  name='name'
                  type='text'
                  value={filterData?.name}
                  onChange={handleChange}
                />
              </FormField>
              <FormField>
                <RangeSlider
                  label={`${t('general.lessons')} ${t('general.quantity')}`}
                  value={filterData?.numberOfLessons as number[]}
                  onRangeChange={handleRangeChange}
                  name='numberOfLessons'
                  max={maxValues.lessons}
                />
              </FormField>
              <FormField>
                <RangeSlider
                  label={`${t('general.topic')} ${t('general.duration')}`}
                  value={filterData?.duration}
                  onRangeChange={handleRangeChange}
                  name='duration'
                  max={maxValues.duration}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type='small'
                onClick={closeDrawer}
                background='#E0E1E2'
                textColor='#414141'
              />
              <Button
                text={t('actions.filter')}
                type='small'
                color='secondary'
                btnType='submit'
                background='#06C68F'
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterDrawer
