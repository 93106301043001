import { useState } from 'react'
import addTestService from 'services/tests/addTestService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { IAttachment } from 'interfaces/common'
import { IQuiz } from 'pages/quizzes/quiz.interface'

export interface IAddTestDto {
  name: string
  attachment: IAttachment | null
  randomizationNumber: number
  tags: string[]
  quizParticipationType: string
  description: string
  passRate: number
  randomization: boolean
  time: number
}

const useAddTestService = (): {
  addTest: (input: IAddTestDto, companyId: string) => Promise<void>
  addTestLoading: boolean
  data: IQuiz | null
  error: string
} => {
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const [data, setData] = useState<IQuiz | null>(null)
  const [loading, setLoading] = useState(false)
  const { addTestMutation } = addTestService()

  const addTest = async (input: IAddTestDto, companyId: string): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { data, errors } = await addTestMutation({
      variables: {
        input,
        companyId,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
      setError(message)
    } else if (data) {
      message = 'Test added successfully'
      variant = 'success'
      setData(data.createTestAssessment)
    }

    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return {
    addTest,
    addTestLoading: loading,
    data,
    error,
  }
}

export default useAddTestService
