import { CustomNotificationFormValues } from './customNotification.interface'

export enum CustomNotificationTemplates {
  MAINTENANCE = 'maintenance',
  CUSTOM = 'custom',
}

export enum CustomNotificationFields {
  BODY = 'body',
  SUBJECT = 'subject',
  COMPANIES = 'companies',
  ROLES = 'roles',
  SEND_TO_ALL_COMPANIES = 'sendToAllCompanies',
  SEND_TO_ALL_ROLES = 'sendToAllRoles',
  SEND_AS_EMAIL = 'sendAsEmail',
  SEND_AS_PUSH_NOTIFICATION = 'sendAsPushNotification',
  TEMPLATE = 'template',
}

export enum CustomNotificationDefaultTexts {
  MAINTENANCE_SUBJECT = 'Suada Planned Maintenance',
  MAINTENANCE_BODY = `🛠️ Scheduled Maintenance Alert! We're upgrading our systems to serve you better. The application will be unavailable on [Date] from [Start Time] to [End Time]. We apologize for any inconvenience and appreciate your patience.`,
}
export const initialValues: CustomNotificationFormValues = {
  body: CustomNotificationDefaultTexts.MAINTENANCE_BODY,
  subject: CustomNotificationDefaultTexts.MAINTENANCE_SUBJECT,
  companies: [],
  roles: [],
  sendToAllCompanies: true,
  sendToAllRoles: true,
  sendAsEmail: false,
  sendAsPushNotification: true,
  template: { value: 'maintenance', label: 'Maintenance' },
}

export const templates = [
  { value: 'maintenance', label: 'Maintenance' },
  { value: 'custom', label: 'Custom' },
]

export const roles = [
  { value: 'ADMIN', label: 'Admin' },
  { value: 'STUDENT', label: 'Student' },
  { value: 'COACH', label: 'Tutor' },
  { value: 'SUPER_ADMIN', label: 'Super Admin' },
  { value: 'ADMIN', label: 'Group Admin' },
]
