import styled from 'styled-components'

export const Wrapper = styled.div<{
  $opened: boolean
  selected: boolean
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px 12px;
  border: 1px solid #08c694;
  transition: 0.3s ease;
  border-radius: 4px;
  background: white;

  h4 {
    color: #08c694;
    font-size: 14px;
    transition: 0.3s ease;
  }

  &:hover {
    border-color: #12a37a;
    h4 {
      color: #12a37a;
    }
  }

  ${({ $opened }): string =>
    ($opened &&
      `border-color: #12A37A; 
        h4 {
            color: #12A37A;
        }
  `) ||
    ''}

  ${({ selected }): string =>
    (selected &&
      `background: #08C694;
        h4 {
           color: white;

        }

        &:hover {
            background: #12A37A;
            h4 {
                color: white;
            }
        }
  `) ||
    ''}
`

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 12px;
  border-bottom: 1px solid #e0e0e0;

  input {
    border: none;
    outline: none;
    font-size: 16px;
    width: 100%;
  }
`

export const Label = styled.h4`
  color: #414141;
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    font-size: 16px;
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    background: transparent;
    transition: 0.3s ease;

    svg {
      width: 100%;
      height: 100%;
      color: white;
    }

    &:hover {
      background: white;

      svg {
        color: #12a37a;
      }
    }
  }
`

export const PopoverContent = styled.div`
  display: flex;
  width: 360px;
  flex-direction: column;
  border-radius: 8px;
`

export const CompanyList = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  max-height: 200px;
  overflow-y: auto;
  padding: 8px;
  font-family: 'Open Sans', sans-serif;

  span {
    padding: 8px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

export const CompanyOptionDiv = styled.div<{
  selected: boolean
}>`
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
  background: ${({ selected }): string => (selected ? '#08C694' : '#fff')};
  color: ${({ selected }): string => (selected ? 'white' : '#414141')};
  transition: 0.3s ease;
  margin-block: 2px;

  &:hover {
    background: #edeef2;
    ${({ selected }): string => (selected ? 'color: #08C694;' : '')}
  }
`
