import { MutationTuple, useMutation } from '@apollo/client'
import { updateCache } from 'services/updateCache'

import {
  UPDATE_PROFILE_AVATAR,
  EDIT_USER_PROFILE,
  GET_CURRENT_USER,
  CHANGE_PASSWORD,
  UPDATE_USER_PASSWORD_BY_ID,
} from 'gql/users.query'
import { useTranslation } from 'react-i18next'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useState } from 'react'
import { IUser } from 'interfaces/users'

interface IChangePasswordInput {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

interface IChangePasswordResponse {
  changePassword: IUser
}

interface IChangePasswordServiceResponse {
  changePassword: MutationTuple<IChangePasswordResponse, IChangePasswordInput>[0]
}

export const changePasswordService = (): IChangePasswordServiceResponse => {
  const [changePassword] = useMutation<IChangePasswordResponse, IChangePasswordInput>(
    CHANGE_PASSWORD,
    {
      update(cache, { data }) {
        if (data?.changePassword) {
          const { changePassword: document } = data
          updateCache({
            objectName: 'currentUser',
            query: GET_CURRENT_USER,
            cache,
            document,
            action: 'update',
          })
        }
      },
    },
  )

  return {
    changePassword,
  }
}

interface IUpdateUserPasswordInput {
  id: string
  password: string
}

interface IUpdateUserPasswordResponse {
  updatePassword: IUser
}

interface IUpdateUserPasswordServiceResponse {
  updatePassword: MutationTuple<IUpdateUserPasswordResponse, IUpdateUserPasswordInput>[0]
}

const updateUserPasswordService = (): IUpdateUserPasswordServiceResponse => {
  const [updatePassword] = useMutation<IUpdateUserPasswordResponse, IUpdateUserPasswordInput>(
    UPDATE_USER_PASSWORD_BY_ID,
  )

  return {
    updatePassword,
  }
}

interface IUseUpdateUserPasswordByIdResponse {
  loading: boolean
  updateUserPassword: (id: string, password: string) => void
}

export const useUpdateUserPasswordById = (): IUseUpdateUserPasswordByIdResponse => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [loading, setLoading] = useState(false)
  const { updatePassword } = updateUserPasswordService()

  const updateUserPassword = async (id: string, password: string): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { errors } = await updatePassword({
      variables: { id, password },
    })

    if (errors && errors.length) {
      setLoading(false)
      message = t('messages.something_wrong')
      variant = 'error'
      return
    }

    message = `${t('reset_password.success')}`
    variant = 'success'

    setSnackbar({ message, variant })
    setLoading(false)
  }

  return {
    loading,
    updateUserPassword,
  }
}

interface IUpdateProfileAvatarInput {
  avatar: string
  userId: string
  action: string
}

interface IUpdateProfileAvatarResponse {
  updateProfileAvatar: IUser
}

interface IUpdateProfileAvatarServiceResponse {
  updateProfileAvatar: MutationTuple<IUpdateProfileAvatarResponse, IUpdateProfileAvatarInput>[0]
}

export const updateProfileAvatarService = (): IUpdateProfileAvatarServiceResponse => {
  const [updateProfileAvatar] = useMutation<
    IUpdateProfileAvatarResponse,
    IUpdateProfileAvatarInput
  >(UPDATE_PROFILE_AVATAR, {
    update(cache, { data }) {
      if (data?.updateProfileAvatar) {
        const { updateProfileAvatar: document } = data
        updateCache({
          objectName: 'currentUser',
          query: GET_CURRENT_USER,
          cache,
          document,
          action: 'update',
        })
      }
    },
  })

  return {
    updateProfileAvatar,
  }
}

interface IEditUserProfileServiceResponse {
  editUserProfile: MutationTuple<IEditUserProfileResponse, IEditUserProfileInput>[0]
}

interface IEditUserProfileInput {
  input: IEditUserProfileServiceParams
}

interface IEditUserProfileResponse {
  editUserProfile: IUser
}

export interface IEditUserProfileServiceParams {
  settings: {
    askPassword: boolean
  }
}

export const editProfileService = (params: IUser): IEditUserProfileServiceResponse => {
  const [editUserProfile] = useMutation<IEditUserProfileResponse, IEditUserProfileInput>(
    EDIT_USER_PROFILE,
    {
      update(cache, { data }) {
        if (data?.editUserProfile) {
          const { editUserProfile: document } = data
          updateCache({
            objectName: 'currentUser',
            query: GET_CURRENT_USER,
            cache,
            document,
            action: 'update',
            params,
          })
        }
      },
    },
  )
  return {
    editUserProfile,
  }
}
