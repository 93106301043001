import moment from 'moment'
import React from 'react'
import { IActionConfig, IColumnConfig, IGridActionItem, Item } from './TasksLayout.interface'
import { CheckBoxAndAvatarContainer } from 'components/UsersLayout/styled-components'
import { Tooltip } from '@mui/material'
import { ReactComponent as DeleteIcon } from 'assets/trash-can-action.svg'
import { ReactComponent as EditRoundedIcon } from 'assets/editRounded.svg'
import Checkbox from 'components/common/Checkbox'
import {
  StyledConditionContainer,
  StyledConditionSpan,
  StyledConditionTypeNameSpan,
  StyledConditionTypeSpan,
  Action,
} from './styled-components'

export const columnConfig = (
  handleTaskSelection: (id: string) => void,
  selectedTasks: string[],
  t: (key: string) => string,
): IColumnConfig[] => {
  const config = [
    {
      id: 'isChecked',
      label: '',
      render: (item: Item): JSX.Element => (
        <CheckBoxAndAvatarContainer>
          <Checkbox
            checked={selectedTasks.includes(item._id)}
            onChange={(): void => handleTaskSelection(item._id)}
          />{' '}
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: false,
    },

    {
      id: 'name',
      label: t('general.name'),
      render: (item: Item): JSX.Element => <span> {item.name}</span>,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'studentCount',
      label: t('general.students'),
      render: (item: Item): JSX.Element => <span> {item.studentsCount}</span>,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'conditionType',
      label: t('general.conditionType'),
      render: (item: Item): JSX.Element =>
        item.courseId ? (
          <StyledConditionTypeSpan>Course</StyledConditionTypeSpan>
        ) : item.moduleId ? (
          <StyledConditionTypeSpan>Module</StyledConditionTypeSpan>
        ) : (
          <>Off</>
        ),
      numeric: false,
      disablePadding: false,
    },

    {
      id: 'condition',
      label: t('general.condition'),
      render: (item: Item): JSX.Element => {
        const { courseId, moduleId, trigger } = item
        const conditionType = courseId ? courseId : moduleId
        const triggerType = trigger?.startsWith('BEFORE') ? 'Start' : 'Finish'

        return (
          <>
            {conditionType ? (
              <StyledConditionContainer>
                <StyledConditionSpan>
                  {trigger?.startsWith('BEFORE') ? 'Before' : 'After'}
                </StyledConditionSpan>{' '}
                - <StyledConditionTypeNameSpan>{conditionType.name}</StyledConditionTypeNameSpan> -{' '}
                <StyledConditionSpan>{triggerType}</StyledConditionSpan>{' '}
              </StyledConditionContainer>
            ) : (
              '-'
            )}
          </>
        )
      },
      numeric: false,
      disablePadding: false,
    },

    {
      id: 'date',
      label: t('general.create_date'),
      render: (item: Item): JSX.Element => (
        <span>{moment(item.createDate).format('DD-MM-YYYY')}</span>
      ),
      numeric: false,
      disablePadding: false,
    },
  ]

  return config
}

export const actionConfig = (
  handleDeleteClick: (id: string) => void,
  handleEditClick: (id: string) => void,
  t: (key: string) => string,
  setIsSearchType?: (value: boolean) => void,
): IActionConfig[] => {
  const actions = [
    {
      id: 'edit',
      // hide: !permissions[`edit${field}`],
      render: (item: IGridActionItem): JSX.Element => (
        <Action
          className='edit'
          hoverColor='#5aa4f5'
          hoverBackground='#eff6fe'
          onClick={(): void => handleEditClick(item._id)}
        >
          <Tooltip title={t('actions.edit')} arrow>
            <EditRoundedIcon />
          </Tooltip>
        </Action>
      ),
    },

    {
      // hide: !permissions[`delete${field}`],
      id: 'delete',
      render: (item: IGridActionItem): JSX.Element => (
        <Action
          className='delete'
          hoverColor='#ea382a'
          hoverBackground='#fdefee'
          onClick={(): void => {
            handleDeleteClick(item._id)
            if (setIsSearchType) {
              setIsSearchType(false)
            }
          }}
        >
          <Tooltip title={t('actions.delete')} arrow>
            <DeleteIcon />
          </Tooltip>
        </Action>
      ),
    },
  ]
  return actions
}
