import React, { ChangeEvent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Wrapper } from './styled-components'
import { IconButton } from '@mui/material'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { StyledLabel } from '../styled-components'
import { TextInput } from 'components/common'

const DrawerFilter = ({
  searchValue,
  handleSearchChange,
}: {
  searchValue: string
  handleSearchChange: (event: ChangeEvent<HTMLInputElement>) => void
}): ReactElement => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <StyledLabel>{t('actions.filter')}</StyledLabel>
      <TextInput
        label={t('general.search_placeholder')}
        type='text'
        size='small'
        onChange={handleSearchChange}
        value={searchValue}
        $iconInStart
        icon={
          <IconButton>
            <SearchRoundedIcon />
          </IconButton>
        }
      />
    </Wrapper>
  )
}

export default DrawerFilter
