import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import Modal from 'components/common/Modal'
import Player from 'components/common/VideoPlayer/Player'
import { GET_ALL_SKILL_TEST_VIDEOS } from 'gql/users.query'
import MyLoader from 'loading/loading'
import Grid from 'components/common/GridV2/Grid'
import getGridConfig from './gridConfig'
import PaginationV2 from 'components/common/PaginationV2'
import { PaginationContainer } from './styled-components'
import PerPageDropdown from 'components/common/PerPageDropDown/PerPageDropDown'
import { CenterParagraph } from 'pages/analytics/Groups/styled-components'
import { IVideo } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'
import { ISkillTestVideo } from 'interfaces/SkillTestVideo/SkillTestVideo.interface'

const CourseSkillTestVideos: React.FC<{
  courseId: string
  groupId?: string | undefined
  companyId: string
}> = ({ courseId, groupId, companyId }) => {
  const { t } = useTranslation()
  const [videoModal, setVideoModal] = useState(false)
  const [video, setVideo] = useState<IVideo | null>(null)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)

  const { data: skillTestVideosData, loading } = useQuery(GET_ALL_SKILL_TEST_VIDEOS, {
    variables: {
      courseId,
      // pass groupId only if it is a single group, otherwise don't pass anything to query all groups
      companyId,
      ...(groupId ? { groupId } : {}),
      currentPage: currentPage,
      perPage: rowsPerPage,
    },
  })

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setRowsPerPage(newRowsPerPage)
  }

  const handlePageChange = (_: ChangeEvent<unknown>, newPage?: number): void => {
    if (newPage !== undefined) {
      setCurrentPage(newPage)
    }
  }

  const totalPages = skillTestVideosData?.getAllSkillTestVideos.totalPages

  const gridConfig = getGridConfig(t)

  if (loading) return <MyLoader width={250} speed={0.8} />

  const handleRowClick = (videoData: ISkillTestVideo): void => {
    setVideo(videoData?.video as IVideo)
    setVideoModal(true)
  }

  return (
    <div style={{ paddingTop: '24px' }}>
      {skillTestVideosData ? (
        <>
          <Grid
            data={skillTestVideosData.getAllSkillTestVideos.data}
            config={gridConfig}
            redirectToDetailsHandler={handleRowClick}
          />
          {skillTestVideosData?.getAllSkillTestVideos?.data?.length > 0 && (
            <PaginationContainer>
              <PerPageDropdown
                value={rowsPerPage}
                onChange={(value): void =>
                  handleRowsPerPageChange({
                    target: { value: value.toString() },
                  } as React.ChangeEvent<HTMLSelectElement>)
                }
                options={[10, 20, 50]}
              />
              <PaginationV2
                currentPage={currentPage}
                totalPages={totalPages}
                handleChange={handlePageChange}
              />
            </PaginationContainer>
          )}
        </>
      ) : (
        <CenterParagraph>{t('general.specific_data')}</CenterParagraph>
      )}

      <Modal isOpened={videoModal} onClose={(): void => setVideoModal(false)}>
        <Player
          url={video?.links?.find(l => l?.fileType === 'hls')?.url}
          downloadLink={video?.links?.find(l => l?.converted)?.url}
          subtitle={'subtitle'}
        />
      </Modal>
    </div>
  )
}

export default CourseSkillTestVideos
