import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useUserValue } from 'context/UserContext'
import { useSwal } from 'hooks/useSwal'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { GroupSchema } from 'helpers/validationSchemas'
import { checkValues } from 'pages/group/checkGroupsValues'
import useAddGroup from 'hooks/group/useAddGroup'
import useEditGroup from 'hooks/group/useEditGroup'
import { initialFormData } from '../useGroupDrawers/useGroupDrawer'
import { generateFullName } from 'utils/utils'
import {
  IGroupFormFormValues,
  IGroupFormProps,
  IGroupsSelectOption,
  IUseGroupFormReturn,
} from './useGroupForm.interface'
import { ICourseBriefData } from 'pages/courses/courseList.interface'
import { IGroupDrawerFormData } from '../useGroupDrawers/useGroupDrawer.interface'

const useGroupForm = ({
  companyId,
  courseData,
  onClose,
  afterSave,
  item,
  groupId,
  adminList,
}: IGroupFormProps): IUseGroupFormReturn => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const { fireSwal } = useSwal()
  const { loading: addLoading, addGroup } = useAddGroup({
    filter: {
      company: {
        type: LowerCaseFilterableFieldType.EXACT,
        value: companyId,
      },
    },
    currentPage: 1,
    perPage: 10,
  })
  const { editGroup, loading: editLoading } = useEditGroup()

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue, setValues } =
    useFormik({
      initialValues: initialFormData,
      validationSchema: GroupSchema,
      onSubmit: async (values: IGroupFormFormValues) => {
        const data = { ...values }

        if (data.admin) {
          data.admin = groupId
            ? (data.admin as IGroupsSelectOption).value
            : data.admin.map((i: IGroupsSelectOption) => i.value)
        }

        data.courses = groupId
          ? (data.courses.map(i => ({
              name: (i as IGroupsSelectOption).label,
              courseId: (i as IGroupsSelectOption).value,
            })) as ICourseBriefData[])
          : (courseData as ICourseBriefData[])
          ? (courseData as ICourseBriefData[])
          : (values.courses?.map(i => ({
              courseId: (i as IGroupsSelectOption).value,
              name: (i as IGroupsSelectOption).label,
            })) as ICourseBriefData[]) || ([] as ICourseBriefData[])

        if (companyId) data.company = companyId
        else if (state.selectedCompany?.id) {
          data.company = state.selectedCompany.id
        }

        if (groupId) {
          await editGroup(data as IGroupDrawerFormData, groupId, onClose)
        } else {
          await addGroup(data)
        }

        if (afterSave) afterSave()
        onClose()
      },
    })

  useEffect(() => {
    if (groupId && item) {
      const { ...args } = initialFormData
      const data: IGroupFormFormValues = {} as IGroupFormFormValues
      for (const key in args) {
        data[key as keyof IGroupFormFormValues] = item[key as keyof typeof item] as never
      }

      data.admin = item.admin ? { label: generateFullName(item.admin) } : null

      data.courses =
        item.courses &&
        (item.courses.map(i => ({
          label: i.name,
          value: i.courseId,
        })) as IGroupsSelectOption[])

      setValues({ ...data })
    }
  }, [groupId, item])

  const handleCloseDrawer = (): void => {
    const isEmpty = checkValues(values)

    if (isEmpty) {
      onClose()
    } else {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        onConfirm: () => {
          onClose()
        },
        onClose: () => undefined,
        confirmText: t('popups.confirm_cancel'),
        cancelText: t('general.no'),
      })
    }
  }

  return {
    courseData,
    t,
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    loading: groupId ? editLoading : addLoading,
    handleCloseDrawer,
    adminList,
  }
}

export default useGroupForm
