// @deprecated
/* eslint-disable */

import { Button } from 'components/common/Button'
import DatePicker from 'components/common/DatePicker/DatePicker'
import { FormField, FormFields } from 'components/common/Form/Form'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'
import { SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import { genderOptions } from 'components/UsersLayout/staticData'
import { useFormik } from 'formik'
import { UserSchema } from 'helpers/validationSchemas'
import useEditUserService from 'hooks/users/useEditUserService'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const formData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  phoneFields: null as any,
  gender: null,
  birthDate: '',
  note: '',
  role: 'ADMIN',
  avatar: '',
  jobTitle: '',
  location: '',
  phoneFieldValue: null,
  biography: '',
}

const EditAdmin = ({ item, onCancel, t }: any) => {
  const [generalError, setGeneralError] = useState('')
  const { updateUser, error, editUserLoading } = useEditUserService()

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue, setValues } =
    useFormik({
      initialValues: formData,
      validationSchema: UserSchema,
      onSubmit(values) {
        const formValues: any = { ...values }
        delete formValues.phoneFieldValue
        formValues.gender = formValues.gender ? formValues.gender.value : null
        if (formValues.phoneFields && formValues.phoneFields.value) {
          formValues.phoneFields = {
            dialCode: formValues.phoneFields.value,
            code: formValues.phoneFields.label,
          }
        } else {
          formValues.phoneFields = null
        }
        updateUser(item.id, formValues, onCancel)

        // if (addUserLoading) return

        // const data = { ...values }

        // if (data.gender)
        //   data.gender = data.gender.value

        // data.group = []

        // data.requestPasswordChange = state.currentUser.settings?.askPassword

        // addUser(
        //   data,
        //   'ADMIN',
        //   companyData.companyData.id,
        //   () => {
        //     refetch()
        //     if (createAdmin === 'reset') {
        //       resetForm()
        //     } else {
        //       resetForm()
        //       setTabValue(1)
        //     }
        //   }
        // )
      },
    })

  useEffect(() => {
    const { gender, ...args }: any = formData
    const data: any = {}
    for (const key in args) {
      data[key] = item[key]
    }

    if (item.gender) {
      const userGender = genderOptions.find(
        (option: { label: string; value: string }) => option.value === item.gender,
      )
      data.gender = { label: userGender?.label, value: item.gender }
    } else {
      data.gender = null
    }

    data.birthDate = data.birthDate ? moment(parseInt(data.birthDate)).format() : null
    setValues({ ...data })
  }, [])

  useEffect(() => {
    setGeneralError('')
  }, [values.email])

  useEffect(() => {
    if (error) {
      setGeneralError(error)
    }
  }, [error])

  const onDateChange = (date: Date | null) => {
    const birthDate = date
    setFieldValue('birthDate', date)
  }

  useEffect(() => {
    setFieldValue('phoneFieldValue', (values.phoneFields && values.phoneFields.label) || null)
    values.phoneFieldValue = values.phoneFields ? values.phoneFields.value : null
  }, [values.phoneFields])

  return (
    <Container>
      <FormFields>
        <FormField width='48%'>
          <TextInput
            error={!!errors.firstName}
            errorMessage={errors.firstName}
            touched={touched.firstName}
            label={`${t('user_details.first_name')}*`}
            name='firstName'
            size='small'
            type='text'
            value={values.firstName}
            onChange={handleChange}
          />
        </FormField>

        <FormField width='48%'>
          <TextInput
            error={!!errors.lastName}
            errorMessage={errors.lastName}
            touched={touched.lastName}
            label={`${t('user_details.last_name')}*`}
            name='lastName'
            size='small'
            type='text'
            value={values.lastName}
            onChange={handleChange}
          />
        </FormField>

        <FormField width='48%'>
          <TextInput
            error={!!errors.email}
            errorMessage={errors.email}
            touched={touched.email}
            label={`${t('user_details.email')}*`}
            name='email'
            size='small'
            type='text'
            value={values.email}
            onChange={handleChange}
          />
        </FormField>

        <FormField width='48%'>
          <PhoneCountryCode
            label={`${t('user_details.phone')}`}
            selectLabel={t('form_fields.dial_code')}
            selectValue={values.phoneFields}
            selectChange={(e: any) => setFieldValue('phoneFields', e)}
            inputLabel={`${t('user_details.phone')}`}
            inputValue={values.phone}
            inputChange={handleChange}
            error={errors.phone || errors.phoneFields || generalError ? true : false}
            errorMessage={(errors.phone || errors.phoneFields || generalError || '') as string}
            touched={(touched.phone || touched.phoneFields) as boolean}
          />
        </FormField>

        <FormField width='48%' style={{ position: 'relative' }}>
          <SelectField
            placeholder={t('user_details.gender')}
            options={genderOptions}
            value={values.gender}
            onChange={(e: any) => setFieldValue('gender', e)}
            isClearable
          />
        </FormField>

        <FormField width='48%'>
          <DatePicker
            label={t('user_details.birthday')}
            touched={touched.birthDate}
            error={errors.birthDate ? true : false}
            errorMessage={errors.birthDate ? errors.birthDate : ''}
            name='birthDate'
            value={values.birthDate}
            onChange={onDateChange}
          />
        </FormField>
        <FormField width='48%'>
          <TextInput
            label={t('form_fields.job_title')}
            size='small'
            name='jobTitle'
            type='text'
            value={values.jobTitle}
            onChange={handleChange}
          />
        </FormField>
        <FormField width='48%'>
          <TextInput
            label={t('form_fields.location')}
            size='small'
            name='location'
            type='text'
            value={values.location}
            onChange={handleChange}
          />
        </FormField>

        <FormField>
          <TextInput
            error={!!errors.note}
            errorMessage={errors.note}
            touched={touched.note}
            label={t('user_details.biography')}
            size='small'
            name='note'
            type='text'
            multiline
            rows='3'
            value={values.biography}
            onChange={handleChange}
            fullWidth={true}
          />
        </FormField>

        <FormField>
          <TextInput
            error={!!errors.note}
            errorMessage={errors.note}
            touched={touched.note}
            label={t('form_fields.note')}
            size='small'
            name='note'
            type='text'
            multiline
            rows='3'
            value={values.note}
            onChange={handleChange}
            fullWidth={true}
          />
        </FormField>

        <FormField>
          <ButtonContainer>
            <ButtonItem style={{ marginRight: '10px' }}>
              <Button
                text={t('actions.cancel')}
                type='small'
                onClick={onCancel}
                background='#E0E1E2'
                textColor='#414141'
              />
            </ButtonItem>

            <ButtonItem>
              <Button
                text={t('actions.save')}
                type='small'
                color='secondary'
                onClick={handleSubmit}
                isDisabled={editUserLoading}
                background='#06C68F'
              />
            </ButtonItem>
          </ButtonContainer>
        </FormField>
      </FormFields>
    </Container>
  )
}

export default EditAdmin

const Container = styled.div`
  box-sizing: border-box;
  padding: 0px 30px;
`

const AvatarContainer = styled.div`
  width: 100%;
  height: 110px;
  outline: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .input-button {
    position: absolute;
    top: 10px;
    left: 9px;
    input {
      opacity: 0;
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      overflow: hidden;
    }
  }
  img {
    width: 96px;
    height: 96px;
  }
`
const InputLabel = styled.label`
  cursor: pointer;
  color: #fff;
  background: #06c68f;
  font-size: 11px;
  padding: 4px 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  span {
    margin-left: 7px;
  }
`
const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const InputWrapper = styled.div`
  margin-top: 30px;
`
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ButtonItem = styled.div``
