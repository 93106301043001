import { MutationTuple, useMutation } from '@apollo/client'

import { EXTRACT_GROUPS_WITH_COURSES } from 'gql/group/group.query'
import { IGroupCourse } from 'interfaces/groups'

interface IExtractGroupsWithCoursesServiceResponse {
  singleUploadStream: MutationTuple<
    IExtractGroupsWithCoursesResponse,
    IExtractGroupsWithCoursesVariables
  >[0]
}

interface IExtractGroupsWithCoursesResponse {
  extractGroupsWithCourses: IExtractGroupsWithCoursesMutationResponse
}

interface IExtractGroupsWithCoursesVariables {
  file: File
}

export interface IExtractGroupsWithCoursesMutationResponse {
  id: string
  name: string
  adminFirstName: string
  adminLastName: string
  adminEmail: string
  tutorFirstName: string
  tutorLastName: string
  tutorEmail: string
  courses: string[] | IGroupCourse[]
  error: string
}
export interface IExtractGroupsWithTestsMutationResponse {
  id: string
  name: string
  adminFirstName: string
  adminLastName: string
  adminEmail: string
  tutorFirstName: string
  tutorLastName: string
  tutorEmail: string
  tests: string[]
  error: string
}

const extractGroupsWithCoursesService = (): IExtractGroupsWithCoursesServiceResponse => {
  const [singleUploadStream] = useMutation<
    IExtractGroupsWithCoursesResponse,
    IExtractGroupsWithCoursesVariables
  >(EXTRACT_GROUPS_WITH_COURSES)

  return {
    singleUploadStream,
  }
}

export default extractGroupsWithCoursesService
