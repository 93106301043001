const wordFileExtensions = ['docx', 'doc', 'docm', 'dot', 'dotm', 'dotx']
const excelFileExtensions = ['xls', 'xlsb', 'xlsm', 'xlsx', 'xlt', 'xltm', 'xltx', 'xlw']
const pdfFileExtensions = ['application/pdf', 'pdf', 'ps', 'eps']

export const getFileType = (type: string): string => {
  let fileType = 'document'

  if (pdfFileExtensions.includes(type)) fileType = 'pdf'

  if (wordFileExtensions.includes(type)) fileType = 'word'

  if (excelFileExtensions.includes(type)) fileType = 'excel'

  return fileType
}
