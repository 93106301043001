import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSwal } from 'hooks/useSwal'
import { useFetchGroups } from 'hooks/group/useGroups'
import {
  IAddGroupInCourseDrawerProps,
  IGroupsFilterOptions,
  IGroup,
  IUseAddGroupInCourseDrawer,
} from 'hooks/course/addCourseDrawer/addGroupInCourseDrawer/addGroupInCourseDrawer.interface'
import {
  IFilter,
  IFilterQueryType,
  ISelectedAll,
  ISelectedItem,
} from 'components/common/FilterInterface/filter.interface'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { IGroupFilterDrawerOptions } from 'pages/group/FilterDrawer/filterDrawer.interface'
import { SelectChangeEvent } from '@mui/material'
import { debounce } from 'lodash'

const useAddGroupInCourseDrawer = (
  props: IAddGroupInCourseDrawerProps,
): IUseAddGroupInCourseDrawer => {
  const { t } = useTranslation()
  const { fireSwal } = useSwal()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [searchText, setSearchText] = useState('')
  const filter = {
    company: {
      type: LowerCaseFilterableFieldType.EXACT,
      value: props.companyId,
    },
    _id: {
      type: LowerCaseFilterableFieldType.ARRAY_NOT_IN,
      value: props.selectedGroup.map((group: IGroup) => group.groupId),
    },
  }

  const [filterOptions, setFilterOptions] = useState<IGroupsFilterOptions | null | IFilter>(filter)
  const [filterDrawer, setFilterDrawer] = useState<IGroupFilterDrawerOptions>({
    filterOptions: filter,
    filterValues: null,
    opened: false,
  })
  const { groups, refetch, loading } = useFetchGroups(
    currentPage,
    perPage,
    filterDrawer?.filterOptions as IFilterQueryType,
  )

  const [selectedItem, setSelectedItem] = useState<ISelectedItem>({})
  const selectedItemIds = Object.values(selectedItem).flat()
  const [selectAll, setSelectAll] = useState<ISelectedAll>({})

  const selectItem = (id: string): void => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = (): void => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = groups?.data.map(n => n.id) as string[]
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const onFormSubmit = (): void => {
    let updatedGroups = [] as IGroup[]
    const allSelectedItemsFromAllPages: string[] = Object.values(selectedItem).flat()
    if (allSelectedItemsFromAllPages?.length) {
      updatedGroups = allSelectedItemsFromAllPages.map(i => ({
        groupId: i,
        name: '',
      }))
    }

    props.selectGroup(updatedGroups)
    props.onClose(updatedGroups)
  }

  const debouncedSearch = debounce(newSearchText => {
    const filters = {
      ...filterOptions,
      name: { type: LowerCaseFilterableFieldType.MATCH, value: newSearchText },
    }
    refetch({
      filter: {
        ...filters,
      },
      currentPage: 1,
      perPage,
    })
    if (filters) setFilterOptions(filters as IFilter)
    setCurrentPage(1)
  }, 500)

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const searchText = e.target.value
    setSearchText(searchText)
    debouncedSearch(searchText)
  }

  const handleCloseDrawer = (): void => {
    if (!selectedItem[currentPage] || selectedItem[currentPage].length === 0) {
      props.onClose()
    } else if (selectedItem[currentPage]) {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        onConfirm: () => {
          props.onClose()
        },
        onClose: () => undefined,
        confirmText: t('popups.confirm_cancel'),
        cancelText: t('general.no'),
      })
    }
  }

  const handlePaginationClick = (_: ChangeEvent<unknown> | null, newPage: number): void => {
    refetch({
      filter: {
        company: {
          type: LowerCaseFilterableFieldType.EXACT,
          value: props.companyId,
        },
        name: { type: LowerCaseFilterableFieldType.MATCH, value: searchText },
      },
      currentPage,
      perPage,
    })
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>): void => {
    const newPerPage = parseInt(event.target.value as string, 10)

    props.refetch({
      filter: {
        company: {
          type: LowerCaseFilterableFieldType.EXACT,
          value: props.companyId,
        },
      },
      currentPage,
      perPage: newPerPage,
    })
    setPerPage(newPerPage)
    setSelectedItem({})
  }

  // const selectedGroups =
  //   groups &&
  //   groups.data &&
  //   groups.data.filter(
  //     (item: any) =>
  //       props.selectedGroup.findIndex((val: any) => item.id === val.groupId) ===
  //       -1,
  //   )

  const filterData = (filters: IGroupsFilterOptions): void => {
    refetch({
      filter: { ...filter, ...filters },
      currentPage,
      perPage: 0,
    })
    if (groups?.data) {
      setFilterDrawer({ ...filterDrawer, opened: false })
    }
  }

  return {
    handlePaginationClick,
    handleChangeRowsPerPage,
    handleSearchChange,
    handleCloseDrawer,
    onFormSubmit,
    selectAllItem,
    selectAll,
    currentPage,
    perPage,
    filter,
    filterData,
    refetch,
    t,
    searchText,
    filterDrawer,
    setFilterDrawer,
    selectedItemIds,
    selectItem,
    groups,
    loading,
  }
}

export default useAddGroupInCourseDrawer
