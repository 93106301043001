import styled from 'styled-components'

export const AvatarSettingContainer = styled.div`
  position: relative;
`

export const AvatarMenu = styled.div`
  position: absolute;
  top: 55px;
  right: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 7px;
  background: #f5f6fa;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.22);
  z-index: 1;
`

export const StyledAvatarWrapper = styled.div`
  width: 120px;
  height: 120px;
  position: relative;

  :hover {
    cursor: pointer;
  }
`
export const DeleteWrapper = styled.div`
  color: red;
  display: flex;
  align-items: center;
  gap: 5px;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

export const AvatarMenuItem = styled.div`
  padding: 5px 3px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  label {
    cursor: pointer;
    border: 1px;
  }
  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    overflow: hidden;
  }
`

export const ImageCropperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .actions {
    display: flex;
    gap: 10px;
    margin-top: 5px;
  }
`
export const EditIconContainer = styled.div`
  position: absolute;
  right: 0px;
  :hover {
    cursor: pointer;
  }
  :active {
    transform: scale(0.95);
  }
`
