import React, { ReactElement } from 'react'
import {
  Card,
  Header,
  IconWrapper,
  Subtitle,
  Title,
  Wrapper,
  DateWrapper,
} from './styled-components'
import CheckBox from 'components/common/Checkbox'
import { IGroupItemProps } from './GroupItem.interface'
import Tooltip from '@mui/material/Tooltip'
import { t } from 'i18next'

import { ReactComponent as Admins } from 'assets/group-default.svg'
import { ReactComponent as Courses } from 'assets/book-with-bookmark.svg'
import { ReactComponent as Students } from 'assets/education-default.svg'

const formatDate = (timestamp: number): string => {
  const date = new Date(timestamp)
  const formattedDate = date.toLocaleDateString('en-GB')
  return formattedDate
}

const GroupItem = ({ item, selectItem, isChecked }: IGroupItemProps): ReactElement => {
  const { name, admin, courses, createDate, numberOfStudents } = item
  const formattedCreateDate = formatDate(createDate as number)

  const courseNames = courses?.map((course: { name: string }) => course.name).join(', ')
  const adminNames = admin?.map((admin: { firstName: string }) => admin.firstName).join(', ')

  return (
    <Card isChecked={isChecked} onClick={(): void => selectItem(item.id as string)}>
      <Header>
        <Title> {name}</Title>
        <CheckBox onChange={(): void => selectItem(item.id as string)} checked={isChecked} />
      </Header>
      <Wrapper>
        <Subtitle> {t('general.admins')} </Subtitle>
        <Tooltip title={adminNames} placement='bottom'>
          <IconWrapper>
            <Admins />
            {admin?.length}
          </IconWrapper>
        </Tooltip>
      </Wrapper>
      <Wrapper>
        <Subtitle> {t('general.courses')} </Subtitle>
        <Tooltip title={courseNames} placement='bottom'>
          <IconWrapper>
            <Courses />
            {courses?.length}
          </IconWrapper>
        </Tooltip>
      </Wrapper>
      <Wrapper>
        <Subtitle>{t('general.students')} </Subtitle>
        <IconWrapper>
          <Students />
          {numberOfStudents}
        </IconWrapper>
      </Wrapper>
      <Wrapper>
        <Subtitle>Creation Date:</Subtitle>
        <DateWrapper>{formattedCreateDate} </DateWrapper>
      </Wrapper>
    </Card>
  )
}

export default GroupItem
