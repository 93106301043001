import React, { useState, ChangeEvent, FormEvent, useCallback, useEffect } from 'react'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'
import { setSelectedCompanyInLocalStorage } from 'helpers/localStorage'
import { useExportDataService } from 'hooks/helpers/useHelpersService'
import { ReactComponent as DeleteIcon } from 'assets/trash-can.svg'
import { ReactComponent as DownloadIcon } from 'assets/download.svg'
import { useUserValue } from 'context/UserContext'
import { useFetchCompanies, PER_PAGE as perPage } from 'hooks/companies/useCompanies'
import { useSwal } from 'hooks/useSwal'
import { useTranslation } from 'react-i18next'
import useDeleteCompanyService from 'hooks/companies/useCompanyDeleteService'
import { DrawerEventEmitter } from 'helpers/drawer'
import { ReactComponent as Company } from 'components/common/Button/icons/companies-breadcrumb.svg'
import { SET_SELECTED_COMPANY } from 'store/types'
import { useNavigate } from 'react-router-dom'
import { actionConfig } from 'pages/companies/gridConfig'

import { FilterObject, IUseCompanyLayout } from './useCompanyLayout.interface'
import { debounce } from 'lodash'
import { Action } from 'components/common/GridV2'
import { Tooltip } from '@mui/material'
import { IFormValues } from 'pages/companies/FilterCompanies/filterCompanies.interface'
import { checkIfFiltered } from 'utils/FilterIconHandler'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { filterOptions as initialValues } from 'pages/companies/staticData'

const useCompanyLayout = (): IUseCompanyLayout => {
  const { t } = useTranslation()
  const [state, dispatch] = useUserValue()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [filterQuery, setFilterQuery] = useState<IFilterQueryType>({})
  const [filterOptions, setFilterOptions] = useState<IFilterQueryType>(
    initialValues as IFilterQueryType,
  )
  const { companies, loading, refetch } = useFetchCompanies(currentPage, perPage, filterQuery)

  const { deleteCompany, deleteCompanyLoading } = useDeleteCompanyService()
  const { exportData } = useExportDataService()

  const [selectedItem, setSelectedItem] = useState<string[]>([])
  const [filterDrawerOpened, setFilterDrawerOpened] = useState(false)
  const [resetVisible, setResetVisible] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [isFiltered, setIsFiltered] = useState(false)
  const { fireSwal } = useSwal()

  const selectItem = (id: string): void => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
    }

    setSelectedItem(newSelected)
  }

  useEffect(() => {
    const isFiltered = checkIfFiltered(filterOptions)
    setIsFiltered(isFiltered)
  }, [filterOptions])

  const debouncedRefetch = useCallback(
    debounce((newFilterQuery: IFilterQueryType) => {
      refetch({
        currentPage: 1,
        perPage,
        filter: newFilterQuery,
      })
    }, 500),
    [refetch, perPage],
  ) // Dependencies

  const handleSearchSubmit = (e: FormEvent): void => {
    e.preventDefault()
    const formValue: IFilterQueryType = {
      name: { type: UpperCaseFilterableFieldType.MATCH, value: searchValue },
    }
    setCurrentPage(1)
    setFilterQuery({ ...filterQuery, ...formValue })
    refetch({
      currentPage: 1,
      perPage,
      filter: filterQuery,
    })
    setResetVisible(true)
  }

  // debounce is working,but it sends request twice
  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setSearchValue(e.currentTarget.value)
      const newFilterQuery: IFilterQueryType = {
        ...filterQuery,
        name: {
          type: UpperCaseFilterableFieldType.MATCH,
          value: e.currentTarget.value,
        },
      }
      setFilterQuery(newFilterQuery)
      debouncedRefetch(newFilterQuery)
    },
    [debouncedRefetch, filterQuery],
  )

  const handleDeleteAllClick = (): void => {
    fireSwal({
      title: `${t('popups.delete_many')} ${t('navigation.companies')}?`,
      onConfirm: () => confirmDelete(selectedItem),
      confirmText: t('popups.confirm_delete'),
    })
  }

  const handleEditClick = (companyId: string): void => {
    DrawerEventEmitter.emit('openDrawer', 'companiesEdit', true, {
      id: companyId,
      data: {
        refetchCompany: () => {
          refetch()
        },
      },
    })
  }

  const confirmDelete = async (ids: string[]): Promise<void> => {
    deleteCompany(ids, refetch)
    setSelectedItem([])
    dispatch({
      type: SET_SELECTED_COMPANY,
      payload: null,
    })
    setSelectedCompanyInLocalStorage(null)
  }

  const handleDeleteClick = (id: string): void => {
    const params = {
      title: `${t('popups.delete_single')}   ${t('general.company')}?`,
      onConfirm: (): void => {
        confirmDelete([id])
      },
      confirmText: t('popups.confirm_delete'),
    }
    fireSwal(params)
  }

  const handleFiltersClick = (): void => {
    setFilterDrawerOpened(true)
  }

  const filter = (filters: IFormValues | FilterObject): void => {
    refetch({
      currentPage: 1,
      perPage,
      filter: filters as FilterObject,
    })
    if (companies) {
      setFilterDrawerOpened(false)
      setResetVisible(true)
    }
  }

  const handleResetClick = (): void => {
    refetch({
      currentPage: 1,
      perPage,
      filter: {},
    })
    setCurrentPage(1)
    setFilterQuery({})
    setSearchValue('')
    setResetVisible(false)
    setIsFiltered(false)
    setFilterOptions(initialValues as IFilterQueryType)
  }

  const handleExportData = (): void => {
    if (selectedItem.length > 0) {
      exportData('company', selectedItem, null, (link: string) => {
        window.open(link, '_blank')
      })
    }
  }

  const handleCloseFilterDrawer = (action: string): void => {
    if (action === 'reset') {
      handleResetClick()
      setFilterDrawerOpened(false)
    } else {
      setFilterDrawerOpened(false)
    }
  }

  const handlePaginationClick = (_: ChangeEvent<unknown>, value: number): void => {
    if (value !== currentPage) {
      refetch({
        currentPage: value,
        perPage,
        filter: filterQuery,
      })
      setCurrentPage(value)
    }
  }

  const actions = actionConfig(handleEditClick, handleDeleteClick, state.userPermission, t)

  let toolbarAction = [
    {
      id: 0,
      color: 'secondary',
      disabled: selectedItem.length < 1,
      onClick: (): void => handleDeleteAllClick(),
      component: (
        <Action $hoverColor='#EA382A'>
          <Tooltip title={t('actions.delete')} arrow>
            <DeleteIcon />
          </Tooltip>
        </Action>
      ),
    },
    {
      id: 2,
      color: 'primary',
      disabled: selectedItem.length < 1,
      onClick: (): void => handleExportData(),
      component: (
        <Action $hoverColor='#3892F4'>
          <Tooltip title={t('actions.export')} arrow>
            <DownloadIcon />
          </Tooltip>
        </Action>
      ),
    },
  ]

  const breadCrumbData = [
    {
      label: t('navigation.companies'),
      icon: <Company />,
    },
  ]

  const handleOpenAddCompanyDrawer = (): void => {
    DrawerEventEmitter.emit('openDrawer', 'companiesAdd', true)
  }

  return {
    t,
    navigate,
    handleOpenAddCompanyDrawer,
    selectedItem,
    toolbarAction,
    resetVisible,
    handleResetClick,
    handleFiltersClick,
    handleSearchSubmit,
    searchValue,
    handleSearchChange,
    loading,
    deleteCompanyLoading,
    companies,
    selectItem,
    actions,
    handlePaginationClick,
    breadCrumbData,
    filterDrawerOpened,
    filter,
    handleCloseFilterDrawer,
    setCurrentPage,
    isFiltered,
    filterOptions,
    setFilterOptions,
  }
}

export default useCompanyLayout
