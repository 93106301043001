import React, { ReactElement } from 'react'
import {
  Container,
  flexEndStyles,
  FullHeightContainer,
  fullHeightStyles,
} from './styled-components'
import TablePagination from 'components/common/Pagination/TablePagination'

import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import { Button } from 'components/common/Button'
import { DrawerHeader, DrawerTitle, DrawerContent } from 'components/DrawerLayout/styled-components'
import { FormGroupWrapper, FormFooter, FormButtons } from 'components/common/Form'
import Grid from 'components/common/GridV2'

import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import IconButton from '@mui/material/IconButton'
import TextInput from 'components/common/TextInput/TextInput'
import useAddStudentInGroupDrawer from 'hooks/students/addStudentInGroupDrawer/useAddStudentInGroupDrawer'
import _ from 'lodash'
import { IAddStudentInGroupDrawerProps } from 'hooks/students/addStudentInGroupDrawer/useAddStudentInGroupDrawer.interface'

const AddStudentsInGroupDrawer = (props: IAddStudentInGroupDrawerProps): ReactElement => {
  const {
    t,
    handleDrawerClose,
    searchValue,
    handleSearchChange,
    loading,
    users,
    config,
    selectAllItem,
    selectedItem,
    currentPage,
    perPage,
    handlePaginationClick,
    handleChangeRowsPerPage,
    onFormSubmit,
    addStudentsLoading,
    selectItem,
  } = useAddStudentInGroupDrawer(props)
  return (
    <>
      <DrawerHeader>
        <DrawerTitle>
          {t('actions.add')} {t('general.students')}
        </DrawerTitle>
        <IconButton onClick={handleDrawerClose}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>

      <DrawerContent headerHeight={141}>
        <FullHeightContainer>
          <FormGroupWrapper style={fullHeightStyles}>
            <Container>
              <Toolbar styles={flexEndStyles}>
                <ToolbarItem>
                  <TextInput
                    label={t('general.search_placeholder')}
                    type='text'
                    size='small'
                    value={searchValue}
                    onChange={handleSearchChange}
                    disabled={loading}
                    $iconInStart
                    icon={
                      <IconButton>
                        <SearchRoundedIcon />
                      </IconButton>
                    }
                  />
                </ToolbarItem>
              </Toolbar>

              <div>
                <Grid
                  data={(users && users.data) || []}
                  config={config}
                  selectItem={selectItem}
                  selectAllItem={selectAllItem}
                  selected={_.size(selectedItem[currentPage])}
                  selectedItems={selectedItem[currentPage] || []}
                  openInExternalTab={true}
                />
                {users && users.totalCount > 0 && (
                  <TablePagination
                    currentPage={users.currentPage || 0}
                    rowsPerPage={perPage}
                    count={users.totalCount || 0}
                    handleChangePage={handlePaginationClick}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                )}
              </div>
            </Container>
          </FormGroupWrapper>
        </FullHeightContainer>
      </DrawerContent>

      <FormFooter>
        <FormButtons>
          <Button
            text={t('actions.cancel')}
            type='small'
            onClick={handleDrawerClose}
            background='#E0E1E2'
            textColor='#414141'
          />

          <Button
            text={t('actions.save')}
            color='secondary'
            type='small'
            onClick={onFormSubmit}
            background='#06C68F'
            isDisabled={addStudentsLoading}
          />
        </FormButtons>
      </FormFooter>
    </>
  )
}

export default AddStudentsInGroupDrawer
