import React, { useState, useEffect, ReactElement } from 'react'
import { CheckboxContainer, ErrorMessage } from './styled-components'

import Checkbox from '@mui/material/Checkbox'
import { ICheckboxProps } from './checkbox.interface'

export default ({
  indeterminate,
  checked,
  onChange,
  inputProps,
  value,
  name,
  color,
  readOnly,
  error,
  disabled,
}: ICheckboxProps): ReactElement => {
  // force re-render when checked prop changes
  const [checkedState, setCheckedState] = useState(false)
  useEffect(() => {
    setCheckedState(checked as boolean)
  }, [checked])

  return (
    <CheckboxContainer onClick={(e): void => e.stopPropagation()}>
      <Checkbox
        indeterminate={indeterminate}
        checked={checkedState}
        onChange={onChange}
        inputProps={inputProps}
        name={name}
        value={value}
        style={
          (readOnly && {
            pointerEvents: 'none',
            cursor: 'default',
          }) ||
          {}
        }
        disabled={disabled}
        color={color}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </CheckboxContainer>
  )
}
