import { useQuery } from '@apollo/client'
import { GET_TASKS_QUERY } from 'gql/tasks.query'
import { IFetchMoreVariables, ITask, ITaskFilter } from './getTasksService.interface'

export const useFetchTasks = (
  companyId: string,
  filter: ITaskFilter,
  currentPage = 1,
  perPage: number = 10,
): {
  tasks: ITask[] | null
  totalPages: number
  loading: boolean
  error: string
  refetch: () => void
  fetchMore: (variables?: IFetchMoreVariables) => void
} => {
  const { data, loading, error, refetch, fetchMore } = useQuery(GET_TASKS_QUERY, {
    variables: {
      filter: { ...filter },
      currentPage,
      perPage,
      companyId,
    },
    skip: !companyId,
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''
  const { tasks = [], totalPages = 1 } = (data && data.getTasksByCompanyId) || {}

  return {
    tasks,
    totalPages,
    loading,
    error: errorMessage,
    refetch,
    fetchMore: variables => fetchMore({ variables }),
  }
}
