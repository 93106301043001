import { useState } from 'react'
import deleteModuleService from 'services/modules/deleteModuleService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import { IModule } from 'pages/modules/ModulesList.interface'

export const useDeleteModuleService = (): {
  deleteModule: (ids: string[]) => Promise<void>
  addModuleLoading: boolean
  deletedModule: IModule[]
  error: string
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [deleted, setDeleted] = useState<IModule[]>([])
  const [error, setError] = useState('')
  const { deleteModuleMutation, loading } = deleteModuleService()

  const deleteModule = async (ids: string[]): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }
    const { data, errors } = await deleteModuleMutation({
      variables: {
        ids,
      },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
      setError(message)
    } else if (data) {
      setDeleted(data.deleteModule)
      message = `${t('general.module')} ${t('messages.deleted')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    deleteModule,
    addModuleLoading: loading,
    deletedModule: deleted,
    error,
  }
}
