import { useLazyQuery } from '@apollo/client'
import { GET_USERS } from 'gql/users.query'
import { IFilterVariables, IGetUsersByRoleReturn } from './getUsersByRole.interface'

export const getUsersByRole = ({ variables }: IFilterVariables): IGetUsersByRoleReturn => {
  const [fetchUsers, { data, loading }] = useLazyQuery(GET_USERS, {
    variables,
  })

  return {
    fetchUsers,
    data,
    loading,
  }
}
