import { useState } from 'react'
import updateSkillTestVideoService, {
  ISkillTestVideoCacheParams,
} from 'services/modules/updateSkillTestVideo'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { ISkillTestVideo } from 'pages/modules/ModuleDetail/SkillTestVideos/SkillTestVideos.interface'

const useUpdateSkillTestVideoService = (
  params: ISkillTestVideoCacheParams,
): {
  updateSkillTestVideo: (
    id: string,
    input: ISkillTestVideo,
    onSuccess?: () => void,
  ) => Promise<void>
  updateSkillTestVideoLoading: boolean
  error: string
} => {
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { updateSkillTestVideoMutation } = updateSkillTestVideoService(params)

  const updateSkillTestVideo = async (
    id: string,
    input: ISkillTestVideo,
    onSuccess?: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    try {
      const { errors } = await updateSkillTestVideoMutation({
        variables: {
          id,
          input,
        },
      })
      if (errors) {
        message = errors[0].message
        if (message === 'User already registered') {
          message = message
          setError(message)
          return
        }
      } else {
        message = 'Skill Test Video has been updated'
        variant = 'success'

        if (onSuccess) {
          onSuccess()
        }
      }
      setSnackbar({ message, variant })
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  return {
    updateSkillTestVideo,
    updateSkillTestVideoLoading: loading,
    error,
  }
}

export default useUpdateSkillTestVideoService
