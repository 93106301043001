import gql from 'graphql-tag'

export const CHANGE_ORDER = gql`
  mutation ($draggedId: String!, $droppedId: String!) {
    changeCoursesOrder(draggedId: $draggedId, droppedId: $droppedId)
  }
`
export const CREATE_COURSE = gql`
  mutation CreateCourse($input: CourseInput, $groupIds: [String]) {
    createCourse(input: $input, groupIds: $groupIds) {
      id
      name
      description
      editorText
      level
      coursePrivacy
      coachType
      price
      completeCriteria
      accredibleGroupId
      originalId
      isBought
      currency
      certificate {
        url
      }
      avatar {
        name
        fileType
        link
      }
      state
      subtitle {
        name
        fileType
        link
      }
      skills {
        label
        id
      }
      coaches {
        firstName
        lastName
        email
        avatar
      }
      groups {
        groupId
        name
      }
      numberOfGroups
      numberOfStudents
      numberOfModules
      numberOfLessons
      duration
      video {
        id
        name
        thumbnail
        title
        links {
          url
          fileType
          converted
        }
      }
      companyId {
        id
        name
      }
      finished
      contentLocked
      certificateIncluded
    }
  }
`

export const GET_COURSES_OPTIONS = gql`
  query GetAllCourseOptions($perPage: Int, $filter: CourseFilterInput, $currentPage: Int) {
    getAllCourseOptions(perPage: $perPage, filter: $filter, currentPage: $currentPage) {
      currentPage
      data {
        id
        originalId
        name
      }
      totalPages
    }
  }
`

export const GET_ALL_COURSE = gql`
  query GetAllCourse($filter: CourseFilterInput, $currentPage: Int, $perPage: Int) {
    getAllCourse(filter: $filter, currentPage: $currentPage, perPage: $perPage) {
      currentPage
      totalPages
      data {
        id
        name
        description
        editorText
        level
        coursePrivacy
        coachType
        price
        accredibleGroupId
        originalId
        isBought
        currency
        certificate {
          url
        }
        avatar {
          name
          fileType
          link
        }
        state
        subtitle {
          name
          fileType
          link
        }
        skills {
          label
          id
        }
        coaches {
          firstName
          lastName
          email
          id
          userId
          avatar
        }
        groups {
          groupId
          name
        }
        numberOfGroups
        numberOfStudents
        numberOfSkillTestVideos
        numberOfNotes
        numberOfFilesOpened
        numberOfModules
        numberOfLessons
        numberOfLikes
        numberOfPosts
        numberOfComments
        duration
        video {
          id
          name
          thumbnail
          title
          links {
            url
            fileType
            converted
          }
        }
        companyId {
          id
          name
        }
        finished
      }
    }
  }
`

export const GET_COURSES_ANALYTICS = gql`
  query GetCoursesAnalytics($filter: CoursesAnalyticsFilterInput) {
    getCoursesAnalytics(filter: $filter) {
      _id
      name
      numberOfComments
      numberOfLikes
      numberOfNotes
      numberOfPosts
      numberOfSkillTestVideos
      numberOfStudents
      state
      groupsCount
      studentsCountWhoCompletedCourse
      passedQuizzesCount
    }
  }
`

export const CHECK_COURSE_NAME = gql`
  mutation CheckCourseName($name: String!) {
    checkName(name: $name)
  }
`

export const GET_COURSE = gql`
  query GetCourse($courseId: String!) {
    getCourseById(courseId: $courseId) {
      id
      name
      description
      editorText
      level
      coursePrivacy
      duration
      coachType
      price
      completeCriteria
      currency
      isBought
      originalId
      certificate {
        url
      }
      price
      currency
      avatar {
        name
        fileType
        link
      }
      state
      subtitle {
        name
        fileType
        link
      }
      skills {
        label
        id
      }
      coaches {
        firstName
        lastName
        email
        id
        userId
        avatar
      }
      groups {
        groupId
        name
      }
      numberOfGroups
      numberOfStudents
      numberOfModules
      numberOfLessons
      duration
      video {
        id
        title
        name
        thumbnail
        links {
          url
          fileType
          converted
        }
      }
      companyId {
        id
        name
      }
      finished
      contentLocked
      isLockedByQuiz
      isLockedBySkillTestVideo
      certificateIncluded
      isAIAssistantEnabled
      assistantId
    }
  }
`

export const EDIT_COURSE = gql`
  mutation EditCourse($id: String!, $input: CourseInput) {
    editCourse(id: $id, input: $input) {
      id
      name
      description
      editorText
      level
      coursePrivacy
      price
      completeCriteria
      currency
      coachType
      price
      currency
      certificate {
        url
      }
      avatar {
        name
        fileType
        link
      }
      state
      subtitle {
        name
        fileType
        link
      }
      skills {
        label
        id
      }
      coaches {
        firstName
        lastName
        email
        id
        userId
        avatar
      }
      groups {
        groupId
        name
      }
      numberOfGroups
      numberOfStudents
      numberOfModules
      numberOfLessons
      duration
      video {
        id
        name
        title
        thumbnail
        links {
          url
          fileType
          converted
        }
      }
      companyId {
        id
        name
      }
      finished
      contentLocked
      isLockedByQuiz
      isLockedBySkillTestVideo
      certificateIncluded
      isSubtitlesUpdated
      isVideoUpdated
    }
  }
`

export const DELETE_COURSE = gql`
  mutation DeleteCourse($ids: [String]) {
    deleteCourse(ids: $ids) {
      message
    }
  }
`

export const COURSE_PUBLISHED_UN_PUBLISHED = gql`
  mutation CoursePublished($courseId: [String], $action: String) {
    coursePublished(courseId: $courseId, action: $action) {
      id
      name
      description
      level
      coursePrivacy
      coachType
      price
      currency
      certificate {
        url
      }
      avatar {
        name
        fileType
        link
      }
      state
      subtitle {
        name
        fileType
        link
      }
      coaches {
        firstName
        lastName
        email
        id
        avatar
      }
      groups {
        groupId
        name
      }
      numberOfGroups
      numberOfStudents
      numberOfModules
      numberOfLessons
      duration
      video {
        id
        name
        title
        thumbnail
        links {
          url
          fileType
          converted
        }
      }
      companyId {
        id
        name
      }
    }
  }
`
export const DELETE_COURSE_MODULE = gql`
  mutation DeleteCourseModule($courseId: String, $moduleIds: [String]) {
    deleteModuleInCourse(courseId: $courseId, moduleIds: $moduleIds) {
      id
      name
      coverImage {
        name
        fileType
        link
      }
      description
      editorText
      duration
      createDate
      numberOfTopics
      numberOfLessons
      duration
      # price
      # currency
      subtitle {
        name
        fileType
        link
      }
      topics {
        id
        name
        # lessons {
        #   id
        #   name
        # }
      }
      attachment {
        name
        fileType
        link
      }
      video {
        name
        thumbnail
        links {
          url
          fileType
          converted
        }
      }
      # companyId
    }
  }
`

export const GET_COURSE_BY_ORIGINAL_ID = gql`
  query GetCourseByOriginalId($courseId: String, $companyId: String) {
    getCourseByOriginalId(courseId: $courseId, companyId: $companyId) {
      id
      name
      description
      editorText
      level
      coursePrivacy
      duration
      coachType
      price
      currency
      isBought
      originalId
      certificate {
        url
      }
      price
      currency
      avatar {
        name
        fileType
        link
      }
      state
      subtitle {
        name
        fileType
        link
      }
      skills {
        label
        id
      }
      coaches {
        firstName
        lastName
        email
        id
        userId
        avatar
      }
      groups {
        groupId
        name
      }
      numberOfGroups
      numberOfStudents
      numberOfModules
      numberOfLessons
      duration
      video {
        id
        title
        name
        thumbnail
        links {
          url
          fileType
          converted
        }
      }
      # companyId {
      #   id
      #   name
      # }
      finished
    }
  }
`
export const CHANGE_COURSE_STEP = gql`
  mutation ChangeCourseStep($courseId: String, $step: Float) {
    changeCourseStep(courseId: $courseId, step: $step) {
      id
      name
      description
      editorText
      level
      coursePrivacy
      duration
      coachType
      price
      currency
      isBought
      originalId
      certificate {
        url
      }
      price
      currency
      avatar {
        name
        fileType
        link
      }
      state
      subtitle {
        name
        fileType
        link
      }
      skills {
        label
        id
      }
      # coaches {
      #   firstName
      #   lastName
      #   email
      #   id
      #   userId
      #   avatar
      # }
      groups {
        groupId
        name
      }
      # numberOfGroups
      # numberOfStudents
      # numberOfModules
      # numberOfLessons
      # duration
      # video {
      #   id
      #   title
      #   name
      #   thumbnail
      #   links {
      #     url
      #     fileType
      #     converted
      #   }
      # }
      # companyId {
      #   id
      #   name
      # }
      finished
    }
  }
`

export const TOGGLE_AI_ASSISTANT = gql`
  mutation ToggleAIAssistant($courseId: String!, $isAIAssistantEnabled: Boolean!) {
    toggleAIAssistant(courseId: $courseId, isAIAssistantEnabled: $isAIAssistantEnabled)
  }
`

export const RE_TRAIN_AI_ASSISTANT = gql`
  mutation ReTrainAIAssistant($assistantId: String!) {
    reTrainAIAssistant(assistantId: $assistantId)
  }
`

export const UPDATE_USER_ASSISTANT = gql`
  mutation UpdateUserAssistant($assistantId: String!) {
    updateUserAssistant(assistantId: $assistantId)
  }
`
