import { useMutation, MutationTuple, ApolloError } from '@apollo/client'
import { GET_FLASHCARDS, ADD_FLASHCARD } from 'gql/flashcards.query'
import { IFlashCard } from 'hooks/modules/FlashCardsLayout/useModuleDetailContent.interface'
import { updateCache } from 'services/updateCache'

interface IAddFlashCardResponse {
  addFlashCard: IFlashCard
}

interface IAddFlashCardVariables {
  moduleId: string
  input: IFlashCard
}

interface IAddFlashCardServiceResponse {
  addFlashCardMutation: MutationTuple<IAddFlashCardResponse, IAddFlashCardVariables>[0]
  loading: boolean
  error?: ApolloError
}

export interface IAddFlashCardServiceParams {
  moduleId: string
  skip: number
  limit: number
}

const addFlashCardService = (params: IAddFlashCardServiceParams): IAddFlashCardServiceResponse => {
  const [addFlashCardMutation, { loading, error }] = useMutation<
    IAddFlashCardResponse,
    IAddFlashCardVariables
  >(ADD_FLASHCARD, {
    update(cache, { data }) {
      if (data?.addFlashCard) {
        const document = data.addFlashCard
        updateCache({
          objectName: 'flashCards',
          query: GET_FLASHCARDS,
          cache,
          document,
          action: 'addToLast',
          params,
        })
      }
    },
  })

  return {
    addFlashCardMutation,
    loading,
    error,
  }
}

export default addFlashCardService
