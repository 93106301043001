import styled from 'styled-components'

export const FormGroupWrapperStyles = { padding: '0 20px' }
export const ToolbarStyles = { justifyContent: 'space-between' }
export const ToolbarItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

// gridConfig

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: circle;
`
export const ListItem = styled.li`
  font-size: 12px;
`
export const ErrorContainer = styled.div<{ error: boolean }>`
  cursor: pointer;
  color: ${({ error }: { error: boolean }): string => (error ? 'red' : 'rgba(0, 0, 0, 0.27)')};
`

export const ErrorMessage = styled.div`
  color: #f44336;
  width: 90%;
  padding: 10px 0 20px 0;
`

export const TemplateButton = styled.div`
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-bottom: 20px;
  div {
    color: #555;
    padding: 0;
    line-height: 40px;
    width: 300px;
    margin: auto;
    display: block;
    border: 2px solid #555;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    &:focus {
      color: lighten(#555, 20%);
      border-color: lighten(#555, 20%);
    }
    display: flex;
    justify-content: center;
    align-items: center;
    .rotate {
      transform: rotate(180deg);
    }
    span {
      padding: 0 10px;
    }
  }
`

// Step 2

export const StyledInput = styled.input`
  cursor: pointer;
  height: 100%;
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
`
