import { useQuery } from '@apollo/client'
import { GET_GROUP_BY_ID } from 'gql/group/group.query'
import { IGetGroupByIdServiceReturn } from './getGroupById.interface'

export const getGroupByIdService = (id?: string): IGetGroupByIdServiceReturn => {
  const { data, refetch, loading } = useQuery(GET_GROUP_BY_ID, {
    variables: {
      id,
    },
  })

  return {
    data,
    refetch,
    loading,
  }
}
