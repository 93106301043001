import React, { ReactElement, useState } from 'react'
import Popover from '@mui/material/Popover'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import { Container, ActionsContent, StyledIconButton } from './styled-components'
import { IGridActionMenuProps } from 'components/common/GridV2/GridActionMenu/gridActionMenu.interface'

const GridActionMenu = (props: IGridActionMenuProps): ReactElement => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: React.BaseSyntheticEvent): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <div>
      {props.actionConfig.length > 0 && (
        <Container onClick={(e): void => e.stopPropagation()}>
          <div onClick={handleClick}>
            {props.button ? (
              props.button
            ) : (
              <StyledIconButton className='Grid'>
                {props.icon ? props.icon : <MoreVertRoundedIcon />}
              </StyledIconButton>
            )}
          </div>

          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <ActionsContent>
              {props.actionConfig.map((i, index: number) => (
                <div key={index} onClick={(): void => setAnchorEl(null)}>
                  {i.render(props.row)}
                </div>
              ))}
            </ActionsContent>
          </Popover>
        </Container>
      )}
    </div>
  )
}

export default GridActionMenu
