import React from 'react'
import { Wrapper, Container, EmptyContainer } from './styled-components'
import { ISkillTestVideo, SkillTestVideosProps } from './SkillTestVideos.interface'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import Loader from 'components/common/Loader'
import { Button } from 'components/common/Button'
import TextInput from 'components/common/TextInput/TextInput'
import { Grid } from 'components/common/Cards/styled-components'
import SkillTestVideoItem from './SkillTestVideoItem'
import useSkillTestVideos from 'hooks/modules/SkillTestVideos/useSkillTestVideos'
import defaultTips from './defaultTips'

const SkillTestVideos = ({
  moduleId,
  moduleName,
  skillTestVideoTemplate,
  canModify,
}: SkillTestVideosProps): React.JSX.Element => {
  const {
    handleCreateSkillTestVideoClick,
    handleSearchSubmit,
    searchValue,
    handleSearchChange,
    defaultOverview,
    loading,
    skillTestVideos,
    filteredSkillTestVideos,
    actions,
  } = useSkillTestVideos({
    moduleId,
    moduleName,
    skillTestVideoTemplate,
    canModify,
  })

  return (
    <div style={{ paddingTop: '20px' }}>
      {skillTestVideoTemplate === 'custom' && (
        <Wrapper>
          <div>
            <Button
              text={'Create Skill Test Video'}
              color='secondary'
              onClick={(): void => handleCreateSkillTestVideoClick()}
              icon={<AddCircleRoundedIcon />}
              background='#06C68F'
            />
          </div>
          <form style={{ marginLeft: 10, marginRight: 10 }} onSubmit={handleSearchSubmit}>
            <TextInput
              label='Search...'
              type='text'
              size='small'
              value={searchValue}
              onChange={handleSearchChange}
              icon={
                <IconButton type='submit'>
                  <SearchRoundedIcon />
                </IconButton>
              }
            />
          </form>
        </Wrapper>
      )}
      <Container>
        {skillTestVideoTemplate === 'default' && (
          <SkillTestVideoItem
            skillTestVideo={{
              id: moduleId as string,
              title: moduleName as string,
              description: defaultOverview,
              tips: defaultTips,
            }}
            actions={null}
            width='50%'
            isDefault
          />
        )}
        {loading && skillTestVideoTemplate === 'custom' && <Loader withBackground={false} />}
        {!loading && skillTestVideoTemplate === 'custom' && skillTestVideos.length === 0 && (
          <EmptyContainer>No Skill Test Video Templates found</EmptyContainer>
        )}
        {!loading && skillTestVideoTemplate === 'custom' && (
          <Grid responsiveCols className='card' cols={4}>
            {filteredSkillTestVideos.map((item: ISkillTestVideo) => (
              <SkillTestVideoItem key={item.id} skillTestVideo={item} actions={actions} />
            ))}
          </Grid>
        )}
      </Container>
    </div>
  )
}

export default SkillTestVideos
