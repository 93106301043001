import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { updateCache } from 'services/updateCache'
import {
  GET_SKILL_TEST_VIDEOS_TEMPLATES,
  UPDATE_SKILL_TEST_VIDEO_TEMPLATE,
} from 'gql/skillTestVideos.query'
import { ISkillTestVideo } from 'pages/modules/ModuleDetail/SkillTestVideos/SkillTestVideos.interface'

interface IUpdateSkillTestVideoServiceResponse {
  updateSkillTestVideoMutation: MutationTuple<
    IUpdateSkillTestVideoResponse,
    IUpdateSkillTestVideoParams
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IUpdateSkillTestVideoResponse {
  updateSkillTestVideoTemplate: ISkillTestVideo
}

interface IUpdateSkillTestVideoParams {
  id: string
  input: ISkillTestVideo
}

export interface ISkillTestVideoCacheParams {
  moduleId: string
}

const updateSkillTestVideoService = (
  params: ISkillTestVideoCacheParams,
): IUpdateSkillTestVideoServiceResponse => {
  const [updateSkillTestVideoMutation, { loading, error }] = useMutation<
    IUpdateSkillTestVideoResponse,
    IUpdateSkillTestVideoParams
  >(UPDATE_SKILL_TEST_VIDEO_TEMPLATE, {
    update(cache, { data }) {
      if (data?.updateSkillTestVideoTemplate) {
        const document = data.updateSkillTestVideoTemplate
        updateCache({
          objectName: 'getSkillTestVideosTemplates',
          query: GET_SKILL_TEST_VIDEOS_TEMPLATES,
          cache,
          document,
          action: 'update',
          params,
        })
      }
    },
  })

  return {
    updateSkillTestVideoMutation,
    loading,
    error,
  }
}

export default updateSkillTestVideoService
