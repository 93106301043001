import React, { ChangeEvent, useState } from 'react'
import { Container, UploadedContent } from './styled-components'
import axios, { AxiosResponse } from 'axios'
import Uploader from 'components/common/FileUploaderV2/Uploader'
import Modal from 'components/common/Modal'
import ImageCropper from 'components/common/ImageCropper'
import { useTranslation } from 'react-i18next'
import {
  useGenerateUploadVideoLinks,
  useUploadImage,
  useUploadFile,
  useUploadAudioService,
} from 'hooks/helpers/useHelpersService'
import { StyledUploadLabel } from 'pages/tests/editTestDrawer/styled-components'
import { FileTypes } from 'components/common/FileUploaderV2/uploader.interface'
import { CropperResultType, IAttachment } from 'interfaces/common'
import { IGenerateUploadVideoLinksMutationResponse } from 'services/helperService/helperService'
import { IVideo } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'
const constants = {
  image: 'image/jpeg|image/jpg|image/png',
  video:
    'video/x-msvideo|video/mpeg|video/ogg|video/mp4|video/x-flv|video/quicktime|video/x-msvideo|video/x-ms-wmv',
  audio: 'audio/mpeg|audio/mpeg|audio/aac|audio/mp4a-latm',
  file: 'application/pdf|application/msword|application/plain|text/plain|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/vnd.ms-excel|application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

interface IUploadContentProps {
  setUploadedFiles: (files: IAttachment[]) => void
  uploadedFiles: IAttachment[]
  currentModule?: string
}

const UploadContent = (props: IUploadContentProps): React.JSX.Element => {
  const { t } = useTranslation()
  const [uploadingProgress, setUploadingProgress] = useState(0)
  const [fileType, setFileType] = useState<FileTypes>(FileTypes.VIDEO)
  const [cropperOpen, setCropperOpen] = useState(false)
  const [cropperImage, setCropperImage] = useState<{
    name: string
    file: string
  } | null>(null)

  const { generateUploadVideoLinks } = useGenerateUploadVideoLinks()
  const { uploadFile, fileLoading } = useUploadFile()
  const { uploadAudio } = useUploadAudioService()
  const { uploadImage, imageLoading } = useUploadImage()

  const uploadVideoService = (url: string, file: File): Promise<AxiosResponse> => {
    return axios.put(url, file, {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: (event: ProgressEvent) => {
        const { total, loaded } = event
        setUploadingProgress((loaded / total) * 100)
      },
    })
  }

  const uploadContentFile = (file: File): void => {
    uploadFile(file, 'lesson/content', (link: string) => {
      const uploaded = [
        {
          type: 'documents',
          name: file.name,
          fileType: file.name.split('.').slice(-1)[0],
          link,
        },
      ]

      props.setUploadedFiles([...props.uploadedFiles, ...uploaded] as IAttachment[])
    })
  }

  const uploadVideoFile = (file: File): void => {
    const videos = [{ fileName: file.name, type: file.type }] as IVideo[]
    const entity = props.currentModule ? 'module_question' : 'question'
    generateUploadVideoLinks(
      videos,
      entity,
      (arg: string) => uploadVideoService(arg, file),
      (args: IGenerateUploadVideoLinksMutationResponse) => {
        const uploaded = [
          {
            ...args.video,
            type: 'videos',
            title: args.video.name,
          },
        ]
        props.setUploadedFiles([...props.uploadedFiles, ...uploaded] as IAttachment[])
        setUploadingProgress(0)
      },
    )
  }

  const uploadAudioFile = (file: File): void => {
    const audioFile = { fileName: file.name, type: file.type }
    uploadAudio(
      audioFile,
      (arg: string) => uploadVideoService(arg, file),
      (fileId: string) => {
        const uploaded = [
          {
            id: fileId,
            type: 'audios',
            name: file.name,
            fileType: file.name.split('.').pop(),
          },
        ]

        props.setUploadedFiles([...props.uploadedFiles, ...uploaded] as IAttachment[])
        setUploadingProgress(0)
      },
    )
  }

  const uploadImageFile = (field: string, croppedFile: CropperResultType): void => {
    uploadImage(croppedFile as string, `questions/${field}`, (link: string) => {
      const uploaded = [
        {
          type: field,
          name: Date.now().toString(),
          fileType: 'png',
          link,
        },
      ]

      props.setUploadedFiles([...props.uploadedFiles, ...uploaded] as IAttachment[])
    })
  }

  const b64Converter = (file: File): void => {
    const reader = new FileReader()

    reader.onload = (): void => {
      const img = new Image()
      img.src = reader.result as string
      setCropperImage({ name: 'images', file: reader.result as string })
    }
    reader.readAsDataURL(file)
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!e.target?.files) return
    const file = e.target.files[0]
    if (constants.file.includes(file.type)) {
      setFileType(FileTypes.DOCUMENT)
      uploadContentFile(file)
      return
    }

    if (constants.audio.includes(file.type)) {
      setFileType(FileTypes.AUDIO)
      uploadAudioFile(file)
      return
    }

    if (constants.video.includes(file.type)) {
      setFileType(FileTypes.VIDEO)
      uploadVideoFile(file)
      return
    }

    if (constants.image.includes(file.type)) {
      setFileType(FileTypes.IMAGE)
      b64Converter(file)
      setCropperOpen(true)

      return
    }
  }

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
    fileType: string,
  ): void => {
    const { value } = e.target

    const field = fileType === 'videos' ? 'title' : 'name'

    const content = [...props.uploadedFiles]
    const item = {
      ...content[index],
    }

    item[field] = value
    content[index] = item

    props.setUploadedFiles([...content])
  }

  const handleDeleteFile = (i: number): void => {
    const content = [...props.uploadedFiles]
    content.splice(i, 1)
    props.setUploadedFiles(content)
  }

  const cropperModalToggle = (): void => {
    setCropperOpen(!cropperOpen)
  }

  const loadings = {
    image: imageLoading === 'images',
    video: uploadingProgress > 0 ? uploadingProgress : false,
    document: fileLoading === 'content',
    audio: uploadingProgress > 0 ? uploadingProgress : false,
    file: false,
  }

  return (
    <Container>
      <UploadedContent>
        <Uploader
          inputValue={''}
          deleteFile={(): void => undefined}
          label={
            <>
              <StyledUploadLabel>{t('actions.upload_content')}</StyledUploadLabel>
            </>
          }
          onFileChange={handleFileChange}
          disabled={true}
          multiple
          placeHolder={t('actions.file_upload_format2')}
          type={fileType}
          uploadedFile={null}
          // fileExtension={''}
          loading={loadings[fileType]}
          id='content'
          // loading={['video', 'audio'].includes(fileType) ? (uploadingProgress > 0 ? uploadingProgress : false) : fileLoading === 'content'}
        />
        {props.uploadedFiles.map((item, index: number) => (
          <>
            <Uploader
              key={index}
              label={t('')}
              deleteFile={(): void => handleDeleteFile(index)}
              type={item.type?.slice(0, item.type.length - 1) as FileTypes}
              inputValue={item.type === 'videos' ? (item.title as string) : (item.name as string)}
              onInputChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                handleInputChange(e, index, item.type as string)
              }
              uploadedFile={item}
            />
          </>
        ))}
      </UploadedContent>

      <Modal isOpened={cropperOpen} onClose={cropperModalToggle}>
        <ImageCropper
          file={cropperImage as CropperResultType}
          hideCropper={cropperModalToggle}
          getCroppedFile={uploadImageFile}
        />
      </Modal>
    </Container>
  )
}

export default UploadContent
