import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Input } from 'components/common/TextInput'
import React, { useState } from 'react'
import { ITextInputProps } from './TextInputV2'

export const PasswordInput: React.FC<ITextInputProps> = ({
  error,
  errorMessage,
  touched,
  label,
  name,
  size,
  value,
  onChange,
}) => {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <Input
      error={error}
      errorMessage={errorMessage}
      touched={touched}
      label={label}
      name={name}
      size={size}
      type={showPassword ? 'text' : 'password'}
      fullWidth
      value={value}
      onChange={onChange}
      endAdornment={
        <InputAdornment position='end'>
          <IconButton onClick={(): void => setShowPassword(x => !x)}>
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
    />
  )
}
