import { useState } from 'react'
import addUserService from 'services/users/addUserService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useData } from 'context/DataContext'
import { SET_CREATED_USER } from 'store/types'
import { useTranslation } from 'react-i18next'
import { IUser } from 'interfaces/users'

const useAddUserServie = (): {
  addUser: (
    input: IUser,
    role: string,
    companyId: string,
    onSuccess?: (data: IUser) => void,
  ) => Promise<void>
  addUserLoading: boolean
  user: IUser | null
  error: string
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [user, setUser] = useState<IUser | null>(null)
  const [error, setError] = useState('')
  const { addUserMutation, loading } = addUserService()
  const [, dispatch] = useData()

  const addUser = async (
    input: IUser,
    role: string,
    companyId: string,
    onSuccess?: (data: IUser) => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }
    const { data, errors } = await addUserMutation({
      variables: {
        input,
        companyId,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        message = `${t('general.user')} ${t('messages.already_registered')}`
        setError(message)
        return
      }
      variant = 'error'
    } else if (data) {
      setUser(data.addUser)
      message = `${t('messages.new')} ${t(`general.${role.toLowerCase()}`)} ${t('messages.added')}`
      variant = 'success'

      dispatch({
        type: SET_CREATED_USER,
        payload: {
          obj: data.addUser,
          type: role.toLowerCase(),
        },
      })

      if (onSuccess) onSuccess(data.addUser)
    }

    setSnackbar({ message, variant })
  }

  return {
    addUser,
    addUserLoading: loading,
    user,
    error,
  }
}

export default useAddUserServie
