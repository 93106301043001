import styled from 'styled-components'

//  Forgot Password

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const Title = styled.div`
  color: rgb(65 65 65 / 67%);
  font-size: 17px;
  line-height: 29px;
`
export const SubText = styled.div`
  color: #06c68f;
  font-size: 23px;
  font-weight: 500;
  line-height: 28px;
`
export const ContentRight = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`

export const StyledLink = styled.div`
  transition: 0.3s ease-out;
  color: #3c8dbc;
  &:hover {
    color: #72afd2;
  }
`

export const Content = styled.div`
  margin: 30px 0;
`

// Login

export const LoginTitle = styled.div`
  color: rgb(65 65 65 / 67%);
  font-size: 18px;
  line-height: 38px;
`
export const LoginSubText = styled.div`
  color: #06c68f;
  font-size: 25px;
  font-weight: 500;
  line-height: 28px;
`

export const CheckboxContainer = styled.div`
  margin-top: -8px;
  margin-bottom: 21px !important;
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #06c68f;
  }
`

export const LoginContent = styled.div`
  position: relative;
  margin: 70px 0 20px 0;
  background: white;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 7%), 0px 4px 5px 0px rgb(0 0 0 / 7%),
    0px 1px 10px 0px rgb(0 0 0 / 7%);
  & form > div:not(:last-child) {
    margin-bottom: 30px;
  }
`

export const ServerErrorBox = styled.div`
  position: absolute;
  top: -65px;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 0.2s ease;

  &.show {
    opacity: 1;
  }

  .MuiAlert-root {
    padding: 0 12px;
  }
`

export const LoginContentRight = styled.div`
  display: flex;
`

export const LoginButton = styled.div`
  width: 100%;
  display: flex;
  .btn-wrapper {
    width: 100%;
  }
`

// ResetPassword

export const ResetContent = styled.div`
  position: relative;
  margin: 40px 0 20px 0;
  & form > div:not(:last-child) {
    margin-bottom: 30px;
  }
`
export const ResetTitle = styled.div`
  color: #06c68f;
  font-size: 25px;
  font-weight: 500;
  line-height: 28px;
`
