import { useState } from 'react'
import addGroupService, { IAddGroupServiceParams } from 'services/group/addGroupService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useData } from 'context/DataContext'
import { SET_CREATED_USER } from 'store/types'
import { useTranslation } from 'react-i18next'
import { IGroup } from 'interfaces/groups'
import { User } from '@sentry/react'

export interface IUseAddGroupReturnType {
  loading: boolean
  addGroup: (payload: User, students?: string[], refetch?: () => void) => Promise<void>
  group: IGroup | null
}

const useAddGroup = (params: IAddGroupServiceParams): IUseAddGroupReturnType => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [loading, setLoading] = useState(false)
  const [group, setGroup] = useState<IGroup | null>(null)
  const { addGroupMutation } = addGroupService(params)
  const [, dispatch] = useData()

  const addGroup = async (
    payload: User,
    students?: string[],
    refetch?: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)
    setGroup(null)

    const { errors, data } = await addGroupMutation({
      variables: { input: payload, students },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else if (data) {
      message = `${t('general.group')} ${t('messages.added')}`
      variant = 'success'
      setGroup(data.addGroup)
      dispatch({
        type: SET_CREATED_USER,
        payload: {
          obj: data.addGroup,
          type: 'group',
        },
      })
    }

    setSnackbar({ message, variant })
    if (refetch) refetch()
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return {
    loading,
    addGroup,
    group,
  }
}

export default useAddGroup
