import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'
import { IAttachment } from 'interfaces/common'
import { IUser } from 'interfaces/users'
import { ICourseOption } from 'pages/tasks/TasksDrawer/tasksDrawer.interface'

export interface ICourseItem {
  isBought: boolean
  originalId: string | null
  id: string
  video?: string

  companyId: {
    id: string
    name: string
  }
}

export type FilterQuery = {
  [key: string]: {
    type: string
    value: string | number | boolean | null
  }
}
export interface IListColumnConfigItem {
  id: string
  name: string
  duration: number
  state: GridStatusEnums
  numberOfModules: number
  numberOfLessons: number
}

export interface ICourse extends IRenderContentItem {
  id: string
  _id: string
  courseId?: string
  assistantId: string
  isChecked?: boolean
  coach?: IUser
  name?: string
}

export interface ICourseBriefData {
  courseId: string
  name: string
}
export interface ICourseListConfig {
  id: string
  render: (item: ICourseItem) => React.ReactElement | null
  numeric: boolean
  disablePadding: boolean
  label?: string
  width?: string
}

export interface ICourseListConfigReturn {
  id: string
  render: (item: IListColumnConfigItem) => JSX.Element
  numeric: boolean
  disablePadding: boolean
  label?: string
  width?: string
}

export interface IActionHandlerType {
  (event: string, field: string | ICourse, action?: string): void
}

export interface IActionHandlerTypeRender {
  (event: string, field: string | ICourse, action?: string): void
}

export interface IAction {
  render: (item: ICourse) => JSX.Element
  hide?: boolean
}

interface CourseAttachment {
  link: string
  name: string
}

export interface CourseCompany {
  id: string
  name: string
}

export enum CourseCompleteCriteriaEnums {
  WATCH = 'watch',
  WATCH_AND_PASS_QUIZZES = 'watchAndPassQuizzes',
  WATCH_AND_PASS_QUIZZES_AND_STV = 'watchAndPassQuizzesAndSTV',
}

export interface CompleteCriteriaOption {
  label: string
  value: CourseCompleteCriteriaEnums
}

export interface IRenderContentItem {
  isBought?: boolean
  id?: string
  editorText?: string
  value?: string
  originalId?: string
  companyId?: CourseCompany | string
  state?: string
  finished?: number
  completeCriteria?: CompleteCriteriaOption | null | string
  description?: string
  coursePrivacy?: string
  coachType?: string
  skills?: Array<{ label: string }>
  certificateIncluded?: boolean
  certificate?: CourseAttachment | null
  contentLocked?: boolean
  avatar?: CourseAttachment | null
  level?: string | null | ICourseOption
  currency?: string
  price?: string | null
  name?: string
  video?: IAttachment | null
  subtitle?: CourseAttachment
  numberOfModules?: number
  numberOfLessons?: number
  duration?: number
  coaches?: IUser[] | string[]
  groups: Array<{ name: string }>
  numberOfStudents?: number
}

type SelectItemFunction = (id: string) => void

export interface ICourseListProps {
  isGridView?: boolean
  companyMode?: boolean
  activeTab?: number
  state?: unknown
  actionHandler?: IActionHandlerType | IActionHandlerTypeRender
  data: ICourse[]
  isDraggable?: boolean
  gridStyle: string
  selectItem?: SelectItemFunction
  withTooltips?: boolean
  selectedItem?: string[]
  actions?: IAction[]
  fromWizard?: boolean
  $canOpen?: boolean
  fromCertificateBuilder?: boolean
  openInExternalTab?: boolean
  withoutChekbox?: boolean
  courseListActions?: IAction[]
  user?: {
    id: string
    groups: string[]
  }
  groupId?: string
}

export interface Group {
  name: string
}
