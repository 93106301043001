// @deprecated
/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { FormFields, FormField } from 'components/common/Form/Form'
import styled from 'styled-components'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import { SelectField } from 'components/common/SelectField'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'
import DatePicker from 'components/common/DatePicker'
import { AvatarInput } from 'components/common/AvatarInput/AvatarInput'
import { TFunction } from 'interfaces/TFunction'

interface Props {
  handleChange: any
  values: any
  errors: any
  touched: any
  handleSubmit: any
  addUserLoading: boolean
  setFieldValue: any
  t: TFunction
}

const CreateCompanySuperAdmin = (props: Props) => {
  const { errors, values, handleChange, touched, handleSubmit, addUserLoading, setFieldValue, t } =
    props

  useEffect(() => {
    setFieldValue('phoneFieldValue', (values.phoneFields && values.phoneFields.label) || null)
    values.phoneFieldValue = values.phoneFields ? values.phoneFields.value : null
  }, [values.phoneFields])

  const [currentDate, setCurrentDate] = useState<Date | null>(null)

  const onDateChange = (date: Date | null) => {
    // setCurrentDate(date)
    const birthDate = date
    setFieldValue('birthDate', date)

    if (birthDate) {
      values.birthDate = birthDate
    }
  }

  useEffect(() => {
    if (values.birthDate) {
      setCurrentDate(
        new Date(typeof (values.birthDate === 'string') ? values.birthDate : +values.birthDate),
      )
    }
  }, [values.birthDate])

  return (
    <Container>
      <FormFields>
        <FormField>
          <AvatarInput setFieldValue={setFieldValue} initialAvatar={values.avatar} />
        </FormField>

        <FormField width='48%'>
          <TextInput
            touched={touched.firstName}
            errorMessage={errors.firstName}
            error={!!errors.firstName}
            label={`${t('user_details.first_name')}*`}
            name='firstName'
            size='small'
            type='text'
            value={values.firstName}
            onChange={handleChange}
            fullWidth={true}
          />
        </FormField>

        <FormField width='48%'>
          <TextInput
            touched={touched.lastName}
            errorMessage={errors.lastName}
            error={!!errors.lastName}
            label={`${t('user_details.last_name')}*`}
            name='lastName'
            size='small'
            type='text'
            value={values.lastName}
            onChange={handleChange}
            fullWidth={true}
          />
        </FormField>

        <FormField width='48%'>
          <TextInput
            error={!!errors.email}
            errorMessage={errors.email}
            touched={touched.email}
            label={`${t('form_fields.email')}*`}
            name='email'
            size='small'
            type='text'
            value={values.email}
            onChange={handleChange}
            fullWidth={true}
          />
        </FormField>

        <FormField width='48%'>
          <PhoneCountryCode
            label={t('user_details.phone')}
            selectLabel={t('form_fields.dial_code')}
            selectValue={values.phoneFields || null}
            selectChange={(e: any) => setFieldValue('phoneFields', e)}
            inputLabel={t('user_details.phone')}
            inputValue={values.phone}
            inputChange={handleChange}
            error={errors.phone || errors.phoneFields ? true : false}
            errorMessage={
              errors.phone || errors.phoneFields ? errors.phone || errors.phoneFields : ''
            }
            touched={touched.phone || touched.phoneFields}
          />
        </FormField>

        <FormField width='48%'>
          <SelectField
            placeholder={t('user_details.gender')}
            options={[
              { value: 'male', label: 'Male' },
              { value: 'female', label: 'Female' },
              { value: 'prefer not to say', label: 'Prefer not to say' },
            ]}
            // error={errors.gender && touched.gender ? true : false}
            // errorMessage={errors.gender ? errors.gender : ''}
            // touched={touched.gender}
            value={values.gender}
            onChange={(e: any) => setFieldValue('gender', e)}
            isClearable
          />
        </FormField>

        <FormField width='48%'>
          <DatePicker
            label={`${t('user_details.birthday')}`}
            name='birthDate'
            value={currentDate}
            onChange={onDateChange}
          />
        </FormField>
        <FormField width='48%'>
          <TextInput
            label={t('form_fields.job_title')}
            size='small'
            name='jobTitle'
            type='text'
            value={values.jobTitle}
            onChange={handleChange}
          />
        </FormField>
        <FormField width='48%'>
          <TextInput
            label={t('form_fields.location')}
            size='small'
            name='location'
            type='text'
            value={values.location}
            onChange={handleChange}
          />
        </FormField>
        <FormField width='100%'>
          <TextInput
            label={t('user_details.biography')}
            size='small'
            name='biography'
            type='text'
            value={values.biography}
            onChange={handleChange}
            multiline
            rows='3'
          />
        </FormField>
        <FormField width='100%'>
          <TextInput
            label={t('user_details.note')}
            size='small'
            name='note'
            type='text'
            value={values.note}
            onChange={handleChange}
            multiline
            rows='3'
          />
        </FormField>

        <FormField>
          <ButtonContainer>
            <ButtonItem style={{ marginRight: '10px' }}>
              <Button
                text={t('company_wizard_layout.save_and_create_another')}
                type='small'
                onClick={() => handleSubmit('reset')}
                isDisabled={addUserLoading}
                background='#06C68F'
              />
            </ButtonItem>

            <ButtonItem>
              <Button
                text={t('company_wizard_layout.save_and_review')}
                type='small'
                onClick={() => handleSubmit('review')}
                isDisabled={addUserLoading}
                background='#06C68F'
              />
            </ButtonItem>
          </ButtonContainer>
        </FormField>
      </FormFields>
    </Container>
  )
}

export default CreateCompanySuperAdmin

const Container = styled.div`
  box-sizing: border-box;
  padding: 0px 30px;
`
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const ButtonItem = styled.div``
