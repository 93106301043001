import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { ADD_QUESTIONS_TO_MODULE } from 'gql/questions.query'
import { IModule } from 'pages/modules/ModulesList.interface'

interface IAddQuestionsToModuleServiceResponse {
  addQuestionsToModuleMutation: MutationTuple<
    IAddQuestionsToModuleResponse,
    IAddQuestionsToModuleParams
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IAddQuestionsToModuleResponse {
  addQuestionsToModule: string
}

interface IAddQuestionsToModuleParams {
  module: IModule
  questionIds: string[]
}

const addQuestionsToModuleService = (): IAddQuestionsToModuleServiceResponse => {
  const [addQuestionsToModuleMutation, { loading, error }] = useMutation<
    IAddQuestionsToModuleResponse,
    IAddQuestionsToModuleParams
  >(ADD_QUESTIONS_TO_MODULE)

  return {
    addQuestionsToModuleMutation,
    loading,
    error,
  }
}

export default addQuestionsToModuleService
