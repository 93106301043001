import React from 'react'
import { Action } from 'components/common/GridV2'
import { ReactComponent as RedDeleteIcon } from 'assets/red-delete-icon.svg'
import { ReactComponent as EditIcon } from 'assets/edit.svg'
import { ReactComponent as EditRoundedIcon } from 'assets/editRounded.svg'
import { ReactComponent as DeleteIcon } from 'assets/trash-can-red.svg'
import { DeleteSpan } from 'components/common/Styled/styled-components'
import { TFunction } from 'interfaces/TFunction'
import { IPermissionsBool } from 'utils/rolesObject/rolesObject.interface'
import {
  ActionHandler,
  IQuizItemData,
  IQuizzesActionItem,
  IQuizzesItem,
  QuizValue,
} from './quiz.interface'
import Tooltip from '@mui/material/Tooltip'
import strCutter from 'utils/strCutter'
import Tags from 'components/common/Tags/Tags'
import { capitalizeFirstLetter } from 'helpers/capitalLetter'
import { StyledQuizTypeSpan } from './QuizList/styled-components'
import { CheckBoxAndAvatarContainer } from 'components/UsersLayout/styled-components'
import Checkbox from 'components/common/Checkbox'

export const actionConfig = (
  t: TFunction,
  actionHandler: ActionHandler,
  permissions: IPermissionsBool,
  setIsSearchType?: (value: boolean) => void,
): IQuizzesActionItem[] => {
  let action: IQuizzesActionItem[] = [
    {
      hide: !permissions.editQuiz,
      render: (item: QuizValue) => (
        <Action className='edit' onClick={(): void => actionHandler('edit', item)}>
          <EditIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions.deleteQuiz,
      render: (item: QuizValue) => (
        <Action
          className='delete'
          onClick={(): void => {
            setIsSearchType && setIsSearchType(false)
            actionHandler('delete', item)
          }}
        >
          <DeleteIcon />
          <DeleteSpan>{t('actions.delete')}</DeleteSpan>
        </Action>
      ),
    },
  ].filter((i: IQuizzesActionItem) => !i.hide)

  return action
}

export const gridActionConfig = (
  t: TFunction,
  actionHandler: ActionHandler,
  permissions: IPermissionsBool,
  setIsSearchType?: (value: boolean) => void,
): IQuizzesActionItem[] => {
  let action: IQuizzesActionItem[] = [
    {
      hide: !permissions.editQuiz,
      render: (item: QuizValue) => (
        <Action className='edit' onClick={(): void => actionHandler('edit', item)}>
          <Tooltip title={t('actions.edit')} arrow>
            <EditRoundedIcon />
          </Tooltip>
        </Action>
      ),
    },
    {
      hide: !permissions.deleteQuiz,
      render: (item: QuizValue) => (
        <Action
          className='delete'
          onClick={(): void => {
            setIsSearchType && setIsSearchType(false)
            actionHandler('delete', item)
          }}
        >
          <RedDeleteIcon />
          <DeleteSpan>{t('actions.delete')}</DeleteSpan>
        </Action>
      ),
    },
  ].filter((i: IQuizzesActionItem) => !i.hide)

  return action
}

export const quizListConfig = (t: TFunction, selectItem: (id: string) => void): IQuizzesItem[] => {
  const grid: IQuizzesItem[] = [
    {
      id: 'isChecked',
      label: '',
      render: (item: IQuizItemData): JSX.Element => (
        <CheckBoxAndAvatarContainer>
          <Checkbox
            checked={item.isChecked}
            onChange={(): void => {
              if (item.id) selectItem(item.id)
            }}
          />
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('general.name'),
      render: (item: IQuizItemData): JSX.Element => (
        <div style={{ cursor: 'pointer' }}>
          {item.name && item.name.length > 30 ? (
            <Tooltip title={item.name}>
              <div>{strCutter(item.name, 30)}</div>
            </Tooltip>
          ) : (
            <div>{item.name}</div>
          )}
        </div>
      ),
    },
    {
      id: 'quizParticipationType',
      label: t('general.type'),
      render: (item: IQuizItemData): JSX.Element => (
        <StyledQuizTypeSpan>{capitalizeFirstLetter(item.quizParticipationType)}</StyledQuizTypeSpan>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'tags',
      label: t('general.tags'),
      render: (item: IQuizItemData): JSX.Element => <Tags tags={item.tags} type='question' />,
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'parentName',
      label: t('general.parent_name'),
      render: (item: IQuizItemData): JSX.Element => (
        <div>
          <span>{item.IQTestType ? t('general.course') : t('general.module')}</span>
          {item.parentName && item.parentName.length > 14 ? (
            <Tooltip title={item.parentName}>
              <span>{strCutter(item.parentName, 14)}</span>
            </Tooltip>
          ) : (
            <span>{item.parentName}</span>
          )}
        </div>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'questions',
      label: t('general.questions'),
      render: (item: IQuizItemData): JSX.Element => (
        <div>{(item.questions && item.questions.length) || 0}</div>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'passRate',
      label: t('quiz_details.pass_rate'),
      render: (item: IQuizItemData): JSX.Element => <div>{item.passRate || 0}%</div>,
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'timeType',
      label: t('general.type'),
      render: (item: IQuizItemData): JSX.Element => (
        <div>{capitalizeFirstLetter(item.timeType)}</div>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'randomization',
      label: t('quiz_details.randomize'),
      render: (item: IQuizItemData): JSX.Element => (
        <div>{item.randomization ? t('general.yes') : t('general.no')}</div>
      ),
      numeric: false,
      disablePadding: true,
    },
  ]
  return grid
}
