import styled from 'styled-components'

// AdminProfile

export const AvatarContainer = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`
export const Half = styled.div`
  width: 50%;
  padding-top: 10px;
`
export const ActionsItem = styled.div`
  display: flex;
  font-size: 12px;
  width: 100%;
  justify-content: center;
  color: #68707a;

  .action-content {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  :hover {
    cursor: pointer;
    opacity: 0.8;
  }

  :active {
    opacity: 0.8;
  }
`

export const InputWrapper = styled.div`
  display: none;
`
export const ActionsGrid = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 25%;
`

export const DetailsHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  padding: 16px 16px;
`

export const InformationBackground = styled.div`
  width: 70%;
  background-color: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  min-height: 300px;
`

export const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
`
export const StyledFlex = styled.div`
  display: flex;
  gap: 30px;
`
export const ProfileBackground = styled.div`
  width: 30%;
  background-color: #ffffff;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`
export const ActionsWrapper = styled.div`
  position: absolute;
  right: 0;
  cursor: pointer;
  padding: 10px;

  :hover {
    background-color: #e7e9ed;
    border-radius: 4px;
  }
`
export const UserNameText = styled.div`
  font-size: 20px;
  color: #020210;
  font-weight: 500;
`
export const UserShortInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Separator = styled.div`
  margin-bottom: 60px;
`

export const NotAllowed = styled.div`
  font-size: 16px;
  color: #989898;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const UserRoleText = styled.div`
  font-size: 12px;
  color: #020210;
`
export const UserInfoWithButtons = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(100% - 32px);
  width: 100%;
`

export const EditAndPasswordButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
`
export const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #06c68f;
  font-size: 16px;
  margin-top: 15px;
  font-weight: 500;
`
export const Circle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: #06c68f;
  margin-right: 5px;
`

// ChangePasswordFields

export const InputsContainer = styled.div`
  width: 90%;
  margin: 0 auto;
`
export const ErrorText = styled.div`
  color: #f44336;
`

// PersonalInformation

export const PersonalInformationHeader = styled.div`
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
  padding-bottom: 8px;
  font-size: 20px;
  color: rgba(112, 112, 112, 0.72);
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
`
export const Line = styled.div`
  opacity: 0.72;
  border-bottom: 2px solid #707070;
`

export const PersonalInformationContainer = styled.div`
  color: #989898;
  background: white;
  /* border-radius 16px; */
  padding: 25px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 7%), 0px 4px 5px 0px rgb(0 0 0 / 7%),
    0px 1px 10px 0px rgb(0 0 0 / 7%);
  margin-bottom: 50px;
`

export const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  color: #414141;
`

export const ConsultantContainer = styled(Grid)`
  justify-content: flex-start;
`

export const GridContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  width: 80%;
`

export const TextWrapper = styled.div`
  text-align: left;
  font-size: 16px;
  color: #414141;
  margin: 20px 0;
`

export const GridWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

//cropper
export const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

export const ImageCropperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .actions {
    display: flex;
    gap: 10px;
    margin-top: 5px;
  }
`
