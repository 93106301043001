import { useMutation, MutationTuple } from '@apollo/client'
import {
  ADD_GROUPS_TO_COURSE,
  ADD_REMOVE_GROUP_FROM_COURSE,
  REMOVE_GROUPS_FROM_COURSE,
} from 'gql/group/group.query'
import { IMessageResponse } from 'interfaces/common'
import { IGroup } from 'interfaces/groups'

// Define interface for the service response
interface IAddRemoveGroupFromCourseServiceResponse {
  addRemoveGroupFromCourseMutation: MutationTuple<
    IAddRemoveGroupFromCourseResponse,
    IAddRemoveGroupFromCourseVariables
  >[0]
  addGroupsToCourseMutation: MutationTuple<
    IAddGroupsToCourseResponse,
    IAddGroupsToCourseVariables
  >[0]
  removeGroupsToCourseMutation: MutationTuple<
    IRemoveGroupsFromCourseResponse,
    IRemoveGroupsFromCourseVariables
  >[0]
}

interface IAddRemoveGroupFromCourseResponse {
  addRemoveGroupFromCourse: IGroup
}

interface IAddRemoveGroupFromCourseVariables {
  courseId: string
  groupIds: string[]
}

interface IAddGroupsToCourseResponse {
  addGroupsToCourse: IMessageResponse
}

interface IAddGroupsToCourseVariables {
  courseId: string
  groupIds: string[]
}

interface IRemoveGroupsFromCourseResponse {
  removeGroupsFromCourse: IMessageResponse
}

interface IRemoveGroupsFromCourseVariables {
  courseId: string
  groupIds: string[]
}

const addRemoveGroupFromCourseService = (): IAddRemoveGroupFromCourseServiceResponse => {
  const [addRemoveGroupFromCourseMutation] = useMutation<
    IAddRemoveGroupFromCourseResponse,
    IAddRemoveGroupFromCourseVariables
  >(ADD_REMOVE_GROUP_FROM_COURSE)

  const [addGroupsToCourseMutation] = useMutation<
    IAddGroupsToCourseResponse,
    IAddGroupsToCourseVariables
  >(ADD_GROUPS_TO_COURSE)
  const [removeGroupsToCourseMutation] = useMutation<
    IRemoveGroupsFromCourseResponse,
    IRemoveGroupsFromCourseVariables
  >(REMOVE_GROUPS_FROM_COURSE)

  return {
    addRemoveGroupFromCourseMutation,
    addGroupsToCourseMutation,
    removeGroupsToCourseMutation,
  }
}

export default addRemoveGroupFromCourseService
