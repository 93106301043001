import React, { ReactElement } from 'react'
import { FormGroupItem, FormGroupWrapper } from 'components/common/Form'
import { TextareaAutosize } from '@mui/material'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import {
  DeleteButtonStyles,
  FormGroupItemStyles,
  StyledFormGroup,
  TextAreaStyles,
} from './styled-components'

export interface multiAnswerItem {
  value: string
  id: string
}

const CriteriaItem = ({
  handleRemoveAnswerClick,
  multiAnswers,
  handleAnswerChange,
}: {
  handleRemoveAnswerClick: (index: number) => void
  multiAnswers: multiAnswerItem[]
  handleAnswerChange: (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    index: number,
  ) => void
}): ReactElement => {
  return (
    <FormGroupWrapper>
      <StyledFormGroup>
        {multiAnswers.map((multiAnswer, index) => (
          <FormGroupItem key={index} style={FormGroupItemStyles}>
            <TextareaAutosize
              name={`multiple-${index}`}
              aria-label='minimum height'
              minRows={1}
              value={multiAnswer.value}
              style={TextAreaStyles}
              onChange={(e): void => handleAnswerChange(e, index)}
            />

            <RemoveCircleIcon
              onClick={(): void => handleRemoveAnswerClick(index)}
              style={DeleteButtonStyles}
            />
          </FormGroupItem>
        ))}
      </StyledFormGroup>
    </FormGroupWrapper>
  )
}

export default CriteriaItem
