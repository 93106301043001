import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { PUBLISH_TEST } from 'gql/tests/tests.query'
import { IQuiz } from 'pages/quizzes/quiz.interface'

interface IPublishUnpublishTestServiceResponse {
  publishUnpublishTestMutation: MutationTuple<
    IPublishUnpublishTestResponse,
    IPublishUnpublishTestParams
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IPublishUnpublishTestResponse {
  publishUnpublishTest: IQuiz
}

interface IPublishUnpublishTestParams {
  testAssessmentId: string
  published: boolean
}

const publishUnpublishTestService = (): IPublishUnpublishTestServiceResponse => {
  const [publishUnpublishTestMutation, { loading, error }] = useMutation<
    IPublishUnpublishTestResponse,
    IPublishUnpublishTestParams
  >(PUBLISH_TEST)

  return {
    publishUnpublishTestMutation,
    loading,
    error,
  }
}

export default publishUnpublishTestService
