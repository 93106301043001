// @deprecated
/* eslint-disable */

import React, { useState, useEffect, ReactElement } from 'react'
import { useFetchCourses, PER_PAGE } from 'hooks/course/useCourses'
import Loader from 'components/common/Loader'
import {
  ContentWrapper,
  ToolbarItemWrapper,
  Title,
  ButtonWrapper,
  TextContainer,
} from './styled-components'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import { Button } from 'components/common/Button'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import IconButton from '@mui/material/IconButton'
import { Grid } from 'components/common/Cards/styled-components'
import { useNavigate } from 'react-router-dom'
import Pagination from 'components/common/Pagination'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import { Input } from 'components/common/TextInput'
import { Container, Wrapper, FooterWrapper } from '../styled-components'
import useCompaniesService from 'hooks/useCompaniesService'
import CourseList from 'pages/courses/CourseList'
import { useAddRemoveCourseFromCompany } from 'hooks/companies/useCompanySettings'
import { TFunction } from 'interfaces/TFunction'

const toolbarStyle = {
  justifyContent: 'space-between',
  borderBottom: '1px solid #ccc',
}

interface PurchasedCoursesProps {
  companyData: any
  setData: any
  changeStep: (step: number) => void
  onCancel: () => void
  t: TFunction
}

const PurchasedCourses = ({
  companyData,
  setData,
  changeStep,
  onCancel,
  t,
}: PurchasedCoursesProps): ReactElement => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [selectAll, setSelectAll] = useState<any>({})
  const [perPage] = useState<number>(PER_PAGE)
  const [searchText, setSearchText] = useState('')
  const { setCompanyState } = useCompaniesService()
  const navigate = useNavigate()

  const query: any = {
    state: {
      type: 'EXACT',
      value: 'PUBLISHED',
    },
    companyId: {
      type: 'EXACT',
      value: null,
    },
  }
  const [filterOptions, setFilterOptions] = useState<any>(query)
  const { addRemoveCourseFromCompany } = useAddRemoveCourseFromCompany()
  const { courses, loading, refetch, error } = useFetchCourses(filterOptions, currentPage, perPage)

  const { selectedCourses } = companyData

  useEffect(() => {
    setSelectedItem({
      ...selectedItem,
      [currentPage]: selectedCourses,
    })
  }, [selectedCourses])

  const selectItem = (id: string): void => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectedItemsOnPage = selectedItem[currentPage] || []

  const handleSubmit = (): void => {
    addRemoveCourseFromCompany(companyData.companyData.id, selectedItemsOnPage, () => {
      setData((prev: any) => ({
        ...prev,
        selectedCourses: selectedItemsOnPage,
      }))
      changeStep(3)
      setCompanyState(companyData.companyData.id, 3)
    })
  }

  const handleSearchSubmit = (e: any): void => {
    e.preventDefault()
    const filters = {
      ...filterOptions,
      name: { type: 'MATCH', value: searchText },
    }
    refetch({
      filter: filters,
      currentPage: 1,
      perPage,
    })
    setFilterOptions(filters)
    setCurrentPage(1)
  }

  const handlePaginationClick = (_: React.ChangeEvent<unknown>, value: number): void => {
    if (value !== currentPage) {
      refetch({
        filter: {
          ...filterOptions,
        },
        currentPage: value,
        perPage,
      })
      setCurrentPage(value)
    }
  }

  const courseList = (courses && courses.data) || []

  return (
    <>
      <Container>
        <Wrapper width='74%'>
          <ContentWrapper>
            <Toolbar styles={toolbarStyle}>
              <ToolbarItemWrapper>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Title>{t('courses_layout.title')}</Title>

                  <GridMultipleActions selectedItems={selectedItemsOnPage.length} actions={[]} />
                </div>

                <div className='wrapper'>
                  <ToolbarItem>
                    <form onSubmit={handleSearchSubmit}>
                      <Input
                        label={t('general.search_placeholder')}
                        type='text'
                        size='small'
                        value={searchText}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                          setSearchText(e.target.value)
                        }
                        icon={
                          <IconButton>
                            <SearchRoundedIcon />
                          </IconButton>
                        }
                      />
                    </form>
                  </ToolbarItem>
                </div>
              </ToolbarItemWrapper>
            </Toolbar>

            <br />

            <Grid className={'card'} style={{ position: 'relative' }} cols={3}>
              {error && error}
              {loading && <Loader withBackground />}
              {courses && (
                <CourseList
                  selectItem={selectItem}
                  selectedItem={selectedItem[currentPage] || []}
                  data={courseList}
                  gridStyle={'card'}
                  fromWizard
                  isGridView={true}
                  openInExternalTab={true}
                  withTooltips={false}
                />
              )}
            </Grid>
            {courses && courses.totalPages > 1 && (
              <div
                style={{
                  marginTop: 20,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Pagination
                  currentPage={courses ? courses.currentPage : 1}
                  totalPages={courses ? courses.totalPages : 0}
                  handleChange={handlePaginationClick}
                />
              </div>
            )}
          </ContentWrapper>
        </Wrapper>

        <Wrapper width='calc(26% - 20px)'>
          <TextContainer>{t('company_wizard_layout.purchased_courses_description')}</TextContainer>
        </Wrapper>
      </Container>

      <FooterWrapper>
        <ButtonWrapper>
          <Button
            text={t('actions.cancel')}
            type='small'
            onClick={onCancel}
            background='#E0E1E2'
            textColor='#414141'
          />
        </ButtonWrapper>

        <ButtonWrapper>
          <div style={{ marginRight: '15px' }}>
            <Button
              text={t('actions.previous')}
              type='small'
              onClick={(): void => changeStep(1)}
              background='#E0E1E2'
              textColor='#414141'
            />
          </div>

          <div style={{ marginRight: '15px', height: '31px' }}>
            <Button
              text={t('actions.save_as_draft')}
              type='small'
              color='secondary'
              onClick={(): void => {
                addRemoveCourseFromCompany(companyData.companyData.id, selectedItemsOnPage, () => {
                  setData((prev: any) => ({
                    ...prev,
                    selectedCourses: selectedItemsOnPage,
                  }))
                  setCompanyState(companyData.companyData.id, 1, '', () => navigate('/Companies'))
                })
              }}
              textColor='#06C68F'
              background='#f5f5f5'
              outline='1px solid #06C68F'
            />
          </div>

          <Button
            text={t('actions.save_and_next')}
            type='small'
            color='secondary'
            onClick={handleSubmit}
            background='#06C68F'
          />
        </ButtonWrapper>
      </FooterWrapper>
    </>
  )
}

export default PurchasedCourses
