import styled from 'styled-components'

// checkbox.jsx

export const Content = styled.div`
  &.light svg {
    fill: #fff;
  }
`

export const ErrorMessage = styled.p`
  color: red;
  position: absolute;
  bottom: -30px;
  white-space: pre;
  left: 11px;
`

// checkbox.tsx

export const CheckboxContainer = styled.div`
  position: relative;
  width: fit-content;
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: #06c68f !important;
    svg {
      color: #06c68f !important;
    }
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #06c68f !important;
    svg {
      color: #06c68f !important;
    }
  }
`
