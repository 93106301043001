import React from 'react'
import AccredibleIframe from './builderIframe/AccredibleIframe'
import { Wrapper } from './styled-components'

const badgeEditor = ({
  id,
  designId,
  closeDrawer,
}: {
  id: string
  designId?: number
  closeDrawer: (badgeData: { id: number; url: string }) => void
}): React.JSX.Element => {
  return (
    <Wrapper>
      <AccredibleIframe id={id} designId={designId} closeDrawer={closeDrawer}></AccredibleIframe>
    </Wrapper>
  )
}

export default badgeEditor
