/* eslint-disable */
import { ITopic } from 'pages/modules/ModulesList.interface'
import {
  SET_CURRENT_TOPIC,
  TOGGLE_DRAWER_CONFIRM,
  SET_CREATED_USER,
  SET_FORMDATA,
  CLEAR_FORMDATA,
  SET_CLOSE_DRAWER,
  IReducerAction,
} from '../types'

interface DrawerConfirm {
  confirm: boolean
  text: string
  values?: Record<string, any>
}

interface CreatedObject {
  type: string
  obj: any | null
}

interface FormData {
  closeDrawerClick: boolean
  type: string
  drawer: string
  values: Record<string, any>
  compareTo: Record<string, any>
  isVideoUpdated?: boolean
}

export interface IDataState {
  currentTopic: ITopic | null
  drawerConfirm: DrawerConfirm
  createdObject: CreatedObject
  selectedCompanyId: string
  selectedCompany: {
    name: string
    id: string
  } | null
  formData: FormData
}

export const dataInitialState: IDataState = {
  currentTopic: null,
  drawerConfirm: {
    confirm: false,
    text: '',
    values: {},
  },
  selectedCompanyId: '',

  createdObject: {
    type: '',
    obj: null,
  },
  selectedCompany: null,
  formData: {
    closeDrawerClick: false,
    type: '',
    drawer: '',
    values: {},
    compareTo: {},
    isVideoUpdated: false,
  },
}

export const dataReducer = (state: IDataState, action: IReducerAction): IDataState => {
  switch (action.type) {
    case SET_CURRENT_TOPIC:
      return { ...state, currentTopic: action.payload }

    case SET_FORMDATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          type: action.payload.type,
          drawer: action.payload.drawer,
          values: action.payload.values,
          compareTo: action.payload.compareTo,
          isVideoUpdated: action.payload.isVideoUpdated,
        },
      }

    case CLEAR_FORMDATA:
      return {
        ...state,
        formData: {
          type: '',
          drawer: '',
          values: {},
          compareTo: {},
          closeDrawerClick: false,
        },
      }

    case SET_CLOSE_DRAWER:
      return {
        ...state,
        formData: {
          ...state.formData,
          closeDrawerClick: action.payload,
        },
      }

    case TOGGLE_DRAWER_CONFIRM:
      return {
        ...state,
        drawerConfirm: {
          confirm: action.payload.confirm,
          text: action.payload.text,
        },
      }

    case SET_CREATED_USER:
      return {
        ...state,
        createdObject: {
          obj: action.payload.obj,
          type: action.payload.type,
        },
      }

    default:
      return state
  }
}
