import { ChangeEvent, useEffect } from 'react'
import { useUploadFile, useUploadImage } from 'hooks/helpers/useHelpersService'
import { useFormik } from 'formik'
import { IAttachment } from 'interfaces/common'
import { BookSchema } from 'helpers/validationSchemas'
import { IBookProps } from 'pages/books/AddBookDrawer/bookDrawer.interface'
import { useSwal } from 'hooks/useSwal'
import { useTranslation } from 'react-i18next'
import { useAddBookService } from 'services/books/addBookService'
import { IBookDto } from 'services/books/bookServices.interface'
import { useEditBookService } from 'services/books/editBookService'
import { useLazyFetchBook } from 'services/books/getBookService'
import { IBookDrawerResponse } from './useBookDrawer.interface'
import removeTypeNameFromObject from 'utils/removeTypeNameFromObject'

const useBookDrawer = ({ onClose, companyId, bookId }: IBookProps): IBookDrawerResponse => {
  const { t } = useTranslation()
  const { fireSwal } = useSwal()
  const { file, cropperOpened, setCropperOpened, handleCropSave, handleImageChange, imageLoading } =
    useUploadImage()
  const { uploadFile, fileLoading } = useUploadFile()

  const formData: IBookDto = {
    title: '',
    contentDocument: null as IAttachment | null,
    coverImage: null as IAttachment | null,
    description: '',
    companyId: null,
  }

  const { addBook } = useAddBookService({ companyId })
  const { editBook } = useEditBookService({ bookId })
  const { getBookById } = useLazyFetchBook()

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.target
    if (!files) return
    const file = files[0]
    const fileType = file.name.split('.').slice(-1)[0]
    uploadFile(file, `books/${file.name}`, (link: string) => {
      setFieldValue('contentDocument', {
        name: file.name,
        fileType,
        link,
      })
    })
  }

  const { handleSubmit, values, errors, touched, setFieldValue, handleChange, setValues } =
    useFormik({
      initialValues: formData,
      validationSchema: BookSchema,
      onSubmit(values) {
        const bookDto = { ...values }
        bookDto.companyId = companyId

        if (bookId) {
          editBook({ bookId, updateBookDto: bookDto })
        } else {
          addBook(bookDto)
        }
        onClose()
      },
    })

  useEffect(() => {
    if (bookId) {
      getBookById(bookId).then(({ data }) => {
        if (!data) return
        const {
          title,
          contentDocument: contentDocumentWithTypeName,
          coverImage: coverImageWithTypeName,
          description,
        } = data.getBookById

        const contentDocument = removeTypeNameFromObject(contentDocumentWithTypeName)
        const coverImage = removeTypeNameFromObject(coverImageWithTypeName)

        setValues(prevValues => ({
          ...prevValues,
          title,
          contentDocument,
          coverImage,
          description,
        }))
      })
    }
  }, [bookId])

  const handleCloseDrawer = (): void => {
    if (!values.contentDocument) {
      onClose()
    } else {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        confirmText: t('popups.confirm_cancel'),
        onConfirm: () => onClose(),
      })
    }
  }

  return {
    t,
    file,
    handleCropSave,
    cropperOpened,
    setCropperOpened,
    handleFileChange,
    handleImageChange,
    imageLoading: !!imageLoading,
    fileLoading: !!fileLoading,
    setFieldValue,
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleCloseDrawer,
  }
}

export default useBookDrawer
