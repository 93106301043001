import IconButton from '@mui/material/IconButton'
import { Button } from 'components/common/Button'
import DateRangePicker from 'components/common/DateRangePicker'
import {
  FormButtons,
  FormContainer,
  FormField,
  FormFields,
  FormFooter,
  FormGroupWrapper,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import RangeSlider from 'components/common/RangeSlider'
import { AsyncSelect, SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import {
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Title,
} from 'components/DrawerLayout/styled-components'
import { filterGenderOptions, statuses } from 'components/UsersLayout/staticData'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useFilterCoaches } from 'hooks/coaches/useFilterCoaches'
import { IFilterCoachesProps } from './filterCoaches.interface'

const FilterCoaches = ({
  closeDrawer,
  filterLoading,
  manualCompanyId,
  forAssignCoaches,
  useCompanyId,
  filterOptions,
  setFilterOptions,
  filter,
  defaultFilter,
  refetchStudents,
}: IFilterCoachesProps): JSX.Element => {
  const {
    handleChange,
    handleRangeChange,
    handleSelectChange,
    handleSubmit,
    error,
    errors,
    t,
    touched,
    values,
    setFieldValue,
    companyLoading,
    companyOptions,
    courseData,
    createDate,
    setCreateDate,
    courseLoading,
    groupData,
    isCompanySelected,
    groupLoading,
  } = useFilterCoaches({
    filterOptions,
    manualCompanyId,
    forAssignCoaches,
    useCompanyId,
    filter,
    defaultFilter,
    refetchStudents,
    setFilterOptions,
    closeDrawer,
  })

  return (
    <>
      {filterLoading && <Loader />}
      <DrawerHeader>
        <DrawerTitle>{t('actions.filters')}</DrawerTitle>
        <IconButton onClick={closeDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField width='48%'>
                <TextInput
                  title={t('user_details.first_name')}
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  placeholder={t('user_details.first_name')}
                  size='small'
                  name='firstName'
                  type='text'
                  value={values.firstName}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width='48%'>
                <TextInput
                  title={t('user_details.last_name')}
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  placeholder={t('user_details.last_name')}
                  size='small'
                  name='lastName'
                  type='text'
                  value={values.lastName}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>

              <FormField width='48%'>
                <Title>{t('general.status')}</Title>
                <SelectField
                  className='select-status'
                  name='status'
                  options={statuses}
                  value={values.status}
                  onChange={(e: string): Promise<void> =>
                    setFieldValue('status', e) as Promise<void>
                  }
                />
              </FormField>

              <FormField width='48%' style={{ position: 'relative' }}>
                <Title>{t('groups_layout.select_group')}</Title>

                <AsyncSelect
                  isMulti
                  onInputChange={(e: string): void => handleSelectChange(e, 'group')}
                  onChange={(e: string): Promise<void> =>
                    setFieldValue('group', e) as Promise<void>
                  }
                  data={(groupData && groupData.getAllGroups.data) || []}
                  value={values.group}
                  label={t('form_fields.type_to_search_group')}
                  loading={groupLoading}
                  labelDataKey='name'
                  valueDataKey='id'
                  valueDataKeyPrimary='originalId'
                />
              </FormField>
              <FormField width='48%' style={{ position: 'relative' }}>
                <Title>{t('groups_layout.select_course')}</Title>
                <AsyncSelect
                  isMulti
                  onInputChange={(e: string): void => handleSelectChange(e, 'course')}
                  onChange={(e: string): Promise<void> =>
                    setFieldValue('course', e) as Promise<void>
                  }
                  data={(courseData && courseData.getAllCourse.data) || []}
                  value={values.course}
                  label={t('form_fields.type_to_search_course')}
                  loading={courseLoading}
                  labelDataKey='name'
                  valueDataKey='id'
                  valueDataKeyPrimary='originalId'
                />
              </FormField>
              {!isCompanySelected && (
                <FormField width='48%' style={{ position: 'relative' }}>
                  <Title>{t('groups_layout.select_company')}</Title>
                  <AsyncSelect
                    isMulti
                    onInputChange={(e: string): void => handleSelectChange(e, 'company')}
                    onChange={(e: string): Promise<void> =>
                      setFieldValue('company', e) as Promise<void>
                    }
                    data={companyOptions}
                    value={values.company}
                    label={t('form_fields.type_to_search_company')}
                    loading={companyLoading}
                    labelDataKey='name'
                    valueDataKey='id'
                    iconDataKey='icon'
                  />
                </FormField>
              )}

              <FormField width='48%' style={{ position: 'relative' }}>
                <Title>{t('user_details.gender')}</Title>
                <SelectField
                  className='select-gender'
                  placeholder={t('user_details.gender')}
                  options={filterGenderOptions}
                  value={values.gender}
                  onChange={(e: string): Promise<void> =>
                    setFieldValue('gender', e) as Promise<void>
                  }
                />
              </FormField>

              <FormField width='48%'>
                <TextInput
                  className='select-location'
                  title={t('form_fields.location')}
                  placeholder={t('form_fields.location')}
                  size='small'
                  name='location'
                  type='text'
                  value={values.location}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width={isCompanySelected ? '48%' : '100%'}>
                <TextInput
                  className='select-note'
                  title={t('user_details.note')}
                  placeholder={t('user_details.note')}
                  size='small'
                  name='note'
                  type='text'
                  value={values.note}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width='100%'>
                <Title>{t('general.create_date')}</Title>
                <DateRangePicker values={createDate} setCreateDate={setCreateDate} />
              </FormField>
              <FormField width='100%'>
                <Title>{t('form_fields.age_range')}</Title>
                <RangeSlider
                  value={values.age}
                  onRangeChange={handleRangeChange}
                  style={{ color: '#08C694' }}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.filter')}
                type='small'
                color='secondary'
                btnType='submit'
                background='#06C68F'
                isDisabled={!!error}
              />
              <Button
                text={t('actions.cancel')}
                type='small'
                onClick={closeDrawer}
                background='#E0E1E2'
                textColor='#414141'
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterCoaches
