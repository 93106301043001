import styled from 'styled-components'

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100%;
  height: 100%;
`

export const Label = styled.span`
  margin-right: 8px;
`
export const StyledSelectFieldContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 8px;
`

export const IconContainer = styled.span`
  justify-content: center;
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  pointer-events: none;
  svg {
    color: #aeb5c1;
    width: 24px;
    height: 24px;
  }
`
export const StyledSelectField = styled.select`
  padding: 8px 12px;
  width: 73px;
  height: 43px;
  border: 1px solid #ccc;
  background: #fff;
  color: #68707a;
  border-radius: 4px;
  font-size: 14px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-size: 12px;
  cursor: pointer;
`

export const Entries = styled.span`
  margin-left: 8px;
`
