import { OperationVariables, useQuery } from '@apollo/client'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { GET_QUESTIONS } from 'gql/questions.query'
import { IQuestion } from 'interfaces/questions'

export const PER_PAGE = 12

export interface IUseFetchQuestionsServiceResponse {
  questions: IGetQuestionsResponse
  loading: boolean
  error: string
  refetch: (variables?: OperationVariables) => void
}

export interface IGetQuestionsResponse {
  currentPage: number
  totalPages: number
  totalCount: number
  data: IQuestion[]
}

export const useFetchQuestions = (
  type: 'module' | '' = '',
  id: string,
  filter?: IFilterQueryType,
  currentPage = 1,
  perPage: number = PER_PAGE,
): IUseFetchQuestionsServiceResponse => {
  const { data, loading, error, refetch } = useQuery(GET_QUESTIONS, {
    variables: { type, id, filter, currentPage, perPage },
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''

  return {
    questions: data && data.getQuestions,
    loading,
    error: errorMessage,
    refetch,
  }
}
