import styled from 'styled-components'

export const Container = styled.div<{ error: boolean }>`
  width: 100%;
  position: relative;
  .css-yk16xz-control {
    border-color: ${({ error }): string => (error ? '#f44336' : 'rgba(0, 0, 0, 0.23)')};
    outline: none;
    &:hover {
      border-color: ${({ error }): string => (error ? '#f44336' : 'rgba(0, 0, 0, 0.23)')};
    }
  }
  .css-1wa3eu0-placeholder {
    color: ${({ error }): string => (error ? '#f44336' : 'rgba(0, 0, 0, 0.54) ')};
  }
  & > .css-1m92ju6-control {
    border-color: ${({ error }): string => (error ? '#f44336' : '#06c68f')};
  }

  & > .css-1n7v3ny-option {
    background-color: rgba(6, 198, 144, 0.1) !important;
  }
`

export const ErrorMessage = styled.div`
  position: absolute;
  color: #f44336;
  bottom: -20px;
  left: 6px;
  font-size: 0.75rem;
`

export const ContainerSelect = styled.div<{ error: boolean }>`
  width: 100%;
  position: relative;
  .css-yk16xz-control {
    border-color: ${({ error }): string => (error ? '#f44336' : 'rgba(0, 0, 0, 0.23)')};
    outline: none;
  }
  .css-1wa3eu0-placeholder {
    color: ${({ error }): string => (error ? '#f44336' : 'rgba(0, 0, 0, 0.54) ')};
  }

  .css-1hb7zxy-IndicatorsContainer {
    padding-right: 10px;
    cursor: pointer;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
`
