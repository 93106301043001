import { useLazyQuery } from '@apollo/client'
import { GET_ALL_COURSE } from 'gql/course/course.query'
import { IGetAllCoursesServiceReturn } from './getAllCourseService.interface'

export const getAllCoursesService = (): IGetAllCoursesServiceReturn => {
  const [fetchCourses, { data, loading }] = useLazyQuery(GET_ALL_COURSE)

  return {
    fetchCourses,
    data,
    loading,
  }
}
