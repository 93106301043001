import { OperationVariables, useQuery } from '@apollo/client'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'

import { GET_COMPANY_TUTORS } from 'gql/users.query'
import { IUser } from 'interfaces/users'

export const PER_PAGE = 10

interface IUseFetchCompanyTutorsResponse {
  users: {
    data: IUser[]
    totalCount: number
    currentPage: number
  }
  loading: boolean
  error?: string
  refetch: (variables?: OperationVariables) => void
}

export const useFetchCompanyTutors = (
  filter: IFilterQueryType = {},
  currentPage = 1,
  perPage: number = PER_PAGE,
  companyId?: string,
): IUseFetchCompanyTutorsResponse => {
  const { data, loading, error, refetch } = useQuery(GET_COMPANY_TUTORS, {
    variables: {
      companyId,
      filter,
      currentPage,
      perPage,
    },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    users: data && data.getCompanyTutors,
    loading,
    error: errorMessage,
    refetch,
  }
}
