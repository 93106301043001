import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSwal } from 'hooks/useSwal'
import { AdminSchema } from 'helpers/validationSchemas'
import useAddUserServie from 'hooks/users/useAddUserService'
import { useUserValue } from 'context/UserContext'
import useEditUserService from 'hooks/users/useEditUserService'
import { useData } from 'context/DataContext'
import { CLEAR_FORMDATA, SET_FORMDATA } from 'store/types'
import { getUserByIdService } from 'services/users/getUserById/getUserByIdService'
import { roles } from 'utils/permission'
import { genderOptions } from 'components/UsersLayout/staticData'
import { IFormData } from 'pages/admins/EditAdmin/editAdmin.interface'
import moment from 'moment'
import { DrawerEventEmitter } from 'helpers/drawer'
import { IUseAdminsDrawerProps, IUseAdminsDrawerReturn } from './useAdminDrawer.interface'
import { ISelectFilterOption } from 'interfaces/common'
import { IUser } from 'interfaces/users'
import useCheckUserEmailService from 'hooks/users/checkUserEmail'

const useAdminDrawer = ({
  onClose,
  adminId,
  drawerData,
}: IUseAdminsDrawerProps): IUseAdminsDrawerReturn => {
  const { t } = useTranslation()
  const { fireSwal } = useSwal()
  const [state] = useUserValue()
  const [dataState, dispatch] = useData()
  const [currentAdmin, setCurrentAdmin] = useState<IFormData | null>(null)

  const closeDrawer = (): void => {
    if (onClose) {
      onClose()
    } else if (drawerData && drawerData.onClose) {
      drawerData.onClose()
    }
  }
  const onSuccess = (): void => {
    closeDrawer()
    dispatch({ type: CLEAR_FORMDATA })
    if (drawerData && drawerData.onSuccess) {
      drawerData.onSuccess()
    }
  }

  const initialValues: IFormData = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    role: '',
    group: [],
    jobTitle: '',
    location: '',
    gender: null,
    phoneFields: null,
    phoneFieldValue: null,
    biography: '',
    note: '',
    birthDate: '',
    avatar: '',
    isTutor: false,
    requestPasswordChange: false,
  }

  const { addUser, addUserLoading } = useAddUserServie()

  const { updateUser, editUserLoading, user } = useEditUserService()
  const { checkUserEmail } = useCheckUserEmailService()
  const { data } = getUserByIdService(adminId ?? '')

  const {
    setFieldValue,
    handleSubmit,
    values,
    handleChange,
    errors,
    touched,
    setValues,
    setErrors,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: AdminSchema,
    onSubmit: async values => {
      //if adminID exists, then we are editing the user
      if (adminId && data) {
        if (values.email !== data.email) {
          const emailError = await checkUserEmail(values.email)
          if (emailError) {
            setErrors({ email: emailError })
            return
          }
        }
        const formValues: IFormData = {
          ...values,
          gender: (values.gender as ISelectFilterOption)?.value ?? values.gender ?? null,
        }
        delete formValues.phoneFieldValue

        if (formValues.phoneFields && formValues.phoneFields.value) {
          formValues.phoneFields = {
            dialCode: formValues.phoneFields.value,
            code: formValues.phoneFields.label,
          }
        } else {
          formValues.phoneFields = null
        }

        updateUser(adminId, formValues as IUser)
      } else {
        if (addUserLoading) return
        const emailError = await checkUserEmail(values.email)
        if (emailError) {
          setErrors({ email: emailError })
          return
        }

        const formValues: IFormData = {
          ...values,
          requestPasswordChange: state.currentUser.settings?.askPassword,
          gender: (values.gender as ISelectFilterOption)?.value ?? values.gender ?? null,
        }
        delete formValues.phoneFieldValue
        formValues.role = drawerData?.role ? drawerData.role : roles.admin

        if (formValues.phoneFields && formValues.phoneFields.value) {
          formValues.phoneFields = {
            dialCode: formValues.phoneFields.value, // dial code should be something like GE
            code: formValues.phoneFields.label, // code should be something like +995
          }
        } else {
          formValues.phoneFields = null
        }

        addUser(
          formValues as IUser,
          roles.admin,
          drawerData?.companyId || (state.selectedCompany?.id as string),
          onSuccess,
        )
      }
    },
  })
  const closeDrawerEdit = (): void => {
    DrawerEventEmitter.emit('openDrawer', 'userAdd', false)
  }

  const handleCloseDrawer = (): void => {
    fireSwal({
      title: t('popups.close_popup'),
      text: t('popups.sure'),
      confirmText: t('popups.confirm_cancel'),
      onConfirm: onClose,
    })
  }
  const [currentDate, setCurrentDate] = useState<Date | null>(null)

  const onDateChange = (date: Date | null): void => {
    // setCurrentDate(date)
    const birthDate = date
    setFieldValue('birthDate', date)

    if (birthDate) {
      values.birthDate = birthDate
    }
  }

  useEffect(() => {
    if (values.birthDate) {
      setCurrentDate(
        new Date(typeof (values.birthDate === 'string') ? values.birthDate : +values.birthDate),
      )
    }
  }, [values.birthDate])

  //this useEffect is for closing drawer when user is edited
  useEffect(() => {
    if (user) {
      closeDrawerEdit()
    }
  }, [user])

  const handleCloseDrawerEdit = (): void => {
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editAdmin',
        values,
        compareTo: currentAdmin,
      },
    })
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawerEdit()
    }
  }, [dataState.formData.closeDrawerClick])

  //this useEffect is for setting values when editing user

  useEffect(() => {
    if (data) {
      const {
        firstName,
        lastName,
        email,
        phone,
        jobTitle,
        location,
        gender,
        phoneFields,
        birthDate,
        biography,
        note,
        isTutor,
        avatar = '',
      } = data
      const userGender = genderOptions.find(
        (option: { value: string; label: string }) => option.value === gender,
      )
      const formData: IFormData = {
        firstName,
        lastName,
        email,
        phone,
        jobTitle,
        location,
        gender: gender
          ? ({
              label: userGender?.label,
              value: gender,
            } as ISelectFilterOption)
          : null,
        phoneFields: phoneFields?.dialCode
          ? { label: phoneFields?.code, value: phoneFields.dialCode }
          : null,
        phoneFieldValue: phoneFields?.dialCode || null,
        biography,
        note,
        birthDate: birthDate ? moment(parseInt(birthDate.toString())).format() : null,
        isTutor,
        avatar,
      }
      setValues({
        ...formData,
      })
      setCurrentAdmin(formData)
    }
  }, [data])

  return {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    handleCloseDrawer,
    handleCloseDrawerEdit,
    setFieldValue,
    onDateChange,
    currentDate,
    t,
    addUserLoading,
    state,
    genderOptions,
    editUserLoading,
  }
}

export default useAdminDrawer
