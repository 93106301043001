import React, { useEffect, useState } from 'react'
import {
  StyledItem,
  StyledItemContainer,
  StyledTitle,
  ItemContainer,
  loaderStyles,
} from './styled-components'
import { SelectField } from 'components/common/SelectField'
import { FormGroupWrapper, FormFooter, FormButtons } from 'components/common/Form'
import { Button } from 'components/common/Button'
import MyLoader from 'loading/loading'
import {
  IAssignComponentProps,
  IProps,
} from 'hooks/course/addCourseDrawer/assignCoach/AssignCoach.interface'
import { useTranslation } from 'react-i18next'
import useAssignCoach from 'hooks/course/addCourseDrawer/assignCoach/useAssignCoach'
import { IOptionType } from 'pages/coaches/AddCoach/addCoach.interface'

const AssignComponent = ({
  item,
  coaches,
  assignCoach,
  courseId,
}: IAssignComponentProps): React.JSX.Element => {
  const { t } = useTranslation()
  const [coach, setCoach] = useState<IOptionType | null>(null)

  useEffect(() => {
    const course = item?.courses && item.courses.find(i => i.courseId === courseId)
    if (course && course.coach && coaches.find(i => i.value === course.coach.coachId)) {
      setCoach({ label: course.coach.name, value: course.coach.coachId })
    }
  }, [item.courses, courseId, coaches])

  return (
    <StyledItem>
      <StyledTitle>{item.name}</StyledTitle>
      <ItemContainer>
        <SelectField
          placeholder={t('general.coach')}
          options={coaches}
          isClearable
          value={coach}
          onChange={(e: IOptionType | null): void => {
            setCoach(e || { label: '', value: '' })
            assignCoach(item.id as string, e ? e.value : undefined, courseId, () => undefined, t)
          }}
        />
      </ItemContainer>
    </StyledItem>
  )
}

const AssignCoach = (props: IProps): React.JSX.Element => {
  const { t } = useTranslation()
  const courseId = props.courseId || ''
  const { assignCoach, loading, tutorsLoading, groups, coaches } = useAssignCoach(
    courseId,
    props.values,
  )

  return (
    <>
      <FormGroupWrapper padding={10}>
        {(loading || tutorsLoading) && <MyLoader width={250} speed={0.8} style={loaderStyles} />}
        <StyledItemContainer>
          {groups.map((i, index: number) => (
            <AssignComponent
              item={i}
              coaches={coaches}
              assignCoach={assignCoach}
              courseId={courseId}
              key={index}
            />
          ))}
        </StyledItemContainer>
      </FormGroupWrapper>

      <FormFooter>
        <FormButtons multiCols={true}>
          <Button
            text={t('actions.cancel')}
            type='small'
            onClick={props.handleDeleteCourse}
            background='#E0E1E2'
            textColor='#414141'
          />

          <Button
            text={t('actions.previous')}
            type='small'
            color='secondary'
            onClick={(): void => props.handleChangeStep(1)}
            background='#E0E1E2'
            textColor='#414141'
          />

          <Button
            text={props.values.finished === 3 ? t('actions.save') : t('actions.finish')}
            type='small'
            color='secondary'
            onClick={(): void => props.handleButtonClick('next')}
            background='#06C68F'
          />
        </FormButtons>
      </FormFooter>
    </>
  )
}

export default AssignCoach
