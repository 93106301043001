import { styled } from 'styled-components'

export const DrawerWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  font-family: Open Sans;
  height: 100%;
  position: relative;
`
export const StyledLabel = styled.h6`
  font-size: 18px;
  font-weight: 600;
`
