import { OperationVariables, useQuery } from '@apollo/client'
import { GET_TUTORS } from 'gql/users.query'
import { IUser } from 'interfaces/users'

export const useFetchTutorsByCourseId = (
  searchValue: string,
  courseId: string,
  groupId: string | null,
  currentPage: number,
  perPage: number,
): {
  tutors: {
    data: IUser[]
    totalCount: number
    totalPages: number
  }
  loading: boolean
  error: string | undefined
  refetch: (variables: OperationVariables) => void
} => {
  const { data, loading, error, refetch } = useQuery(GET_TUTORS, {
    variables: {
      searchValue,
      courseId,
      groupId,
      currentPage,
      perPage,
    },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    tutors: data && data.getTutorsByCourseId,
    loading,
    error: errorMessage,
    refetch,
  }
}
