import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useUserValue } from 'context/UserContext'
import { useData } from 'context/DataContext'
import { DrawerEventEmitter } from 'helpers/drawer'
import { SET_FORMDATA, CLEAR_FORMDATA } from 'store/types'
import {
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Title,
} from 'components/DrawerLayout/styled-components'
import {
  FormButtons,
  FormContainer,
  FormField,
  FormFields,
  FormFooter,
  FormGroupWrapper,
} from 'components/common/Form/Form'
import TextInput from 'components/common/TextInput/TextInput'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'
import { SelectField, AsyncSelect } from 'components/common/SelectField'
import DatePicker from 'components/common/DatePicker'
import CustomSwitcher from 'components/common/Switcher/CustomSwitcher'
import Loader from 'components/common/Loader'
import { Button } from 'components/common/Button'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { genderOptions } from 'components/UsersLayout/staticData'
import { NotAllowed } from 'pages/profile/AdminProfile/styled-components'
import useModeratorsDrawer from 'hooks/useModeratorsDrawer/useModeratorsDrawer'
import { ISelectFilterOption } from 'interfaces/common'

interface Props {
  title?: string
  role: string
  drawerName?: string
  closeDrawer?: () => void
  id: string
}

const EditModerator = ({ title, role, drawerName, id }: Props): React.JSX.Element => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [dataState, dispatch] = useData()

  const onSuccess = (): void => {
    closeDrawer()
    dispatch({ type: CLEAR_FORMDATA })
  }

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    userLoading,
    companyOptions,
    companyLoading,
    addingCompanyUser,
    setAddingCompanyUser,
    currentModerator,
    onDateChange,
    getCompanyOptions,
    companyData,
  } = useModeratorsDrawer({
    role,
    userId: id,
    companyId: state.selectedCompany?.id as string,
    onSuccess,
  })

  const closeDrawer = (): void => {
    DrawerEventEmitter.emit('openDrawer', { drawerName }, false)
  }

  const handleCloseDrawer = (): void => {
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editModerator',
        values,
        compareTo: currentModerator,
      },
    })
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  if (state?.currentUser?.role === 'CONSULTANT' && !state?.selectedCompany?.id) {
    return (
      <>
        <DrawerHeader>
          <DrawerTitle>{title}</DrawerTitle>
          <IconButton onClick={handleCloseDrawer}></IconButton>
        </DrawerHeader>
        <DrawerContent headerHeight={68}>
          <NotAllowed>
            <p>Please switch to any company context to edit Super Admin</p>
          </NotAllowed>
        </DrawerContent>
      </>
    )
  }

  return (
    <>
      {userLoading && <Loader />}
      <DrawerHeader>
        <DrawerTitle>{title}</DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent headerHeight={68}>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField width='48%'>
                <TextInput
                  title={t('user_details.first_name')}
                  required
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  size='small'
                  placeholder={t('user_details.first_name')}
                  name='firstName'
                  type='text'
                  value={values.firstName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width='48%'>
                <TextInput
                  title={t('user_details.last_name')}
                  required
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  size='small'
                  placeholder={t('user_details.last_name')}
                  name='lastName'
                  type='text'
                  value={values.lastName}
                  onChange={handleChange}
                />
              </FormField>

              <FormField width='48%'>
                <TextInput
                  error={!!errors.email}
                  errorMessage={errors.email || ''}
                  touched={touched.email}
                  title={t('user_details.email')}
                  required
                  size='small'
                  placeholder={t('user_details.email')}
                  name='email'
                  type='text'
                  value={values.email}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width='48%'>
                <Title>{t('user_details.phone')}</Title>
                <PhoneCountryCode
                  selectLabel={t('form_fields.dial_code')}
                  selectValue={values.phoneFields || null}
                  selectChange={(e: ISelectFilterOption | null): void =>
                    setFieldValue('phoneFields', e as ISelectFilterOption)
                  }
                  inputLabel='Phone'
                  inputValue={values.phone}
                  inputChange={handleChange}
                  error={errors.phone || errors.phoneFields ? true : false}
                  errorMessage={
                    errors.phone || errors.phoneFields ? errors.phone || errors.phoneFields : ''
                  }
                  touched={touched.phone || touched.phoneFields}
                />
              </FormField>

              <FormField width='100%'>
                <Title>{t('user_details.select_gender')}</Title>
                <SelectField
                  placeholder={t('user_details.select_gender')}
                  options={genderOptions}
                  value={values.gender}
                  onChange={(e: string): void => setFieldValue('gender', e)}
                  isClearable
                />
              </FormField>
              <FormField width='100%'>
                <Title>{t('user_details.birthday')}</Title>
                <DatePicker
                  name='birthDate'
                  value={values.birthDate}
                  onChange={(date: Date | null): void =>
                    onDateChange(date ? date.toISOString() : '')
                  }
                />
              </FormField>
              <FormField width='100%'>
                <TextInput
                  title={`${t('form_fields.job_title')}`}
                  size='small'
                  placeholder={`${t('form_fields.job_title')}`}
                  name='jobTitle'
                  type='text'
                  value={values.jobTitle}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width='100%'>
                <TextInput
                  title={`${t('form_fields.location')}`}
                  size='small'
                  placeholder={`${t('form_fields.location')}`}
                  name='location'
                  type='text'
                  value={values.location}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width='100%'>
                <TextInput
                  title={`${t('user_details.biography')}`}
                  size='small'
                  placeholder={`${t('quiz_details.type_here')}`}
                  name='biography'
                  type='text'
                  value={values.biography}
                  onChange={handleChange}
                  multiline
                  rows='3'
                />
              </FormField>
              <FormField width='100%'>
                <TextInput
                  title={`${t('user_details.note')}`}
                  placeholder={`${t('user_details.note')}`}
                  size='small'
                  name='note'
                  type='text'
                  value={values.note}
                  onChange={handleChange}
                  multiline
                  rows='3'
                />
              </FormField>
              {!state.currentUser?.companyId && !state.selectedCompany?.id && (
                <FormField width='100%'>
                  <CustomSwitcher
                    onChange={(): void => {
                      setAddingCompanyUser(!addingCompanyUser)
                      if (!companyData) {
                        getCompanyOptions({
                          variables: {
                            currentPage: 0,
                            perPage: 0,
                          },
                        })
                      }
                      if (values.consultantOfCompanies) {
                        setFieldValue('consultantOfCompanies', [])
                      }
                    }}
                    label={t('users_layout.company_consultant')}
                    name='consultantOfCompanies'
                    value={addingCompanyUser}
                  />
                </FormField>
              )}
              {addingCompanyUser && (
                <FormField
                  width='100%'
                  style={{
                    height: 320,
                  }}
                >
                  <AsyncSelect
                    error={errors.consultantOfCompanies}
                    touched={addingCompanyUser}
                    errorMessage={errors.consultantOfCompanies}
                    isMulti
                    onChange={(e: string): void => setFieldValue('consultantOfCompanies', e || [])}
                    loading={companyLoading}
                    data={companyOptions}
                    value={values.consultantOfCompanies}
                    labelDataKey='name'
                    valueDataKey='id'
                    label={t('notifications_layout.companies')}
                  />
                </FormField>
              )}
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.save')}
                type='small'
                color='secondary'
                btnType='submit'
                background='#06C68F'
              />
              <Button
                text={t('actions.cancel')}
                type='small'
                onClick={handleCloseDrawer}
                background='#E0E1E2'
                textColor='#414141'
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default EditModerator
