import styled from 'styled-components'

export const LayoutWrapper = styled.div`
  background-color: #fff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const DetailsItemContainer = styled.div`
  display: flex;
  justify-content: space-around;
`
export const DetailsItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const Whiteboard = styled.div`
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  min-height: 450px;
  padding: 20px;
  display: flex;
  border: 1px solid #e0e0e0;
  flex-direction: column;
  align-items: center;
`

export const DetailsItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  span {
    text-transform: capitalize;
  }
`

export const Header = styled.header``

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
`
