import { Radio } from '@mui/material'
import React, { ChangeEvent, ReactElement } from 'react'

const RadioButton = ({
  checked,
  value,
  onChange,
}: {
  value?: string
  checked: boolean
  ariaLabel?: string
  name?: string
  onChange?: (value: string) => void
}): ReactElement => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void =>
    onChange && onChange(event.target.value)

  return (
    <Radio
      checked={checked}
      onChange={handleChange}
      value={value}
      sx={{
        '&.Mui-checked': {
          color: '#00905E',
        },
      }}
    />
  )
}

export default RadioButton
