import { IUser } from 'interfaces/users'
import {
  SET_CURRENT_COMPANY,
  FETCH_CURRENT_USER,
  SET_SELECTED_COMPANY_ID,
  SET_SELECTED_COMPANY,
  TOGGLE_CLOSE_DRAWER,
  CURRENT_USER_PERMISSION,
  IReducerAction,
} from '../types'
import { ICompany } from 'pages/companies/CompanyDetails/types'
import { IPermissionsBool } from 'utils/rolesObject/rolesObject.interface'

export interface IUserState {
  isAuthenticated: boolean
  currentUser: IUser
  currentCompany: ICompany
  selectedCompany: {
    name: string
    id: string
  } | null
  selectedCompanyId: string
  closeDrawer: {
    text: string
    confirm: boolean
  }
  refetch: boolean
  userPermission: IPermissionsBool
}

export const userInitialState: IUserState = {
  isAuthenticated: false,
  currentUser: {} as IUser,
  currentCompany: {} as ICompany,
  selectedCompanyId: '',
  selectedCompany: null,
  userPermission: {},
  closeDrawer: {
    text: '',
    confirm: false,
  },
  refetch: false,
}

export const userReducer = (state: IUserState, action: IReducerAction): IUserState => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, isAuthenticated: true }

    case 'LOGOUT':
      return { ...state, isAuthenticated: false }

    case 'SET_REFETCH':
      return { ...state, refetch: true }

    case 'REMOVE_REFETCH':
      return { ...state, refetch: false }

    case FETCH_CURRENT_USER:
      return { ...state, currentUser: action.payload }

    case SET_CURRENT_COMPANY:
      return { ...state, currentCompany: action.payload }

    case CURRENT_USER_PERMISSION:
      return { ...state, userPermission: action.payload }

    case SET_SELECTED_COMPANY_ID: {
      return { ...state, selectedCompanyId: action.payload }
    }
    case SET_SELECTED_COMPANY: {
      return { ...state, selectedCompany: action.payload }
    }

    case TOGGLE_CLOSE_DRAWER: {
      return {
        ...state,
        closeDrawer: {
          text: action.payload.text,
          confirm: action.payload.confirm,
        },
      }
    }

    default:
      return state
  }
}
