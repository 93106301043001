import { useState } from 'react'
import addModuleService from 'services/modules/addModuleService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import { IModule } from 'pages/modules/ModulesList.interface'
import { ICourseBriefData } from 'pages/courses/courseList.interface'
import { ICourseData } from 'components/modules/AddModule.interface'

const useAddModuleService = (): {
  addModule: (input: ICourseData, courseData: ICourseBriefData) => Promise<void>
  addModuleLoading: boolean
  newModule: IModule | null
  error: string
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [newModule, setNewModule] = useState<IModule | null>(null)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { addModuleMutation } = addModuleService()

  const addModule = async (input: ICourseData, courseData: ICourseBriefData): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { data, errors } = await addModuleMutation({
      variables: {
        input,
        courseData,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        message = `${t('general.user')} ${t('messages.already_registered')}`
        setError(message)
        return
      }
      variant = 'error'
    } else if (data) {
      setNewModule(data.addModule)
      message = courseData.name
        ? `${t('general.module')} ${t('messages.saved_and_added_to_course')}`
        : `${t('general.module')} ${t('messages.saved')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return {
    addModule,
    addModuleLoading: loading,
    newModule,
    error,
  }
}

export default useAddModuleService
