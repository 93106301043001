import React from 'react'
import { Button } from 'components/common/Button'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { DrawerContent, DrawerHeader, DrawerTitle } from 'components/DrawerLayout/styled-components'
import { FormButtons, FormFooter } from 'components/common/Form'
import { IGroup } from 'interfaces/groups'
import {
  BooksContainer,
  BooksGrid,
  BooksHeader,
  BooksWrapper,
  CheckBoxFlex,
  DrawerContentContainer,
  FilterHeader,
  FilterTitle,
} from '../styled-components'
import BookItem from 'pages/books/BookItem/BookItem'
import TextInput from 'components/common/TextInput/TextInput'
import { ReactComponent as SearchRoundedIcon } from 'assets/search.svg'
import CheckBox from 'components/common/Checkbox'
import useAddBookToGroup from 'hooks/books/useAddBookToGroup'

const AddBookToGroup = ({
  groupData,
  closeDrawer,
}: {
  groupData: IGroup
  closeDrawer: () => void
  bookId?: string
}): React.JSX.Element => {
  const {
    t,
    onSearchSubmit,
    searchValue,
    handleSearchChange,
    selectAllItem,
    isAllSelected,
    books,
    handleButtonClick,
    selectedItems,
    handleSelectItem,
  } = useAddBookToGroup({ groupData, closeDrawer })

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>{t('books_layout.add_book')}</DrawerTitle>
        <IconButton onClick={closeDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent headerHeight={68}>
        <FilterHeader>
          <FilterTitle>{t('actions.filters')}</FilterTitle>
        </FilterHeader>
        <FilterHeader width='50%'>
          <form onSubmit={onSearchSubmit}>
            <TextInput
              label={t('general.search_placeholder')}
              type='text'
              size='small'
              value={searchValue}
              onChange={handleSearchChange}
              icon={
                <IconButton type='submit'>
                  <SearchRoundedIcon />
                </IconButton>
              }
              $iconInStart
            />
          </form>
        </FilterHeader>
        <DrawerContentContainer>
          <BooksHeader>
            <FilterTitle>{t('general.books')}</FilterTitle>
            <BooksWrapper>
              <CheckBoxFlex>
                <FilterTitle>{t('general.select_all')}</FilterTitle>
                <CheckBox onChange={selectAllItem} checked={isAllSelected} />
              </CheckBoxFlex>
            </BooksWrapper>
          </BooksHeader>
          <>
            <BooksContainer>
              {(books?.data?.length ?? 0) > 0 && (
                <BooksGrid>
                  {books.data?.map(book => (
                    <BookItem
                      key={book.id}
                      book={book}
                      setIsSearchType={(): void => undefined}
                      selected={selectedItems.includes(book.id)}
                      onSelect={handleSelectItem}
                      showCheckbox={true}
                    />
                  ))}
                </BooksGrid>
              )}
            </BooksContainer>
          </>

          <FormFooter>
            <div
              style={{
                backgroundColor: 'white',
                position: 'relative',
                zIndex: 2,
              }}
            >
              <FormButtons>
                <Button
                  text={t('actions.save')}
                  type='small'
                  onClick={handleButtonClick}
                  background='#06C68F'
                />
                <Button
                  text={t('actions.cancel')}
                  type='small'
                  onClick={closeDrawer}
                  background='#E0E1E2'
                  textColor='#414141'
                />
              </FormButtons>
            </div>
          </FormFooter>
        </DrawerContentContainer>
      </DrawerContent>
    </>
  )
}

export default AddBookToGroup
