import { FormGroupItem } from 'components/common/Form'
import { PasswordInput } from 'components/common/TextInput/PasswordInput'
import React, { useCallback, useEffect, useState } from 'react'
import { InputsContainer, ErrorText } from './styled-components'
import { PasswordWithValidations } from './PasswordWithValidations'
import { TFunction } from 'interfaces/TFunction'

interface IFormValues {
  oldPassword?: string | undefined
  newPassword?: string | undefined
  confirmPassword?: string | undefined
}

interface IFormTouched {
  oldPassword?: boolean | undefined
  newPassword?: boolean | undefined
  confirmPassword?: boolean | undefined
}

interface IEditProfileFieldsProps {
  values: IFormValues
  touched: IFormTouched
  handleChange: (eventOrTextValue: string | React.FormEvent<HTMLInputElement>) => void
  currentPasswordError: string
  t: TFunction
}

export const ChangePasswordFields: React.FC<IEditProfileFieldsProps> = ({
  values,
  touched,
  handleChange,
  currentPasswordError,
  t,
}) => {
  const [confirmPasswordError, setConfirmPasswordError] = useState('')

  const onChangeConfirmPassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(e)
      if (values.confirmPassword !== e.target.value) {
        setConfirmPasswordError('Passwords do not match')
      } else setConfirmPasswordError('')
    },
    [values.confirmPassword, confirmPasswordError],
  )

  useEffect(() => {
    if (
      values.newPassword !== values.confirmPassword &&
      values.confirmPassword &&
      values.confirmPassword.length > 0
    ) {
      setConfirmPasswordError('Passwords do not match')
    } else setConfirmPasswordError('')
  }, [values.newPassword, values.confirmPassword])

  return (
    <InputsContainer>
      <FormGroupItem>
        <PasswordInput
          touched={touched.oldPassword}
          label={t('form_fields.current_password')}
          name='oldPassword'
          value={values.oldPassword}
          onChange={handleChange}
          error={currentPasswordError.length > 1}
          size='small'
        />
        <ErrorText>{currentPasswordError}</ErrorText>
      </FormGroupItem>
      <PasswordWithValidations handleChange={handleChange} />
      <FormGroupItem>
        <PasswordInput
          touched={touched.confirmPassword}
          label={t('form_fields.confirm_password')}
          name='confirmPassword'
          size='small'
          onChange={onChangeConfirmPassword}
          value={values.confirmPassword}
          error={confirmPasswordError.length > 1}
        />
        <ErrorText>{confirmPasswordError}</ErrorText>
      </FormGroupItem>
    </InputsContainer>
  )
}
