import React, { useState, forwardRef } from 'react'

import { ClearTextIcon, ShowPasswordIcon, ViewOffIcon } from 'assets/svg'

import { ICustomInputProps } from './CustomInput.interface'
import {
  StyledInputWrapper,
  StyledInput,
  StyledInputLabel,
  StyledTextareaLabel,
  StyledTextarea,
} from './CustomInput.styles'

const CustomInput = forwardRef<HTMLInputElement | HTMLTextAreaElement, ICustomInputProps>(
  (
    {
      type,
      placeholder,
      onChange,
      label,
      iconLeft,
      iconRight,
      value,
      disabled = false,
      readOnly = false,
      customStyles,
      rows,
      cols,
      name,
      autoComplete = 'off',
      onClick,
      customWrapperStyles,
    },
    ref,
  ) => {
    const [inputType, setInputType] = useState(type)

    const handleVisibilityToggle = (): void => {
      setInputType(inputType === 'password' ? 'text' : 'password')
    }

    const clearText = (): void => {
      onChange({
        target: { name, value: '' },
      } as React.ChangeEvent<HTMLInputElement>)
    }

    const PasswordSvgIcon = inputType === 'password' ? ShowPasswordIcon : ViewOffIcon

    return (
      <StyledInputWrapper onClick={onClick} style={customWrapperStyles}>
        {type === 'textarea' ? (
          <StyledTextareaLabel>
            {label}
            <StyledTextarea
              ref={ref as React.RefObject<HTMLTextAreaElement>}
              name={name}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readOnly}
              rows={rows}
              cols={cols}
              style={customStyles}
              autoComplete={autoComplete}
            />
          </StyledTextareaLabel>
        ) : (
          <StyledInputLabel>
            {iconLeft}
            {label}
            <StyledInput
              ref={ref as React.RefObject<HTMLInputElement>}
              name={name}
              type={inputType}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              $paddingLeft={!!iconLeft}
              $paddingRight={!!iconRight || type === 'password'}
              $valueFilled={!!value}
              disabled={disabled}
              readOnly={readOnly}
              style={customStyles}
              autoComplete={autoComplete}
            />
            {value && type !== 'textarea' && !readOnly && !disabled && (
              <ClearTextIcon className={'clearTextIcon'} onClick={clearText} />
            )}
            {type !== 'password' && !!iconRight && iconRight}
            {type === 'password' && (
              <PasswordSvgIcon className={'showPasswordIcon'} onClick={handleVisibilityToggle} />
            )}
          </StyledInputLabel>
        )}
      </StyledInputWrapper>
    )
  },
)

CustomInput.displayName = 'CustomInput'

export default CustomInput
