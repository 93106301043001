import React, { ReactElement } from 'react'
import DomainIcon from '@mui/icons-material/Domain'
import PersonIcon from '@mui/icons-material/Person'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
import WidgetsIcon from '@mui/icons-material/Widgets'
import CustomStepper from 'components/common/Stepper/Custom/Stepper'
import { Container } from './styled-components'
import { TFunction } from 'interfaces/TFunction'

interface Props {
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  completedSteps: number[]
  t: TFunction
}

const WizardStepper = ({ step, setStep, completedSteps, t }: Props): ReactElement => {
  const steps = [
    { label: t('company_wizard_layout.company_details'), icon: <DomainIcon /> },
    {
      label: t('company_wizard_layout.invite_company_super_admins'),
      icon: <span className='material-icons'>groups</span>,
    },
    { label: t('general.purchased_courses'), icon: <WidgetsIcon /> },
    {
      label: t('company_wizard_layout.group_admins'),
      icon: <PersonIcon />,
    },
    {
      label: t('company_wizard_layout.group_of_students'),
      icon: <span className='material-icons'>groups</span>,
    },
    { label: t('general.students'), icon: <PersonIcon /> },
    {
      label: t('company_wizard_layout.assign_coaches'),
      icon: <RecordVoiceOverIcon />,
    },
  ]

  return (
    <Container>
      <CustomStepper
        activeStep={step}
        setStep={setStep}
        steps={steps}
        completedSteps={completedSteps}
      />
    </Container>
  )
}

export default WizardStepper
