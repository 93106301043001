import React from 'react'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { useNavigate } from 'react-router-dom'
import { Container } from './styled-components'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import IconButton from '@mui/material/IconButton'
import { IBreadcrumbItem, IBreadcrumbState } from './types'

export default function Breadcrumb({
  data,
  backBtn = true,
}: {
  data: IBreadcrumbItem[]
  backBtn?: boolean
}): JSX.Element {
  const navigate = useNavigate()

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    link: string,
    state: IBreadcrumbState = { id: 0, name: '' },
  ): void => {
    event.preventDefault()
    navigate(link, {
      state,
    })
  }
  return (
    <Container>
      {backBtn && (
        <IconButton onClick={(): void => navigate(-1)}>
          <KeyboardBackspaceIcon />
        </IconButton>
      )}
      <Breadcrumbs aria-label='breadcrumb'>
        {data &&
          data.map((item: IBreadcrumbItem, index: number) => {
            return item.link ? (
              <Link
                color='inherit'
                href='/'
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void =>
                  handleClick(e, item.link || '', item.state)
                }
                key={index}
              >
                {item.label}
              </Link>
            ) : (
              <Typography key={index} color='textPrimary'>
                {item.label}
              </Typography>
            )
          })}
      </Breadcrumbs>
    </Container>
  )
}
