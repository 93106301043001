import styled from 'styled-components'

export const SkillTestDetailsField = styled.div`
  margin-bottom: 16px;
  width: 100%;
  ${({ hidden }): string => (hidden && 'display: none') || ''}
`

export const SkillTestDetailsFieldTitle = styled.label`
  display: block;
  width: 100%;
  font-size: 16px;
  margin-bottom: 10px;
  opacity: 1;
  font-weight: 600;
`
export const BadgePlaceholder = styled.div`
  width: 150px;
  height: 150px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: rgb(6, 198, 143);
  }
`

export const DrawerContent = styled.div<{ headerHeight?: number }>`
  //margin of image container

  .sc-ejhnle {
    margin-top: 20px !important;
  }

  //this is restyle of addDrawer badge container
  /* .sc-hFCkeM { */
  /* width: 200px; */
  /* height: 200px; */
  /* } */

  /* .sc-kgTcBI { */
  /* width: 200px; */
  /* height: 200px; */
  /* } */
  /* .sc-gCabtr { */
  /* width: 200px; */
  /* height: 200px; */
  /* } */

  //this is restyle of editDrawer badge container

  /* .sc-hbEPms { */
  /* width: 200px; */
  /* height: 200px; */
  /* } */

  //this is restyle of drawers input container
  .sc-cbDbxV {
    margin-bottom: 10px !important;
  }
  .MuiFormControlLabel-root {
    justify-content: space-between !important;
    width: 250px !important;
  }

  .MuiSwitch-root {
    width: 70px;
    height: 53px;
  }

  .MuiSwitch-track {
    border-radius: 50px;
  }

  .MuiButtonBase-root {
  }

  .MuiSwitch-thumb {
    color: #fff !important;
    width: 24px;
    height: 24px;
    margin-top: 5px;
    margin-left: 4px;
  }

  .Mui-checked + .MuiSwitch-track {
    background-color: #00905e !important;
  }
  position: relative;
  height: ${({ headerHeight }): string =>
    headerHeight ? `calc(100% - ${headerHeight}px)` : `calc(100% - 68px)`};
`

export const BadgeField = styled.div`
  height: 170px;
`
export const StyledUploadLabel = styled.p<{ withoutMargin?: boolean }>`
  font-size: 1rem;
  font-weight: 550;
  color: black;
  margin-bottom: ${({ withoutMargin }): string => (withoutMargin ? '0' : '5px')};
`
