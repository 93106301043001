import styled from 'styled-components'

export const SentForReview = styled.th`
  display: table-cell;
  font-size: 0.875rem;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
  & > div {
    min-height: 60px;
    display: inline-block;
    padding: 10px 0px;
    & > div {
      cursor: pointer;
      display: inline-block;
      margin-right: 10px;
    }
  }
`

export const HeaderRow = styled.thead`
  > tr {
    > th {
      display: table-cell;
      font-size: 0.875rem;
      text-align: left;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-weight: 400;
      line-height: 1.43;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      letter-spacing: 0.01071em;
      vertical-align: inherit;
      cursor: pointer;
      > svg {
        color: #0000008a;
        font-size: 18px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 3px;
        margin-bottom: 4px;
      }
    }
  }
`

export const TableRow = styled.tr`
  cursor: pointer;
  &:hover {
    background-color: #0000000a;
  }
`
export const Cell = styled.th`
  display: table-cell;
  font-size: 0.875rem;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
  > img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
  }
  > svg {
    color: #cccccc;
    margin-left: 8px;
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
  }
`

export const StatusWrapper = styled.th`
  display: table-cell;
  font-size: 0.875rem;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
  font-size: 14px;

  &.approved {
    color: green;

    span {
      background: green;
    }
  }

  &.declined {
    color: red;

    span {
      background: red;
    }
  }

  &.pending {
    color: rgb(207, 207, 9);

    span {
      background: rgb(207, 207, 9);
    }
  }

  &.unassigned {
    color: rgba(0, 0, 0, 0.5);

    span {
      background: rgba(0, 0, 0, 0.5);
    }
  }
`

export const StyledStudentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
export const StyledDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 32px;
`
export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .Mui-selected {
    color: #fff !important;
  }
`
export const ThumbNailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CustomAvatarStyles = {
  width: 32,
  height: 32,
  borderRadius: 4,
  backgroundColor: '#f3f3f3',
}

export const CustomThumbnailStyles = {
  width: 60,
  height: 60,
  backgroundColor: '#f3f3f3',
}

export const EmailSpanStyles = {
  color: '#68707A',
  fontSize: 11,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap' as const,
  maxWidth: '150px',
}
