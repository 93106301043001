import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { UPDATE_TEST } from 'gql/tests/tests.query'
import { IQuiz } from 'pages/quizzes/quiz.interface'

interface IUpdateTestServiceResponse {
  updateTestMutation: MutationTuple<IUpdateTestResponse, IUpdateTestParams>[0]
  loading: boolean
  error?: ApolloError
}

interface IUpdateTestResponse {
  updateTest: IQuiz
}

interface IUpdateTestParams {
  testId: string
  input: IQuiz
}

export const updateTestService = (): IUpdateTestServiceResponse => {
  const [updateTestMutation, { loading, error }] = useMutation<
    IUpdateTestResponse,
    IUpdateTestParams
  >(UPDATE_TEST)

  return {
    updateTestMutation,
    loading,
    error,
  }
}
