import React, { useEffect, useState } from 'react'
import { Wrapper } from '../styled-components'
import {
  InputContainer,
  LoaderStyles,
  SelectFieldWrapper,
  SelectorContainer,
  StyledTableContainer,
} from './styled-component'
import { SelectField } from 'components/common/SelectField'
import { useTranslation } from 'react-i18next'
import { ITestsAnalyticsProps } from './testsAnalytics.interface'
import { useFetchStudents } from 'hooks/users/useFetchStudents'
import { IAnalyticsStudentOption } from '../analytics.interface'
import { User } from '@sentry/react'
import GridV2 from 'components/common/GridV2'
import { CenterParagraph } from '../Groups/styled-components'
import { testConfig } from './gridConfig'
import MyLoader from 'loading/loading'
import { AvatarContainer } from 'pages/notifications/styled-components'
import { StudentProfile, StudentsAnalyticsProfile } from '../Students/styled-components'
import { useTestAnalytics } from 'services/analytics/testAnalyticsUserService'
import AvatarIcon from 'assets/profile.svg'
import {
  collapseColumnConfig,
  getCollapsedData,
} from 'pages/profile/AllUserProfile/quizes/gridConfig'

const TestsAnalytics: React.FC<ITestsAnalyticsProps> = ({
  companyId,
  testGroupsOptions,
  onGroupChange,
  selectedGroup,
  groupsLoading,
  testsOptions,
  onTestChange,
  selectedTest,
  testsLoading,
  user,
}) => {
  const [studentsFilter, setStudentsFilter] = useState<object>({})
  const [selectedStudent, setSelectedStudent] = useState<IAnalyticsStudentOption | null>(null)

  const { t } = useTranslation()

  const [studentsOptions, setStudentsOptions] = useState<IAnalyticsStudentOption[]>([])

  const onStudentChange = (student: { value: string; label: string }): void => {
    setSelectedStudent(student)
  }

  const { users, loading } = useFetchStudents(studentsFilter, 1, 0, companyId as string)

  useEffect(() => {
    if (users) {
      const options = users.data.map((user: User) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      })) as IAnalyticsStudentOption[]
      setStudentsOptions(options)
    }
  }, [users, selectedGroup])

  useEffect(() => {
    setSelectedStudent(null)
    if (selectedGroup && selectedGroup.value) {
      setStudentsFilter({
        group: {
          type: 'nestedArrayIn',
          value: [selectedGroup.value],
          nestedField: 'groupId',
        },
      })
    } else {
      setStudentsOptions([])
    }
  }, [selectedGroup])

  useEffect(() => {
    if (!(selectedGroup && studentsOptions)) return
    if (user) {
      const student = studentsOptions.find(student => student.value === user.id)
      if (student) setSelectedStudent(student)
      else setSelectedStudent(null)
    }
  }, [selectedGroup, studentsOptions, user])

  const {
    testData,
    userData,
    loading: testsFetchLoading,
  } = useTestAnalytics({
    testId: selectedTest?.value as string,
    userId: selectedStudent?.value,
  })

  const gridConfig = testConfig(t)
  const collapseConfig = collapseColumnConfig(t)

  return (
    <Wrapper>
      <InputContainer>
        <SelectorContainer>
          <SelectFieldWrapper>
            <SelectField
              placeholder={t('analytics.searchByTests')}
              value={selectedTest}
              options={testsOptions}
              onChange={onTestChange}
              isLoading={testsLoading}
              isClearable
            />
          </SelectFieldWrapper>
          <SelectFieldWrapper>
            <SelectField
              placeholder={t('analytics.searchByGroup')}
              value={selectedGroup}
              options={testGroupsOptions}
              onChange={onGroupChange}
              isClearable
              isDisabled={!selectedTest?.value}
              isLoading={groupsLoading}
            />
          </SelectFieldWrapper>
          <SelectFieldWrapper>
            <SelectField
              placeholder={t('analytics.searchByStudent')}
              value={selectedStudent}
              options={studentsOptions}
              onChange={onStudentChange}
              isClearable
              isDisabled={!selectedGroup?.value}
              isLoading={loading}
            />
          </SelectFieldWrapper>
        </SelectorContainer>
      </InputContainer>
      {testsFetchLoading && selectedStudent?.value && (
        <MyLoader width={250} speed={0.8} style={LoaderStyles} />
      )}
      {!testsFetchLoading && (
        <>
          {selectedStudent?.value && selectedGroup?.value && selectedTest?.value && testData ? (
            <>
              <StudentsAnalyticsProfile>
                <StudentProfile>
                  <AvatarContainer>
                    {userData?.avatar ? (
                      <img src={userData?.avatar} alt='userAvatar' />
                    ) : (
                      <img src={AvatarIcon} alt='defaultAvatar' />
                    )}
                  </AvatarContainer>
                  {userData?.firstName + ' ' + userData?.lastName}
                </StudentProfile>
              </StudentsAnalyticsProfile>

              <StyledTableContainer>
                <GridV2
                  data={[testData]}
                  config={gridConfig}
                  collapseConfig={collapseConfig}
                  getCollapsedData={getCollapsedData}
                />
              </StyledTableContainer>
            </>
          ) : (
            <CenterParagraph>{t('analytics.selectStudentTest')}</CenterParagraph>
          )}
        </>
      )}
    </Wrapper>
  )
}

export default TestsAnalytics
