import styled from 'styled-components'

export const Header = styled.header`
  margin-bottom: 20px;

  &.withBorder {
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
    padding-bottom: 10px;
  }

  &.noMarginBottom {
    margin-bottom: 0;
  }

  button.makeStyles-button-247 {
    padding: 0;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
`
