import { ISnackbarProps } from 'interfaces/snackbar'
import useSnackbarAlert from 'hooks/useSnackbar'
import { useTranslation } from 'react-i18next'

import extractGroupsWithCourses, {
  IExtractGroupsWithCoursesMutationResponse,
} from 'services/group/uploadGroupsWithCoursesService'

const useExtractGroupsWithCourses = (): {
  uploadFileStream: (
    file: File,
    cb: (resp: IExtractGroupsWithCoursesMutationResponse) => void,
  ) => Promise<void>
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const { singleUploadStream } = extractGroupsWithCourses()

  const uploadFileStream = async (
    file: File,
    cb: (resp: IExtractGroupsWithCoursesMutationResponse) => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors, data } = await singleUploadStream({
      variables: {
        file,
      },
    })

    if (errors && errors.length) {
      message = errors[0].message
      variant = 'error'
    } else if (data) {
      message = t('messages.extracted_groups')
      variant = 'success'

      cb(data.extractGroupsWithCourses)
    }

    setSnackbar({ message, variant })
  }

  return {
    uploadFileStream,
  }
}

export default useExtractGroupsWithCourses
