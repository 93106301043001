import { gql } from 'graphql.macro'

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUserForAdmin {
      id
      firstName
      lastName
      email
      consultantOfCompanies {
        id
        name
      }
      role
      companyId {
        name
        id
        platformService
        exploreCourses
      }
      avatar
      avatarThumbnail
      requestPasswordChange
      settings {
        askPassword
      }
      jobTitle
      biography
      location
      phone
      gender
      birthDate
      createDate
      note
      status
      age
      phoneFields {
        code
        dialCode
      }
      isCompanyAdmin
      isSuperAdmin
      assistants {
        assistantId
        threadId
        isUserNotifiedAboutReTraining
      }
    }
  }
`

export const GET_COMPANY_ASSIGNED_TUTORS = gql`
  query GetCompanyAssignedTutors(
    $companyId: String
    $filter: UserFilter = {}
    $currentPage: Int
    $perPage: Int
  ) {
    getAssignedSuadaTutors(
      companyId: $companyId
      filter: $filter
      currentPage: $currentPage
      perPage: $perPage
    ) {
      currentPage
      totalPages
      totalCount
      data {
        id
        firstName
        lastName
        email
        phone
        phoneFields {
          code
          dialCode
        }
        role
        status
        group {
          groupId
          name
        }
        courses {
          name
          courseId
        }
        companyId {
          name
          id
          platformService
        }
        avatar
        avatarThumbnail
        createDate
      }
    }
  }
`
export const GET_COMPANY_TUTORS = gql`
  query GetCompanyTutors(
    $companyId: String
    $filter: UserFilter = {}
    $currentPage: Int
    $perPage: Int
  ) {
    getCompanyTutors(
      companyId: $companyId
      filter: $filter
      currentPage: $currentPage
      perPage: $perPage
    ) {
      currentPage
      totalPages
      totalCount
      data {
        id
        firstName
        lastName
        email
        phone
        phoneFields {
          code
          dialCode
        }
        isTutor
        role
        status
        group {
          groupId
          name
        }
        courses {
          name
          courseId
        }
        companyId {
          name
          id
          platformService
        }
        avatar
        avatarThumbnail
        createDate
      }
    }
  }
`

export const GET_USERS = gql`
  query GetUsers($role: String!, $filter: UserFilter = {}, $currentPage: Int, $perPage: Int) {
    usersByRole(role: $role, filter: $filter, currentPage: $currentPage, perPage: $perPage) {
      currentPage
      totalPages
      totalCount
      data {
        id
        firstName
        lastName
        email
        phone
        phoneFields {
          code
          dialCode
        }
        consultantOfCompanies {
          id
          name
        }
        role
        gender
        birthDate
        age
        biography
        note
        isQualified
        status
        jobTitle
        isTutor
        location
        group {
          groupId
          name
        }
        courses {
          name
          courseId
        }
        companyId {
          name
          id
          platformService
        }
        avatar
        avatarThumbnail
        createDate
      }
    }
  }
`

export const GET_TUTORS = gql`
  query GetTutorsByCourseId(
    $searchValue: String
    $courseId: String!
    $groupId: String
    $currentPage: Int
    $perPage: Int
  ) {
    getTutorsByCourseId(
      searchValue: $searchValue
      courseId: $courseId
      groupId: $groupId
      currentPage: $currentPage
      perPage: $perPage
    ) {
      totalPages
      totalCount
      data {
        id
        firstName
        lastName
        email
        phone
        phoneFields {
          code
          dialCode
        }
        role
        gender
        birthDate
        age
        biography
        note
        isQualified
        status
        jobTitle
        location
        group {
          groupId
          name
        }
        courses {
          name
          courseId
        }
        companyId {
          name
          id
          platformService
        }
        avatar
        avatarThumbnail
        createDate
      }
    }
  }
`
export const GET_STUDENTS = gql`
  query GetStudents(
    $companyId: String
    $filter: UserFilter = {}
    $currentPage: Int
    $perPage: Int
  ) {
    getStudentsForAdmin(
      companyId: $companyId
      filter: $filter
      currentPage: $currentPage
      perPage: $perPage
    ) {
      currentPage
      totalPages
      totalCount
      data {
        id
        firstName
        lastName
        email
        phone
        phoneFields {
          code
          dialCode
        }
        role
        gender
        birthDate
        age
        biography
        note
        isQualified
        status
        jobTitle
        location
        group {
          groupId
          name
        }
        courses {
          name
          courseId
        }
        companyId {
          name
          id
          platformService
        }
        avatar
        avatarThumbnail
        createDate
      }
    }
  }
`

export const GET_STUDENTS_BY_TEST_ID = gql`
  query GetStudents(
    $testAssessmentId: String!
    $filter: UserFilter = {}
    $currentPage: Int
    $perPage: Int
  ) {
    getStudentsByTestAssessmentId(
      testAssessmentId: $testAssessmentId
      filter: $filter
      currentPage: $currentPage
      perPage: $perPage
    ) {
      currentPage
      totalPages
      totalCount
      data {
        id
        firstName
        lastName
        email
        phone
        phoneFields {
          code
          dialCode
        }
        role
        gender
        birthDate
        age
        biography
        note
        isQualified
        status
        jobTitle
        location
        group {
          groupId
          name
        }
        courses {
          name
          courseId
        }
        companyId {
          name
          id
          platformService
        }
        avatar
        avatarThumbnail
        createDate
      }
    }
  }
`

export const GET_AVAILABLE_TUTORS = gql`
  query GetAvailableTutors($courseId: String!) {
    getAvailableTutors(courseId: $courseId) {
      id
      firstName
      lastName
      email
      avatar
      avatarThumbnail
    }
  }
`

export const GET_USER = gql`
  query GetUser($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      isTutor
      email
      phone
      role
      gender
      age
      birthDate
      isQualified
      consultantOfCompanies {
        id
        name
      }
      biography
      note
      group {
        groupId
        name
      }
      companyId {
        name
        id
        platformService
      }
      courses {
        courseId
        name
      }
      jobTitle
      location
      phoneFields {
        code
        dialCode
      }
      avatar
      avatarThumbnail
      createDate
      status
      age
    }
  }
`

export const CHECK_USER_EMAIL = gql`
  mutation CheckUserEmail($email: String!) {
    checkUserEmail(email: $email) {
      id
    }
  }
`

export const ADD_USER = gql`
  mutation AddUser($input: UserInput, $companyId: String) {
    addUser(input: $input, companyId: $companyId) {
      id
      firstName
      lastName
      email
      phone
      role
      createDate
      gender
      courses {
        courseId
        name
      }
      birthDate
      age
      biography
      note
      isQualified
      status
      avatar
      group {
        groupId
        name
      }
      companyId {
        name
        id
        platformService
      }
    }
  }
`
export const ADD_STUDENT = gql`
  mutation AddStudent($input: UserInput, $companyId: String) {
    addStudent(input: $input, companyId: $companyId) {
      id
      firstName
      lastName
      email
      phone
      role
      createDate
      gender
      courses {
        courseId
        name
      }
      birthDate
      age
      biography
      note
      isQualified
      status
      avatar
      group {
        groupId
        name
      }
      companyId {
        name
        id
        platformService
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser($id: String, $input: UserInput) {
    updateUser(id: $id, input: $input) {
      id
      firstName
      lastName
      email
      phone
      role
      gender
      birthDate
      age
      isTutor
      biography
      note
      consultantOfCompanies {
        id
        name
      }
      isQualified
      status
      group {
        groupId
        name
      }
      companyId {
        name
        id
        platformService
      }
      jobTitle
      location
      phoneFields {
        code
        dialCode
      }
      avatar
      avatarThumbnail
    }
  }
`
export const UPDATE_STUDENT = gql`
  mutation UpdateStudent($id: String, $input: UserInput) {
    updateStudent(id: $id, input: $input) {
      id
      firstName
      lastName
      email
      phone
      role
      gender
      birthDate
      age
      biography
      note
      isQualified
      status
      group {
        groupId
        name
      }
      courses {
        courseId
        name
      }
      companyId {
        name
        id
        platformService
      }
      jobTitle
      location
      phoneFields {
        code
        dialCode
      }
      avatar
      avatarThumbnail
    }
  }
`
export const UPDATE_USER_FIRST_LOGIN = gql`
  mutation UpdateUserFirstLogin($id: String) {
    updateUserFirstLogin(id: $id) {
      id
    }
  }
`

export const UPDATE_USER_STATUS = gql`
  mutation UpdateUserStatus($ids: [String]!, $status: String) {
    updateUserStatus(ids: $ids, status: $status) {
      id
      firstName
      lastName
      email
      phone
      role
      gender
      birthDate
      age
      biography
      note
      isQualified
      status
      companyId {
        name
        id
        platformService
      }
    }
  }
`

export const DELETE_USER = gql`
  mutation DeleteUser($ids: [String!]!, $role: String!) {
    delete(ids: $ids, role: $role)
  }
`

export const UPDATE_USER_PASSWORD = gql`
  mutation UpdateUserPassword($id: String!) {
    updateUserPassword(id: $id) {
      id
      firstName
      lastName
      email
      phone
      role
      gender
      birthDate
      age
      biography
      note
      isQualified
      status
      companyId {
        name
        id
        platformService
      }
    }
  }
`

export const INSERT_STUDENTS_WITH_GROUPS = gql`
  mutation InsertStudents($students: [InsertStudentWithGroupsInput], $companyId: String) {
    insertStudentsWithGroups(students: $students, companyId: $companyId) {
      id
      firstName
      lastName
      email
      phone
      phoneFields {
        code
        dialCode
      }
      error
      message
      biography
      note
      gender
      createDate
      status
      companyId {
        name
      }
      group {
        groupId
        name
      }
      courses {
        name
        courseId
      }
    }
  }
`
export const INSERT_STUDENTS = gql`
  mutation InsertStudents(
    $students: [InsertStudentInput]
    $groupId: String
    $groupName: String
    $companyId: String
  ) {
    insertStudents(
      students: $students
      groupId: $groupId
      groupName: $groupName
      companyId: $companyId
    ) {
      id
      firstName
      lastName
      email
      phone
      phoneFields {
        code
        dialCode
      }
      error
      message
      biography
      note
      gender
      createDate
      status
      companyId {
        name
      }
      group {
        groupId
        name
      }
      courses {
        name
        courseId
      }
    }
  }
`

export const UPDATE_PROFILE_AVATAR = gql`
  mutation UpdateProfileAvatar($avatar: String, $userId: String, $action: String) {
    updateProfileAvatar(avatar: $avatar, userId: $userId, action: $action) {
      id
      firstName
      lastName
      email
      role
      companyId {
        name
        id
        platformService
      }
      avatar
      avatarThumbnail
    }
  }
`

// Testing filter
export const FILTER_USERS = gql`
  mutation FilterUsers($filters: UserFilter) {
    filter(filters: $filters) {
      id
      firstName
      lastName
      email
      phone
      role
      gender
      birthDate
      age
      biography
      note
      isQualified
      status
      avatar
    }
  }
`

// ---- end Testing filter
export const EDIT_USER_PROFILE = gql`
  mutation EditUserProfile($input: EditProfileInput) {
    editUserProfile(input: $input) {
      id
      firstName
      lastName
      email
      role
      companyId {
        name
        id
        platformService
      }
      avatar
      avatarThumbnail
      settings {
        askPassword
      }
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!, $confirmPassword: String!) {
    changePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    ) {
      id
      firstName
      lastName
      email
      role
      # companyId {
      #   name
      #   id
      #   platformService
      # }
      avatar
      avatarThumbnail
      requestPasswordChange
    }
  }
`

export const CHECK_RESET_PASSWORD_TOKEN = gql`
  query CheckResetPasswordToken($token: String!) {
    checkResetPasswordToken(token: $token) {
      isValid
    }
  }
`

export const GET_PORTFOLIO = gql`
  query GetPortfolio($id: String!, $userId: String!) {
    getPortfolioForAnalytics(id: $id, userId: $userId) {
      courseDetails {
        progress
      }
      quizzes {
        passed
        name
        id
        module {
          id
          name
        }
        topic {
          id
          name
        }
        lesson {
          id
          name
        }
        questionList {
          question
          answers {
            value
            id
            isCorrect
          }
          userAnswer {
            isCorrect
            answers
            boolAnswer
          }
        }
        score
        questionsCount
      }
      iqTests {
        IQTestType
        id
        name
        score
        questionsCount
        passed
        questionList {
          question
          answers {
            value
            id
            isCorrect
          }
          userAnswer {
            isCorrect
            answers
            boolAnswer
          }
        }
      }
    }
  }
`
export const GET_USER_Short_Info = gql`
  query GetUserShortInfo($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      avatar
      avatarThumbnail
      group {
        groupId
        name
      }
      leaderboard {
        point
      }
    }
  }
`

export const GET_SKILL_TEST_PERFORMANCE = gql`
  query GetSkillTestPerfomance($courseId: String!, $userId: String) {
    getSkillTestVideos(courseId: $courseId, userId: $userId) {
      totalPages
      data {
        video {
          name
          id
          title
          thumbnail
          duration
          links {
            url
            fileType
            converted
          }
        }
        module {
          id
          name
        }
        version
        sentForReview {
          id
          firstName
          lastName
          role
          rate
          userId
        }
        averageRating
        status
        createDate
        criterias {
          criteriaId
          criteria
          checked
          checkedAt
        }
        createdBy {
          id
          firstName
          lastName
          avatar
          avatarThumbnail
          group {
            groupId
          }
        }
      }
    }
  }
`

export const GET_ALL_SKILL_TEST_VIDEOS = gql`
  query GetAllSkillTestVideos(
    $courseId: String!
    $companyId: String
    $groupId: String
    $currentPage: Int
    $perPage: Int
  ) {
    getAllSkillTestVideos(
      courseId: $courseId
      companyId: $companyId
      groupId: $groupId
      currentPage: $currentPage
      perPage: $perPage
    ) {
      data {
        video {
          name
          id
          title
          thumbnail
          duration
          links {
            url
            fileType
            converted
          }
        }
        module {
          id
          name
        }
        version
        sentForReview {
          id
          firstName
          lastName
          role
          rate
          userId
        }
        averageRating
        status
        createDate
        createdBy {
          id
          firstName
          lastName
          avatar
          email
          group {
            groupId
          }
        }
      }
      totalPages
    }
  }
`

export const GET_GROUPED_STUDENTS_FOR_PROGRESS_QUERY = gql`
  query GetGroupedStudentsByCourseIdV2(
    $courseId: String!
    $filter: UserFilter
    $currentPage: Int
    $perPage: Int
    $getDetailedProgress: Boolean
  ) {
    getGroupedStudentsByCourseIdV2(
      courseId: $courseId
      filter: $filter
      currentPage: $currentPage
      perPage: $perPage
      getDetailedProgress: $getDetailedProgress
    ) {
      students {
        group {
          groupId
          name
        }
        id
        firstName
        lastName
        avatarThumbnail
        avatar
        email
        status
        progress
        moduleProgresses {
          moduleId
          moduleName
          progress
          lessonProgresses {
            lessonId
            lessonName
            progress
          }
        }
      }
      totalPages
    }
  }
`

export const UPDATE_USER_PASSWORD_BY_ID = gql`
  mutation UpdateUserPasswordById($id: String!, $password: String!) {
    updateUserPassword(id: $id, password: $password) {
      id
      firstName
      lastName
      email
      role
    }
  }
`
export const GET_COACHES_BY_COMPANYID = gql`
  query GetCoachByCompanyId($companyId: String!) {
    getCoachesByCompany(companyId: $companyId) {
      id
      firstName
      lastName
    }
  }
`
