import { OperationVariables, useQuery } from '@apollo/client'
import { GET_TESTS_BY_USER } from 'gql/tests/tests.query'
import { IQuiz } from 'pages/quizzes/quiz.interface'

const useGetTestAssessmentsByUser = (
  currentPage = 1,
  limit = 0,
  searchText = '',
  userId = '',
): {
  tests: {
    currentPage: number
    totalPages: number
    data: IQuiz[]
  }
  loading: boolean
  error?: string
  refetch: (variables?: OperationVariables) => void
} => {
  const { data, loading, error, refetch } = useQuery(GET_TESTS_BY_USER, {
    variables: { currentPage, limit, searchText, userId },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    tests: data && data.getTestAssessmentsByUserForAdmin,
    loading,
    error: errorMessage,
    refetch,
  }
}

export default useGetTestAssessmentsByUser
