import styled from 'styled-components'

export const Header = styled.header`
  margin-bottom: 20px;

  &.withBorder {
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
    padding-bottom: 10px;
  }

  &.noMarginBottom {
    margin-bottom: 0;
  }

  button.makeStyles-button-247 {
    padding: 0;
  }
`
export const NoDataDiv = styled.div`
  font-size: 20px;
  width: 100%;
`

export const DrawerHeader = styled.header`
  padding: 10px 20px;
  background: #ffffff;
  position: sticky;
  z-index: 2;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 13px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
`
export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`
export const GridHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`

export const CheckBoxFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
export const CustomPadding = styled.div`
  padding: 10px;
`
