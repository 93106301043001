import { styled } from 'styled-components'

export const TutorItemWrapper = styled.div<{ active: boolean }>`
  display: flex;
  gap: 16px;
  cursor: pointer;
  padding: 8px;
  align-items: center;
  border: 2px solid;
  border-radius: 8px;
  position: relative;
  transition: all 0.3s ease-in-out;

  ${({ active }): string => (active ? `border-color: #00905E;` : 'border-color: #d6dae0;')}
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-family: Open Sans;
  font-size: 14px;

  p,
  span {
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p {
    margin: 0;
    font-weight: 600;
    color: #020210;
  }

  span {
    color: #68707a;
  }
`

export const TutorAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  object-fit: cover;
`

export const RadioButtonContainer = styled.div`
  position: absolute !important;
  right: 8px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
`
