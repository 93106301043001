import styled from 'styled-components'

// gridConfig

export const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

// SkillTestVideos

interface WrapperProps {
  fullWidth?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(props): string => (props.fullWidth ? 'width: 100%;' : '')}
`
export const Container = styled.div`
  margin-top: 20px;
  position: relative;
`
export const EmptyContainer = styled.div`
  text-align: center;
  opacity: 0.6;
`
