import { useState } from 'react'
import deleteFlashCardsService from 'services/modules/deleteFlashCardsService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

const useDeleteFlashCardsService = (): {
  deleteFlashCards: (ids: string[], onSuccess: () => void) => Promise<void>
  deleteFlashCardsLoading: boolean
  error: string
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { deleteFlashCardsMutation } = deleteFlashCardsService()

  const deleteFlashCards = async (ids: string[], onSuccess: () => void): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { errors } = await deleteFlashCardsMutation({
      variables: {
        ids,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        message = `${t('general.user')} ${t('messages.already_registered')}`
        setError(message)
        return
      }
      variant = 'error'
    } else {
      onSuccess()
      message =
        ids.length > 1
          ? `${t('general.flash_cards')} ${t('messages.deleted')}`
          : `${t('general.flash_card')} ${t('messages.deleted')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return {
    deleteFlashCards,
    deleteFlashCardsLoading: loading,
    error,
  }
}

export default useDeleteFlashCardsService
