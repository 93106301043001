interface ILink {
  converted: boolean
  fileType: string
  url: string
  __typename: string
}

/**
 * Retrieves the download link for a lesson.
 * @param links - An array of ILink objects representing different types of links.
 * @returns The download link for the lesson.
 */
export const getLessonDownloadLink = (links: ILink[] | undefined): string => {
  let url = ''
  if (!links) return ''
  const originalVideoUrl = links?.find(link => link.fileType === 'Original')?.url
  const cloudfrontAddressForOldBucket = 'dce3l9i4wbim5.cloudfront.net'

  const containsOldAddress = originalVideoUrl?.includes(cloudfrontAddressForOldBucket)

  if (containsOldAddress) {
    // if cloudfront address is dce3l9i4wbim5 it means video is in old bucket that is deleted, so we will converted video with 1080px for download
    url = links.find(link => link.fileType === 'mp4' && link.url.includes('_1080'))?.url as string
  } else {
    url = originalVideoUrl as string
  }

  return url
}
