import React, { ReactElement } from 'react'
import AvatarIcon from 'assets/profile.svg'
import moment from 'moment'
import { IDataItem } from './courseSkillTest.interface'
import { IConfigItem } from './courseSkillTest.interface'
import {
  StyledStudentContainer,
  CustomAvatarStyles,
  StyledDetailContainer,
  EmailSpanStyles,
  CustomThumbnailStyles,
  ThumbNailContainer,
} from './styled-components'
import { Tooltip } from '@mui/material'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'
import useConditionalTooltip from 'hooks/tooltip/useConditionalTooltip'

const getGridConfig = (t: (key: string) => string): IConfigItem[] => [
  {
    id: 'student',
    label: t('user_details.student_details'),
    render: (dataItem: IDataItem): JSX.Element => {
      const { firstName, lastName, avatar, email } = dataItem.createdBy
      const { showTooltip, contentRef, title } = useConditionalTooltip({
        title: email,
        maxWidth: 150,
      })

      return (
        <StyledStudentContainer>
          {avatar ? (
            <img src={avatar} alt={`${firstName} ${lastName}`} style={CustomAvatarStyles} />
          ) : (
            <img src={AvatarIcon} alt='Profile' style={CustomAvatarStyles} />
          )}
          <div ref={contentRef}>
            {showTooltip ? (
              <Tooltip title={title} arrow>
                <StyledDetailContainer>
                  <span>{`${firstName} ${lastName}`}</span>
                  <span style={EmailSpanStyles}>{email}</span>
                </StyledDetailContainer>
              </Tooltip>
            ) : (
              <StyledDetailContainer>
                <span>{`${firstName} ${lastName}`}</span>
                <span style={EmailSpanStyles}>{email}</span>
              </StyledDetailContainer>
            )}
          </div>
        </StyledStudentContainer>
      )
    },
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'skillTestVideo',
    label: t('analytics.stv'),
    render: (dataItem: IDataItem): ReactElement => {
      return (
        <ThumbNailContainer>
          <img src={dataItem?.video?.thumbnail} alt='thumbnail' style={CustomThumbnailStyles} />
        </ThumbNailContainer>
      )
    },

    numeric: false,
    disablePadding: false,
  },
  {
    id: 'module',
    label: t('general.module'),
    render: (dataItem: IDataItem) => <span>{dataItem.module.name}</span>,
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'version',
    label: t('user_tabs.version'),
    render: (dataItem: IDataItem) => <span>{dataItem.version}</span>,
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'sendForReview',
    label: t('user_tabs.sentForReview'),
    render: (dataItem: IDataItem) => (
      <span> {dataItem.sentForReview?.[0]?.firstName || 'No reviewers'} </span>
    ),

    numeric: false,
    disablePadding: false,
  },
  {
    id: 'status',
    label: t('general.status'),
    render: (dataItem: IDataItem) => (
      <StatusIndicator status={dataItem.status.toLocaleUpperCase() as GridStatusEnums} />
    ),
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'receivedStars',
    label: t('user_tabs.receivedStars'),
    render: (dataItem: IDataItem) => <span> {dataItem.sentForReview?.[0]?.rate || '-'}</span>,
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'date',
    label: t('general.create_date'),
    render: (dataItem: IDataItem) => (
      <span> {moment(dataItem.createDate).format('DD.MM.YYYY')} </span>
    ),

    numeric: false,
    disablePadding: false,
  },
]

export default getGridConfig
