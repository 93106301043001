import i18n from 'i18n/index'
interface FlashCardTab {
  label: string
  value: string
}

export let FlashCardsIconsTabs: FlashCardTab[] = []

function translate(): void {
  FlashCardsIconsTabs = [
    {
      label: i18n.t('flashcards_layout.bussiness'),
      value: 'bussiness',
    },
    {
      label: i18n.t('flashcards_layout.everyday'),
      value: 'everyday',
    },
    {
      label: i18n.t('flashcards_layout.influence'),
      value: 'influence',
    },
    {
      label: i18n.t('flashcards_layout.social_media'),
      value: 'social',
    },
  ]
}

i18n.on('languageChanged init', () => {
  translate()
})
