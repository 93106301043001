import { styled } from 'styled-components'
import Modal from '@mui/material/Modal'

export const Root = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledMain = styled.div<{ $isSidebarOpened: boolean }>`
  width: ${({ $isSidebarOpened }: { $isSidebarOpened: boolean }): string =>
    `calc(100% - ${$isSidebarOpened ? '240px' : '70px'})`};
  margin-top: 64px;
`

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`
