import React, { useState, ReactElement, FormEvent, ChangeEvent, useCallback } from 'react'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Favorite } from 'components/common/Button/icons/favorite.svg'
import { IBreadCrumbItem } from 'interfaces/common'
import { IUseBooksLayout } from './useBooksLayout.interface'
import AddCircleIcon from '@mui/icons-material/Add'
import { Button } from 'components/common/Button'
import { useFetchBooks } from 'services/books/getBooksService'
import { debounce } from 'lodash'

const useBooksLayout = (): IUseBooksLayout => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [isSearchType, setIsSearchType] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const currentPage = 1
  const perPage = 12
  const [addBookDrawerOpened, setAddBookDrawerOpened] = useState<boolean>(false)

  const companyId = state.selectedCompany?.id || null

  const breadCrumbData: IBreadCrumbItem[] = [
    {
      label: 'Evaluation',
      icon: <Favorite />,
    },
    {
      label: 'Books',
    },
  ]

  const createButton = (): ReactElement => (
    <Button
      text={t('books_layout.add_book')}
      color='secondary'
      onClick={(): void => setAddBookDrawerOpened(true)}
      icon={<AddCircleIcon />}
      background='#06C68F'
    />
  )

  const { books, loading, refetch } = useFetchBooks({
    searchValue: '',
    companyId: companyId as string,
    currentPage,
    perPage,
  })

  const debouncedRefetch = useCallback(
    debounce((newSearchValue: string) => {
      refetch({
        searchValue: newSearchValue,
        companyId,
        currentPage,
        perPage,
      })
      setIsSearchType(newSearchValue !== '')
    }, 500),
    [companyId, currentPage, perPage, refetch],
  )

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setIsSearchType(true)
      const newSearchValue = e.target.value
      setSearchValue(newSearchValue)
      debouncedRefetch(newSearchValue)
    },
    [debouncedRefetch],
  )

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    setIsSearchType(true)
    refetch({
      searchValue,
      companyId,
      currentPage,
      perPage,
    })
  }

  return {
    t,
    books,
    booksLoading: loading,
    refetchBooks: refetch,
    companyId,
    breadCrumbData,
    createButton,
    searchValue,
    onSearchSubmit,
    handleSearchChange,
    addBookDrawerOpened,
    setAddBookDrawerOpened,
    isSearchType,
    setIsSearchType,
  }
}

export default useBooksLayout
