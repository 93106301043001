import React, { createContext, ReactElement, ReactNode, useContext, useReducer } from 'react'
import { IUserState } from 'store/reducers/userReducer'
import { IReducerAction } from 'store/types'

export const UserContext = createContext({} as [IUserState, React.Dispatch<IReducerAction>])

interface UserProviderProps {
  reducer: (state: IUserState, action: IReducerAction) => IUserState
  initialState: IUserState
  children: ReactNode
}

export const UserProvider = ({
  reducer,
  initialState,
  children,
}: UserProviderProps): ReactElement => (
  <UserContext.Provider value={useReducer(reducer, initialState)}>{children}</UserContext.Provider>
)

export const useUserValue = (): [IUserState, React.Dispatch<IReducerAction>] =>
  useContext(UserContext)
