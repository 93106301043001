import styled from 'styled-components'

export const Container = styled.div`
  background: #fff;
  padding: 20px 0 30px 0;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 7%), 0px 4px 5px 0px rgb(0 0 0 / 7%),
    0px 1px 10px 0px rgb(0 0 0 / 7%);
  border-radius: 14px;

  .MuiPaper-root {
    background: transparent !important;
  }
`
