import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import { ReactComponent as EditIcon } from 'assets/edit.svg'
import { ReactComponent as EditRoundedIcon } from 'assets/editRounded.svg'
import { ReactComponent as RedDeleteIcon } from 'assets/red-delete-icon.svg'
import { ReactComponent as DeleteForeverRoundedIcon } from 'assets/trash-can-red.svg'
import { Action } from 'components/common/GridV2'
import { DeleteSpan } from 'components/common/Styled/styled-components'
import { TFunction } from 'interfaces/TFunction'
import {
  IActionHandler,
  IActionItem,
  IGridColumn,
  IGridItem,
  IModuleListItem,
} from './gridConfig.interface'
import { IPermissionsBool } from 'utils/rolesObject/rolesObject.interface'
import { IColumnItem } from './ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'
import { StyledModuleContent } from './styled-components'
import { Link } from 'react-router-dom'
import TooltipHeight from 'components/common/Tooltip/TooltipHeight'
import { formatDuration } from 'helpers/formatDuration'
import Tooltip from '@mui/material/Tooltip'

export const columnConfig = (): IGridColumn[] => {
  const grid: IGridColumn[] = [
    {
      id: 'isChecked',
      render: (item: IColumnItem) => <Checkbox checked={item.isChecked} />,
      numeric: false,
      disablePadding: true,
    },

    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      render: (item: IColumnItem) => <div>{item.name}</div>,
    },
    {
      numeric: false,
      disablePadding: true,
      label: 'Lessons',
      id: 'lessons',
      render: (item: IColumnItem) => <div>{item.lessons.length}</div>,
    },
  ]

  return grid
}

export const nestedColumConfig = (): IGridItem[] => {
  const grid: IGridItem[] = [
    {
      numeric: false,
      disablePadding: true,
      label: 'Name',
      id: 'name',
      render: (item: IColumnItem) => <div>{item.name}</div>,
    },
  ]

  return grid
}

export const actionConfig = (
  actionHandler: IActionHandler,
  permissions: IPermissionsBool,
  t: TFunction<'translation', undefined>,
  useRoundedIcons: boolean,
): IActionItem[] => {
  const action: IActionItem[] = [
    {
      hide: !permissions.editGroup,
      render: (item: IColumnItem) => (
        <Action
          onClick={(): void | (() => void) => actionHandler('edit', item, 'edit')}
          className='edit'
        >
          {useRoundedIcons ? (
            <Tooltip title={t('actions.edit')} arrow>
              <EditRoundedIcon />
            </Tooltip>
          ) : (
            <>
              <EditIcon />
              <span>{t('actions.edit')}</span>
            </>
          )}
        </Action>
      ),
    },
    {
      hide: !permissions.deleteGroup,
      render: (item: IColumnItem) => (
        <Action
          onClick={(): void | (() => void) => actionHandler('delete', item, 'delete')}
          className='delete'
        >
          {useRoundedIcons ? <RedDeleteIcon /> : <DeleteForeverRoundedIcon />}
          <DeleteSpan>{t('actions.delete')}</DeleteSpan>
        </Action>
      ),
    },
  ]

  return action.filter((i: IActionItem) => !i.hide)
}

export const modulesListConfig = (t: TFunction): IModuleListItem[] => {
  return [
    {
      id: 'name',
      label: t('general.name'),
      render: (item: IColumnItem) => (
        <Link to={`/modules/${item.id}`}>
          <TooltipHeight text={item.name} lines={3} />
        </Link>
      ),
    },
    {
      id: 'topics',
      label: t('general.topics'),
      render: (item: IColumnItem) => (
        <StyledModuleContent>
          {item.numberOfTopics} {t('general.topics')}
        </StyledModuleContent>
      ),
    },
    {
      id: 'lessons',
      label: t('general.lessons'),
      render: (item: IColumnItem) => (
        <StyledModuleContent>
          {item.numberOfLessons} {t('general.lessons')}
        </StyledModuleContent>
      ),
    },
    {
      id: 'duration',
      label: t('general.duration'),
      render: (item: IColumnItem) => (
        <StyledModuleContent>{formatDuration(item.duration)}</StyledModuleContent>
      ),
    },
  ]
}
