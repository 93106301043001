import { useState, ChangeEvent, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import {
  IAssignTutorDrawerProps,
  IUseAssignTutorDrawerResponse,
} from './assignTutorDrawer.interface'
import useEditGroup from 'hooks/group/useEditGroup'

const useAssignTutorDrawer = ({
  groupData,
  courseData,
  closeDrawer,
}: IAssignTutorDrawerProps): IUseAssignTutorDrawerResponse => {
  const { t } = useTranslation()
  const { name: groupName, id: groupId } = groupData
  const { name: courseName, courseId } = courseData
  const [searchValue, setSearchValue] = useState('')
  const [selectedTutor, selectTutor] = useState<string | undefined>(undefined)

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value)
  }

  useEffect(() => {
    if (groupData?.courses) {
      const currentTutor = groupData.courses.find(course => course.courseId === courseId)?.coach
      if (currentTutor) selectTutor(currentTutor.coachId)
    }
  }, [groupData])

  const { assignCoach, loading } = useEditGroup()

  const handleAssignTutor = (): void => {
    assignCoach(groupId as string, selectedTutor, courseId, () => {
      closeDrawer()
    })
  }

  return {
    t,
    groupName,
    courseName: courseName as string,
    courseId: courseId as string,
    searchValue,
    handleSearchChange,
    handleAssignTutor,
    selectedTutor,
    selectTutor,
    assignTutorLoading: loading,
  }
}

export default useAssignTutorDrawer
