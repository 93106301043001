import React from 'react'
import strCutter from 'utils/strCutter'
import Checkbox from 'components/common/Checkbox'
import moment from 'moment'

import Tooltip from '@mui/material/Tooltip'
import { ReactComponent as TrashCanRed } from 'assets/trash-can-red.svg'
import { ReactComponent as EditIcon } from 'assets/edit.svg'
import GetAppIcon from '@mui/icons-material/GetApp'
import { StudentAction } from '../GroupStudents/styled-components'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import { NoValueWrapper } from 'components/common/Grid/styled-components'
import IconButton from '@mui/material/IconButton'
import SchoolIcon from '@mui/icons-material/School'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import { IGroupDashboardGridItem, IGroupDashboardActionItem } from '../groups.interface'
import { TFunction } from 'interfaces/TFunction'
import { ReactComponent as Library } from 'components/common/Button/icons/list-boxes.svg'
import { GroupsWrapper, GroupItem, GroupTitle, ClickableDiv } from './styled-components'
import { Action } from 'components/common/GridV2'
import { DeleteSpan } from 'components/common/Styled/styled-components'
import { IPermissionsBool } from 'utils/rolesObject/rolesObject.interface'
import { IGroup } from 'interfaces/groups'
import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'

export const columnConfig = (
  handelClick: (e: React.MouseEvent<HTMLDivElement>, item: IGroup) => void,
  selectItem: (id: string) => void,
  t: TFunction<'translation', undefined>,
  checkbox?: boolean,
): IGroupDashboardGridItem[] => {
  const grid: IGroupDashboardGridItem[] = [
    {
      id: 'isChecked',
      render: (item: IGroup): React.JSX.Element => (
        <div style={{ visibility: !checkbox ? 'hidden' : 'visible' }}>
          <Checkbox checked={item.isChecked} onChange={(): void => selectItem(item.id as string)} />
        </div>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: `${t('general.name')}`,
      render: (item: IGroup): React.JSX.Element =>
        (item.name ?? '').length > 30 ? (
          <Tooltip title={item.name}>
            <ClickableDiv onClick={(e): void => handelClick(e, item)}>
              {strCutter(item.name, 30)}
            </ClickableDiv>
          </Tooltip>
        ) : (
          <ClickableDiv onClick={(e): void => handelClick(e, item)}>{item.name}</ClickableDiv>
        ),
    },
    {
      id: 'admin',
      numeric: true,
      disablePadding: false,
      label: `${t('general.admin')}`,
      render: (item: IGroup): React.JSX.Element => {
        return (
          <div>
            {item.admin
              ? item.admin.map((i: { firstName: string }) => i.firstName).join(', ')
              : 'N/A'}
          </div>
        )
      },
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: `${t('general.status')}`,
      render: (item: IGroup): React.JSX.Element => (
        <StatusIndicator status={item.status as GridStatusEnums} />
      ),
    },
    {
      id: 'numberOfStudents',
      numeric: true,
      disablePadding: false,
      label: `${t('general.students')}`,
      render: (item: IGroup): React.JSX.Element => <div>{item.numberOfStudents || 0}</div>,
    },
    {
      id: 'courses',
      numeric: false,
      disablePadding: false,
      label: `${t('general.courses')}`,
      render: (item: IGroup): React.JSX.Element => {
        const courses = item.courses || []
        const firstTwoCourses = courses.slice(0, 2)
        const exCourses = courses.slice(2, courses.length)

        return (
          <div>
            {firstTwoCourses.length === 0 && <NoValueWrapper>N/A</NoValueWrapper>}
            <GroupsWrapper>
              {firstTwoCourses.map(
                (course, index: number) =>
                  index < 3 && (
                    <GroupItem key={course.courseId}>
                      <IconButton>
                        <SchoolIcon fontSize='small' />
                      </IconButton>
                      <GroupTitle className='group-item-title'>{course.name}</GroupTitle>
                    </GroupItem>
                  ),
              )}

              {exCourses.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {exCourses.map(exCourse => (
                        <div key={exCourse.courseId}>{exCourse.name}</div>
                      ))}
                    </>
                  }
                >
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                  >
                    ...
                  </span>
                </Tooltip>
              )}
            </GroupsWrapper>
          </div>
        )
      },
    },
    {
      id: 'companyId',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.company')}`,
      render: (item: IGroup): React.JSX.Element => (
        <div>{item.company && item.company.name ? strCutter(item.company.name, 25) : <>N/A</>}</div>
      ),
    },

    {
      id: 'createDate',
      numeric: true,
      disablePadding: false,
      label: `${t('general.create_date')}`,
      render: (item: IGroup): React.JSX.Element => (
        <div>{moment(item.createDate).format('DD-MM-YYYY')}</div>
      ),
    },
  ]

  return grid.filter((i: IGroupDashboardGridItem) => !i.hide)
}

export const actionConfig = (
  handleClick: (e: string | string[] | IGroup, field?: string) => void,
  permission: IPermissionsBool,
  t: TFunction<'translation', undefined>,
): IGroupDashboardActionItem[] => {
  const action: IGroupDashboardActionItem[] = [
    {
      hide: !permission.createGroup,
      render: (item: IGroup): React.JSX.Element => (
        <Action onClick={(): void => handleClick(item, 'info')}>
          <EditIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !permission.deleteGroup,
      render: (item: IGroup): React.JSX.Element => (
        <Action onClick={(): void => handleClick(item.id as string, 'delete')}>
          <TrashCanRed />
          <DeleteSpan>{t('actions.delete')}</DeleteSpan>
        </Action>
      ),
    },
  ]
  return action.filter((i: IGroupDashboardActionItem) => !i.hide)
}

export const studentActionConfig = (
  handleClick: (action: string) => void,
  t: TFunction<'translation', undefined>,
): { render: () => React.JSX.Element }[] => {
  const action = [
    {
      render: (): React.JSX.Element => (
        <StudentAction onClick={(): void => handleClick('importGroups')}>
          <GetAppIcon />
          <span>{t('general.groups_to_courses')}</span>
        </StudentAction>
      ),
    },
    {
      render: (): React.JSX.Element => (
        <StudentAction onClick={(): void => handleClick('importGroupsToTests')}>
          <Library />
          <span>{t('general.groups_to_tests')}</span>
        </StudentAction>
      ),
    },
    {
      render: (): React.JSX.Element => (
        <StudentAction onClick={(): void => handleClick('importStudentsInGroups')}>
          <PlaylistAddIcon />
          <span>{t('actions.import_students_in_groups')}</span>
        </StudentAction>
      ),
    },
  ]

  return action
}
