import { useQuery } from '@apollo/client'
import { GET_COURSES_ANALYTICS } from 'gql/course/course.query'
import {
  getCoursesAnalyticsResponse,
  IFilter,
  IUseGetCoursesAnalyticsResponse,
} from 'interfaces/courses/courses.interface'

export const useGetCoursesAnalytics: getCoursesAnalyticsResponse = variables => {
  const { data, loading, error, refetch } = useQuery<IUseGetCoursesAnalyticsResponse, IFilter>(
    GET_COURSES_ANALYTICS,
    {
      variables,
    },
  )
  return {
    data: data && data.getCoursesAnalytics,
    loading,
    error: error?.message,
    refetch,
  }
}
