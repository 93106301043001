import { useMutation, MutationTuple } from '@apollo/client'
import { DELETE_GROUP } from 'gql/group/group.query'
import { IMessageResponse } from 'interfaces/common'

interface IDeleteGroupResponse {
  deleteGroup: IMessageResponse
}

interface IDeleteGroupVariables {
  id: string[]
}

interface IDeleteGroupServiceResponse {
  deleteGroup: MutationTuple<IDeleteGroupResponse, IDeleteGroupVariables>[0]
}

const deleteGroupService = (): IDeleteGroupServiceResponse => {
  const [deleteGroup] = useMutation<IDeleteGroupResponse, IDeleteGroupVariables>(DELETE_GROUP)

  return {
    deleteGroup,
  }
}

export default deleteGroupService
