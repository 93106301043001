// @deprecated
/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { Button } from 'components/common/Button'
import { ContentWrapper, TextContainer } from './styled-components'
import { useFormik } from 'formik'
import { TabNavigation, TabPanel, activeTabClassName } from 'components/common/Tabs/Tabs'
import AppBar from '@mui/material/AppBar'
import { CHECK_USER_EMAIL } from 'gql/users.query'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
// import { StudentSchema } from 'helpers/validationSchemas'
import { useNavigate } from 'react-router-dom'
import useAddUserServie from 'hooks/users/useAddUserService'
import { useFetchUsers } from 'hooks/users/useUsers'
import { useMutation } from '@apollo/client'
import useCompaniesService from 'hooks/useCompaniesService'
import { useUserValue } from 'context/UserContext'

import { Container, Wrapper, FooterWrapper, ButtonWrapper } from '../styled-components'

import CreateStudent from './CreateStudent'
import StudentList from './StudentList'
import ImportStudents from './ImportStudents'
import { StudentSchema } from 'helpers/validationSchemas'

const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
}

const formData: any = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  gender: null,
  birthDate: '',
  note: '',
  role: 'STUDENT',
  biography: '',
  group: null,
  avatar: '',
  jobTitle: '',
  location: '',
  phoneFields: null,
  phoneFieldValue: null,
}

const CompanyStudents = (props: any) => {
  const [state] = useUserValue()
  const [tabValue, setTabValue] = useState(0)
  const [createStudent, setCreateStudent] = useState('')
  const { companyData, setData } = props
  const navigate = useNavigate()
  const { setCompanyState } = useCompaniesService()

  const [checkEmail] = useMutation(CHECK_USER_EMAIL)
  const { addUser, addUserLoading } = useAddUserServie()

  const { users, refetch }: any = useFetchUsers('STUDENT', {
    companyId: { type: 'exact', value: companyData.companyData.id },
  })

  useEffect(() => {
    if (users && users.data) {
      setData((prev: any) => ({
        ...prev,
        students: users,
      }))
    }
  }, [users])

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue, resetForm } =
    useFormik({
      initialValues: formData,
      validationSchema: StudentSchema,
      async onSubmit(values) {
        if (addUserLoading) return

        const data = { ...values }

        delete data.phoneFieldValue

        data.group = []

        if (data.gender) data.gender = data.gender.value

        if (values.group) {
          data.group = values.group.map((i: any) => ({
            name: i.label,
            groupId: i.value,
          }))
        }

        if (data.phoneFields && data.phoneFields.value) {
          data.phoneFields = {
            dialCode: data.phoneFields.value,
            code: data.phoneFields.label,
          }
        } else {
          data.phoneFields = null
        }

        data.requestPasswordChange = state.currentUser.settings?.askPassword

        const res = await checkEmail({
          variables: { email: values.email },
        })

        if (res.errors) {
          errors.email = res.errors[0].message
          return false
        }

        addUser(data, 'STUDENT', companyData.companyData.id, () => {
          refetch()
          if (createStudent === 'reset') {
            resetForm()
          } else {
            resetForm()
            setTabValue(2)
          }
        })
      },
    })

  const onFormSubmit = (action: string) => {
    setCreateStudent(action)
    handleSubmit()
  }

  const { t } = props

  return (
    <>
      <Container>
        <Wrapper width='74%'>
          <ContentWrapper>
            <TabNavigation>
              <AppBar position='static' style={styles.bar}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label='simple tabs example'>
                  <Tab
                    label={t('company_wizard_layout.create_new_student')}
                    {...activeTabClassName(0)}
                    style={styles.btn}
                  />
                  <Tab
                    label={t('company_wizard_layout.upload_students_in_group')}
                    {...activeTabClassName(1)}
                    style={styles.btn}
                  />
                  <Tab
                    label={t('company_wizard_layout.student_list')}
                    {...activeTabClassName(2)}
                    style={styles.btn}
                  />
                </Tabs>
              </AppBar>

              <TabPanel value={tabValue} index={0}>
                <CreateStudent
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  handleSubmit={onFormSubmit}
                  groupList={companyData.groups}
                  addUserLoading={addUserLoading}
                  t={t}
                />
              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                <ImportStudents
                  groupList={companyData.groups}
                  companyId={companyData.companyData.id}
                  refetch={refetch}
                  setTabValue={setTabValue}
                  t={t}
                />
              </TabPanel>

              <TabPanel value={tabValue} index={2}>
                <StudentList
                  studentsData={companyData.students}
                  groupList={companyData.groups}
                  companyId={companyData.companyData.id}
                  refetch={refetch}
                  t={t}
                />
              </TabPanel>
            </TabNavigation>
          </ContentWrapper>
        </Wrapper>

        <Wrapper width='calc(26% - 20px)'>
          <TextContainer>{t('company_wizard_layout.students_description')}</TextContainer>
        </Wrapper>
      </Container>

      <FooterWrapper>
        <ButtonWrapper>
          <Button
            text={t('actions.cancel')}
            type='small'
            onClick={props.onCancel}
            background='#E0E1E2'
            textColor='#414141'
          />
        </ButtonWrapper>

        <ButtonWrapper>
          <div style={{ marginRight: '15px' }}>
            <Button
              text={t('actions.previous')}
              type='small'
              onClick={() => props.changeStep(4)}
              background='#E0E1E2'
              textColor='#414141'
            />
          </div>

          <div style={{ marginRight: '15px', height: '31px' }}>
            <Button
              text={t('actions.save_as_draft')}
              type='small'
              onClick={() => {
                setCompanyState(companyData.companyData.id, 5, 'Success', () =>
                  navigate('/Companies'),
                )
              }}
              textColor='#06C68F'
              background='#f5f5f5'
              outline='1px solid #06C68F'
            />
          </div>

          <Button
            text={t('actions.save_and_next')}
            type='small'
            onClick={() => {
              setCompanyState(companyData.companyData.id, 6, 'Success', () => props.changeStep(6))
            }}
            background='#06C68F'
          />
        </ButtonWrapper>
      </FooterWrapper>
    </>
  )
}

export default CompanyStudents
