import { ICourseDetailParams } from 'components/courseDetail/interfaces'
import { QuizLayout } from 'pages/quizzes'
import React, { ReactElement } from 'react'
import { ModulesTabWrapper } from './styled-components'

interface Props {
  course: ICourseDetailParams
}

const AssessmentsTab = ({ course }: Props): ReactElement => {
  return (
    <ModulesTabWrapper>
      <QuizLayout
        IQTest
        type='course'
        id={course && course.id}
        companyCourse={!!course?.originalId}
        name={course.name}
        canCreate={2}
        hasCheckbox={false}
        gridStyle='card'
        routerState={{
          from: `/courses/${course.id}`,
          state: { tab: 1 },
        }}
        isDraggable={!course.isBought}
        companyId={course?.companyId?.id}
      />
    </ModulesTabWrapper>
  )
}

export default AssessmentsTab
