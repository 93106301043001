import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`

export const StyledLogo = styled.img`
  object-fit: initial;
  height: auto;
`

export const LogotypeContainer = styled.div`
  width: 32%;
  background: #343a40;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 5%;
`

export const LogoTypeWrapper = styled.div``

export const AuthContainer = styled.div`
  width: calc(67% - 3px);
  background: #f5f6f8;
  border-left: 3px solid #ccc;
  display: flex;
  justify-content: center;
`
export const AuthWrapper = styled.div`
  width: 350px;
  height: 100vh;
  display: flex;
  align-items: center;
`
