import React, { useState, useEffect, useCallback } from 'react'
import update from 'immutability-helper'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { formatDuration } from 'helpers/formatDuration'
import {
  GridItem,
  GridItemImage,
  GridItemContent,
  GridItemContentHeader,
  GridItemTitle,
  GridItemLessons,
} from 'components/common/Cards/styled-components'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { CHANGE_ORDER } from 'gql/modules.query'
import TooltipHeight from 'components/common/Tooltip/TooltipHeight'
import {
  CardActionBar,
  StyledModuleContent,
  StylesCardWrapper,
} from 'pages/modules/styled-components'
import { IModule, IModulesList } from 'pages/modules/ModulesList.interface'
import { IUseModulesListReturnType } from './useModuleList.interface'

const useModulesList = ({ data, gridStyle, actions }: IModulesList): IUseModulesListReturnType => {
  const { t } = useTranslation()
  const [changeModulesOrder] = useMutation(CHANGE_ORDER)
  const navigate = useNavigate()

  const [cards, setCards] = useState(data)
  const moveCard = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex]
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [cards],
  )

  const handleChangeRequest = async (
    draggedId: string,
    droppedId: string | undefined,
  ): Promise<void> => {
    await changeModulesOrder({
      variables: { draggedId, droppedId },
    })
  }

  useEffect(() => {
    setCards(data)
  }, [data])

  const renderContent = (item: IModule): JSX.Element => (
    <StylesCardWrapper
      className='wrapper'
      onClick={(): void => {
        navigate(`/modules/${item.id}`)
      }}
    >
      <CardActionBar $switched={!!(gridStyle === 'card')}>
        <div style={{ display: 'flex', alignItems: 'center' }}></div>
        <div className='action_block'>
          <GridActionMenu actionConfig={actions} row={item} />
        </div>
      </CardActionBar>
      <GridItem>
        <GridItemImage>
          <img
            src={
              (item.coverImage && item.coverImage.link) ||
              'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSHw6OeiQMdYX_iXu8otmzMnlbD07xEWr87EMnJ5r4X9vYC-kNr&usqp=CAU'
            }
          />
        </GridItemImage>
        <GridItemContent>
          <GridItemContentHeader>
            <GridItemTitle>
              <Link to={`/modules/${item.id}`}>
                <TooltipHeight text={item.name} lines={3} />
              </Link>
            </GridItemTitle>
          </GridItemContentHeader>
        </GridItemContent>
      </GridItem>
      <GridItemLessons>
        <StyledModuleContent>
          {item.numberOfTopics} {t('general.topics')}
        </StyledModuleContent>
        <StyledModuleContent>
          {item.numberOfLessons} {t('general.lessons')}
        </StyledModuleContent>
        <StyledModuleContent>{formatDuration(item.duration)}</StyledModuleContent>
      </GridItemLessons>
    </StylesCardWrapper>
  )

  return {
    cards,
    moveCard,
    handleChangeRequest,
    renderContent,
  }
}

export default useModulesList
