import { useState } from 'react'
import addCompanyService, { IAddCompanyResponse } from 'services/companies/addCompanyService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import { ICompany } from 'pages/companies/CompanyDetails/types'

interface ICompanyType {
  id: string
  name: string
}

interface IReturnType {
  addCompany: (
    input: ICompany,
    coursesIds: string[],
    callBack: (company: ICompanyType) => void,
  ) => Promise<void>
  data: IAddCompanyResponse | string
  loading: boolean
  error?: string
}

const useAddCompanyService = (): IReturnType => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const { addCompanyMutation, loading, error } = addCompanyService()
  const [data, setData] = useState<IAddCompanyResponse | string>('')

  const addCompany = async (
    input: ICompany,
    coursesIds: string[],
    callBack: (company: ICompanyType) => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }
    try {
      const { data, errors } = await addCompanyMutation({
        variables: { input, coursesIds },
      })
      if (errors) {
        message = errors[0].message
        variant = 'error'
      } else if (data) {
        setData(data)
        message = `${t('general.company')} ${t('messages.added')}`
        variant = 'success'
        callBack(data.addCompany)
      }
    } catch (err) {
      console.log('ERR', err)
      message = t('messages.something_wrong')
      variant = 'error'
    } finally {
      setSnackbar({ message, variant })
    }
  }

  return {
    addCompany,
    data,
    loading,
    error,
  }
}

export default useAddCompanyService
