import { ApolloError, QueryResult, useQuery } from '@apollo/client'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { GET_QUESTION_IDS } from 'gql/questions.query'

interface IGetQuestionIdsServiceResponse {
  questionIds: string[]
  loading: boolean
  error?: ApolloError
}

interface IGetQuestionIdsResponse {
  getQuestionIds: string[]
}

interface IGetQuestionIdsParams {
  filter: IFilterQueryType
}

const getQuestionIdsService = (filter: IFilterQueryType): IGetQuestionIdsServiceResponse => {
  const { data, loading, error }: QueryResult<IGetQuestionIdsResponse, IGetQuestionIdsParams> =
    useQuery(GET_QUESTION_IDS, {
      variables: {
        filter,
      },
    })

  return {
    questionIds: data?.getQuestionIds || [],
    loading,
    error,
  }
}

export default getQuestionIdsService
