import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { IQuiz } from 'pages/quizzes/quiz.interface'
import { useTranslation } from 'react-i18next'

import createQuizService from 'services/quizzes/createQuizService'

const useCreateQuizService = (): {
  createQuiz: (
    input: IQuiz,
    type: string,
    actionId: string,
    companyId: string | null,
    fun: () => void,
  ) => Promise<void>
  loading: boolean
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()

  const { createQuizMutation, loading } = createQuizService()

  const createQuiz = async (
    input: IQuiz,
    type: string,
    actionId: string,
    companyId: string | null,
    fun: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await createQuizMutation({
      variables: {
        type,
        actionId,
        input,
        companyId,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      message =
        type === 'course'
          ? `${t('general.iq_test')} ${t('messages.saved')}`
          : `${t('general.quiz')} ${t('messages.saved')}`
      variant = 'success'
      fun()
    }

    setSnackbar({ message, variant })
  }
  return {
    createQuiz,
    loading,
  }
}

export default useCreateQuizService
