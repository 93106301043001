import React from 'react'
import { Action } from 'components/UsersLayout/styled-components'
import { ReactComponent as DeleteIconRed } from 'assets/trash-can-red.svg'
import { ReactComponent as Resend } from 'assets/send-alt.svg'
import { ReactComponent as Reset } from 'assets/reset.svg'
import { ReactComponent as Upload } from 'components/common/Button/icons/upload.svg'
import { t } from 'i18next'
import { ActionCustomFlex } from 'pages/profile/AllUserProfile/UsersProfile/styled-components'
import { IPersonalInformation } from 'pages/profile/AdminProfile/PersonalInformation.interface'
import { IAction } from 'components/common/GridV2'

export const actionConfig = (
  handleAvatarDelete: () => void,
  setResetPasswordOpen: React.Dispatch<React.SetStateAction<boolean>>,
  handleResendPasswordClick: (userId: string) => void,
  profileData: IPersonalInformation,
): IAction[] => {
  let action: IAction[] = [
    {
      render: () => (
        <>
          <Action color='#06C68F'>
            <label htmlFor='file'>
              <ActionCustomFlex>
                <Upload />
                <span> {t('actions.upload_avatar')}</span>
              </ActionCustomFlex>
            </label>
          </Action>
        </>
      ),
    },
    {
      render: () => (
        <Action
          onClick={(): void => {
            setResetPasswordOpen && setResetPasswordOpen(true)
          }}
        >
          <Reset />
          <span>{t('actions.reset_password')}</span>
        </Action>
      ),
    },
    {
      render: (item: IPersonalInformation) => (
        <Action
          onClick={(): void => {
            handleResendPasswordClick(item.id as string)
          }}
        >
          <Resend />
          <span>{t('actions.resend_password')}</span>
        </Action>
      ),
    },
  ]

  if (profileData.avatar) {
    action.splice(1, 0, {
      render: () => (
        <Action color='#FF0000' onClick={(): void => handleAvatarDelete()}>
          <DeleteIconRed />
          <span>{t('actions.delete_avatar')}</span>
        </Action>
      ),
    })
  }
  return action.filter(i => !i.hide)
}
