import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { DELETE_TOPIC } from 'gql/topics.query'
import { IModule } from 'pages/modules/ModulesList.interface'

interface IDeleteTopicServiceResponse {
  deleteTopicMutation: MutationTuple<IDeleteTopicResponse, IDeleteTopicParams>[0]
  loading: boolean
  error?: ApolloError
}

interface IDeleteTopicResponse {
  deleteTopics: IModule
}

interface IDeleteTopicParams {
  moduleId: string
  topicIds: string[]
}

const deleteTopicService = (): IDeleteTopicServiceResponse => {
  const [deleteTopicMutation, { loading, error }] = useMutation<
    IDeleteTopicResponse,
    IDeleteTopicParams
  >(DELETE_TOPIC)

  return {
    deleteTopicMutation,
    loading,
    error,
  }
}

export default deleteTopicService
