import { useQuery } from '@apollo/client'
import { GET_STUDENTS_BY_TASK_ID } from 'gql/tasks.query'
import { IUser } from 'interfaces/users'

interface IUseFetchStudentsByTaskIdServiceResponse {
  students: IUser[] | null
  loading: boolean
  error: string
  refetch: () => void
}

interface IUseFetchStudentsByTaskIdResponse {
  getStudentsByTaskId: IUser[]
}

interface IUseFetchStudentsByTaskIdParams {
  taskId?: string
}

export const useFetchStudentsByTaskId = (
  taskId?: string,
): IUseFetchStudentsByTaskIdServiceResponse => {
  const { data, loading, error, refetch } = useQuery<
    IUseFetchStudentsByTaskIdResponse,
    IUseFetchStudentsByTaskIdParams
  >(GET_STUDENTS_BY_TASK_ID, {
    variables: {
      taskId,
    },
    skip: !taskId,
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''

  return {
    students: (data && data.getStudentsByTaskId) || [],
    loading,
    error: errorMessage,
    refetch,
  }
}
