import { CSSProperties } from 'react'
import styled from 'styled-components'

// FilterCompanies

export const Label = styled.div`
  position: absolute;
  top: -21px;
  font-size: 13px;
`

// CompaniesLayout
export const ToolbarItemWrapper = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
  width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
`

export const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

// CompanyList

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  @media (max-width: 1630px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
export const loaderStyles: CSSProperties = {
  position: 'absolute',
  top: '100px',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}

export const toolbarStyle: CSSProperties = {
  justifyContent: 'space-between',
}

export const RelativeContainer = styled.div`
  position: relative;
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

// GridConfig

export const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
`
export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;

  &.active {
    color: green;

    div {
      background: green;
    }
  }

  &.suspended {
    color: red;

    div {
      background: red;
    }
  }
`
export const StatusCircle = styled.div`
  margin-right: 5px;
  margin-top: -3px;
  border-radius: 50%;
  width: 12px;
  height: 12px;
`
