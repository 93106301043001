import React, { ReactElement } from 'react'

import './Thumbnail.scss'

interface IThumbnailProps {
  DrawerOpened?: boolean
  width?: number
  height?: number
  direction?: string
  bgColor?: string
  type?: string
  title?: string
  imageSrc?: string
}

const Thumbnail = ({
  DrawerOpened = true,
  width = 70,
  height = 70,
  direction = 'row',
  bgColor = 'transparent',
  type = 'default',
  title,
  imageSrc,
}: IThumbnailProps): ReactElement => {
  return (
    <div className='thumbnail-wrapper' style={{ backgroundColor: bgColor }}>
      <div
        className={`thumbnail thumbnail--${direction} thumbnail--${type} ${
          DrawerOpened ? 'opened' : 'closed'
        }`}
      >
        <figure
          className='thumbnail__image'
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
        >
          {imageSrc ? <img src={imageSrc} /> : <img src='/profile-photo.png' />}
        </figure>
        <h2 className='thumbnail__title'>{title}</h2>
      </div>
    </div>
  )
}

export default Thumbnail
