import React, { ReactElement } from 'react'
import { Wrapper, styles, AnalyticsIcon, AnalyticsHeaderContainer } from './styled-components'
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { activeTabClassName, TabNavigation } from 'components/common/Tabs/Tabs'
import { useTranslation } from 'react-i18next'
import CoursesAnalytics from './Courses/CoursesAnalytics'
import GroupsAnalytics from './Groups/GroupsAnalytics'
import { useParams } from 'react-router-dom'
import { useUserValue } from 'context/UserContext'

import StudentsAnalytics from './Students/StudentsAnalytics'
import TestsAnalytics from './Tests/TestsAnalytics'
import useAnalyticsData from 'hooks/analytics/useAnalyticsData'
import { roles } from 'utils/permission'

const Analytics = (): ReactElement => {
  const { t } = useTranslation()

  const {
    currentTab,
    courseOptions,
    groupOptions,
    testOptions,
    selectedCourse,
    selectedGroup,
    studentsSelectedGroup,
    studentsGroupOptions,
    selectedTest,
    coursesLoading,
    groupsLoading,
    testsLoading,
    testGroupsLoading,
    handleTabChange,
    handleCourseChange,
    handleGroupChange,
    handleTestChange,
    locationState,
    testGroupOptions,
    handleTestGroupChange,
    selectedTestGroup,
    handleGroupTabChange,
    tabValue,
  } = useAnalyticsData()

  const { id: companyIdFromUrl } = useParams()

  const [state] = useUserValue()
  const companyId = companyIdFromUrl
    ? companyIdFromUrl
    : state.selectedCompany
    ? state.selectedCompany.id
    : null

  const isAdmin = state.currentUser?.role === roles.admin

  return (
    <Wrapper>
      <AnalyticsHeaderContainer>
        <AnalyticsIcon>
          <TrendingUpOutlinedIcon style={styles.border} />
          <p style={styles.paragraph}>{t('navigation.analytics')}</p>
        </AnalyticsIcon>
        <span>
          <h1 style={styles.header}>{t('navigation.analytics')} </h1>
        </span>
      </AnalyticsHeaderContainer>
      {(isAdmin && (
        <TabNavigation transparent>
          <AppBar position='static' style={styles.bar}>
            <Tabs
              value={currentTab}
              onChange={(_, value: string): void => handleTabChange(value)}
              aria-label='basic tabs example'
            >
              <Tab label={t('analytics.groups')} {...activeTabClassName(0)} style={styles.btn} />
              <Tab label={t('analytics.students')} {...activeTabClassName(1)} style={styles.btn} />
              <Tab label={t('analytics.tests')} {...activeTabClassName(2)} style={styles.btn} />
            </Tabs>
          </AppBar>
        </TabNavigation>
      )) || (
        <TabNavigation transparent>
          <AppBar position='static' style={styles.bar}>
            <Tabs
              value={currentTab}
              onChange={(_, value: string): void => handleTabChange(value)}
              aria-label='basic tabs example'
            >
              <Tab label={t('analytics.groups')} {...activeTabClassName(0)} style={styles.btn} />
              <Tab label={t('analytics.students')} {...activeTabClassName(1)} style={styles.btn} />
              <Tab label={t('analytics.tests')} {...activeTabClassName(2)} style={styles.btn} />
              <Tab label={t('analytics.courses')} {...activeTabClassName(3)} style={styles.btn} />
            </Tabs>
          </AppBar>
        </TabNavigation>
      )}
      {(isAdmin && (
        <>
          {currentTab === 0 && (
            <GroupsAnalytics
              companyId={companyId}
              onCourseChange={handleCourseChange}
              coursesOptions={courseOptions}
              groupsOptions={groupOptions}
              selectedCourse={selectedCourse}
              onGroupChange={handleGroupChange}
              selectedGroup={selectedGroup}
              groupsLoading={groupsLoading}
              coursesLoading={coursesLoading}
              handleTabChange={handleGroupTabChange}
              tabValue={tabValue}
            />
          )}
          {currentTab === 1 && (
            <StudentsAnalytics
              companyId={companyId}
              onCourseChange={handleCourseChange}
              coursesOptions={courseOptions}
              groupsOptions={studentsGroupOptions}
              selectedCourse={selectedCourse}
              onGroupChange={handleGroupChange}
              selectedGroup={studentsSelectedGroup}
              groupsLoading={groupsLoading}
              coursesLoading={coursesLoading}
              user={locationState?.user}
            />
          )}
          {currentTab === 2 && (
            <TestsAnalytics
              companyId={companyId}
              testGroupsOptions={testGroupOptions}
              onGroupChange={handleTestGroupChange}
              selectedGroup={selectedTestGroup}
              groupsLoading={testGroupsLoading}
              testsOptions={testOptions}
              onTestChange={handleTestChange}
              selectedTest={selectedTest}
              testsLoading={testsLoading}
              user={locationState?.user}
            />
          )}
        </>
      )) || (
        <>
          {currentTab === 0 && (
            <GroupsAnalytics
              companyId={companyId}
              onCourseChange={handleCourseChange}
              coursesOptions={courseOptions}
              groupsOptions={groupOptions}
              selectedCourse={selectedCourse}
              onGroupChange={handleGroupChange}
              selectedGroup={selectedGroup}
              groupsLoading={groupsLoading}
              coursesLoading={coursesLoading}
              handleTabChange={handleGroupTabChange}
              tabValue={tabValue}
            />
          )}
          {currentTab === 1 && (
            <StudentsAnalytics
              companyId={companyId}
              onCourseChange={handleCourseChange}
              coursesOptions={courseOptions}
              groupsOptions={studentsGroupOptions}
              selectedCourse={selectedCourse}
              onGroupChange={handleGroupChange}
              selectedGroup={studentsSelectedGroup}
              groupsLoading={groupsLoading}
              coursesLoading={coursesLoading}
              user={locationState?.user}
            />
          )}
          {currentTab === 2 && (
            <TestsAnalytics
              companyId={companyId}
              testGroupsOptions={testGroupOptions}
              onGroupChange={handleTestGroupChange}
              selectedGroup={selectedTestGroup}
              groupsLoading={testGroupsLoading}
              testsOptions={testOptions}
              onTestChange={handleTestChange}
              selectedTest={selectedTest}
              testsLoading={testsLoading}
              user={locationState?.user}
            />
          )}
          {currentTab === 3 && (
            <CoursesAnalytics
              companyId={companyId}
              coursesOptions={courseOptions}
              selectedCourse={selectedCourse}
              onCourseChange={handleCourseChange}
              // coursesLoading={coursesLoading}
            />
          )}
        </>
      )}
    </Wrapper>
  )
}

export default Analytics
