import styled from 'styled-components'

export const CourseContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CourseContentLeft = styled.div`
  flex: 2;
`

export const CourseContentRight = styled.div`
  flex: 1;
  padding-top: 20px;
  margin-left: 30px;

  &.noVideo {
    position: absolute;
    right: 20px;
  }

  &.textRight {
    text-align: right;
  }
`

export const CourseContentHeader = styled.header`
  display: flex;
`

export const CourseContentHeaderSection = styled.section`
  margin-top: 20px;
  font-size: 16px;
  width: 100%;
  article {
    margin-top: 6px;
  }
`

export const CourseTitle = styled.h1`
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 500;
  margin-top: 10px;

  div > div:not(:last-child) {
    margin-right: 10px;
  }
`
export const CourseContentDescription = styled.article`
  position: relative;
  font-size: 16px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
`

export const CourseContentSkillsWrapper = styled.div`
  padding: 0 20px;
`
export const CourseContentSkilsTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`

export const CourseContentSkilsList = styled.ul`
  display: flex;
  padding-left: 0;
  margin-top: 5px;
`
export const CourseContentSkillItem = styled.li`
  padding: 8px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 4px;

  &:not(:last-child) {
    margin-right: 8px;
  }
`

export const CourseContentReview = styled.div`
  padding: 20px 0 0 20px;
  display: flex;
  align-items: center;
`
export const CourseContentReviewList = styled.div`
  font-size: 12px;
  margin-left: 10px;

  a:hover {
    text-decoration: underline;
  }
`

export const CourseContentCoachWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const CourseContentCoachInfo = styled.section`
  font-size: 12px;
`

export const CourseContentCoachTitle = styled.h4`
  font-size: 14px;
  font-weight: 500;
`
export const CourseContentVideoCover = styled.figure`
  position: relative;
  margin-bottom: 0;
  width: 100%;
  height: 208px;
  background: #ccc;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
`

export const CourseContentPlayIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    fill: #fff;
    font-size: 3rem;
    transition: fill 0.2s ease;
  }

  &:hover svg {
    fill: #ff3a52;
  }
`

export const CourseContentDetailsList = styled.ul`
  padding-left: 0;
  margin-top: 20px;
  font-size: 16px;

  li:not(:last-child) {
    margin-bottom: 10px;
  }
`

export const CourseContentHeaderButtons = styled.div`
  margin-right: 20px;
  display: flex;
  align-items: center;
  align-self: flex-end;

  > div:not(:last-child) {
    margin-right: 12px;
  }
`

export const CourseContentHeaderEditActions = styled.div`
  display: flex;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
  > div:not(:last-child) {
    margin-right: 6px;
  }

  &.show {
    opacity: 1;
    pointer-events: auto;
  }
`

export const EditCourseOverviewButton = styled.div`
  position: relative;

  &:hover > div {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
`

export const EditIconText = styled.div`
  position: absolute;
  width: 140px;
  pointer-events: none;
  opacity: 0;
  z-index: 2;
  text-align: center;
  padding: 5px 8px;
  bottom: -35px;
  left: 50%;
  color: #333;
  font-size: 12px;
  transform: translateX(-50%) scale(0);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0.5px 12px rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s ease, transform 0.2s ease;
`
export const TextWrapper = styled.article`
  display: inline-block;
  text-transform: capitalize;
`

// actionConfig

export const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
`
// CourseDetailContent

export const StyledItemWrapper = styled.div`
  span {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    white-space: nowrap;
  }
  display: flex;
  align-items: center;
`

export const StyledItem = styled.div`
  font-size: 12px;
  color: #06c68f;
  cursor: pointer;
  margin-left: 5px;
  text-decoration: underline;
`
