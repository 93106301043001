import styled from 'styled-components'

export const ContentContainer = styled.div`
  box-sizing: border-box;
  padding: 20px 30px;
`

export const ItemFieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  /* border: 1px solid #ccc; */
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Title = styled.div``

export const TextContainer = styled.div`
  box-sizing: border-box;
  padding: 20px;
`
export const ExpandableContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const ExpandableHeader = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`
export const ExpandableContent = styled.div``
