import styled from 'styled-components'

export const Card = styled.div<{ isChecked: boolean }>`
  background: #fff;
  padding: 16px;
  border: 1px solid #d6dae0;
  border-color: ${({ isChecked }: { isChecked: boolean }): string =>
    isChecked ? '#00905E' : '#d6dae0'};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Title = styled.h1`
  font-weight: 400;
  font-size: 19px;
  padding-bottom: 10px;
`
export const Tags = styled.div``

export const TagsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

export const Tag = styled.div`
  border: 1px solid #e7e9ed;
  width: fit-content;
  white-space: nowrap;
  border-radius: 5px;
  color: #6e707d;
  padding: 5px;
`
export const Subtitle = styled.h2`
  font-weight: 350;
  font-size: 17px;
  color: #68707a;
  width: 100%;
`
