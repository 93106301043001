import { GET_TASK_COMMENT_BY_TASK_ID } from 'gql/tasks.query'

import { ApolloError, useQuery } from '@apollo/client'
import {
  IGetTaskCommentsByTaskIdParams,
  IGetTaskCommentsByTaskIdResponse,
  IGetTaskCommentsResponse,
} from './getTaskCommentsService'

interface IUseFetchTaskCommentsServiceResponse {
  taskCommentsReplies?: IGetTaskCommentsResponse | null
  loading: boolean
  error?: ApolloError
  taskCommentsRefetch: () => void
}

export const useFetchTaskCommentsReplies = (
  taskId: string,
  replyId: string,
  skip: number = 0,
  count: number = 10,
): IUseFetchTaskCommentsServiceResponse => {
  const filter = taskId && replyId ? { taskId: taskId, repliedTo: replyId } : null

  const { data, loading, error, refetch } = useQuery<
    IGetTaskCommentsByTaskIdResponse,
    IGetTaskCommentsByTaskIdParams
  >(GET_TASK_COMMENT_BY_TASK_ID, {
    variables: {
      filter,
      skip,
      count,
    },
    fetchPolicy: 'cache-first',
    skip: !taskId,
  })

  return {
    taskCommentsReplies: data && data.getTaskComments,
    loading,
    error,
    taskCommentsRefetch: refetch,
  }
}
