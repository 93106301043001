import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetchTaskById } from 'services/tasks/getTaskByIdService'
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined'
import { ReactComponent as MoreIcon } from 'components/common/Button/icons/drop.svg'
import { ReactComponent as AttachmentIcon } from 'assets/document-attachment.svg'
import {
  StyledBottomBorder,
  StyledConditionsContainer,
  StyledDetailsContainer,
  StyledFlexRowContainer,
  StyledInDetailContainer,
  StyledOverviewContainer,
  StyledTaskDetailContainer,
  StyledTableContainer,
  styles,
  ActionsWrapper,
  StyledAttachmentContainer,
} from './styled-component'
import { useTranslation } from 'react-i18next'
import {
  StyledConditionContainer,
  StyledConditionSpan,
  StyledConditionTypeNameSpan,
  StyledConditionTypeSpan,
} from '../styled-components'
import moment from 'moment'
import { useFetchStudentsByTaskId } from 'services/tasks/getStudentsByTaskIdService'
import Grid from 'components/common/GridV2/Grid'
import { columnConfig, actionsConfig, studentsActionConfig } from './gridConfig'
import BreadCrumbV2 from 'components/common/BreadCrumbV2'
import { Link } from 'react-router-dom'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { useDeleteTask } from 'services/tasks/deleteTaskService'
import useTaskLayout from 'hooks/tasks/useTaskLayout'
import { useSwal } from 'hooks/useSwal'
import Drawer from 'components/common/Drawer'
import TasksDrawer from '../TasksDrawer/TaskDrawer'
import { useUserValue } from 'context/UserContext'
import { useFetchTaskComments } from 'services/tasks/getTaskCommentsService'
import TaskCommentsDrawer from '../CommentsDrawer/TaskCommentsDrawer'
import { ICourse } from 'pages/courses/courseList.interface'

const TaskDetails = (): JSX.Element => {
  const { taskId } = useParams()
  const { t } = useTranslation()
  const [taskDrawerOpened, setTaskDrawerOpened] = useState<boolean>(false)
  const [taskCommentsDrawerOpened, setTaskCommentsDrawerOpened] = useState<boolean>(false)
  const [taskIdComment, setTaskIdComment] = useState<string>('')
  const { task, taskRefetch } = useFetchTaskById(taskId)
  const { id: companyIdFromUrl } = useParams()

  const [state] = useUserValue()

  const companyId = companyIdFromUrl
    ? companyIdFromUrl
    : state.selectedCompany
    ? state.selectedCompany.id
    : null

  const { mappedCourses, mappedGroups, loadingCourses, groupsLoading, setCurrentTaskId } =
    useTaskLayout(companyId)

  const { name, trigger, description, courseId, moduleId, createDate, attachment } = task || {}
  const conditionType = courseId ? courseId : moduleId
  const triggerType = trigger?.startsWith('BEFORE') ? 'Start' : 'Finish'
  const { fireSwal } = useSwal()
  const navigate = useNavigate()

  const { students } = useFetchStudentsByTaskId(taskId)
  const { deleteTask } = useDeleteTask()

  const { taskComments, loading: taskCommentsLoading } = useFetchTaskComments(taskIdComment || '')

  const handleDeleteClick = async (): Promise<void> => {
    const taskIdArray = [taskId].filter(id => id !== undefined) as string[]

    fireSwal({
      title: t('tasks_layout.sure_delete_task'),
      confirmText: t('tasks_layout.yes_delete'),
      onConfirm: async () => {
        try {
          await deleteTask(taskIdArray)
          navigate('/tasks')
        } catch (error) {
          console.error('Failed to delete task:', error)
        }
      },
    })
  }
  const handleEditClick = (): void => {
    setCurrentTaskId(taskId || '')
    setTaskDrawerOpened(true)
  }

  const handleActionsClick = (id?: string): void => {
    if (!id) return
    setTaskIdComment(id)
    setTaskCommentsDrawerOpened(true)
  }

  const config = columnConfig(t)
  const actions = actionsConfig(handleDeleteClick, t, handleEditClick)
  const studentAction = studentsActionConfig(handleActionsClick, t)

  const breadCrumbData = [
    {
      label: `Tasks `,
      icon: <TrendingUpOutlinedIcon style={styles.border} />,
      link: `/tasks`,
    },
    {
      label: `${name}`,
    },
  ]

  return (
    <>
      <BreadCrumbV2 data={breadCrumbData} />

      <StyledTaskDetailContainer>
        <StyledBottomBorder>
          <h1 style={styles.title}>{name}</h1>
          <ActionsWrapper>
            <GridActionMenu actionConfig={actions} button={<MoreIcon />} />
          </ActionsWrapper>
        </StyledBottomBorder>

        <StyledDetailsContainer>
          <StyledOverviewContainer>
            <h4>{t('general.overview')}</h4>
            <p style={styles.description}>{description}</p>
          </StyledOverviewContainer>
          <StyledConditionsContainer>
            <h4>{t('general.details')}</h4>
            <StyledFlexRowContainer>
              <StyledInDetailContainer>
                <p style={styles.detailTitle}>{t('general.conditionType')}:</p>
                {courseId ? (
                  <StyledConditionTypeSpan>Course</StyledConditionTypeSpan>
                ) : moduleId ? (
                  <StyledConditionTypeSpan>Module</StyledConditionTypeSpan>
                ) : (
                  <>Off</>
                )}
              </StyledInDetailContainer>
              <StyledInDetailContainer>
                <p style={styles.detailTitle}>{t('general.condition')}:</p>
                {conditionType ? (
                  <StyledConditionContainer>
                    <StyledConditionSpan>
                      {trigger?.startsWith('BEFORE') ? 'Before' : 'After'}
                    </StyledConditionSpan>
                    <span>-</span>
                    <StyledConditionTypeNameSpan>
                      {(conditionType as ICourse).name}
                    </StyledConditionTypeNameSpan>
                    <span>-</span> <StyledConditionSpan>{triggerType}</StyledConditionSpan>{' '}
                  </StyledConditionContainer>
                ) : (
                  '-'
                )}
              </StyledInDetailContainer>
            </StyledFlexRowContainer>
            <StyledFlexRowContainer>
              <StyledInDetailContainer>
                <p style={styles.detailTitle}>{t('general.create_date')}:</p>
                <p style={styles.content}> {moment(createDate).format('DD-MM-YYYY')} </p>
              </StyledInDetailContainer>
              <StyledInDetailContainer>
                {attachment && (
                  <>
                    <p style={styles.detailTitle}>{t('general.fileAttached')}:</p>
                    <StyledAttachmentContainer>
                      <AttachmentIcon />
                      <Link to={attachment?.link || ''} target='_blank' style={styles.fileTitle}>
                        {attachment.name}
                      </Link>
                    </StyledAttachmentContainer>
                  </>
                )}
              </StyledInDetailContainer>
            </StyledFlexRowContainer>
          </StyledConditionsContainer>
        </StyledDetailsContainer>
      </StyledTaskDetailContainer>

      <h3 style={styles.student}>{t('general.students')}</h3>
      <StyledTableContainer>
        <Grid
          data={students}
          config={config}
          actionConfig={studentAction}
          gridActionLabel={t('actions.activity')}
        />
      </StyledTableContainer>

      <Drawer opened={taskDrawerOpened} totalWidth='630px'>
        <TasksDrawer
          taskId={taskId || ''}
          setTaskDrawerOpen={setTaskDrawerOpened}
          companyId={companyId}
          onTaskAdded={async (): Promise<void> => {
            await taskRefetch()
          }}
          mappedCourses={mappedCourses}
          mappedGroups={mappedGroups}
          coursesLoading={loadingCourses}
          groupsLoading={groupsLoading}
          setTaskId={setCurrentTaskId}
        />
      </Drawer>

      <Drawer opened={taskCommentsDrawerOpened} totalWidth='630px'>
        <TaskCommentsDrawer
          setDrawerOpened={setTaskCommentsDrawerOpened}
          data={taskComments}
          taskCommentsLoading={taskCommentsLoading}
        />
      </Drawer>
    </>
  )
}

export default TaskDetails
