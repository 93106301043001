import { Button } from 'components/common/Button'
import { useUserValue } from 'context/UserContext'
import { useUpdateProfileAvatar } from 'hooks/users/useEditProfileService'
import React, { ReactElement, RefObject, useEffect, useRef, useState } from 'react'
import { ReactComponent as MoreIcon } from 'components/common/Button/icons/drop.svg'
import Cropper, { ReactCropperElement } from 'react-cropper'
import { ReactComponent as CoachIcon } from 'assets/coach.svg'
import Modal from '@mui/material/Modal'
import { useTranslation } from 'react-i18next'
import {
  UserNameText,
  UserShortInfo,
  UserRoleText,
  StyledFlex,
  ProfileBackground,
  AvatarContainer,
  InformationBackground,
  DetailsHeader,
  Half,
  ImageCropperContainer,
  ActionsWrapper,
  InputWrapper,
  ActionsGrid,
  ActionsItem,
  styles,
} from './styled-components'
import { ChangePassword } from './ChangePassword'
import EditAdminProfile from './EditProfile'
import { useParams } from 'react-router-dom'
import { roles } from 'utils/permission'
import { IPersonalInformation } from './PersonalInformation.interface'
import UserDetailsComponent, {
  StatusWrapperComponent,
  WrapperComponent,
} from '../AllUserProfile/UsersProfile/Components/Wrapper'
import {
  CustomBorder,
  DetailsFlex,
  StyledBorder,
} from '../AllUserProfile/UsersProfile/styled-components'
import { getInfoItemsRow } from 'hooks/users/staticData'
import GridActionMenu from 'components/common/GridV2/GridActionMenu/GridActionMenu'
import { actionConfig, getActions } from './gridConfig'
import { EditableAvatarV2 } from 'components/common/EditableAvatar.V2/EditableAvatar'
import { useSwal } from 'hooks/useSwal'
import { Tooltip } from '@mui/material'
import { IUserCompany } from 'interfaces/users'

export const getRole = (role: string, isCompany?: boolean): string => {
  const roleMap = {
    [roles.super_admin]: isCompany ? 'Company Super Admin' : 'Suada Super Admin',
    [roles.admin]: isCompany ? 'Group Admin' : 'Suada Admin',
    [roles.coach]: 'Tutor',
    [roles.student]: 'Student',
    [roles.consultant]: 'Suada Consultant',
  }

  return roleMap[role] || role
}

const AdminProfile = (): ReactElement => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const { fireSwal } = useSwal()
  const [isClickedOnEdit, setIsClickedOnEdit] = useState(false)
  const { id: userId } = useParams<{ id: string }>()
  const [cropperOpen, setCropperOpen] = useState(false)
  const [file, setFile] = useState<string | null>(null)
  const cropper = useRef<ReactCropperElement>(null)
  const avatarRef = useRef(null)

  const [isClickedOnChangePassword, setIsClickedOnChangePassword] = useState(false)

  const {
    firstName,
    lastName,
    email,
    avatar,
    id,
    role,
    gender,
    birthDate,
    location,
    jobTitle,
    companyId,
    phone,
    createDate,
    biography,
    note,
    status,
    age,
    phoneFields,
    isCompanyAdmin,
    isSuperAdmin,
  } = state.currentUser
  const [profileData, setProfileData] = useState<IPersonalInformation>({
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    companyId: {
      id: '',
      name: '',
    },
    createDate: new Date(),
    birthDate: new Date(),
    gender: 'prefer not to say',
    location: '',
    phone: '',
    jobTitle: '',
    biography: '',
    note: '',
    status: '',
    id: '',
    age: 0,
    phoneFields: null,
    isCompanyAdmin: false,
    isSuperAdmin: false,
    phoneFieldValue: null,
    avatar: '',
  })

  useEffect(() => {
    const data = {
      firstName,
      lastName,
      email,
      role,
      companyId,
      createDate,
      birthDate,
      gender,
      location,
      phone,
      jobTitle,
      biography,
      note,
      status,
      id,
      age,
      phoneFields,
      isCompanyAdmin,
      isSuperAdmin,
      phoneFieldValue: phoneFields ? phoneFields.code : null,
      avatar,
    } as IPersonalInformation
    setProfileData(data)
  }, [
    firstName,
    lastName,
    email,
    role,
    companyId,
    createDate,
    birthDate,
    gender,
    location,
    phone,
    jobTitle,
    biography,
    note,
    status,
    id,
    age,
    phoneFields,
    isCompanyAdmin,
    isSuperAdmin,
    avatar,
  ])

  const userCompany = companyId as IUserCompany
  const isCompany = userCompany && userCompany.id && userCompany.name

  const { updateAvatar, hasResponseReceived } = useUpdateProfileAvatar()

  const finishCrop = (
    action: string,
    crudFunction: (isFinished: boolean) => void,
    cropper: RefObject<ReactCropperElement> | null,
  ): void => {
    let croppedFile = null
    if (action === 'update') {
      croppedFile = cropper?.current?.cropper.getCroppedCanvas()?.toDataURL()
    }
    if (userId) updateAvatar(croppedFile as string, userId, action, crudFunction)
  }

  const infoItemsRow = getInfoItemsRow(profileData, !!isCompany)

  const setResetPasswordOpen = (): void => {
    setIsClickedOnChangePassword(true)
  }
  const handleEditClick = (): void => {
    setIsClickedOnEdit(true)
  }

  const handleAvatarDelete = (): void => {
    fireSwal({
      title: `${t('popups.delete_avatar')} `,
      onConfirm: () => {
        finishCrop('delete', () => undefined, null)
        setFile(null)
      },
      confirmText: t('popups.confirm_delete'),
    })
  }

  const actions = actionConfig(handleAvatarDelete, profileData)

  const visibleActions = getActions(profileData, handleEditClick, setResetPasswordOpen)

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault()

    const { files } = e.target
    const reader = new FileReader()

    reader.onload = (): void => {
      const img: HTMLImageElement = new Image() as HTMLImageElement
      if (typeof reader.result === 'string') {
        img.src = reader.result
        setFile(reader.result)
      }
      setCropperOpen(true)
    }
    if (files) reader.readAsDataURL(files[0])
  }

  const cropperModalToggle = (): void => {
    setCropperOpen(!cropperOpen)
  }

  const crudFunction = (isFinished: boolean): void => {
    if (isFinished) {
      setCropperOpen(false)
      setFile(null)
    }
  }

  const onSave = (): void => {
    finishCrop('update', crudFunction, cropper)
    setFile(null)
    setCropperOpen(false)
  }

  return (
    <div>
      <>
        <StyledFlex>
          <ProfileBackground>
            <ActionsWrapper>
              {actions && actions.length > 0 && (
                <GridActionMenu actionConfig={actions} row={profileData} button={<MoreIcon />} />
              )}
              <InputWrapper>
                <input
                  type='file'
                  name='file'
                  id='file'
                  ref={avatarRef}
                  onChange={handleAvatarChange}
                />
              </InputWrapper>
            </ActionsWrapper>
            <AvatarContainer>
              <EditableAvatarV2
                img={avatar}
                finishCrop={finishCrop}
                hasResponseReceived={hasResponseReceived}
                role={role ?? ''}
              />
              <UserShortInfo>
                <UserNameText>
                  {firstName} {lastName}
                  {profileData.isTutor && (
                    <Tooltip title={t('users_layout.tutor_admin_indicator')} arrow>
                      <CoachIcon />
                    </Tooltip>
                  )}
                </UserNameText>
                <UserRoleText>{getRole(profileData.role || 'N/A', !!isCompany)}</UserRoleText>
              </UserShortInfo>
            </AvatarContainer>
            <ActionsGrid>
              {visibleActions.map((action, index) => (
                <React.Fragment key={index}>
                  <ActionsItem onClick={action.onClick}>
                    <div className='action-content'>
                      {action.icon}
                      {action.text}
                    </div>
                  </ActionsItem>
                  {index !== actions.length - 1 && <CustomBorder />}
                </React.Fragment>
              ))}
            </ActionsGrid>
          </ProfileBackground>
          <InformationBackground>
            <DetailsHeader>{t('general.detailed_information')} </DetailsHeader>
            <DetailsFlex>
              <Half>
                <StatusWrapperComponent />
                {infoItemsRow.slice(0, 4).map((item, index) => (
                  <WrapperComponent key={index} item={item} />
                ))}
              </Half>
              <StyledBorder />
              <Half>
                {infoItemsRow.slice(4).map((item, index) => (
                  <WrapperComponent key={index} item={item} />
                ))}
              </Half>
            </DetailsFlex>
          </InformationBackground>
        </StyledFlex>

        <UserDetailsComponent profileData={profileData} />
        {isClickedOnEdit && (
          <EditAdminProfile setIsClickedOnEdit={setIsClickedOnEdit} initialValues={profileData} />
        )}
        {isClickedOnChangePassword && (
          <ChangePassword setIsClickedOnChangePassword={setIsClickedOnChangePassword} />
        )}
      </>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={cropperOpen}
        onClose={cropperModalToggle}
        style={styles.modal}
        closeAfterTransition
      >
        <ImageCropperContainer>
          <Cropper
            ref={cropper}
            src={file || undefined}
            style={{ height: 500, width: 600 }}
            aspectRatio={16 / 16}
            guides={false}
          />
          <div className='actions'>
            <Button
              color='primary'
              onClick={cropperModalToggle}
              background='#E0E1E2'
              textColor='#414141'
              text={t('actions.cancel')}
              type='small'
            />
            <Button
              color='primary'
              onClick={onSave}
              background='#06C68F'
              text={t('actions.save')}
              type='small'
            />
          </div>
        </ImageCropperContainer>
      </Modal>
    </div>
  )
}

export default AdminProfile
