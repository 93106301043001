import React, { ReactElement } from 'react'
import Statistic from './Statistic'

import { Container, Content, SelectCourseById, StyledTableContainer } from './styled-components'
import { SelectField } from 'components/common/SelectField'
import { ICoursesAnalyticsProps } from './coursesAnalytics.interface'

import { SelectFieldWrapper } from '../Groups/styled-components'
import Grid from 'components/common/GridV2/Grid'
import useCoursesAnalytics from 'hooks/analytics/useCourseAnalytics'
import MyLoader from 'loading/loading'

const CoursesAnalytics = ({
  companyId,
  onCourseChange,
  coursesOptions,
  selectedCourse,
}: ICoursesAnalyticsProps): ReactElement => {
  const { t, config, courses, coursesLoading, coursesError, loading, analytics } =
    useCoursesAnalytics(companyId as string, selectedCourse)

  return (
    <Container>
      <SelectCourseById>
        <SelectFieldWrapper>
          <SelectField
            placeholder={t('analytics.searchByCourse')}
            value={selectedCourse}
            options={coursesOptions}
            onChange={onCourseChange}
            isClearable={true}
            isLoading={coursesLoading}
          />
        </SelectFieldWrapper>
      </SelectCourseById>

      <Content>
        {/* <AnalyticsStatistic analytics={analytics} loading={loading} /> */}
        <Statistic
          companyId={companyId as string}
          additionalQuantities={{
            groupsQuantity: analytics?.groupsQuantity,
            skillTestVideosQuantity: analytics?.skillTestVideosQuantity,
            studentsQuantity: analytics?.studentsQuantity,
            coursesQuantity: coursesOptions?.length,
          }}
          loading={loading}
        />
      </Content>
      <StyledTableContainer>
        {coursesLoading ? (
          <MyLoader width={250} speed={0.8} />
        ) : coursesError ? (
          <div>Error: {coursesError}</div>
        ) : courses ? (
          <Grid data={courses} config={config} firstRowColor='#e5f4e4' isFirstRowBold={true} />
        ) : (
          <div>No courses available or data is not in expected format.</div>
        )}
      </StyledTableContainer>
    </Container>
  )
}

export default CoursesAnalytics
