import styled from 'styled-components'

export const ModuleWrapper = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  svg {
    color: #f50057;
    cursor: pointer;
  }
`
