import { useUserValue } from 'context/UserContext'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { roles } from 'utils/permission'
import { ITasksOption } from 'hooks/tasks/useTaskDrawer.interface'
import { useQuery } from '@apollo/client'
import { GET_ALL_SKILLS } from 'gql/skilss.query'
import { IAddCourseTemplateResponse } from './addCourseTemplate.interface'
import { ReactCropperElement } from 'react-cropper'
import { ValueType } from 'react-select'
import { ISelectDropDownOption } from 'components/common/SelectDropDown/selectDropDown.interface'

const useAddCourseTemplate = ({
  setFieldValue,
  handleImageChange,
}: {
  setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void
  handleImageChange: (file: string | null, field: string, fileName?: string) => void
}): IAddCourseTemplateResponse => {
  const [state] = useUserValue()
  const cropper = useRef<ReactCropperElement>(null)
  const [file, setFile] = useState<string>('')
  const [imageType, setImageType] = useState('')
  const [cropperOpen, setCropperOpen] = useState(false)

  const { role } = state.currentUser

  const rolePermission = !state.selectedCompany?.id && role === roles.super_admin

  useEffect(() => {
    if (rolePermission) {
      setFieldValue('coursePrivacy', 'public')
    }
  }, [])

  const { data: skillsData } = useQuery(GET_ALL_SKILLS)

  const cropperModalToggle = (): void => {
    setCropperOpen(!cropperOpen)
  }

  const finishCrop = (): void => {
    let croppedFile = null
    croppedFile = cropper.current?.cropper.getCroppedCanvas().toDataURL()

    handleImageChange(croppedFile as string, imageType, file.match(/[^:/]\w+(?=;|,)/)![0])
    setFile('')
    setCropperOpen(false)
  }

  const imageChangeHandler = (e: ChangeEvent<HTMLInputElement>, field: string): void => {
    const { files } = e.target
    if (!files) return
    const reader = new FileReader()
    reader.onload = (): void => {
      const img = new Image()
      img.src = reader.result as string
      setFile(reader.result as string)
    }
    reader.readAsDataURL(files[0])
    setCropperOpen(true)
    setImageType(field)
  }

  const deleteImage = (field: string): void => {
    handleImageChange(null, field)
  }

  const skillList =
    skillsData &&
    skillsData.getAllSkills.map((i: ITasksOption) => ({
      label: i.label,
      value: i.label,
    }))

  const handleIsValidNewOption = (
    inputValue: string,
    selectOptions: ValueType<ISelectDropDownOption, true>,
  ): boolean => {
    // Check for the same value --> ASD === ASD ?
    const exactValueExists = selectOptions?.find(el => el.value === inputValue)
    // Check if the value has a valid length.
    // Without this, it will show create option for empty values.
    const valueIsNotEmpty = inputValue.trim().length
    // If true show create option.
    return !exactValueExists && !!valueIsNotEmpty
  }

  return {
    imageChangeHandler,
    deleteImage,
    skillList,
    handleIsValidNewOption,
    cropper,
    cropperModalToggle,
    finishCrop,
    cropperOpen,
    file,
  }
}

export default useAddCourseTemplate
