import { Button } from 'components/common/Button'
import Modal from 'components/common/Modal'
import { PasswordInput } from 'components/common/TextInput/PasswordInput'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateUserPasswordById } from 'services/users/editProfileService'
import { Wrapper, ButtonsContainer } from './styled-components'

const ResetUsersPassword: React.FC<{
  isModalOpen: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  userId?: string
}> = ({ isModalOpen, setModalOpen, userId }) => {
  if (!userId) return null
  const { t } = useTranslation()
  const [newPassword, setNewPassword] = useState('')
  const handleCloseDrawer = (): void => setModalOpen(false)
  const { updateUserPassword } = useUpdateUserPasswordById()
  const onSave = useCallback(() => {
    updateUserPassword(userId, newPassword)
    handleCloseDrawer()
  }, [userId, newPassword])

  return (
    <Modal isOpened={isModalOpen} onClose={handleCloseDrawer}>
      <Wrapper>
        <h2>{t('general.set_temporary_password')}</h2>
        <PasswordInput
          label={t('general.set_temporary_password')}
          name='newpassword'
          size='small'
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            setNewPassword(e.target.value)
          }
          value={newPassword}
        />
        <ButtonsContainer>
          <Button
            text={t('actions.cancel')}
            type='small'
            onClick={handleCloseDrawer}
            background='#E0E1E2'
            textColor='#414141'
          />
          <Button
            text={t('actions.save')}
            type='small'
            color='secondary'
            background='#06C68F'
            isDisabled={newPassword.length < 1}
            onClick={onSave}
          />
        </ButtonsContainer>
      </Wrapper>
    </Modal>
  )
}

export default ResetUsersPassword
