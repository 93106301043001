import gql from 'graphql-tag'

export const GET_USER_ACTIVITIES = gql`
  query UserActivities($userId: String!) {
    getUserActivities(userId: $userId) {
      user {
        firstName
        lastName
        avatar
        avatarThumbnail
        leaderboard {
          companyPosition
          globalPosition
        }
      }
      activity {
        document
        documentModel
        action
        title
        createDate
      }
    }
  }
`
