import {
  CustomAvatarStyles,
  EmailSpanStyles,
  StyledDetailContainer,
  StyledStudentContainer,
} from 'pages/courses/CourseSkillTestVideos/styled-components'
import { ReactComponent as DeleteIcon } from 'assets/trash-can-red.svg'
import { ReactComponent as EditRoundedIcon } from 'assets/edit.svg'
import { ReactComponent as ActionIcon } from 'assets/user-activity.svg'
import AvatarIcon from 'assets/profile.svg'

import React, { ReactElement } from 'react'

import { Tooltip } from '@mui/material'
import { NoValueWrapper } from 'components/common/GridV2/styled-components'
import { GroupItem, GroupTitle, GroupsWrapper } from 'components/UsersLayout/styled-components'
import IconButton from '@mui/material/IconButton'
import {
  IGridItemConfig,
  IStudentActions,
  IStudentColumnConfig,
  IStudentsActionConfig,
  ITaskAdmin,
} from '../TasksDrawer/tasksDrawer.interface'
import { Action } from 'components/common/GridV2'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'
import { DeleteSpan } from 'components/common/Styled/styled-components'
import useConditionalTooltip from 'hooks/tooltip/useConditionalTooltip'

export const columnConfig = (t: (key: string) => string): IStudentColumnConfig[] => {
  const StudentDetailsCell = ({ item }: { item: IGridItemConfig }): JSX.Element => {
    const { firstName, lastName, avatar, email } = item
    const { showTooltip, contentRef, title } = useConditionalTooltip({
      title: email,
      maxWidth: 150,
    })

    return (
      <StyledStudentContainer>
        {avatar ? (
          <img src={avatar} alt={`${firstName} ${lastName}`} style={CustomAvatarStyles} />
        ) : (
          <img src={AvatarIcon} alt='Profile' style={CustomAvatarStyles} />
        )}
        <div ref={contentRef}>
          {showTooltip ? (
            <Tooltip title={title} arrow>
              <StyledDetailContainer>
                <span>{`${firstName} ${lastName}`}</span>
                <span style={EmailSpanStyles}>{email}</span>
              </StyledDetailContainer>
            </Tooltip>
          ) : (
            <StyledDetailContainer>
              <span>{`${firstName} ${lastName}`}</span>
              <span style={EmailSpanStyles}>{email}</span>
            </StyledDetailContainer>
          )}
        </div>
      </StyledStudentContainer>
    )
  }

  const config: IStudentColumnConfig[] = [
    {
      id: 'student',
      label: t('user_details.student_details'),
      render: (item: IGridItemConfig): JSX.Element => <StudentDetailsCell item={item} />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'groups',
      label: t('general.groups'),
      render: (item: IGridItemConfig): JSX.Element => {
        const firstTwoGroups = item?.groups?.slice(0, 2)
        const exGroups = item?.groups?.slice(2, item.groups.length)
        return (
          <div>
            {firstTwoGroups.length === 0 && <NoValueWrapper>N/A</NoValueWrapper>}
            <GroupsWrapper>
              {firstTwoGroups.map(
                (group, index: number) =>
                  index < 3 && (
                    <GroupItem key={group.groupId}>
                      <IconButton>
                        <span className='material-icons'>groups</span>{' '}
                      </IconButton>
                      <GroupTitle className='group-item-title'>{group.name}</GroupTitle>
                    </GroupItem>
                  ),
              )}

              {exGroups.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {exGroups.map((exGroup: { id: string; name: string }) => (
                        <div key={exGroup.id}>{exGroup.name}</div>
                      ))}
                    </>
                  }
                >
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                  >
                    ...
                  </span>
                </Tooltip>
              )}
            </GroupsWrapper>
          </div>
        )
      },
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'tutor',
      label: t('general.tutor'),
      render: (item: IGridItemConfig): JSX.Element => {
        return (
          <div>
            {item?.admins ? (
              <>{item?.admins.map((admin: ITaskAdmin) => admin.firstName).join(', ')}</>
            ) : (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
          </div>
        )
      },
      numeric: false,
      disablePadding: false,
    },

    {
      id: 'status',
      label: t('general.status'),
      render: (item: IGridItemConfig): JSX.Element => {
        return (
          <div>
            {item?.task?.status ? (
              <StatusIndicator status={item?.task?.status as unknown as GridStatusEnums} />
            ) : (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
          </div>
        )
      },
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'commentsCount',
      label: t('general.comments'),
      render: (item: IGridItemConfig): JSX.Element => {
        return (
          <div>
            {item.commentsCount != null ? (
              <span>{item.commentsCount}</span>
            ) : (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
          </div>
        )
      },
      numeric: false,
      disablePadding: false,
    },

    {
      id: 'filesAttached',
      label: t('general.filesAttached'),
      render: (item: IGridItemConfig): JSX.Element => {
        return (
          <div>
            {item.attachmentsCount != null ? (
              <span>{item.attachmentsCount}</span>
            ) : (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
          </div>
        )
      },
      numeric: false,
      disablePadding: false,
    },
  ]

  return config
}

export const actionsConfig = (
  deleteTask: () => void,
  t: (key: string) => string,
  handleEditClick: () => void,
): {
  render: () => ReactElement
}[] => [
  {
    render: () => (
      <Action onClick={handleEditClick}>
        <EditRoundedIcon /> <span>{t('actions.edit')}</span>
      </Action>
    ),
  },
  {
    render: () => (
      <Action onClick={deleteTask}>
        <DeleteIcon /> <DeleteSpan>{t('actions.delete')}</DeleteSpan>
      </Action>
    ),
  },
]

export const studentsActionConfig = (
  handleActionClick: (id?: string) => void,
  t: (key: string) => string,
): IStudentsActionConfig[] => {
  const action = [
    {
      id: 'activity',
      render: (item: IStudentActions): JSX.Element => (
        <IconButton onClick={(): void => handleActionClick(item?.task?._id)}>
          <Tooltip title={t('general.actions')} arrow>
            <ActionIcon />
          </Tooltip>
        </IconButton>
      ),
    },
  ]
  return action
}
