import { useMutation, MutationTuple, ApolloError } from '@apollo/client'
import { DELETE_FLASHCARDS } from 'gql/flashcards.query'

interface IDeleteFlashCardsResponse {
  deleteFlashCards: string
}

interface IDeleteFlashCardsVariables {
  ids: string[]
}

interface IDeleteFlashCardsServiceResponse {
  deleteFlashCardsMutation: MutationTuple<IDeleteFlashCardsResponse, IDeleteFlashCardsVariables>[0]
  loading: boolean
  error?: ApolloError
}

const deleteFlashCardsService = (): IDeleteFlashCardsServiceResponse => {
  const [deleteFlashCardsMutation, { loading, error }] = useMutation<
    IDeleteFlashCardsResponse,
    IDeleteFlashCardsVariables
  >(DELETE_FLASHCARDS)

  return {
    deleteFlashCardsMutation,
    loading,
    error,
  }
}

export default deleteFlashCardsService
