import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { ADD_QUESTIONS_TO_TEST } from 'gql/questions.query'
import { GET_TEST } from 'gql/tests/tests.query'

interface IAddQuestionsToTestAssessmentServiceResponse {
  addQuestionsToTestAssessmentMutation: MutationTuple<
    IAddQuestionsToTestResponse,
    IAddQuestionsToTestParams
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IAddQuestionsToTestResponse {
  addQuestionsToTest: string
}

interface IAddQuestionsToTestParams {
  testAssessmentId: string
  questionIds: string[]
}

const addQuestionsToTestAssessmentService = (
  testId: string,
): IAddQuestionsToTestAssessmentServiceResponse => {
  const [addQuestionsToTestAssessmentMutation, { loading, error }] = useMutation<
    IAddQuestionsToTestResponse,
    IAddQuestionsToTestParams
  >(ADD_QUESTIONS_TO_TEST, {
    refetchQueries: [
      {
        query: GET_TEST,
        variables: {
          testId,
        },
      },
    ],
  })

  return {
    addQuestionsToTestAssessmentMutation,
    loading,
    error,
  }
}

export default addQuestionsToTestAssessmentService
