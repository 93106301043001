import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { UPDATE_USER } from 'gql/users.query'
import { IUser } from 'interfaces/users'

interface IUpdateUserServiceResponse {
  updateUserMutation: MutationTuple<IUpdateUserResponse, IUpdateUserParams>[0]
  loading: boolean
  error?: ApolloError
}

export interface IUpdateUserParams {
  id: string
  input: IUser
}

export interface IUpdateUserResponse {
  updateUser: IUser
}

const updateUserService = (): IUpdateUserServiceResponse => {
  const [updateUserMutation, { loading, error }] = useMutation<
    IUpdateUserResponse,
    IUpdateUserParams
  >(UPDATE_USER, {})

  return {
    updateUserMutation,
    loading,
    error,
  }
}

export default updateUserService
