import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { GET_QUESTIONS, EDIT_QUESTION, CHANGE_QUESTION_ORDER } from 'gql/questions.query'
import { IQuestion } from 'interfaces/questions'
import { updateCache } from 'services/updateCache'

interface IEditQuestionServiceResponse {
  editQuestionMutation: MutationTuple<IEditQuestionResponse, IEditQuestionParams>[0]
  loading: boolean
  error?: ApolloError
}

interface IEditQuestionResponse {
  editQuestion: IQuestion
}

interface IEditQuestionParams {
  moduleId: string
  questionId: string
  input: IQuestion
}

export const editQuestionService = (): IEditQuestionServiceResponse => {
  const [editQuestionMutation, { loading, error }] = useMutation<
    IEditQuestionResponse,
    IEditQuestionParams
  >(EDIT_QUESTION)

  return {
    editQuestionMutation,
    loading,
    error,
  }
}

interface IChangeQuestionsOrderServiceResponse {
  changeQuestionsOrderMutation: MutationTuple<
    IChangeQuestionsOrderResponse,
    IChangeQuestionsOrderParams
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IChangeQuestionsOrderResponse {
  changeQuestionsOrder: IQuestion
}

interface IChangeQuestionsOrderParams {
  draggedId: string
  droppedId: string
}

export const changeQuestionsOrderService = (
  params: IChangeQuestionsOrderParams,
): IChangeQuestionsOrderServiceResponse => {
  const [changeQuestionsOrderMutation, { loading, error }] = useMutation<
    IChangeQuestionsOrderResponse,
    IChangeQuestionsOrderParams
  >(CHANGE_QUESTION_ORDER, {
    update(cache, { data }) {
      if (data?.changeQuestionsOrder) {
        const document = data.changeQuestionsOrder
        updateCache({
          objectName: 'questions',
          query: GET_QUESTIONS,
          cache,
          document,
          action: 'update',
          params,
        })
      }
    },
  })

  return {
    changeQuestionsOrderMutation,
    loading,
    error,
  }
}
