import { useQuery } from '@apollo/client'
import { GET_COURSE } from 'gql/course/course.query'
import { ICourse } from 'pages/courses/courseList.interface'

interface GetCourseByIdParams {
  courseId: string
}

interface GetCourseByIdResponse {
  getCourseById: ICourse
}

interface IGetCourseByIdReturn {
  course?: ICourse
  loading: boolean
  refetch: () => void
  error: string | undefined
}

export const getCourseById = (courseId: string): IGetCourseByIdReturn => {
  const {
    data: courseData,
    loading,
    refetch,
    error,
  } = useQuery<GetCourseByIdResponse, GetCourseByIdParams>(GET_COURSE, {
    variables: { courseId },
  })

  return {
    course: courseData?.getCourseById,
    loading,
    refetch,
    error: error?.message,
  }
}
