import { OperationVariables, useQuery } from '@apollo/client'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'

import { GET_STUDENTS_BY_TEST_ID } from 'gql/users.query'
import { IUser } from 'interfaces/users'

export const PER_PAGE = 10

export const useFetchStudentsByTestAssessmentId = (
  testAssessmentId: string,
  filter: IFilterQueryType = {},
  currentPage = 1,
  perPage: number = PER_PAGE,
  companyId?: string,
): {
  users: {
    totalCount: number
    data: IUser[]
  }
  loading: boolean
  error?: string
  refetch: (variables: OperationVariables) => void
} => {
  const { data, loading, error, refetch } = useQuery(GET_STUDENTS_BY_TEST_ID, {
    variables: {
      testAssessmentId,
      filter,
      currentPage,
      perPage,
      companyId,
    },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    users: data && data.getStudentsByTestAssessmentId,
    loading,
    error: errorMessage,
    refetch,
  }
}
