import i18n from 'i18n/index'
import { IFilterData } from './FilterDrawer/filterDrawer.interface'

interface IOption {
  label: string
  value: string
}
export let existData: IOption[] = []
export let filterOption: IFilterData = {} as IFilterData

function translate(): void {
  existData = [
    { label: i18n.t('general.all'), value: 'all' },
    { label: i18n.t('general.yes'), value: 'yes' },
    { label: i18n.t('general.no'), value: 'no' },
  ]

  filterOption = {
    name: '',
    courses: [],
    coverImage: { label: i18n.t('general.all'), value: 'all' },
    video: { label: i18n.t('general.all'), value: 'all' },
    attachment: { label: i18n.t('general.all'), value: 'all' },
  }
}

i18n.on('languageChanged init', () => {
  translate()
})
