import { useMutation, MutationTuple, ApolloError } from '@apollo/client'
import { updateCache } from 'services/updateCache'
import {
  GET_SKILL_TEST_VIDEOS_TEMPLATES,
  ADD_SKILL_TEST_VIDEO_TEMPLATE,
} from 'gql/skillTestVideos.query'
import { ISkillTestVideo } from 'pages/modules/ModuleDetail/SkillTestVideos/SkillTestVideos.interface'

interface IAddSkillTestVideoTemplateResponse {
  addSkillTestVideoTemplate: ISkillTestVideo
}

interface IAddSkillTestVideoTemplateVariables {
  moduleId: string
  input: ISkillTestVideo
}

interface IAddSkillTestVideoTemplateServiceResponse {
  addSkillTestVideoTemplateMutation: MutationTuple<
    IAddSkillTestVideoTemplateResponse,
    IAddSkillTestVideoTemplateVariables
  >[0]
  loading: boolean
  error?: ApolloError
}

export interface IAddSkillTestVideoTemplateParams {
  moduleId: string
}

const addSkillTestVideoTemplateService = (
  params: IAddSkillTestVideoTemplateParams,
): IAddSkillTestVideoTemplateServiceResponse => {
  const [addSkillTestVideoTemplateMutation, { loading, error }] = useMutation<
    IAddSkillTestVideoTemplateResponse,
    IAddSkillTestVideoTemplateVariables
  >(ADD_SKILL_TEST_VIDEO_TEMPLATE, {
    update(cache, { data }) {
      if (data?.addSkillTestVideoTemplate) {
        updateCache({
          objectName: 'getSkillTestVideosTemplates',
          query: GET_SKILL_TEST_VIDEOS_TEMPLATES,
          cache,
          document,
          action: 'add',
          params,
        })
      }
    },
  })

  return {
    addSkillTestVideoTemplateMutation,
    loading,
    error,
  }
}

export default addSkillTestVideoTemplateService
