import React, { ReactElement } from 'react'
import moment from 'moment'
import {
  Action,
  CheckBoxAndAvatarContainer,
  GroupsWrapper,
  GroupItem,
  GroupTitle,
  EditActionToolTipPopperStyles,
} from './styled-components'
import strCutter from 'utils/strCutter'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { ReactComponent as DeleteIcon } from 'assets/trash-can-action.svg'
import { ReactComponent as EditRoundedIcon } from 'assets/editRounded.svg'
import { ReactComponent as BackspaceRoundedIcon } from 'assets/checkmark-outline-action.svg'
import { ReactComponent as CloseIcon } from 'assets/close-outline-action.svg'
import { ReactComponent as TimeLineIcon } from 'assets/activity.svg'
import { ReactComponent as SendIcon } from 'assets/send-alt.svg'
import { ReactComponent as LockIcon } from 'assets/reset.svg'
import SchoolIcon from '@mui/icons-material/School'
import Checkbox from 'components/common/Checkbox'
import { NoValueWrapper } from 'components/common/Grid/styled-components'
import AvatarIcon from 'assets/profile.svg'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import {
  FlexWrapper,
  StudentDetailsContainer,
  StudentDetailsTextContainer,
} from 'pages/tests/testDetails/studentsLayout/styled-components'
import { IUser, IUserCompany } from 'interfaces/users'
import { ReactComponent as CoachIcon } from 'assets/coach.svg'
import { RolesCapitalized, roles } from 'utils/permission'
import { TFunction } from 'interfaces/TFunction'
import { IPermissionsBool } from 'utils/rolesObject/rolesObject.interface'
import { IAction } from 'components/common/GridV2'

export const columnConfig = (
  role: string,
  selectItem: (id: string) => void,
  t: TFunction<'translation', undefined>,
  label: string,
  checkboxHidden = false,
  fromGroupTutors = false,
): IAction[] => {
  let grid: IAction[] = [
    {
      id: 'firstName',
      numeric: false,
      disablePadding: true,
      label,
      render: (item: IUser) => (
        <StudentDetailsContainer>
          <CheckBoxAndAvatarContainer style={{ marginLeft: 10 }}>
            <img src={item.avatarThumbnail || item.avatar || AvatarIcon} />
          </CheckBoxAndAvatarContainer>
          <StudentDetailsTextContainer>
            <FlexWrapper>
              {item.firstName} {item.lastName}{' '}
              {item.isTutor && (
                <Tooltip title={t('users_layout.tutor_admin_indicator')} arrow>
                  <CoachIcon />
                </Tooltip>
              )}
            </FlexWrapper>
            <div>{item.email}</div>
          </StudentDetailsTextContainer>
        </StudentDetailsContainer>
      ),
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.phone')}`,
      render: (item: IUser) => (
        <div>
          {item.phoneFields && item.phoneFields.code && `(${item.phoneFields.code}) `}
          {item.phone}
        </div>
      ),
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: `${t('general.status')}`,
      render: (item: IUser) => <StatusIndicator status={item.status} />,
    },
  ]

  if ([roles.admin, roles.student].includes(role)) {
    grid = [
      ...grid,
      {
        id: 'group',
        numeric: false,
        disablePadding: false,
        label: `${t('general.groups')}`,
        render: (item: IUser): ReactElement => {
          const groups = item.group || []
          const firstTwoGroups = groups.slice(0, 2)
          const exGroups = groups.slice(2, groups.length)
          return (
            <div>
              {firstTwoGroups.length === 0 && <NoValueWrapper>N/A</NoValueWrapper>}
              <GroupsWrapper>
                {firstTwoGroups.map(
                  (group, index: number) =>
                    index < 3 && (
                      <GroupItem key={group.groupId}>
                        <IconButton>
                          <span className='material-icons'>groups</span>{' '}
                        </IconButton>
                        <GroupTitle className='group-item-title'>{group.name}</GroupTitle>
                      </GroupItem>
                    ),
                )}

                {exGroups.length > 0 && (
                  <Tooltip
                    title={
                      <>
                        {exGroups.map(exGroup => (
                          <div key={exGroup.groupId}>{exGroup.name}</div>
                        ))}
                      </>
                    }
                  >
                    <span
                      style={{
                        display: 'inline-block',
                        cursor: 'pointer',
                        marginLeft: 5,
                      }}
                    >
                      ...
                    </span>
                  </Tooltip>
                )}
              </GroupsWrapper>
            </div>
          )
        },
      },
    ]
  }

  if ([roles.coach, roles.student].includes(role) && !fromGroupTutors) {
    grid = [
      ...grid,
      {
        id: 'courses',
        numeric: false,
        disablePadding: false,
        label: `${t('general.courses')}`,
        render: (item: IUser): ReactElement => {
          const courses = item.courses || []
          const firstTwoCourses = courses.slice(0, 2)
          const exCourse = courses.slice(3, courses.length)
          return (
            <div>
              {firstTwoCourses.length === 0 && <NoValueWrapper>N/A</NoValueWrapper>}
              <GroupsWrapper>
                {courses.map(
                  (course, index: number) =>
                    index < 3 && (
                      <GroupItem key={course.courseId}>
                        <IconButton>
                          <SchoolIcon fontSize='small' />
                        </IconButton>
                        <GroupTitle className='group-item-title'>{course.name}</GroupTitle>
                      </GroupItem>
                    ),
                )}

                {exCourse.length > 0 && (
                  <Tooltip
                    title={
                      <>
                        {exCourse.map(exGroup => (
                          <div key={exGroup.courseId}> {exGroup.name}</div>
                        ))}
                      </>
                    }
                  >
                    <span
                      style={{
                        display: 'inline-block',
                        cursor: 'pointer',
                        marginLeft: 5,
                      }}
                    >
                      ...
                    </span>
                  </Tooltip>
                )}
              </GroupsWrapper>
            </div>
          )
        },
      },
    ]
  }

  // if (role === 'COACH') {
  //   grid = [
  //     ...grid,
  //     {
  //       id: 'isQualified',
  //       numeric: false,
  //       disablePadding: false,
  //       label: 'Is Qualified',
  //       render: (item: User) => (
  //         <div>{item.isQualified ? 'Yes' : 'No'}</div>
  //       ),
  //     },
  //   ]
  // }

  grid = [
    ...grid,
    {
      id: 'companyId',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.company')}`,
      render: (item: IUser): ReactElement => (
        <div>
          {item.companyId ? (
            strCutter((item.companyId as IUserCompany).name, 25)
          ) : (
            <>SUADA {item.role === roles.consultant ? roles.consultant : roles.super_admin}</>
          )}
        </div>
      ),
    },
  ]
  grid = [
    ...grid,
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: `${t('general.create_date')}`,
      render: (item: IUser) => <>{moment(parseInt(item.createDate ?? '0')).format('DD-MM-YYYY')}</>,
    },
  ]

  if (!checkboxHidden) {
    grid = [
      {
        id: 'isChecked',
        label: '',
        render: (item: IUser) => (
          <CheckBoxAndAvatarContainer>
            <Checkbox
              checked={item.isChecked}
              onChange={(): void => {
                if (item.id) selectItem(item.id)
              }}
            />
          </CheckBoxAndAvatarContainer>
        ),
        numeric: false,
        disablePadding: true,
      },
      ...grid,
    ]
  }

  return grid
}

export const actionConfig = (
  handleEditClick: (id: string) => void,
  handleDeleteClick: (item: IUser) => void,
  handleActivityClick: (id: string) => void,
  handleResendPasswordClick: (id: string) => void,
  handleChangeStatusClick: (id: string, status?: string) => void,
  permissions: IPermissionsBool,
  field: RolesCapitalized,
  t: TFunction<'translation', undefined>,
  setResetPasswordOpen?: React.Dispatch<React.SetStateAction<boolean>>,
  setUserId?: React.Dispatch<React.SetStateAction<string | undefined>>,
): IAction[] => {
  const action: IAction[] = [
    {
      hide: !permissions[`edit${field}`],
      render: (item: IUser) => (
        <>
          {field === RolesCapitalized.COACH && item.isTutor ? (
            <Action disabled>
              <Tooltip
                title={t('users_layout.tutor_admin_edit_permission')}
                arrow
                PopperProps={{ style: EditActionToolTipPopperStyles }}
              >
                <EditRoundedIcon />
              </Tooltip>
            </Action>
          ) : (
            <Action
              onClick={(): void => {
                if (item.id) handleEditClick(item.id)
              }}
              className='user_edit_icon'
            >
              <Tooltip title={t('actions.edit')} arrow>
                <EditRoundedIcon />
              </Tooltip>
            </Action>
          )}
        </>
      ),
    },

    {
      hide: !permissions[`suspend${field}`],
      render: (item: IUser) => (
        <Action
          className={item.status === 'ACTIVE' ? 'suspend-action' : 'activate-action'}
          onClick={(): void => {
            if (item.id) handleChangeStatusClick(item.id, item.status)
          }}
        >
          <Tooltip
            title={item.status === 'ACTIVE' ? t('actions.suspend') : t('actions.activate')}
            arrow
          >
            {item.status === 'ACTIVE' ? <CloseIcon /> : <BackspaceRoundedIcon />}
          </Tooltip>
        </Action>
      ),
    },

    {
      hide: !permissions[`delete${field}`],
      render: (item: IUser) => (
        <Action onClick={(): void => handleDeleteClick(item)} className='user_delete_icon'>
          <Tooltip title={t('actions.delete')} arrow>
            <DeleteIcon />
          </Tooltip>
        </Action>
      ),
    },
    {
      hide: !permissions[`delete${field}`],
      render: (item: IUser) => (
        <Action
          onClick={(): void => {
            if (item.id) handleActivityClick(item.id)
          }}
        >
          <TimeLineIcon />
          <span>{t('actions.activity')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions[`resendUserPassword${field}`],
      render: (item: IUser) => (
        <Action
          onClick={(): void => {
            if (item.id) handleResendPasswordClick(item.id)
          }}
        >
          <SendIcon />
          <span>{t('actions.resend_password')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions.resetPassword,
      render: (item: IUser) => (
        <Action
          onClick={(): void => {
            if (setUserId) setUserId(item.id)
            if (setResetPasswordOpen) {
              setResetPasswordOpen(true)
            }
          }}
        >
          <LockIcon />
          <span>{t('actions.reset_password')}</span>
        </Action>
      ),
    },
  ]
  return action.filter(i => !i.hide)
}
