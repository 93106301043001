import styled from 'styled-components'

export const StyledWrapper = styled.div``

export const StyledToolbarWrapper = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledFilesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media (max-width: 1800px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 1350px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const StyledFileItem = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d6dae0;
  padding: 16px;
  gap: 8px;
`

export const StyledSpan = styled.span`
  cursor: pointer;
`
