import { useState, useEffect, useRef } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useFormik } from 'formik'
import { useUserValue } from 'context/UserContext'
import { GET_COMPANIES } from 'gql/companies.query'
import { GET_GROUP } from 'gql/group/group.query'
import { LowerCaseFilterableFieldType, UpperCaseFilterableFieldType } from 'enums/filterEnum'
import {
  ICompanyOption,
  IFilter,
  IFormValues,
  IUseFilterAdminProps,
  IUseFilterAdminsReturnType,
} from 'pages/admins/FilterAdmins/filterAdmins.interface'
import { useTranslation } from 'react-i18next'
import { adminsFilterOptions, IAdminsFilterOptions } from 'components/UsersLayout/staticData'
import { FilterUserSchema } from 'helpers/validationSchemas'
import { useFilterContext } from 'context/FilterContext'
import { useFilterRoleContext } from 'context/FilterRoleContext'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'

export const useFilterAdmins = ({
  setFilterOptions,
  filterOptions,
  manualCompanyId,
  filter,
}: IUseFilterAdminProps): IUseFilterAdminsReturnType => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [error, setError] = useState(false)
  const ref = useRef<NodeJS.Timeout | undefined>()
  const { createDate, setCreateDate } = useFilterContext()
  const { filterRole, setFilterRole } = useFilterRoleContext()

  setFilterRole('ADMIN')
  const [fetchCompany, { data: companyData, loading: companyLoading }] = useLazyQuery(GET_COMPANIES)
  const [fetchGroup, { data: groupData, loading: groupLoading }] = useLazyQuery(GET_GROUP)

  const { handleSubmit, handleChange, values, errors, touched, setValues, setFieldValue } =
    useFormik({
      initialValues: adminsFilterOptions,
      validationSchema: FilterUserSchema,
      onSubmit(values) {
        const formValues: IFormValues = {}
        if (values.firstName) {
          formValues.firstName = {
            type: LowerCaseFilterableFieldType.MATCH,
            value: values.firstName,
          }
        }

        if (values.lastName) {
          formValues.lastName = {
            type: LowerCaseFilterableFieldType.MATCH,
            value: values.lastName,
          }
        }

        if (values.note) {
          formValues.note = {
            type: LowerCaseFilterableFieldType.MATCH,
            value: values.note,
          }
        }

        if (values.location) {
          formValues.location = {
            type: LowerCaseFilterableFieldType.MATCH,
            value: values.location,
          }
        }
        if (values?.status?.value !== 'all') {
          formValues.status = {
            type: LowerCaseFilterableFieldType.EXACT,
            value: values?.status?.value,
          }
        }

        if (values.group) {
          formValues.group = {
            type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
            value: values.group.map((group: { value: string }) => group.value),
            nestedField: 'groupId',
          }
        }

        if (values.company) {
          const companyIds = values.company.map((i: { value: string | null }) =>
            i.value === '-1' ? (i.value = null) : i.value,
          )
          const query = { companyId: { $in: companyIds } }
          // formValues.companyId = {
          //   type: 'arrayIn',
          //   value: companyIds,
          // }
          formValues.query = {
            type: LowerCaseFilterableFieldType.QUERY,
            value: JSON.stringify(query),
          }
        }

        const isAllZero = values?.age?.every(item => item === 0)

        if (!isAllZero) {
          formValues.age = {
            type: LowerCaseFilterableFieldType.RANGE,
            value: `${values?.age?.[0]}-${values?.age?.[1]}`,
          }
        }

        if (values?.gender?.value !== 'all') {
          formValues.gender = {
            type: LowerCaseFilterableFieldType.EXACT,
            value: values?.gender?.value,
          }
        }

        //if user wants to clear the date, we should send undefined
        const safeCreateDate = createDate || [undefined, undefined]

        safeCreateDate[0] = safeCreateDate[0] && new Date(safeCreateDate[0])
        safeCreateDate[1] = safeCreateDate[1] && new Date(safeCreateDate[1])

        const isSameDate =
          safeCreateDate[0] === safeCreateDate[1] && safeCreateDate[0] !== undefined

        if (!safeCreateDate[1] && safeCreateDate[0] !== undefined) safeCreateDate[1] = new Date()

        const formattedData = isSameDate
          ? safeCreateDate[0]
          : `${safeCreateDate[0] ? safeCreateDate[0].toISOString() : ''}${'-'}${
              safeCreateDate[1] ? safeCreateDate[1].toISOString() : ''
            }`

        if (safeCreateDate[0] !== undefined || safeCreateDate[1] !== undefined) {
          formValues.createDate = {
            type: isSameDate
              ? LowerCaseFilterableFieldType.EXACT
              : LowerCaseFilterableFieldType.DATE_RANGE,
            value: formattedData as string,
          }
        }

        filter(formValues as IFilterQueryType)
        setFilterOptions(values as IFilterQueryType)
      },
    })

  //clear createDate value when we switch tabs
  useEffect(() => {
    if (createDate && filterRole != 'ADMIN') {
      setCreateDate([undefined, undefined])
    }
  }, [filterRole])

  useEffect(() => {
    if (filterOptions) {
      setValues(filterOptions as IAdminsFilterOptions)
    }
  }, [])

  const loadDataOptions = (e: string, action: string): NodeJS.Timeout =>
    setTimeout(() => {
      const value = e

      if (action === 'company') {
        fetchCompany({
          variables: {
            filter: {
              name: {
                type: UpperCaseFilterableFieldType.MATCH,
                value,
              },
            },
          },
        })
      }

      if (action === 'group') {
        const filter: IFilter = {
          name: {
            type: LowerCaseFilterableFieldType.MATCH,
            value,
          },
          ...((state.selectedCompany?.id || manualCompanyId) && {
            company: {
              type: LowerCaseFilterableFieldType.EXACT,
              value: manualCompanyId || (state.selectedCompany?.id as string),
            },
          }),
        }
        fetchGroup({
          variables: {
            filter,
            perPage: 0,
          },
        })
      }
    }, 200)

  const handleSelectChange = (e: string, action: string): void => {
    if (ref.current) {
      clearTimeout(ref.current)
    }
    if (e) {
      ref.current = loadDataOptions(e, action)
    }
  }
  const handleRangeChange = (range: number | number[]): void => {
    setFieldValue('age', range)
  }

  const [companyOptions, setCompanyOptions] = useState([] as ICompanyOption[])

  useEffect(() => {
    if (companyData) {
      setCompanyOptions(companyData.companies.data)
    }
  }, [companyData])

  const ageValue =
    Array.isArray(values.age) && values.age.every(item => typeof item === 'number')
      ? values.age
      : undefined

  return {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    handleSelectChange,
    handleRangeChange,
    companyOptions,
    error,
    t,
    setError,
    isCompanySelected: Boolean(state?.selectedCompany?.id),
    companyLoading,
    groupLoading,
    groupData,
    ageValue,
    setCreateDate,
    createDate,
  }
}
