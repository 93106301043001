import styled from 'styled-components'
import { styled as MuiStyled } from '@mui/material/styles'

//  AddCourseDrawer

export const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
`

// AssignCoach

export const StyledItemContainer = styled.ul`
  margin-top: 20px;
  padding: 0;
`
export const StyledItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 70px;
  margin-top: 20px;
`

export const StyledTitle = styled.div``

export const ItemContainer = styled.div`
  width: 210px;
`

// groupGridConfig

export const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
`

// Step1

export const StyledFlex = styled.div`
  width: 100%;
  display: 'flex';
`

export const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledFromGroupContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
`

export const CourseDetailsContainer = styled.div`
  flex: 1;
  box-sizing: border-box;
`

export const ImageCropperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .actions {
    /* width: 500px; */
    /* background: #fff; */
    display: flex;
    gap: 10px;
    margin-top: 5px;
  }
`

export const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledTextContainer = styled.div``

export const InputFieldContainer = styled.div`
  display: flex;
`

export const FieldItemContainer = styled.div<{ width: string }>`
  margin-left: 5px;
  margin-right: 5px;
  width: ${({ width }): string => width};
`

// Step2

export const StepContainer = styled.div`
  padding: 15px;
`

export const FormGroupWrapperStyles = { padding: 10, height: '100%' }

export const PREFIX = 'AddCourseTemplateInnerComponent'

export const classes = {
  modal: `${PREFIX}-modal`,
  fab: `${PREFIX}-fab`,
}

export const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
export const Root = MuiStyled('div')(({ theme }) => ({
  height: '100%',
  [`& .${classes.fab}`]: {
    margin: theme.spacing(2),
  },
}))

export const loaderStyles = {
  margin: '0 auto',
  display: 'block',
}

// AddGroupsToCourses drawer
export const Wrapper = styled.div<{ extraMargin?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ extraMargin }): string => (extraMargin ? '20px' : '0')};
`

export const GroupItemContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`
