import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useState } from 'react'
import deleteTestService, { IDeleteTestsResponse } from 'services/tests/deleteTestService'

const useDeleteTestService = (): {
  deleteTest: (ids: string[]) => Promise<void>
  loading: boolean
  error: string
  data: IDeleteTestsResponse | null
} => {
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { deleteTestMutation } = deleteTestService()
  const [data, setData] = useState<IDeleteTestsResponse | null>(null)

  const deleteTest = async (ids: string[]): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { data, errors } = await deleteTestMutation({
      variables: {
        ids,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
      setError(message)
    } else if (data) {
      message = 'Test Deleted successfully'
      variant = 'success'
      setData(data.deleteTestAssessments)
    }

    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return { deleteTest, loading, error, data }
}

export default useDeleteTestService
