import React, { ChangeEvent, useState } from 'react'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'
import { columnConfig } from '../../../pages/group/ImportGroups/gridConfig'
import DeleteIcon from '@mui/icons-material/Delete'
import { ImportStudentsInGroupsProps } from 'pages/group/ImportStudentsInGroups/importStudentsInGroups.interface'
import useExtractGroupsWithCourses from './useExtractGroupsServices'
import useImportGroupsWithCourses from './useImportGroupsServices'
import { IGroup, IInsertGroupsWithCoursesInput } from 'interfaces/groups'
import { IExtractGroupsWithCoursesMutationResponse } from 'services/group/uploadGroupsWithCoursesService'
import removeTypeNameFromObject from 'utils/removeTypeNameFromObject'
import { TFunction } from 'interfaces/TFunction'
import { IImportGroupsGridItem } from 'pages/group/ImportGroups/importGroups.interface'

interface IToolbarAction {
  id: number
  color: string
  tooltipText: string
  disabled: boolean
  onClick: () => void
  component: React.JSX.Element
}

const useImportGroupsServices = ({
  refetch,
  onClose,
}: ImportStudentsInGroupsProps): {
  t: TFunction
  handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void
  selectedGroups: string[]
  toolbarAction: IToolbarAction[]
  imported: boolean
  groupList: IInsertGroupsWithCoursesInput[] | IGroup[] | IExtractGroupsWithCoursesMutationResponse
  atLeastOneSelectedError: boolean
  config: IImportGroupsGridItem[]
  groupsImportLoading: boolean
  selectAllItem: () => void
  handleFormSubmit: () => void
} => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [selectedGroups, setSelectedGroups] = useState<string[]>([])
  const [importedGroups, setImportedGroups] = useState<
    IInsertGroupsWithCoursesInput[] | IGroup[] | IExtractGroupsWithCoursesMutationResponse
  >([])
  const [imported, setImported] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [atLeastOneSelectedError, setAtLeastOneSelectedError] = useState(false)

  const { uploadFileStream } = useExtractGroupsWithCourses()
  const { importGroupsWithCourses, loading: groupsImportLoading } = useImportGroupsWithCourses()

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files) {
      const files = Array.from(e.target.files)
      handleUploadFile(files[0])
    }
  }

  const handleUploadFile = (file: File): void => {
    if (file) {
      uploadFileStream(file, (data: IExtractGroupsWithCoursesMutationResponse) => {
        setImportedGroups(data)
        setImported(false)
      })
    }
  }

  const addGroups = (selectedGroups: string[], groups: IInsertGroupsWithCoursesInput[]): void => {
    if (selectedGroups.length <= 0) return
    const data = groups.filter(item => selectedGroups.findIndex(val => item.id === val) !== -1)

    const groupsData = data.map(group => removeTypeNameFromObject(group))

    importGroupsWithCourses(groupsData, state.selectedCompany?.id as string, data => {
      if (data) {
        setImported(true)
        setImportedGroups(data.filter(group => group?.error))
        setSelectedGroups([])
        setSelectAll(false)
        if (refetch) refetch()
        if (!data.find(item => item.error)) {
          onClose()
        }
      }
    })
  }

  const handleFormSubmit = (): void => {
    if (!imported) {
      addGroups(selectedGroups, importedGroups as IInsertGroupsWithCoursesInput[])
    }

    if (
      selectedGroups.find((groupId: string) =>
        (importedGroups as IGroup[]).find(group => group.id === groupId && group.error),
      )
    ) {
      return
    }

    selectedGroups.length <= 0
      ? setAtLeastOneSelectedError(true)
      : setAtLeastOneSelectedError(false)
  }

  const handleDeleteGroups = (): void => {
    if (selectedGroups.length > 0) {
      const data = (importedGroups as IGroup[]).filter(
        importedGroup =>
          selectedGroups.findIndex(selectedGroup => importedGroup.id === selectedGroup) === -1,
      )
      setImportedGroups(data)
      setSelectedGroups([])
    }
  }

  const selectItem = (id: string): void => {
    const selectedIndex = selectedGroups.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedGroups, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedGroups.slice(1))
    } else if (selectedIndex === selectedGroups.length - 1) {
      newSelected = newSelected.concat(selectedGroups.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedGroups.slice(0, selectedIndex),
        selectedGroups.slice(selectedIndex + 1),
      )
    }

    setSelectedGroups(newSelected)
  }

  const groupList = importedGroups || []

  const selectAllItem = (): void => {
    if (!selectAll) {
      const newArr: string[] = (groupList as IGroup[]).map(student => student.id as string)
      setSelectedGroups(newArr)
      setSelectAll(true)
      return
    }
    setSelectedGroups([])
    setSelectAll(false)
  }

  const toolbarAction: IToolbarAction[] = [
    {
      id: 0,
      color: 'secondary',
      tooltipText: 'Delete',
      disabled: !(selectedGroups.length > 0),
      onClick: (): void => handleDeleteGroups(),
      component: <DeleteIcon fontSize='small' />,
    },
  ]

  const config = columnConfig(selectItem, imported, t)
  return {
    t,
    handleFileChange,
    selectedGroups,
    toolbarAction,
    imported,
    groupList,
    atLeastOneSelectedError,
    config,
    groupsImportLoading,
    selectAllItem,
    handleFormSubmit,
  }
}

export default useImportGroupsServices
