import IconButton from '@mui/material/IconButton'
import styled from 'styled-components'

export const Container = styled.div``

export const ActionsContent = styled.div`
  padding: 5px;
`

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  &:hover {
    background-color: transparent !important;
  }
`
