import styled from 'styled-components'

// columnConfig

export const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
  .MuiTypography-body1 {
    font-size: 0.875rem;
  }
`

export const GroupsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const GroupItem = styled.div`
  position: relative;
  &:hover > span {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
`

export const GroupTitle = styled.span`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  z-index: 2;
  text-align: center;
  padding: 5px 8px;
  bottom: -35px;
  left: 50%;
  color: #333;
  font-size: 12px;
  transform: translateX(-50%) scale(0);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0.5px 12px rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s ease, transform 0.2s ease;
`

// CompanyGroup

export const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 0px 50px;
`
export const TextContainer = styled.div`
  box-sizing: border-box;
  padding: 20px;
`

// CreateGroup - EditGroup

export const Container = styled.div`
  box-sizing: border-box;
  padding: 0px 30px;
`
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
export const ButtonItem = styled.div``

// GroupList

export const ToolbarItemWrapper = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
  width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`
export const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
`
