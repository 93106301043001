import styled from 'styled-components'

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const FormHeader = styled.header`
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const FormTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
`

export const FormFields = styled.div`
  margin: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  & > div {
    width: 48%;

    div {
      width: 100%;
    }
  }
`

export const FormGroupItemLabel = styled.label`
  display: inline-block;
  margin-bottom: 5px;
`

export const FormButtons = styled.div`
  padding: 0 20px;
  align-self: flex-end;
  display: flex;

  div:not(:last-child) {
    margin-right: 10px;
  }
`
