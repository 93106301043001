import { useState } from 'react'
import deleteStudentsService from 'services/group/deleteStudentsService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

export interface IUseDeleteStudentsFromGroupReturnType {
  loading: boolean
  deleteStudentsFromGroup: (groupId: string, ids: string[], refetch: () => void) => Promise<void>
}

const useDeleteStudentsFromGroup = (groupId: string): IUseDeleteStudentsFromGroupReturnType => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [loading, setLoading] = useState(false)
  const { deleteStudents } = deleteStudentsService(groupId)

  const deleteStudentsFromGroup = async (
    groupId: string,
    ids: string[],
    refetch: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { errors } = await deleteStudents({
      variables: { groupId, ids },
    })

    if (errors && errors.length) {
      setLoading(false)
      message = t('messages.something_wrong')
      variant = 'error'
      return
    }
    message = `${ids.length > 1 ? t('general.students') : t('general.student')} ${t(
      'messages.successfully',
    )} ${t('general.deleted')}`
    variant = 'success'

    setSnackbar({ message, variant })
    setLoading(false)
    refetch()
  }

  return {
    loading,
    deleteStudentsFromGroup,
  }
}

export default useDeleteStudentsFromGroup
