import React from 'react'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import './CustomButton.scss'

interface ICustomButtonProps {
  dataTestId?: string
  className?: string
  text: string
  type?: string
  btnType?: 'button' | 'submit' | 'reset' | undefined
  color?: 'inherit' | 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | undefined
  icon?: React.ReactElement | ''
  iconPosition?: string
  size?: 'small' | 'medium' | 'large' | undefined
  loading?: boolean
  isDisabled?: boolean
  onClick?: () => void
  width?: number | string
  height?: number
  component?: React.ReactNode
  background?: string
  textColor?: string
  outline?: string
  disableRipple?: boolean
  disableElevation?: boolean
}

const CustomButton = ({
  dataTestId,
  className,
  text,
  type = 'default',
  color = 'primary',
  btnType = 'button',
  icon = '',
  iconPosition = 'left',
  size = 'medium',
  loading = false,
  isDisabled = false,
  onClick = (): void => {
    return
  },
  width,
  height,
  component,
  background,
  textColor,
  outline,
  disableRipple = false,
  disableElevation = false,
}: ICustomButtonProps): React.JSX.Element => {
  return (
    <div className={`btn-wrapper btn--${type} ${className || ''}`}>
      <Button
        data-testid={dataTestId}
        onClick={(): void => onClick()}
        startIcon={iconPosition === 'left' ? icon : ''}
        endIcon={iconPosition === 'right' ? icon : ''}
        color={color}
        size={size}
        type={btnType}
        disabled={isDisabled ? true : false}
        style={{
          background,
          color: textColor,
          border: outline,
          width,
          height,
          whiteSpace: 'nowrap',
        }}
        disableRipple={disableRipple}
        disableElevation={disableElevation}
      >
        {component}
        {text}
        {loading && (
          <LoaderContent>
            <CircularProgress
              style={{
                width: '16px',
                height: '16px',
                marginLeft: '10px',
                alignSelf: 'center',
                justifySelf: 'center',
              }}
            />
          </LoaderContent>
        )}
      </Button>
    </div>
  )
}

export default CustomButton

const LoaderContent = styled.div`
  & > div {
    width: 12px !important;
    height: 12px !important;
    margin-left: 10px;
    color: #fff;
  }
`
