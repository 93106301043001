import React, { ReactElement } from 'react'
import { styled } from '@mui/material/styles'
import { Pagination as MUIPagination, SelectChangeEvent } from '@mui/material'
import { Select } from '@mui/material'

const PREFIX = 'Pagination'

const classes = {
  root: `${PREFIX}-root`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}))

interface IPaginationProps {
  currentPage: number
  totalPages: number
  perPage?: number
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void
  handlePerPageChange?: (event: SelectChangeEvent<number>, child: React.ReactNode) => void
}

const Pagination = ({
  currentPage,
  totalPages,
  handleChange,
  perPage,
  handlePerPageChange,
}: IPaginationProps): ReactElement => {
  return (
    <Root className={classes.root}>
      {perPage && (
        <Select
          variant='standard'
          native
          value={perPage}
          onChange={handlePerPageChange}
          inputProps={{
            name: 'perPage',
            id: 'perPage',
          }}
        >
          <option value={12}>12</option>
          <option value={24}>24</option>
          <option value={48}>48</option>
        </Select>
      )}
      <MUIPagination
        count={totalPages}
        shape='rounded'
        page={currentPage}
        onChange={handleChange}
      />
    </Root>
  )
}

export default Pagination
