// @deprecated
/* eslint-disable */

import React, { ReactElement } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import strCutter from 'utils/strCutter'
import Checkbox from 'components/common/Checkbox'
import IconButton from '@mui/material/IconButton'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { NoValueWrapper } from 'components/common/Grid/styled-components'
import { GroupsWrapper } from 'components/common/DetailsLayout'
import {
  CheckBoxAndAvatarContainer,
  GroupItem,
  GroupTitle,
} from '../CompanyStudents/styled-components'
import AvatarIcon from 'assets/profile.svg'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'
import { TFunction } from 'interfaces/TFunction'

interface itemProps {
  id: string
  isChecked: boolean
  firstName: string
  lastName: string
  email: string
  status: GridStatusEnums
  phone: string
  phoneFields: {
    dialCode: string
    code: string
  }
  gender: string
  birthDate: string
  age: string
  biography: string
  note: string
  group: any
  isQualified: boolean
  avatar: string
  avatarThumbnail: string
  createDate: any
  companyId: any
}

interface GridColumnConfig {
  id: string
  numeric: boolean
  disablePadding: boolean
  label?: string
  render: (item: itemProps) => JSX.Element
}

export const columnConfig = (
  t: TFunction,
  selectItem: any,
  checkbox?: boolean,
): GridColumnConfig[] => {
  const grid: GridColumnConfig[] = [
    {
      id: 'isChecked',
      render: (item: itemProps) => (
        <CheckBoxAndAvatarContainer>
          <div style={{ visibility: !checkbox ? 'hidden' : 'visible' }}>
            <Checkbox checked={item.isChecked} onChange={(): void => selectItem(item.id)} />
          </div>
          <img src={item.avatarThumbnail || item.avatar || AvatarIcon} />
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'firstName',
      numeric: false,
      disablePadding: true,
      label: t('user_details.first_name'),
      render: (item: itemProps) => <div style={{ cursor: 'pointer' }}>{item.firstName}</div>,
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: false,
      label: t('user_details.last_name'),
      render: (item: itemProps) => <div>{item.lastName}</div>,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: t('user_details.email'),
      render: (item: itemProps) => <div>{item.email}</div>,
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: t('user_details.phone'),
      render: (item: itemProps): ReactElement => {
        return (
          <div>
            {item.phoneFields && item.phoneFields.code && `(${item.phoneFields.code})`}
            {item.phone}
          </div>
        )
      },
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: t('status'),
      render: (item: itemProps) => <StatusIndicator status={item.status} />,
    },
    {
      id: 'group',
      numeric: false,
      disablePadding: false,
      label: t('general.groups'),
      render: (item: itemProps): ReactElement => {
        const groups = item.group || []
        const firstTwoGroups = groups.slice(0, 2)
        const exGroups = groups.slice(2, groups.length)
        return (
          <div>
            {firstTwoGroups.length === 0 && <NoValueWrapper>N/A</NoValueWrapper>}
            <GroupsWrapper>
              {firstTwoGroups.map(
                (group: any, index: number) =>
                  index < 3 && (
                    <GroupItem key={group.groupId}>
                      <IconButton>
                        <span className='material-icons'>groups</span>
                      </IconButton>
                      <GroupTitle className='group-item-title'>{group.name}</GroupTitle>
                    </GroupItem>
                  ),
              )}

              {exGroups.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {exGroups.map((exGroup: any) => (
                        <div key={exGroup.groupId}>{exGroup.name}</div>
                      ))}
                    </>
                  }
                >
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                  >
                    ...
                  </span>
                </Tooltip>
              )}
            </GroupsWrapper>
          </div>
        )
      },
    },
    {
      id: 'companyId',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.company')}`,
      render: (item: itemProps) => (
        <div>{item.companyId ? strCutter(item.companyId.name, 25) : <>N/A</>}</div>
      ),
    },
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: `${t('general.create_date')}`,
      render: (item: itemProps) => <>{moment(parseInt(item.createDate)).format('DD-MM-YYYY')}</>,
    },
  ]

  return grid
}

export const actionConfig = (handleClick: any, t: TFunction): any => {
  const actions: any = [
    {
      render: (item: any) => (
        <Action onClick={(): void => handleClick('edit', item)}>
          <EditRoundedIcon />
          <Typography>{t('actions.edit')}</Typography>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action onClick={(): void => handleClick('delete', item.id)}>
          <DeleteForeverRoundedIcon />
          <Typography>{t('actions.delete')}</Typography>
        </Action>
      ),
    },
  ]
  return actions
}

const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
`
