import React, { CSSProperties, memo, ReactElement, useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ActionsContainer as DefaultActionsContainer,
  GridTableHeader,
  GridTableTitle,
  GridTableWrapper,
  NoRecordsContainer,
  Root,
  StyledPaper,
  StyledTable,
  StyledTableCell,
  VisuallyHidden,
  StyledIconButton,
  rowStyles,
  StyledTd,
  StyledCollapse,
} from './styled-components'
import DndItem from './DndGrid/DndItem'

import _ from 'lodash'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Checkbox from 'components/common/Checkbox'
import GridActionMenu from './GridActionMenu/GridActionMenu'
import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import FilterListIcon from '@mui/icons-material/FilterList'
import { MultipleAction, MultipleActionTooltip } from './styled-components'
import MyLoader from 'loading/loading'
import { loaderStyles } from 'pages/courses/CourseQuizzes/styled.components'
import { GridItemType, IEnhancedTableV2Props, IGridError, IGridV2Props } from './grid.interface'
import { getComparator, stableSort, useGridServices } from './useGridServices'
import { ExpandMoreOutlined } from '@mui/icons-material'
import { IAction } from './GridActionMenu/gridActionMenu.interface'
import { IDragFunction } from './DndGrid/types'

const EnhancedTableHead = ({
  order,
  config,
  selectAllItem,
  actionMenu,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  disabledSelect,
  tableCellStyles,
  tableRowStyles,
  gridActionName,
}: IEnhancedTableV2Props): ReactElement => {
  const { t } = useTranslation()
  const createSortHandler = (property: string) => (event: MouseEvent) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow style={tableRowStyles}>
        {config.map(headCell => {
          const styles = tableCellStyles || {}

          if (headCell.id === 'isChecked') {
            return (
              <StyledTableCell
                $clickable
                padding='none'
                key={headCell.id}
                style={tableCellStyles}
                onClick={(): void => selectAllItem(!(rowCount === numSelected))}
              >
                <Root disabled={disabledSelect}>
                  <Checkbox
                    checked={!!(rowCount > 0 && numSelected && numSelected >= rowCount)}
                    disabled={rowCount === 0}
                    onChange={(): void => selectAllItem(!(rowCount === numSelected))}
                  />
                </Root>
              </StyledTableCell>
            )
          }
          if (headCell.width) {
            styles.width = headCell.width
          }
          return (
            <TableCell
              key={headCell.id}
              // align={headCell.numeric ? 'right' : 'left'}
              padding={'none'}
              style={styles}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id || '')}
                style={{ whiteSpace: 'nowrap' }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <VisuallyHidden>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </VisuallyHidden>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        })}

        {actionMenu ? (
          <TableCell component='th' scope='row' padding='none' align='center'>
            <div> {gridActionName ? gridActionName : t('grid.action')}</div>
          </TableCell>
        ) : null}
      </TableRow>
    </TableHead>
  )
}

const Grid = ({
  title,
  resetFilters,
  onFiltersClick,
  data,
  error,
  paging,
  selected,
  selectItem,
  selectedItems,
  selectAllItem,
  allSelected,
  disabledSelect,
  tableCellStyles,
  tableRowStyles,
  loading,
  actionConfig,
  actionTilesLength = 2,
  resetVisible,
  openInExternalTab,
  config,
  collapseConfig,
  getCollapsedData,
  nestedCollapseConfig,
  getNestedCollapsedData,
  width,
  redirectToDetailsHandler,
  indexOfSpecificWidth,
  isDraggable,
  moveCard,
  changeOrder,
  firstRowColor,
  isFirstRowBold,
  gridActionLabel,
  ActionsContainer = DefaultActionsContainer,
}: IGridV2Props): ReactElement => {
  const {
    t,
    order,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    orderBy,
    isSelected,
    handleSelectAllClick,
    handleRequestSort,
  } = useGridServices({
    data,
    selectedItems,
    openInExternalTab,
  })

  const renderRow = (itemOriginal: GridItemType, isFirstRow: boolean): ReactElement => {
    const isItemSelected = isSelected(itemOriginal.id as string)

    // duplicating the item to avoid changing the original item (original object is not extensible)
    const item = { ...itemOriginal }
    item.isChecked = isItemSelected as boolean

    const generateStyledTableCellStyles = (index: number): CSSProperties => {
      const customStyles: CSSProperties = {}

      if ((width && !indexOfSpecificWidth) || (width && indexOfSpecificWidth === index))
        customStyles.width = width

      if (isFirstRow && isFirstRowBold) {
        customStyles.fontWeight = 'bold'
        customStyles.border = '2px solid #e4e4e4'
        customStyles.boxShadow = '0 0 0 1.5px #e4e4e4'
      }

      return customStyles
    }

    return (
      <>
        {config.map((i, index: number) => (
          <StyledTableCell
            $clickable={!!redirectToDetailsHandler}
            component='th'
            scope='row'
            padding='none'
            key={i.id || index}
            style={generateStyledTableCellStyles(index)}
            onClick={(): void =>
              i.id === 'isChecked' && selectItem
                ? selectItem(item.id as string)
                : redirectToDetailsHandler && redirectToDetailsHandler(item as GridItemType)
            }
          >
            {i.render(item)}
          </StyledTableCell>
        ))}
        {actionConfig && actionConfig.length !== 0 && (
          <TableCell component='th' scope='row' padding='none' align='center'>
            <ActionsContainer>
              {actionConfig.slice(0, actionTilesLength).map((action: IAction, actionIndex) => (
                <React.Fragment key={actionIndex}>{action.render(itemOriginal)}</React.Fragment>
              ))}
              {actionConfig.length > actionTilesLength && (
                <GridActionMenu
                  actionConfig={actionConfig.slice(actionTilesLength) as IAction[]}
                  row={itemOriginal}
                />
              )}
            </ActionsContainer>
          </TableCell>
        )}
      </>
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderBody = (data: any, error: IGridError): ReactElement => {
    const cardIds = data.map((card: { id: string }) => card.id)
    return isDraggable ? (
      <>
        {data.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (row: any, index: number) => {
            const styles: CSSProperties = { ...rowStyles }
            const isFirstRow = index === 0
            if (isFirstRow && firstRowColor) styles.background = firstRowColor
            if (error) {
              styles.background = row[error.error as string] ? error.errorColor : error.successColor
            }
            return (
              <DndItem
                id={row.id}
                key={row.id}
                index={index}
                moveCard={moveCard as IDragFunction}
                onEnd={changeOrder}
                cardIds={cardIds}
                isTable
                isNested
                className={`row ${
                  !row.error && (disabledSelect || selectedItems?.includes(row.id))
                    ? 'Mui-selected'
                    : row.error
                    ? 'Mui-selected-error'
                    : ''
                }`}
              >
                {renderRow(row, isFirstRow)}
              </DndItem>
            )
          },
        )}
      </>
    ) : (
      <>
        {stableSort(data, getComparator(order, orderBy)).map((row, index) => {
          const styles: CSSProperties = { ...rowStyles }
          const isFirstRow = index === 0
          if (isFirstRow && firstRowColor) styles.background = firstRowColor
          if (error) {
            styles.background = row[error.error as string] ? error.errorColor : error.successColor
          }

          if (collapseConfig && getCollapsedData) {
            const [collapsed, setCollapsed] = useState(false)
            return (
              <>
                <TableRow
                  role='checkbox'
                  tabIndex={-1}
                  key={row.id}
                  style={styles}
                  className={`row ${
                    !row.error && (disabledSelect || selectedItems?.includes(row.id as string))
                      ? 'Mui-selected'
                      : row.error
                      ? 'Mui-selected-error'
                      : ''
                  }`}
                >
                  {renderRow(row, isFirstRow)}
                  <StyledIconButton onClick={(): void => setCollapsed(prev => !prev)}>
                    <ExpandMoreOutlined
                      style={{
                        transform: `rotate(${collapsed ? '180deg' : '0deg'})`,
                        transition: 'transform 0.3s ease',
                      }}
                    />
                  </StyledIconButton>
                </TableRow>
                <StyledTd colSpan={config.length} collapsed={collapsed}>
                  <StyledCollapse in={collapsed} timeout={'auto'} unmountOnExit>
                    <Grid
                      data={getCollapsedData(row)}
                      config={collapseConfig}
                      collapseConfig={nestedCollapseConfig}
                      getCollapsedData={getNestedCollapsedData}
                    />
                  </StyledCollapse>
                </StyledTd>
              </>
            )
          } else {
            return (
              <TableRow
                role='checkbox'
                tabIndex={-1}
                key={row.id}
                style={styles}
                className={`row ${
                  !row.error && (disabledSelect || selectedItems?.includes(row.id as string))
                    ? 'Mui-selected'
                    : row.error
                    ? 'Mui-selected-error'
                    : ''
                }`}
              >
                {renderRow(row, isFirstRow)}
              </TableRow>
            )
          }
        })}
      </>
    )
  }

  return (
    <Root>
      <StyledPaper>
        <GridTableWrapper>
          <TableContainer>
            <GridTableHeader>
              {title && <GridTableTitle>{title}</GridTableTitle>}
              {resetFilters && onFiltersClick && (
                <div style={{ display: 'flex' }}>
                  <MultipleAction>
                    <IconButton onClick={resetFilters} disabled={!resetVisible}>
                      <SyncIcon fontSize='small' />
                    </IconButton>
                    <MultipleActionTooltip>{t('actions.reset')}</MultipleActionTooltip>
                  </MultipleAction>
                  <MultipleAction>
                    <IconButton onClick={onFiltersClick}>
                      <FilterListIcon fontSize='small' />
                    </IconButton>
                    <MultipleActionTooltip>{t('actions.filters')}</MultipleActionTooltip>
                  </MultipleAction>
                </div>
              )}
            </GridTableHeader>

            <StyledTable aria-labelledby='tableTitle' size={'medium'} aria-label='enhanced table'>
              <EnhancedTableHead
                numSelected={selected}
                order={order}
                orderBy={orderBy as string}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data?.length}
                config={config}
                selectAllItem={selectAllItem || ((): void => undefined)}
                actionMenu={!(!actionConfig || actionConfig.length <= 0)}
                allSelected={allSelected}
                disabledSelect={disabledSelect}
                tableCellStyles={tableCellStyles}
                tableRowStyles={tableRowStyles}
                gridActionName={gridActionLabel}
              />
              <TableBody>
                {loading && <MyLoader width={250} speed={0.8} style={loaderStyles} />}
                {renderBody(data, error as IGridError)}
              </TableBody>
            </StyledTable>
            {!data.length && !loading && (
              <NoRecordsContainer>{t('general.no_records')}</NoRecordsContainer>
            )}
          </TableContainer>
        </GridTableWrapper>
        {data.length > 10 && paging ? (
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </StyledPaper>
    </Root>
  )
}

export default memo(Grid, (prev, next) => {
  if (!_.isEqual(prev.data, next.data)) return false
  if (prev.selectAllItem !== next.selectAllItem) return false
  if (prev.selected !== next.selected) return false

  return true
})
