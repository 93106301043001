import {
  ApolloError,
  ApolloQueryResult,
  FetchMoreOptions,
  FetchMoreQueryOptions,
  useQuery,
} from '@apollo/client'
import { RefetchFunction } from '@apollo/client/react/hooks/useSuspenseQuery'

import { GET_REJECTION } from 'gql/rejections/rejections.query'
import { IRejection } from 'interfaces/rejections'
import { setRejectionStateService } from 'services/rejections/setRejectionStateService'

interface IUseFetchRejectionServiceResponse {
  rejection?: IRejection
  loading: boolean
  error: string
  refetch: RefetchFunction<IGetRejectionResponse, IGetRejectionParams>
  fetchMore: (
    fetchMoreOptions: FetchMoreQueryOptions<IGetRejectionParams, IGetRejectionResponse> &
      FetchMoreOptions<IGetRejectionResponse>,
  ) => Promise<ApolloQueryResult<IGetRejectionResponse>>
}

interface IGetRejectionResponse {
  getRejection: IRejection
}

interface IGetRejectionParams {
  id: string
}

export const useFetchRejection = (id: string): IUseFetchRejectionServiceResponse => {
  const { data, loading, error, refetch, fetchMore } = useQuery<
    IGetRejectionResponse,
    IGetRejectionParams
  >(GET_REJECTION, {
    variables: { id },
  })

  const errorMessage = error ? error?.message.split(':')[1] : ''
  return {
    rejection: data && data.getRejection,
    loading,
    error: errorMessage,
    refetch,
    fetchMore,
  }
}

export const useSetRejectionState = (): {
  setRejectionState: (id: string, action: string, onSuccess: () => void) => void
  error?: ApolloError
  loading: boolean
} => {
  const { setRejectionStateMutation, error, loading } = setRejectionStateService()

  const setRejectionState = async (
    id: string,
    action: string,
    onSuccess: () => void,
  ): Promise<void> => {
    const { data, errors } = await setRejectionStateMutation({
      variables: { id, action },
    })

    if (errors && errors.length) {
    } else if (data) {
      if (data.setRejectionState) {
        onSuccess()
      }
    }
  }

  return {
    setRejectionState,
    error,
    loading,
  }
}
