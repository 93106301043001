import styled from 'styled-components'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Fab from '@mui/material/Fab'

// Courses

export const Wrapper = styled.div`
  width: 100%;
  .addCourse {
    width: 150px;
  }
  .coursetable {
    margin-top: 15px;
  }
`
// Dashboard

export const DashboardWrapper = styled.div`
  display: flex;
`

export const StyledCard = styled(Card)`
  max-width: 300px;
  margin-right: 35px;
`
export const StyledFab = styled(Fab)`
  margin-left: 40px;
`

export const TextWrapper = styled.div``
export const StyledCardContent = styled(CardContent)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-bottom: 0 !important;
  background: rgba(0, 0, 0, 0.04);
  padding: 15px !important;
`

export const HeaderText = styled.span`
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
`

export const NumberText = styled.span`
  font-size: 20px;
  font-weight: 500;
  display: block;
`

// Moderators

export const GridContent = styled.div`
  // padding-top: 50px;
`

export const ModeratorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  margin: 20px 0;
`

export const GridLayoutActions = styled.div`
  display: flex;
  align-items: center;

  & > div {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`

export const GridLayoutForm = styled.form`
  display: flex;
  align-items: center;

  & > div {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
`

//  Settings

export const StyledH1 = styled.h1`
  margin-bottom: 10px;
`

export const ColumnContainer = styled.div<{ companyEdit?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;

  & > label {
    gap: 40px;

    & > .MuiTypography-root {
      min-width: 300px;
    }
  }

  & > label {
    justify-content: space-between !important;
  }
`
export const Container = styled.div`
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #06c68f;
  }
`
