import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import {
  Container,
  Header,
  Row,
  StyledCollapseContainer,
  StyledParentContainer,
} from './styled-components'
import update from 'immutability-helper'

import List from '@mui/material/List'
import Collapse from '@mui/material/Collapse'

import ListItem from '@mui/material/ListItem'
import Loader from 'components/common/Loader'
import { DefaultText } from 'components/common/TextEditor/styled-components'
import { useNavigate } from 'react-router-dom'
import DndItemV2 from 'components/common/Grid/DndGridV2/DndItemV2'
import { LessonActionsContainer } from 'components/common/GridV2/styled-components'
import { ICollapseItem, IColumnItem, IConfigItem, INestedGridProps } from './ModuleTopic.interface'
import { useChangeLessonsOrder } from 'hooks/topics/useEditTopic'
import { IAction } from 'components/common/GridV2/GridActionMenu/gridActionMenu.interface'
import { ITopic } from 'pages/modules/ModulesList.interface'

const PREFIX = 'NestedGrid'

const classes = {
  root: `${PREFIX}-root`,
  collapse: `${PREFIX}-collapse`,
  nested: `${PREFIX}-nested`,
  isVideo: `${PREFIX}-isVideo`,
  isCheckbox: `${PREFIX}-isCheckbox`,
}

const StyledContainer = styled(Container)(() => ({
  backgroundColor: '#f5f6fa',

  [`& .${classes.root}`]: {
    width: '100%',
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: '#f5f6fa',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },

  [`& .${classes.collapse}`]: {
    background: '#fff',
  },

  [`& .${classes.nested}`]: {
    borderTop: '1px solid #ccc',
    width: '100%',
    height: '100%',
  },

  [`& .${classes.isVideo}`]: {
    width: 'unset',
    padding: 0,
    display: 'flex',
  },
}))

const CollapseComponent = ({
  classes,
  item,
  nestedAction,
  nestedConfig,
  routerState,
  canModify,
  selectedItems,
  topicId,
}: ICollapseItem): ReactElement => {
  const navigate = useNavigate()

  const { changeLessonsOrder } = useChangeLessonsOrder({
    topicId,
    filter: {},
  })

  const isSelected = (id: string): boolean => selectedItems.includes(id)

  const itemWithChecked = item.map(lesson => ({
    ...lesson,
    isChecked: isSelected(lesson.id),
  }))
  const [cards, setCards] = useState<IColumnItem[]>(itemWithChecked)
  const moveCard = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex]

      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [cards],
  )

  useEffect(() => {
    const updatedCards = item.map(lesson => ({
      ...lesson,
      isChecked: isSelected(lesson.id),
    }))
    setCards(updatedCards)
  }, [selectedItems, item])

  if (item.length <= 0) {
    return (
      <List className={classes.nested}>
        <ListItem>
          <div>No Lessons</div>
        </ListItem>
      </List>
    )
  }

  return (
    <div style={{ height: '100%' }}>
      <List>
        <StyledParentContainer>
          {(canModify &&
            cards.map((lesson: IColumnItem, index: number) => (
              <DndItemV2
                key={lesson.id}
                id={lesson.id}
                index={index}
                moveCard={moveCard}
                onEnd={changeLessonsOrder}
                cardIds={itemWithChecked.map((lesson: IColumnItem) => lesson.id)}
                type='lesson'
              >
                <StyledCollapseContainer className={classes.nested}>
                  {nestedConfig.map((nested: IConfigItem) => (
                    <ListItem
                      key={nested.id}
                      onClick={(): void =>
                        navigate(`/lessons/${lesson.id}`, {
                          state: routerState,
                        })
                      }
                      className={
                        nested.id === 'video' || nested.id === 'checkbox' ? classes.isVideo : ''
                      }
                    >
                      {nested.render(lesson, index)}
                    </ListItem>
                  ))}

                  <LessonActionsContainer>
                    {nestedAction.map((action: IConfigItem) => action.render(lesson))}
                  </LessonActionsContainer>
                </StyledCollapseContainer>
              </DndItemV2>
            ))) ||
            cards.map((lesson: IColumnItem, index: number) => (
              <StyledCollapseContainer className={classes.nested}>
                {nestedConfig.map((nested: IConfigItem) => (
                  <ListItem
                    key={nested.id}
                    onClick={(): void =>
                      navigate(`/lessons/${lesson.id}`, {
                        state: routerState,
                      })
                    }
                    className={
                      nested.id === 'video' || nested.id === 'checkbox' ? classes.isVideo : ''
                    }
                  >
                    {nested.render(lesson, index)}
                  </ListItem>
                ))}

                <LessonActionsContainer>
                  {nestedAction.map((action: IConfigItem) => action.render(lesson))}
                </LessonActionsContainer>
              </StyledCollapseContainer>
            ))}
        </StyledParentContainer>
      </List>
    </div>
  )
}

const NestedGridV2 = ({
  title,
  config,
  data,
  actions,
  nestedConfig,
  nestedAction,
  selectedItems = [],
  loading,
  changeTopicsOrder,
  moveCard,
  canModify,
  t,
  noRecordsDefaultText,
  routerState,
  onCollapseClick,
  setCollapsedItems,
  collapsedItems,
}: INestedGridProps): ReactElement => {
  useEffect(() => {
    if (data) {
      setCollapsedItems(data.map(item => item.id as string) as string[])
    }
  }, [data])

  const renderBody = (itemOriginal: ITopic, index: number): ReactElement => {
    const item = { ...itemOriginal }

    return canModify ? (
      <List>
        <DndItemV2
          key={item.id as string}
          id={item.id as string}
          index={index}
          moveCard={moveCard}
          cardIds={data.map(card => card.id) as string[]}
          onEnd={changeTopicsOrder}
          isNested
          type='topic'
        >
          <Row
            onClick={(): void => onCollapseClick(item.id as string)}
            style={{
              cursor: 'pointer',
            }}
          >
            {config.map(column => {
              return (
                <ListItem key={column.id} className={`${column.id !== 'isChecked' ? 'item' : ''}`}>
                  {column.render(item, index)}
                </ListItem>
              )
            })}

            <LessonActionsContainer>
              {actions.map((action: IAction) => action.render(itemOriginal))}
            </LessonActionsContainer>
          </Row>

          <Collapse in={collapsedItems.includes(item.id as string)} className={classes.collapse}>
            {collapsedItems.includes(item.id as string) && (
              <CollapseComponent
                item={item.lessons as unknown as IColumnItem[]}
                routerState={routerState}
                classes={classes}
                nestedConfig={nestedConfig}
                nestedAction={nestedAction}
                selectedItems={selectedItems}
                topicId={item.id as string}
                canModify={canModify}
              />
            )}
          </Collapse>
        </DndItemV2>
      </List>
    ) : (
      <List>
        <Row
          onClick={(): void => onCollapseClick(item.id as string)}
          style={{
            cursor: 'pointer',
          }}
        >
          {config.map(column => {
            return (
              <ListItem key={column.id} className={`${column.id !== 'isChecked' ? 'item' : ''}`}>
                {column.render(item, index)}
              </ListItem>
            )
          })}

          <LessonActionsContainer>
            {actions.map((action: IAction) => action.render(itemOriginal))}
          </LessonActionsContainer>
        </Row>

        <Collapse in={collapsedItems.includes(item.id as string)} className={classes.collapse}>
          {collapsedItems.includes(item.id as string) && (
            <CollapseComponent
              item={item.lessons as unknown as IColumnItem[]}
              routerState={routerState}
              classes={classes}
              nestedConfig={nestedConfig}
              nestedAction={nestedAction}
              selectedItems={selectedItems}
              topicId={item.id as string}
              canModify={canModify}
            />
          )}
        </Collapse>
      </List>
    )
  }

  return (
    <StyledContainer>
      {title && <h2 style={{ padding: 10 }}>{title}</h2>}
      <Header>
        {data.map((topic, index: number) => (
          <React.Fragment key={topic.id}>{renderBody(topic, index)}</React.Fragment>
        ))}
        {loading && <Loader withBackground={true} />}
        {data.length === 0 && (
          <DefaultText style={{ padding: 10, textAlign: 'center' }}>
            {noRecordsDefaultText || t('general.no_records')}
          </DefaultText>
        )}
      </Header>
    </StyledContainer>
  )
}

export default NestedGridV2
