import { useState } from 'react'
import createCourseService, {
  ICreateCourseResponse,
  ICreateCourseVariables,
} from 'services/course/createCourseService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

export interface IUseCreateCourseReturnType {
  useCreateCourseService: (
    input: ICreateCourseResponse['createCourse'],
    // videos: any,
    groupIds: string[],
    callback: (data?: ICreateCourseResponse | null) => void,
  ) => Promise<void>
  createCourseLoading: boolean
}

const useCreateCourse = (params: ICreateCourseVariables): IUseCreateCourseReturnType => {
  const { t } = useTranslation()
  const { createCourse } = createCourseService(params)
  const { setSnackbar } = useSnackbarAlert()
  const [loading, setLoading] = useState(false)
  const useCreateCourseService = async (
    input: ICreateCourseResponse['createCourse'],
    // videos: any,
    groupIds: string[],
    callback: (data?: ICreateCourseResponse | null) => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { errors, data } = await createCourse({
      variables: {
        input,
        groupIds,
      },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${t('general.course')} ${t('messages.added')}`
      variant = 'success'
      callback(data)
    }

    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return {
    useCreateCourseService,
    createCourseLoading: loading,
  }
}

export default useCreateCourse
