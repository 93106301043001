import { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { IGroup } from 'interfaces/groups'
import { useFetchBooksByGroup } from 'services/books/getBooksByGroupService'
import {
  IBookToGroupDto,
  GetBooksByGroupFiltersByEnums,
  IUseAddBookToGroupReturnType,
} from 'services/books/bookServices.interface'
import { useAddBookToGroupService } from 'services/books/addBookToGroupService'

const useAddBookToGroup = ({
  groupData,
  closeDrawer,
}: {
  groupData: IGroup
  closeDrawer: () => void
  bookId?: string
}): IUseAddBookToGroupReturnType => {
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [isAllSelected, setIsAllSelected] = useState(false)
  const groupId = groupData.id

  const { addBook } = useAddBookToGroupService({ groupId })

  const handleButtonClick = async (): Promise<void> => {
    if (selectedItems.length > 0) {
      const bookToGroupData: IBookToGroupDto = {
        bookIds: selectedItems,
        groupId: groupData.id,
      }
      await addBook(bookToGroupData)
      closeDrawer()
    }
  }

  const { books, refetch } = useFetchBooksByGroup({
    searchValue,
    filterBy: GetBooksByGroupFiltersByEnums.NOT_IN_GROUP,
    groupId,
  })

  const debouncedRefetch = useCallback(
    debounce((newSearchValue: string) => {
      refetch({
        searchValue: newSearchValue,
        groupId,
      })
    }, 500),
    [groupId, refetch],
  )

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const newSearchValue = e.target.value
      setSearchValue(newSearchValue)
      debouncedRefetch(newSearchValue)
    },
    [debouncedRefetch],
  )

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    refetch({
      searchValue,
      groupId,
    })
  }

  const selectAllItem = (): void => {
    if (!isAllSelected) {
      setSelectedItems(books.data ? books.data.map(book => book.id) : [])
    } else {
      setSelectedItems([])
    }
    setIsAllSelected(!isAllSelected)
  }

  const handleSelectItem = (id: string): void => {
    let updatedSelectedItems = []
    if (selectedItems.includes(id)) {
      updatedSelectedItems = selectedItems.filter(item => item !== id)
    } else {
      updatedSelectedItems = [...selectedItems, id]
    }
    setSelectedItems(updatedSelectedItems)
    setIsAllSelected(updatedSelectedItems.length === books.data?.length)
  }

  return {
    t,
    onSearchSubmit,
    searchValue,
    handleSearchChange,
    selectAllItem,
    isAllSelected,
    books,
    handleButtonClick,
    selectedItems,
    handleSelectItem,
  }
}

export default useAddBookToGroup
