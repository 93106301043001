import { useState, useEffect, ChangeEvent } from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useUserValue } from 'context/UserContext'
import useCompaniesService from 'hooks/useCompaniesService'
import { CompanyPolicyEnums } from 'pages/main/main.interface'
import { DrawerEventEmitter } from 'helpers/drawer'
import { CompaniesWizardPage1Schema } from 'helpers/validationSchemas'
import { useQuery } from '@apollo/client'
import { GET_ALL_INDUSTRY } from 'gql/skilss.query'
import { TOGGLE_CLOSE_DRAWER } from 'store/types'
import {
  IAddCompanyDrawerResponse,
  IFormData,
  ICompanyActivity,
} from 'pages/companies/CompaniesAdd/addCompanyDrawer.interface'
import { useUploadImage } from 'hooks/helpers/useHelpersService'
import removeTypeNameFromObject from 'utils/removeTypeNameFromObject'
import { ICompany } from 'pages/companies/CompanyDetails/types'
import { CropperResultType } from 'interfaces/common'

export const formData: IFormData = {
  name: '',
  avatar: null,
  avatarAttachment: null,
  companyEmail: '',
  keyContactName: '',
  address: '',
  phone: '',
  companyActivity: null,
  country: '',
  city: '',
  note: '',
  platformService: false,
  exploreCourses: false,
  numberOfUsers: '',
  phoneFields: null,
  finished: 0,
  postalCode: '',
  phoneFieldValue: null,
}

export const useAddCompany = (): IAddCompanyDrawerResponse => {
  const { t } = useTranslation()
  const [state, dispatch] = useUserValue()
  const [cropperOpen, setCropperOpened] = useState(false)
  const { addCompany, error, removeCompanyAvatar, loading } = useCompaniesService()
  const [generalError, setGeneralError] = useState('')
  const [file, setFile] = useState<CropperResultType>({ name: '', file: '' })
  const [imageType, setImageType] = useState('')
  const { uploadImage, imageLoading } = useUploadImage()

  const { data } = useQuery(GET_ALL_INDUSTRY)

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik({
    initialValues: formData,
    validationSchema: CompaniesWizardPage1Schema,
    async onSubmit(values) {
      delete values.phoneFieldValue
      const data: IFormData = {
        name: values.name,
        keyContactName: values.keyContactName,
        companyEmail: values.companyEmail,
        city: values.city,
        address: values.address,
        note: values.note,
        postalCode: values.postalCode,
        phone: values.phone,
      }

      if (values.avatarAttachment) {
        const avatarAttachment = values.avatarAttachment
        data.avatar = avatarAttachment.link as string
        data.avatarAttachment = removeTypeNameFromObject(avatarAttachment)
      } else {
        data.avatar = null
        data.avatarAttachment = null
      }

      if (values.companyActivity) {
        data.companyActivity = [
          {
            label: (values.companyActivity as ICompanyActivity).label,
          },
        ]
      } else {
        data.companyActivity = []
      }

      data.showLeaderboardBy = values.showLeaderboardByGroup
        ? CompanyPolicyEnums.GROUP
        : CompanyPolicyEnums.COMPANY

      data.showNewsFeedBy = values.showNewsFeedByGroup
        ? CompanyPolicyEnums.GROUP
        : CompanyPolicyEnums.COMPANY

      if (values.phoneFields && values.phoneFields.value) {
        data.phoneFields = {
          dialCode: values.phoneFields?.value,
          code: values.phoneFields?.label,
        }
      } else {
        data.phoneFields = null // it's optional
      }

      if (typeof values.country === 'object' && values.country !== null) {
        data.country = values.country.value
      }
      data.finished = 7
      if (values.numberOfUsers) data.numberOfUsers = JSON.stringify(values.numberOfUsers)

      addCompany(data as ICompany, () => {
        closeDrawer()
      })
    },
  })

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>, field: string): void => {
    const { files, name } = e.target

    if (!files || !files.length) return
    const reader: FileReader = new FileReader()

    reader.onload = (): void => {
      const img: HTMLImageElement = new Image()
      img.src = reader.result as string
      setFile({ name, file: reader.result as string })
    }
    reader.readAsDataURL(files[0])
    if (field !== 'certificateImage') {
      setCropperOpened(true)
    }
    setImageType(field)
  }

  const deleteImage = (field: string): void => {
    setFieldValue(field, '')
  }

  const activityList =
    (data &&
      data.getAllIndustry.map((i: { label: string; id: string }) => ({
        label: i.label,
        value: i.id,
      }))) ||
    []

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement> | string): void => {
    const { confirm } = state.closeDrawer
    if (!confirm) {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: t('popups.close_popup'),
          confirm: true,
        },
      })
    }
    handleChange(e)
  }

  useEffect(() => {
    if (error) setGeneralError(error)
  }, [error])

  useEffect(() => {
    setFieldValue('phoneFieldValue', (values.phoneFields && values.phoneFields.label) || null)
    values.phoneFieldValue = values.phoneFields ? values.phoneFields.value : null
  }, [values.phoneFields])

  const removeAvatarAndCloseDrawer = async (): Promise<void> => {
    if (values.avatarAttachment?.link) {
      await removeCompanyAvatar(values.avatarAttachment.link as string)
    }
    closeDrawer()
  }
  const closeDrawer = async (): Promise<void> => {
    DrawerEventEmitter.emit('openDrawer', 'companiesAdd', false)
  }

  const handleCropSave = (field: string, croppedFile: CropperResultType): void => {
    uploadImage(croppedFile as string, `avatar`, (link: string) =>
      setFieldValue(field, {
        name: Date.now().toString(),
        link,
        fileType: 'png',
      }),
    )
  }

  const cropperModalToggle = (): void => {
    setCropperOpened(!cropperOpen)
  }

  return {
    t,
    handleImageChange,
    deleteImage,
    imageLoading,
    generalError,
    values,
    errors,
    touched,
    handleValueChange,
    setFieldValue,
    removeAvatarAndCloseDrawer,
    handleSubmit,
    activityList,
    data,
    cropperOpen,
    handleCropSave,
    file,
    loading,
    imageType,
    cropperModalToggle,
  }
}
