import { styled } from 'styled-components'

export const Information = styled.div`
  padding: 16px;
  border: 1px solid #d6dae0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 16px;

  p {
    color: #68707a;
    margin: 0;
  }
  span {
    color: #020210;
    font-weight: 600;
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export const InformationContent = styled.div`
  display: flex;
  gap: 12px;
`

export const TextWrappers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
