import styled from 'styled-components'

export const DetailsItemContainer = styled.div`
  display: flex;
  justify-content: space-around;
`
export const DetailsItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.header``

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
`
export const LayoutWrapper = styled.div`
  background-color: #fff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`
export const TopLayoutWrapper = styled.div`
  /* background-color: #fff; */
  /* padding: 15px; */
  display: flex;
  flex-direction: column;
  gap: 15px;
`
export const RightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`
export const ModuleContentDetail = styled.li`
  &:not(:last-child) {
    margin-right: 15px;
  }
  display: flex;
  flex-direction: column;

  svg {
    color: red;
  }
`
export const QuizContentDetailsContainer = styled.div`
  margin-top: 10px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`
export const QuizContentHeader = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* padding: 10px; */
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const CourseContentDetailsContainerFlex = styled.div`
  display: flex;
  height: 100%;
`
export const StyledCourseDetailsLeftParent = styled.div`
  border-right: 1px solid #e7e9ed;

  width: 30%;
  height: auto;
  padding: 16px 16px;
`
export const StyledCourseDetailsLeft = styled.div`
  width: 100%;
  height: 100%;
`
export const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
  detailTitle: {
    color: '#68707A',
    padding: 0,
    margin: 0,
    lineHeight: '20px',
    fontSize: '14px',
    fontWeight: '20px',
  },
  detailHeader: {
    fontWeight: 500,
    fontSize: '16px',
    lineWeight: '26px',
  },
  actionSpan: {
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '18px',
    color: '#31313d',
    marginLeft: '0',
  },
}
export const StyledCourseDetailsBetweenParent = styled.div`
  width: 90%;
  height: 90%;
  padding: 16px 16px;
`
export const DetailsItem = styled.li`
  padding-top: 25px;
  &:not(:last-child) {
    margin-right: 15px;
  }
  display: flex;
  flex-direction: column;

  svg {
    color: red;
  }
`
