import { useState } from 'react'
import addTopicService from 'services/topics/addTopicService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import { IModule, ITopic } from 'pages/modules/ModulesList.interface'
import { ILessonOrderServiceProps } from 'services/topics/editTopicService'

export const useAddTopic = (
  params: ILessonOrderServiceProps,
): {
  addTopic: (moduleId: string, input: ITopic, callback?: (data: ITopic) => void) => Promise<void>
  addTopicLoading: boolean
  newTopic: IModule | null
  error: string
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [newTopic, setNewTopic] = useState<IModule | null>(null)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { addTopicMutation } = addTopicService(params)

  const addTopic = async (
    moduleId: string,
    input: ITopic,
    callback?: (data: ITopic) => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { data, errors } = await addTopicMutation({
      variables: {
        moduleId,
        input,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        message = `${t('general.user')} ${t('messages.already_registered')}`
        setError(message)
        return
      }
      variant = 'error'
    } else if (data) {
      setNewTopic(data.addTopic)
      if (callback) callback(data.addTopic?.createdTopic)
      message = `${t('general.topic')} ${t('messages.saved')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return {
    addTopic,
    addTopicLoading: loading,
    newTopic,
    error,
  }
}
