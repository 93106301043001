import { gql } from 'graphql.macro'

export const SET_NOTIFICATION_VIEW_STATUS = gql`
  mutation SetNotificationViewStatus($ids: [String]) {
    setNotificationViewStatus(ids: $ids) {
      message
    }
  }
`
export const GET_NOTIFICATIONS = gql`
  query GetNotifications($skip: Int!, $count: Int!) {
    getNotifications(skip: $skip, count: $count) {
      totalCount
      hasMore
      data {
        message
        id
        view
        actionName
        actionId
        objectName
        state
        createDate
        additionalText
        company {
          name
          id
        }
        owner {
          id
          firstName
          lastName
          email
          avatar
          role
          companyId
        }
      }
    }
  }
`
export const GET_NOTIFICATION_COUNT = gql`
  query GetNotificationCount {
    getNotificationCount {
      numberOfNotifications
    }
  }
`
export const NOTIFICATION_LISTENER = gql`
  subscription {
    registerNotification {
      message
    }
  }
`
export const READ_NOTIFICATIONS = gql`
  mutation ReadNotifications($ids: [String], $action: String) {
    readNotification(ids: $ids, action: $action) {
      message
    }
  }
`

export const SEND_CUSTOM_NOTIFICATION = gql`
  mutation SendCustomNotificaion($input: CustomNotificationInput) {
    sendCustomNotification(input: $input) {
      message
    }
  }
`

export const DELETE_NOTIFICATIONS = gql`
  mutation DeleteNotifications($ids: [String], $action: String) {
    deleteNotifications(ids: $ids, action: $action) {
      message
    }
  }
`
