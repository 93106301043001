import React from 'react'

interface IAudioPlayerProps {
  url: string
}

const AudioPlayer = (props: IAudioPlayerProps): React.JSX.Element => {
  return (
    <audio controls autoPlay>
      <source src={props.url} />
    </audio>
  )
}

export default AudioPlayer
