import React, { useCallback, useEffect, useState } from 'react'
import { columnConfig } from './columnConfig'
import { debounce } from 'lodash'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { ITutorsLayout, IUseTutorsLayoutResponse } from './tutorsLayout.interface'
import { useNavigate } from 'react-router-dom'
import { IUser } from 'interfaces/users'
import { useFetchTutorsByCourseId } from 'hooks/users/useFetchTutorsByCourseId'
import { IFilterQuery, IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { useTranslation } from 'react-i18next'

const useTutorsLayout = ({
  courseId,
  groups,
  companyId,
}: ITutorsLayout): IUseTutorsLayoutResponse => {
  const navigate = useNavigate()
  const [searchText, setSearchText] = useState<string>('')
  const [groupOptions, setGroupOptions] = useState<IFilterQuery[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [selectedGroup, setSelectedGroup] = useState<IFilterQuery | null>(null)
  const [initialLoad, setInitialLoad] = useState(true)
  const [isSearchType, setIsSearchType] = useState<boolean>(false)

  const groupIds = groups?.map(group => group.groupId) || []
  const defaultFilter: IFilterQueryType = {
    group: {
      type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
      value: groupIds,
      nestedField: 'groupId',
    },
  }

  const [filterOptions, setFilterOptions] = useState(defaultFilter)

  const { tutors, loading, refetch } = useFetchTutorsByCourseId(
    searchText,
    courseId,
    (selectedGroup?.value as string) || null,
    currentPage,
    perPage,
  )

  const handleResetClick = (resetGroups = true): void => {
    if (resetGroups) setSelectedGroup(null)
    setSearchText('')
    setFilterOptions(defaultFilter)
    refetch({
      filter: defaultFilter,
      currentPage: 1,
      perPage,
      companyId,
    })
  }

  const debouncedRefetch = useCallback(
    debounce((newSearchValue: string) => {
      const searchQuery = {
        search: {
          type: LowerCaseFilterableFieldType.SEARCH,
          value: newSearchValue,
          fields: ['firstName', 'lastName', 'email', 'phone'],
        },
      }

      const updatedFilterOptions = {
        ...filterOptions,
        ...searchQuery,
      }
      setFilterOptions(updatedFilterOptions)
      refetch({
        filter: updatedFilterOptions,
        currentPage: 1,
        perPage,
      })
      setIsSearchType(newSearchValue !== '')
    }, 500),
    [currentPage, perPage, refetch],
  )

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setIsSearchType(true)
      const newSearchValue = e.currentTarget.value
      setSearchText(newSearchValue)
    },
    [debouncedRefetch],
  )

  useEffect(() => {
    if (!loading) {
      setInitialLoad(false)
    }
  }, [loading])

  useEffect(() => {
    if (!groups) return
    const currGroupOptions =
      groups.map(group => ({
        label: group.name,
        value: group.groupId,
      })) || []

    setGroupOptions(currGroupOptions)
  }, [groups])

  const handleClick = (student: IUser): void => {
    navigate(`/profile/${student.id}`)
  }

  useEffect(() => {
    if (!selectedGroup) return
    const groupFilter = {
      group: {
        type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
        value: [selectedGroup?.value],
        nestedField: 'groupId',
      },
    }

    const updatedFilterOptions = {
      ...filterOptions,
      ...groupFilter,
    }
    setFilterOptions(updatedFilterOptions as IFilterQueryType)
    refetch({
      filter: updatedFilterOptions,
      currentPage,
      perPage,
    })
  }, [selectedGroup])

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const newPerPage = parseInt(event.target.value, 10)
    setPerPage(newPerPage)
    refetch({
      filter: filterOptions,
      currentPage,
      perPage: newPerPage,
    })
  }

  const handlePaginationClick = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    const newCurrentPage = newPage + 1
    setCurrentPage(newCurrentPage)
    refetch({
      filter: filterOptions,
      currentPage: newCurrentPage,
      perPage,
    })
  }

  const { t } = useTranslation()
  const config = columnConfig(t)

  return {
    setSelectedGroup,
    setSearchText,
    handleClick,
    selectedGroup,
    searchText,
    currentPage,
    handleResetClick,
    perPage,
    handlePaginationClick,
    handleChangeRowsPerPage,
    handleSearchChange,
    groupOptions,
    tutors,
    loading,
    config,
    isSearchType,
    initialLoad,
  }
}

export default useTutorsLayout
