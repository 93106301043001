import { useState } from 'react'
import useSnackbarAlert from 'hooks/useSnackbar'
import updateUserStatusService from 'services/users/updateUserStatusService'
import { useTranslation } from 'react-i18next'
import formatRoleForTranslation, { RoleFormats } from 'helpers/formatRole'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'

export interface IUpdateStatusUserServiceParams {
  role?: string
  defaultFilter?: IFilterQueryType
}

const useUpateStatusUserService = (
  params: IUpdateStatusUserServiceParams,
): {
  updateUserStatus: (ids: string[], status: string) => Promise<void>
  updateUserStatusLoading: boolean
  error: string
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')

  const { updateUserStatusMutation, loading } = updateUserStatusService(params)

  const updateUserStatus = async (ids?: string[], status?: string): Promise<void> => {
    if (!ids || !status) return

    let message = ''
    let variant: 'default' | 'error' | 'warning' | 'success' | 'info' | undefined
    const { data, errors } = await updateUserStatusMutation({
      variables: {
        ids,
        status,
      },
    })

    if (errors) {
      // eslint-disable-next-line no-console
      console.log(errors)
      message = t('messages.something_wrong')
      variant = 'error'
      setSnackbar({ message, variant })

      setError(JSON.stringify(errors))
    } else if (data && data.updateUserStatus.length) {
      const { role, status } = data.updateUserStatus[0]
      const single = data.updateUserStatus.length === 1
      if (!role) return
      let message = single
        ? t(formatRoleForTranslation(role))
        : t(formatRoleForTranslation(role, RoleFormats.PLURAL))

      if (status === 'ACTIVE') message += ` ${t('messages.activated')}`
      else message += ` ${t('messages.suspended')}`

      variant = 'success'
      setSnackbar({ message, variant })
    }
  }

  return {
    updateUserStatus,
    updateUserStatusLoading: loading,
    error,
  }
}

export default useUpateStatusUserService
