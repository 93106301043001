import styled from 'styled-components'

// AddGroupDrawer

export const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
`
export const Header = styled.header`
  position: sticky;
  top: 0;
  z-index: 2;
`

export const Title = styled.div`
  font-size: 2em;
  font-weight: normal;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 10px;
`

// AddStudentsInNewGroup

export const NewContainer = styled.div`
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 70px);
`

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 350px;
`

export const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

// StudentListDrawer

export const ListContainer = styled.div`
  box-sizing: border-box;
  padding: 25px;
  height: 100%;
`
export const ListTitle = styled.div`
  font-size: 23px;
  font-weight: 500;
  padding-bottom: 80px;
`
