import { styled } from 'styled-components'

export const StyledTable = styled.table`
  display: flex;
  justify-content: flex-end;
  background: #fff;
  border: 1px solid #e7e9ed;
  border-top: none;
`
export const MUITablePaginationStyles = {
  border: 'none',
}
