import React, { ReactElement } from 'react'
import { LabelContainer, TutorsListContainer, Wrapper } from './styled-components'
import { StyledLabel } from '../styled-components'
import { ITutorsListProps } from 'hooks/course/assignTutor'
import useTutorsList from 'hooks/course/assignTutor/tutorsList/useTutorsList'
import { SplashScreen } from 'components/common'
import TutorItem from './TutorItem/TutorItem'
import MyLoader from 'loading/loading'

const TutorsList = ({
  courseId,
  searchValue,
  selectedTutor,
  selectTutor,
}: ITutorsListProps): ReactElement => {
  const { t, tutors, tutorsLoading, listRef } = useTutorsList({
    courseId,
    searchValue,
  })

  return (
    <Wrapper>
      <LabelContainer>
        <StyledLabel>{`${t('general.all')} ${t('general.coaches')}`}</StyledLabel>
        {selectedTutor && (
          <span onClick={(): void => selectTutor(undefined)}>{t('actions.clear_selection')}</span>
        )}
      </LabelContainer>
      <TutorsListContainer ref={listRef}>
        {tutorsLoading && !tutors.length && <MyLoader width={200} />}
        {tutors.length
          ? tutors.map(tutor => (
              <TutorItem selectedTutor={selectedTutor} selectTutor={selectTutor} tutor={tutor} />
            ))
          : !tutorsLoading && <SplashScreen minHeight='50vh' subTitle={t('general.no_coaches')} />}
      </TutorsListContainer>
    </Wrapper>
  )
}

export default TutorsList
