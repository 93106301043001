import { DrawerEventEmitter } from 'helpers/drawer'

const useAdminsLayout = (): { handleGroupClick: (groupId: string) => void } => {
  const handleGroupClick = (groupId: string): void => {
    DrawerEventEmitter.emit('openDrawer', 'groupInformation', true, {
      id: groupId,
    })
  }

  return {
    handleGroupClick,
  }
}

export default useAdminsLayout
