import { useQuery } from '@apollo/client'
import { GET_ANALYTICS_QUANTITIES } from 'gql/analytics/statistic.query'
import {
  GetFetchAnalyticsResponse,
  IGetFetchAnalytics,
  IUseFetchAnalyticsArgs,
} from 'interfaces/courses/courses.interface'

// Hook
export const useFetchAnalytics: GetFetchAnalyticsResponse = (courseId, companyId, groupId) => {
  const { data, loading, error } = useQuery<IGetFetchAnalytics, IUseFetchAnalyticsArgs>(
    GET_ANALYTICS_QUANTITIES,
    {
      variables: {
        courseId,
        companyId,
        groupId,
      },
    },
  )

  return {
    analytics: data?.getCourseAnalyticsQuantities || null,
    loading,
    error: error?.message,
  }
}
