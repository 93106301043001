import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Header = styled.header`
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  padding-left: 20px;
  padding-top: 10px;
  border-bottom: 1px solid #ccc;
  position: sticky;
  z-index: 9999;
  top: 0;
  background: #fff;
`

export const FormFooter = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.3);

  & > div {
    width: 50%;
  }

  & nav {
    margin-top: 0;
    display: flex;
    justify-content: flex-end;
  }
`
export const FormButtons = styled.div`
  align-self: flex-end;
  display: flex;
  padding: 20px;

  div:not(:last-child) {
    margin-right: 10px;
  }
`
