import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
`
export const ColorInputContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 14px 10px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &.error {
    border: 1px solid red;
  }

  .btn-wrapper {
    margin-right: 5px;
  }

  button {
    height: 38px;
    padding: 0 !important;
  }
`

export const ErrorMessage = styled.article`
  position: absolute;
  color: rgb(211, 47, 47);
  bottom: -25px;
  left: 0;
  font-size: 0.75rem;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
`

export const ModalContainer = styled.div`
  background: #fff;
  width: 1000px;
  height: 80%;
  padding: 40px;
  border-radius: 5px;
`

export const ModalContent = styled.div`
  border: 1px solid #73787a;
  border-radius: 5px;
  height: 90%;
  overflow-y: scroll;
  display: flex;
  align-items: center;
`
export const ModalButtons = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;

  .btn-wrapper {
    margin-right: 5px;
  }
`

export const UploadInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  overflow: hidden;
`

export const Label = styled.div`
  position: absolute;
  left: 11px;
  top: -9px;
  background: #fff;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);

  &.error {
    color: red;
  }
`
export const InputLabel = styled.label`
  cursor: pointer;
  color: #fff;
  background: #06c68f;
  border-radius: 4px;
  width: 80px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.disabled {
    color: rgba(0, 0, 0, 0.26);
    pointer-events: none;
    background: rgba(0, 0, 0, 0.3);
  }
`

export const ImagePlaceholder = styled.figure`
  position: relative;
  margin-top: 2px;
  width: 36px;
  img {
    width: 36px;
    height: 36px;
  }
`
export const CloseIcon = styled.span`
  position: absolute;
  cursor: pointer;
  top: -10px;
  right: -10px;
`

export const ColorPlaceholder = styled.div<{ background: string }>`
  cursor: pointer;
  width: 36px;
  height: 36px;
  background: ${({ background }): string => background};
`

export const AudioUploader = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  label {
    display: block;
    width: 80px;
    height: 38px;
  }
`
