// @deprecated
/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { Button } from 'components/common/Button'
import { useQuery } from '@apollo/client'
import { ContentWrapper, TextContainer } from './styled-components'
import { useFormik } from 'formik'
import { TabNavigation, TabPanel, activeTabClassName } from 'components/common/Tabs/Tabs'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { GET_GROUP } from 'gql/group/group.query'
import { useNavigate } from 'react-router-dom'

import useAddGroup from 'hooks/group/useAddGroup'
import useCompaniesService from 'hooks/useCompaniesService'
import { GroupSchema } from 'helpers/validationSchemas'

import { Container, Wrapper, FooterWrapper, ButtonWrapper } from '../styled-components'

import CreateGroup from './CreateGroup'
import GroupList from './GroupList'

const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
}

const formData: any = {
  name: '',
  location: '',
  admin: null,
  courses: null,
  note: '',
}

const CompanyGroup = (props: any) => {
  const [tabValue, setTabValue] = useState(0)
  const [createGroup, setCreateGroup] = useState('')
  const { companyData, setData } = props
  const navigate = useNavigate()
  const { setCompanyState } = useCompaniesService()

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  const { data, refetch } = useQuery(GET_GROUP, {
    variables: {
      filter: {
        company: {
          type: 'exact',
          value: companyData.companyData.id,
        },
      },
      perPage: 0,
    },
  })

  useEffect(() => {
    if (data && data.getAllGroups) {
      setData((prev: any) => ({
        ...prev,
        groups: data.getAllGroups.data,
      }))
    }
  }, [data])

  const { loading, addGroup } = useAddGroup({
    filter: {
      company: {
        type: 'exact',
        value: companyData.companyData.id,
      },
    },
  })

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue, resetForm } =
    useFormik({
      initialValues: formData,
      validationSchema: GroupSchema,
      onSubmit(values) {
        if (loading) return

        const data = { ...values }

        data.courses = []
        if (data.admin) {
          data.admin = data.admin.value
        }

        if (values.courses) {
          data.courses = values.courses.map((i: any) => ({
            name: i.label,
            courseId: i.value,
            state: i.state,
          }))
        }

        data.company = companyData.companyData.id
        // delete data.courses

        addGroup(data, [], () => {
          refetch()
          if (createGroup === 'reset') {
            resetForm()
          } else {
            resetForm()
            setTabValue(1)
          }
        })
      },
    })

  const onFormSubmit = (action: string) => {
    setCreateGroup(action)
    handleSubmit()
  }

  const { t } = props

  return (
    <>
      <Container>
        <Wrapper width='74%'>
          <ContentWrapper>
            <TabNavigation>
              <AppBar position='static' style={styles.bar}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label='simple tabs example'>
                  <Tab
                    label={t('company_wizard_layout.create_new_group')}
                    {...activeTabClassName(0)}
                    style={styles.btn}
                  />
                  <Tab
                    label={t('company_wizard_layout.group_list')}
                    {...activeTabClassName(1)}
                    style={styles.btn}
                  />
                </Tabs>
              </AppBar>

              <TabPanel value={tabValue} index={0}>
                <CreateGroup
                  handleSubmit={onFormSubmit}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  adminList={companyData.admins}
                  companyId={companyData.companyData.id}
                  addGroupLoading={loading}
                  t={t}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <GroupList
                  groupList={companyData.groups}
                  adminList={companyData.admins}
                  companyId={companyData.companyData.id}
                  refetch={refetch}
                  t={t}
                />
              </TabPanel>
            </TabNavigation>
          </ContentWrapper>
        </Wrapper>

        <Wrapper width='calc(26% - 20px)'>
          <TextContainer>{t('company_wizard_layout.group_of_students_description')}</TextContainer>
        </Wrapper>
      </Container>

      <FooterWrapper>
        <ButtonWrapper>
          <Button
            text={t('actions.cancel')}
            type='small'
            onClick={props.onCancel}
            background='#E0E1E2'
            textColor='#414141'
          />
        </ButtonWrapper>

        <ButtonWrapper>
          <div style={{ marginRight: '15px' }}>
            <Button
              text={t('actions.previous')}
              type='small'
              onClick={() => props.changeStep(3)}
              background='#E0E1E2'
              textColor='#414141'
            />
          </div>

          <div style={{ marginRight: '15px', height: '31px' }}>
            <Button
              text={t('actions.save_as_draft')}
              type='small'
              color='secondary'
              onClick={() => {
                setCompanyState(companyData.companyData.id, 4, 'Success', () =>
                  navigate('/Companies'),
                )
              }}
              textColor='#06C68F'
              background='#f5f5f5'
              outline='1px solid #06C68F'
            />
          </div>

          <Button
            text={t('actions.save_and_next')}
            type='small'
            color='secondary'
            onClick={() => {
              setCompanyState(companyData.companyData.id, 5, 'Success', () => props.changeStep(5))
            }}
            background='#06C68F'
          />
        </ButtonWrapper>
      </FooterWrapper>
    </>
  )
}

export default CompanyGroup
