import { useQuery } from '@apollo/client'
import { GET_MODULE } from 'gql/modules.query'
import useSnackbarAlert from 'hooks/useSnackbar'
import { SnackbarVariants } from 'interfaces/common'
import { IModuleServiceResponse } from './moduleDetailService.interface'

export const useModuleDetailService = (moduleId: string): IModuleServiceResponse => {
  const { setSnackbar } = useSnackbarAlert()

  const { data, loading, error, refetch } = useQuery(GET_MODULE, {
    variables: { moduleId: moduleId || '' },
  })

  const errorMessage = error ? error?.message.split(':')[1] : ''

  if (error) {
    setSnackbar({
      message: errorMessage,
      variant: SnackbarVariants.ERROR,
    })
  }

  return { data, loading, error: errorMessage, refetch }
}
