import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: white;
  border-radius: 14px;
  display: flex;
  width: 40%;
  padding: 25px;
  gap: 20px;
  flex-direction: column;
  box-shadow: 0px 0px 29px 5px rgba(0, 0, 0, 0.03);
`

export const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #06c68f;
  }
`

export const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const FormFieldStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '10px',
}

export const MarginNormalizer = {
  marginInline: '0',
  paddingBlock: '5px',
}

export const TemplateFieldContainer = styled.div``

export const StyledLabel = styled.p`
  font-size: 1rem;
  margin-block: 10px;
  font-family: 'Helvetica neue', sans-serif;
  color: rgba(0, 0, 0, 0.6);
`
