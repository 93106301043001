import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { ISelectDropDownOption } from 'components/common/SelectDropDown/selectDropDown.interface'
import i18n from 'i18n/index'

export interface FilterOptions {
  name: string
  status: ISelectDropDownOption
  numberOfStudents: number[]
  company: null | IValueArray
  admin: null | IValueArray
  course: null | IValueArray
  location: string
  note: string
  filterValues?: IFilterQueryType
  createDate: number[] | null[]
}

export type IValueArray = { value: string | null }[]

export let statuses: {
  value: string
  label: string
}[] = []

export let filterOptions: FilterOptions = {
  name: '',
  status: { label: i18n.t('general.all'), value: 'all' },
  admin: null,
  numberOfStudents: [0, 0],
  company: null,
  course: null,
  createDate: [null, null],
  location: '',
  note: '',
}

function translate(): void {
  statuses = [
    { value: 'all', label: i18n.t('general.all') },
    { value: 'DRAFT', label: i18n.t('general.draft') },
    { value: 'ACTIVE', label: i18n.t('general.active') },
    { value: 'SUSPENDED', label: i18n.t('general.suspended') },
  ]

  filterOptions = {
    name: '',
    status: { label: i18n.t('general.all'), value: 'all' },
    admin: null,
    numberOfStudents: [0, 0],
    company: null,
    course: null,
    createDate: [null, null],
    location: '',
    note: '',
  }
}

i18n.on('languageChanged init', () => {
  translate()
})
