import { MutationTuple, useMutation } from '@apollo/client'
import { ADD_COMPANY } from 'gql/companies.query'
import { ICompany } from 'pages/companies/CompanyDetails/types'

interface IAddCompanyVariables {
  input: ICompany
  coursesIds: string[]
}

export interface IAddCompanyResponse {
  addCompany: ICompany
}

interface IUseAddCompanyServiceResponse {
  addCompanyMutation: MutationTuple<IAddCompanyResponse, IAddCompanyVariables>[0]
  loading: boolean
  error?: string
}

const addCompanyService = (): IUseAddCompanyServiceResponse => {
  const [addCompanyMutation, { loading, error }] = useMutation<
    IAddCompanyResponse,
    IAddCompanyVariables
  >(ADD_COMPANY, {})
  const errorMessage = error ? error.message.split(':')[1] : ''

  return {
    addCompanyMutation,
    loading,
    error: errorMessage,
  }
}

export default addCompanyService
