import styled from 'styled-components'

export const CapitalizeWrapper = styled.span`
  text-transform: capitalize;
`
export const RejectionContent = styled.div`
  width: 100%;
  height: 100%;
`

export const RejectionStateWrapper = styled.article<{ color: string }>`
  color: ${({ color }): string => color};
  font-weight: 500;
  font-size: 16px;
`
export const UserProfileWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const UserAvatarWrapper = styled.figure`
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`
export const UsernameWrapper = styled.h3``

export const PostContentWrapper = styled.div`
  margin: 10px 0 20px 0;
  padding: 0 15px 15px 15px;
  width: 100%;
  max-height: 380px;
  overflow: auto;
`
export const RejectionListWrapper = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  width: 100%;
  max-height: 380px;
  overflow: auto;
  padding: 15px;
`
export const RejectionListTitle = styled.h3`
  text-transform: uppercase;
`
export const DateWrapper = styled.span`
  margin-left: 20px;
`
export const PostContentContainer = styled.div`
  background: rgb(250, 250, 250);
  border-radius: 5px;
  padding: 10px;
`
export const PostMediaContainer = styled.div`
  margin: 10px 0 0 0;
`
export const PostMediaImage = styled.figure`
  width: 300px;
  height: 240px;
  margin: 0 auto;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  img {
    width: 300px;
    height: 240px;
    object-fit: cover;
    border-radius: 5px;
  }

  button {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      font-size: 36px;
    }
  }
`

export const UserDetails = styled.section`
  margin-top: 30px;
`
