// @deprecated
/* eslint-disable */

import React, { useState } from 'react'
import _ from 'lodash'
import { useFetchCourses, PER_PAGE } from 'hooks/course/useCourses'

import { Container, ButtonContainer, ButtonItem } from './styled-components'
import { FormFields, FormField } from 'components/common/Form/Form'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import { generateFullName } from 'utils/utils'
import { AsyncSelect, SelectField } from 'components/common/SelectField'
import { ISelectOption } from 'pages/customNotifications/customNotification.interface'

const CreateGroup = ({
  handleSubmit,
  handleChange,
  values,
  errors,
  touched,
  setFieldValue,
  adminList,
  companyId,
  addGroupLoading,
  t,
}: any) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)

  const query: any = {
    companyId: {
      type: 'EXACT',
      value: companyId,
    },
    isBought: {
      type: 'EXACT',
      value: true,
    },
  }

  const { courses } = useFetchCourses(query, currentPage, perPage)

  const courseList = (courses && courses.data) || []

  const courseData = courseList.map((i: any) => ({
    label: i.name,
    value: i.id,
    state: i.state,
  }))

  return (
    <Container>
      <FormFields>
        <FormField>
          <TextInput
            error={!!errors.name}
            errorMessage={errors.name}
            touched={touched.name}
            label={`${t('form_fields.name')}*`}
            name='name'
            size='small'
            type='text'
            value={values.name}
            onChange={handleChange}
          />
        </FormField>

        <FormField>
          <TextInput
            label={t('form_fields.location')}
            name='location'
            size='small'
            type='text'
            value={values.location}
            onChange={handleChange}
          />
        </FormField>

        <FormField style={{ position: 'relative' }}>
          <AsyncSelect
            isMulti
            onChange={(e: ISelectOption) => setFieldValue('admin', e)}
            data={adminList || []}
            value={values.admin}
            label={t('form_fields.type_to_search_admin')}
            labelDataName={generateFullName}
            valueDataKey='id'
            error={!!errors.admin}
            touched={!!touched.admin}
            errorMessage={errors.admin ? errors.admin : ''}
          />
        </FormField>

        <FormField style={{ position: 'relative' }}>
          <SelectField
            error={!!errors.courses}
            errorMessage={errors.courses}
            touched={touched.courses}
            placeholder={`${t('general.courses')}*`}
            name='courses'
            options={courseData}
            value={values.courses}
            onChange={(e: any) => setFieldValue('courses', e)}
            isClearable
            isMulti
          />
        </FormField>

        <FormField>
          <TextInput
            label={t('form_fields.note')}
            size='small'
            name='note'
            type='text'
            multiline
            rows='3'
            value={values.note}
            onChange={handleChange}
            fullWidth={true}
          />
        </FormField>

        <FormField>
          <ButtonContainer>
            <ButtonItem style={{ marginRight: '10px' }}>
              <Button
                text={t('company_wizard_layout.save_and_create_another')}
                type='small'
                onClick={() => handleSubmit('reset')}
                isDisabled={addGroupLoading}
                background='#06C68F'
              />
            </ButtonItem>

            <ButtonItem>
              <Button
                text={t('company_wizard_layout.save_and_review')}
                type='small'
                onClick={() => handleSubmit('review')}
                isDisabled={addGroupLoading}
                background='#06C68F'
              />
            </ButtonItem>
          </ButtonContainer>
        </FormField>
      </FormFields>
    </Container>
  )
}

export default CreateGroup
