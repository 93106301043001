import React, { ReactElement, useState } from 'react'
import _ from 'lodash'
import { FormFooter, FormButtons } from './styled-components'
import { useFetchCourses, PER_PAGE } from 'hooks/course/useCourses'
import CourseList from 'pages/courses/CourseList'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import SyncIcon from '@mui/icons-material/Sync'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Grid } from 'components/common/Cards/styled-components'
import { FormGroupWrapper } from 'components/common/Form'
import Drawer from 'components/common/Drawer'
import FilterDrawer, { CourseFilterDrawerContext } from 'pages/courses/FilterDrawer/FilterDrawer'
import { Button } from 'components/common/Button'
import { MultipleAction, MultipleActionTooltip } from 'components/common/Grid/styled-components'
import TextInput from 'components/common/TextInput/TextInput'
import Pagination from 'components/common/Pagination'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'
import { TFunction } from 'interfaces/TFunction'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { IGroupsFilterDrawer } from 'hooks/course/addCourseDrawer/addGroupInCourseDrawer/addGroupInCourseDrawer.interface'

interface Props {
  boughtCourses: string[]
  selectItem: (item: string) => void
  selectedItem: string
  handleDeleteCourse: () => void
  handleButtonClick: (action: string) => void
  t: TFunction
}

type filterData = {
  values: object
  filterOptions: object
}

const initialValues = {
  name: '',
  state: { value: 'all', label: 'All' },
  level: { value: 'all', label: 'All' },
  coaches: null,
  group: null,
  skills: null,
  video: { value: 'all', label: 'All' },
  studentRange: [0, 300],
}

const Step1 = (props: Props): ReactElement => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage] = useState<number>(PER_PAGE)
  const [searchText, setSearchText] = useState('')
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [filterQuery, setFilterQuery] = useState<IFilterQueryType>({})
  const [filterData, setFilterData] = useState<IGroupsFilterDrawer>({
    values: initialValues,
    filterOptions: {},
  })

  const defaultFilter = {
    _id: {
      type: UpperCaseFilterableFieldType.ARRAY_NOT_IN,
      value: props.boughtCourses,
    },
    state: {
      type: UpperCaseFilterableFieldType.EXACT,
      value: 'PUBLISHED',
    },
    companyId: {
      type: UpperCaseFilterableFieldType.EXACT,
      value: null,
    },
    isBought: {
      type: UpperCaseFilterableFieldType.EXACT,
      value: false,
    },
  }

  const { courses, refetch } = useFetchCourses(
    { ...defaultFilter, ...filterQuery, ...filterData.filterOptions },
    currentPage,
    perPage,
  )

  const courseList = (courses && courses.data) || []

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setSearchText(e.target.value)

  const handleSearchSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    const formValue = { name: { type: 'MATCH', value: searchText } }
    setCurrentPage(1)
    setFilterQuery({
      ...filterQuery,
      ...formValue,
      ...filterData.filterOptions,
    } as IFilterQueryType)
    refetch({
      currentPage: 1,
      perPage,
      filter: filterQuery,
    })
  }

  const resetFilter = (): void => {
    setSearchText('')
    setFilterQuery({})
    setFilterData({} as filterData)
    setFilterDrawerOpened(false)
  }

  const handlePaginationClick = (_: React.ChangeEvent<unknown>, value: number): void => {
    if (value !== currentPage) {
      setCurrentPage(value)
    }
  }

  const { t } = props
  return (
    <>
      <Drawer opened={filterDrawerOpened} toggleDrawer={(): void => undefined} totalWidth='700px'>
        <CourseFilterDrawerContext.Consumer>
          {(value): ReactElement => (
            <FilterDrawer
              filterData={filterData}
              setFilterData={setFilterData}
              refetchCourse={refetch}
              resetFilter={resetFilter}
              onClose={(): void => setFilterDrawerOpened(false)}
              isCooursePrivacyHidden={true}
              setCurrentPage={setCurrentPage}
              companyId={value.companyId}
              t={t}
            />
          )}
        </CourseFilterDrawerContext.Consumer>
      </Drawer>
      <FormGroupWrapper padding={10}>
        <div
          style={{
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <form onSubmit={handleSearchSubmit} style={{ marginRight: 10 }}>
            <TextInput
              label={t('general.search_placeholder')}
              type='text'
              size='small'
              value={searchText}
              onChange={handleSearchChange}
              icon={
                <IconButton type='submit'>
                  <SearchRoundedIcon />
                </IconButton>
              }
            />
          </form>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <MultipleAction>
              <IconButton
                disabled={!_.isEmpty(filterData) || searchText ? false : true}
                onClick={resetFilter}
              >
                <SyncIcon fontSize='small' />
              </IconButton>
              <MultipleActionTooltip>{t('actions.reset')}</MultipleActionTooltip>
            </MultipleAction>
            <MultipleAction>
              <IconButton onClick={(): void => setFilterDrawerOpened(true)}>
                <FilterListIcon fontSize='small' />
              </IconButton>
              <MultipleActionTooltip>{t('actions.filter')}</MultipleActionTooltip>
            </MultipleAction>
          </div>
        </div>
        <Grid className={'card'} style={{ position: 'relative' }} cols={3}>
          {courses && (
            <CourseList
              data={courseList}
              gridStyle={'card'}
              isGridView={true}
              selectItem={props.selectItem}
              selectedItem={[props.selectedItem]}
              fromWizard
              openInExternalTab={true}
            />
          )}
        </Grid>
      </FormGroupWrapper>

      <FormFooter>
        <FormButtons>
          <Button
            text={t('actions.cancel')}
            type='small'
            onClick={props.handleDeleteCourse}
            background='#E0E1E2'
            textColor='#414141'
          />

          <Button
            text={t('actions.save_as_draft')}
            type='small'
            isDisabled={props.selectedItem.length === 0}
            color='secondary'
            onClick={(): void => props.handleButtonClick('draft')}
            textColor='#06C68F'
            background='#f5f5f5'
            outline='1px solid #06C68F'
          />

          <Button
            text={t('actions.next')}
            type='small'
            isDisabled={props.selectedItem.length === 0}
            color='secondary'
            onClick={(): void => props.handleButtonClick('next')}
            background='#06C68F'
          />
        </FormButtons>
        {courseList && courseList.length > 0 && (
          <div>
            <Pagination
              currentPage={courses ? courses.currentPage : 1}
              totalPages={courses ? courses.totalPages : 0}
              handleChange={handlePaginationClick}
            />
          </div>
        )}
      </FormFooter>
    </>
  )
}

export default Step1
