import gql from 'graphql-tag'

export const GET_LEADERBOARD = gql`
  query Leaderboard($companyId: String) {
    getLeaderboard(companyId: $companyId) {
      id
      firstName
      lastName
      email
      point
    }
  }
`
