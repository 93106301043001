import { useMutation } from '@apollo/client'
import { DELETE_TEST_ASSESSMENT_FROM_GROUPS } from 'gql/tests/tests.query'
import useSnackbarAlert from 'hooks/useSnackbar'
import { SnackbarVariants } from 'interfaces/common'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

interface IDeleteTestAssessmentFromGroupsServiceResponse {
  deleteTestAssessmentFromGroup: (variables: IDeleteTestAssessmentFromGroupsParams) => Promise<void>
  data?: string
  loading: boolean
  error?: string
}

interface IDeleteTestAssessmentFromGroupsResponse {
  deleteTestAssessmentFromGroupsById: string
}

interface IDeleteTestAssessmentFromGroupsParams {
  testAssessmentId: string
  groupIds: string[]
}

const useDeleteTestAssessmentFromGroupsService =
  (): IDeleteTestAssessmentFromGroupsServiceResponse => {
    const { t } = useTranslation()
    const { setSnackbar } = useSnackbarAlert()

    // mutation initialization
    const [deleteTestAssessmentFromGroupMutation, { data, loading, error }] = useMutation<
      IDeleteTestAssessmentFromGroupsResponse,
      IDeleteTestAssessmentFromGroupsParams
    >(DELETE_TEST_ASSESSMENT_FROM_GROUPS)

    const errorMessage = error ? error.message.split(':')[1] : ''

    const deleteTestAssessmentFromGroup = async (
      variables: IDeleteTestAssessmentFromGroupsParams,
    ): Promise<void> => {
      try {
        let { message, variant }: ISnackbarProps = {
          message: '',
          variant: undefined,
        }

        const { errors } = await deleteTestAssessmentFromGroupMutation({
          variables,
        })
        if (errors) {
          message = errors[0].message
          variant = SnackbarVariants.ERROR
        } else {
          message =
            variables.groupIds.length > 1
              ? t('books_layout.groups_removed')
              : t('books_layout.group_removed')
          variant = SnackbarVariants.SUCCESS
        }

        setSnackbar({ message, variant })
      } catch (error) {
        console.log(SnackbarVariants.ERROR, error)
      }
    }

    return {
      deleteTestAssessmentFromGroup,
      data: data?.deleteTestAssessmentFromGroupsById,
      loading,
      error: errorMessage,
    }
  }

export default useDeleteTestAssessmentFromGroupsService
