import useDeleteUserService from 'hooks/users/useDeleteUserService'
import {
  IUseHelperDeleteUserProps,
  IUseHelperDeleteUserReturn,
} from './useHelperDeleteUser.interface'

export const useHelperDeleteUser = ({
  ROLE,
  defaultFilter,
  refetch,
  perPage,
  currentPage,
  filteredByCompany,
  selectedItem,
  setSelectedItem,
}: IUseHelperDeleteUserProps): IUseHelperDeleteUserReturn => {
  const { deleteUser } = useDeleteUserService()

  const confirmDeleteMany = (role?: string): void => {
    const allSelectedItems = Object.values(selectedItem).flat()
    deleteUser(allSelectedItems, role || ROLE || '', () =>
      refetch({
        filter: {
          ...defaultFilter,
        },
        companyId: filteredByCompany,
        currentPage,
        perPage,
      }),
    )
    setSelectedItem([])
  }

  const confirmDelete = async (userIds: string[]): Promise<void> => {
    deleteUser(userIds, ROLE || '', () =>
      refetch({
        ROLE,
        filter: {
          ...defaultFilter,
        },
        companyId: filteredByCompany,
        currentPage,
        perPage,
      }),
    )
  }

  return { confirmDelete, confirmDeleteMany }
}
