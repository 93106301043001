import { ITask } from 'services/tasks/getTasksService.interface'

export interface IUpdateTasksDrawerProps {
  setTaskDrawerOpen: (open: boolean) => void
  companyId: string | null
  onTaskAdded: () => void
  taskId?: string
  mappedCourses: ICourseOption[]
  mappedGroups?: ICourseOption[]
  coursesLoading: boolean
  groupsLoading: boolean
  setTaskId: (taskId: string | null) => void
}

export interface IFlexWrapperProps {
  animate: boolean
}

export enum TriggerEnum {
  BEFORE_COURSE = 'BEFORE_COURSE',
  BEFORE_MODULE = 'BEFORE_MODULE',
  AFTER_COURSE = 'AFTER_COURSE',
  AFTER_MODULE = 'AFTER_MODULE',
}

export interface ICourseOption {
  value?: string
  label?: string
}

export interface ITaskAdmin {
  _id: string
  firstName: string
  lastName: string
  fullName: string
}

export interface IGridItemConfig {
  id: string
  name: string
  attachmentsCount: number
  commentsCount: number
  task: ITask
  courseId: string
  moduleId: string
  createDate: string
  trigger: string
  description: string
  groups: {
    name: string
    id: string
    groupId: string
  }[]
  admins: ITaskAdmin[]
  firstName: string
  lastName: string
  email: string
  avatar: string
}

export interface IStudentColumnConfig {
  id: string
  label: string
  render: (item: IGridItemConfig) => JSX.Element
  numeric: boolean
  disablePadding: boolean
}
export interface IStudentsActionConfig {
  id: string
  render: (item: IGridItemConfig) => JSX.Element
}

export interface IStudentActions {
  task: ITask
}

export enum TaskStatusEnum {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  TRY_AGAIN = 'TRY_AGAIN',
  APPROVED = 'APPROVED',
}
