import React from 'react'
import { Action } from 'components/common/GridV2'
import { ReactComponent as EditRoundedIcon } from 'assets/edit.svg'
import { ReactComponent as DeleteIcon } from 'assets/trash-can-red.svg'
import { DeleteSpan } from 'components/common/Styled/styled-components'

interface Item {
  id: number
  name: string
}

type ActionHandler = (event: string, field: Item, action?: string) => void
type Translator = (key: string) => string

export const actionConfig = (
  actionHandler: ActionHandler,
  t: Translator,
): { render: (item: Item) => JSX.Element }[] => {
  const actions = [
    {
      render: (item: Item) => (
        <Action onClick={(): void => actionHandler('edit', item)}>
          <EditRoundedIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      render: (item: Item) => (
        <Action onClick={(): void => actionHandler('delete', item)}>
          <DeleteIcon />
          <DeleteSpan>{t('actions.delete')}</DeleteSpan>
        </Action>
      ),
    },
  ]

  return actions
}
