import { useState, useEffect, useRef, useCallback } from 'react'
import debounce from 'lodash/debounce'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { IUseDebouncedSearchProps, IUseDebouncedSearchReturn } from './useHelperSearch.interface'

export const useDebouncedSearch = ({
  fetchFunction,
  defaultFilter,
  currentPage,
  perPage,
  role,
}: IUseDebouncedSearchProps): IUseDebouncedSearchReturn => {
  const [inputValue, setInputValue] = useState('')
  const debouncedFetchRef = useRef<ReturnType<typeof debounce>>()
  const [searchValue, setSearchValue] = useState('')
  const [isSearchType, setIsSearchType] = useState(false)

  useEffect(() => {
    debouncedFetchRef.current = debounce((searchValue: string) => {
      fetchFunction({
        filter: {
          ...defaultFilter,
          search: {
            type: LowerCaseFilterableFieldType.SEARCH,
            value: searchValue,
            fields: ['firstName', 'lastName', 'email', 'phone'],
          },
        },
        currentPage: 1,
        perPage,
      })
      setIsSearchType(searchValue !== '')
    }, 500)

    return (): void => {
      if (debouncedFetchRef.current) {
        debouncedFetchRef.current.cancel()
      }
    }
  }, [fetchFunction, defaultFilter, currentPage, perPage, role])

  useEffect(() => {
    if (debouncedFetchRef.current) {
      debouncedFetchRef.current(inputValue)
    }
  }, [inputValue])

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const newSearchValue = e.currentTarget.value
      setSearchValue(newSearchValue)
      setInputValue(newSearchValue)
      setIsSearchType(true)
    },
    [setInputValue],
  )

  return { handleSearchChange, searchValue, isSearchType }
}
