import styled from 'styled-components'

// AddCourseModuleDrawer

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  position: sticky;
  z-index: 9999;
  top: 0;
  background: #fff;
`

export const Title = styled.h2`
  font-size: 22px;
  font-weight: 500;
`

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SelectedWrapper = styled.div`
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  &.disabled {
    opacity: 0.5;
  }
`

export const FormTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
`

export const FormFooter = styled.footer`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
`

export const FormFieldsWrapper = styled.div`
  flex: 2;
  overflow-y: scroll;
`

export const ModulesList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const FormButtons = styled.div`
  align-self: flex-end;
  display: flex;

  div:not(:last-child) {
    margin-right: 10px;
  }
`

// gridConfig

export const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`
