import styled from 'styled-components'

export const PushNotificationContainer = styled.div`
  display: flex;
  gap: 40px;
  background-color: white;
  border-radius: 15px;
  padding-top: 40px;
  padding-inline: 40px;
  align-items: center;
  width: 40vw;
  flex-direction: column;
  overflow: hidden;
  min-height: 652px;

  @media (max-width: 1888px) {
    width: 60vw;
  }
  @media (max-width: 1366px) {
    width: 80vw;
  }
`

export const InputContainer = styled.form`
  width: 40%;
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .actions {
    display: flex;
    gap: 10px;
    align-items: center;
  }
`

export const StyledLabel = styled.label`
  font-size: 16px;
`

export const Heading = styled.h6`
  font-size: 24px;
  text-align: center;
`

export const NotificationPreview = styled.div`
  position: relative;
  min-width: 60%;

  .lock-screen {
    margin-bottom: -10px;
  }
`
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  align-items: center;
`

export const NotificationContainer = styled.div`
  background-color: rgba(244, 244, 244, 0.5);
  border-radius: 15px;
  position: absolute;
  top: 53%;
  left: 50%;
  padding: 10px;
  transform: translate(-50%, -50%);
  width: 73%;
  display: flex;
  align-items: center;
  gap: 15px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 80%;

    .heading {
      font-weight: bold;
      font-size: 15px;
    }
    .body {
      font-size: 15px;
    }

    .body,
    .heading {
      margin: 0;
      inline-size: 230px;
      overflow-wrap: break-word;
      line-height: 1.1;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }

  .logo {
    width: 20%;
    aspect-ratio: 1 / 1;
    border-radius: 15px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    img {
      width: 100%;
      height: 100%;
    }
  }
`

export const StyledInput = styled.input`
  width: 100%;
  outline: none;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  margin-top: 8px;
`

export const StyledTextArea = styled.textarea`
  width: 100%;
  outline: none;
  padding: 10px;
  border-radius: 10px;
  resize: none;
  font-family: 'Roboto', sans-serif;
  border: 1px solid #e6e6e6;
  margin-top: 8px;
`
