import styled from 'styled-components'

// CourseDeatilInformation

export const StyledItemContainer = styled.div``
export const StyledItemWrapper = styled.div`
  span {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    white-space: nowrap;
  }
  display: flex;
  align-items: center;
`

export const StyledItem = styled.div`
  font-size: 12px;
  color: #06c68f;
  cursor: pointer;
  margin-left: 5px;
  text-decoration: underline;
`

// ResetPassword

export const Wrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  width: 400px;
  height: 200px;
  padding: 20px 30px;
  box-shadow: 5px 5px 10px #00000029;
  border-radius: 10px;
  > h2 {
    width: 100%;
    text-align: left;
  }
`
export const ButtonsContainer = styled.div`
  display: flex;
  width: 146px;
  align-items: center;
  justify-content: space-between;
`

// UserStatistics

export const Title = styled.div`
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: -10px;
`
