import { ApolloError, OperationVariables, useQuery } from '@apollo/client'

import { GET_MODULES, GET_MODULES_OPTIONS } from 'gql/modules.query'
import { GET_FLASHCARD, GET_FLASHCARDS } from 'gql/flashcards.query'
import { GET_SKILL_TEST_VIDEOS_CRITERIAS } from 'gql/skillTestVideos.query'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { IModule, IModuleBriefData } from 'pages/modules/ModulesList.interface'
import { IFlashCard } from './FlashCardsLayout/useModuleDetailContent.interface'
import { ISkillTestVideoReviewCriteria } from 'pages/modules/SkillTestVideoCriterias/skillTestVideoCriteria.interface'

export const PER_PAGE = 12

export enum ModuleSort {
  NEW_TO_OLD = 'newToOld',
  OLD_TO_NEW = 'oldToNew',
}
export const useFetchModules = (
  filter: IFilterQueryType,
  currentPage = 1,
  perPage: number = PER_PAGE,
): {
  modules: {
    currentPage: number
    totalPages: number
    data: IModule[]
  }
  loading: boolean
  error?: string
  refetch: (variables?: OperationVariables) => void
} => {
  const { data, loading, error, refetch } = useQuery(GET_MODULES, {
    variables: { filter, currentPage, perPage },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    modules: data && data.allModules,
    loading,
    error: errorMessage,
    refetch,
  }
}

export interface IGetModulesOptionsResponse {
  currentPage: number
  totalPages: number
  data: IModuleBriefData[]
}

export const useFetchModuleOptions = (
  filter: IFilterQueryType,
  currentPage = 1,
  perPage: number = 0,
): {
  moduleOptions: IGetModulesOptionsResponse
  loading: boolean
  error?: ApolloError
  refetch: (variables?: OperationVariables) => void
} => {
  const { data, loading, error, refetch } = useQuery(GET_MODULES_OPTIONS, {
    variables: { filter, currentPage, perPage },
  })

  return { moduleOptions: data && data.allModules, loading, error, refetch }
}

export const useFetchFlashCard = (
  id: string,
): {
  flashCard: IFlashCard
  loading: boolean
  error?: string
  refetch: (variables?: OperationVariables) => void
} => {
  const { data, loading, error, refetch } = useQuery(GET_FLASHCARD, {
    variables: { id },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    flashCard: data && data.flashCard,
    loading,
    error: errorMessage,
    refetch,
  }
}

export const useFetchSkillTestVideoCriterias = (
  moduleId: string,
): {
  skillTestVideoCriterias: {
    total: number
    criterias: ISkillTestVideoReviewCriteria[]
  }
  loading: boolean
  error?: string
  refetch: (variables?: OperationVariables) => void
} => {
  const { data, loading, error, refetch } = useQuery(GET_SKILL_TEST_VIDEOS_CRITERIAS, {
    variables: { moduleId },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    skillTestVideoCriterias: (data && data.getSkillTestVideoCriteriasByModuleId) || {
      total: 0,
      criterias: [],
    },
    loading,
    error: errorMessage,
    refetch,
  }
}

export const useFetchFlashCards = (
  moduleId: string,
  skip = 0,
  limit = 0,
): {
  flashCards: IFlashCard[]
  loading: boolean
  error?: string
  refetch: (variables?: OperationVariables) => void
} => {
  const { data, loading, error, refetch } = useQuery(GET_FLASHCARDS, {
    variables: { moduleId, skip, limit },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    flashCards: data && data.flashCards,
    loading,
    error: errorMessage,
    refetch,
  }
}
