import { gql } from 'graphql.macro'

export const GET_ASSISTANT_MESSAGES = gql`
  query GetAssistantMessages2($assistantId: String, $filter: IAssistantMessagesFilter) {
    getAssistantMessages2(assistantId: $assistantId, filter: $filter) {
      _id
      role
      content
      createdAt
    }
  }
`
