import styled from 'styled-components'

export const GridITemWrapper = styled.div<{ fullHeight?: boolean }>`
  height: ${({ fullHeight = true }): string => (fullHeight ? 'auto' : '40px')};
  display: flex;
  align-items: center;
  & > img {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    margin-right: 5px;
  }
`

export const StyledTableContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
`

export const StyledPagination = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  padding-bottom: 5px;
`

const PREFIX = 'GroupsByUsers'

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  table: `${PREFIX}-table`,
  visuallyHidden: `${PREFIX}-visuallyHidden`,
}

export const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.paper}`]: {
    width: '100%',
    marginBottom: '20px',
    padding: '0px 15px',
  },

  [`& .${classes.table}`]: {
    minWidth: 750,
    position: 'relative',
  },

  [`& .${classes.visuallyHidden}`]: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))
