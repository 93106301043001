import styled from 'styled-components'

export const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

export const Card = styled.div<{ isChecked: boolean }>`
  cursor: pointer;
  background: #fff;
  padding: 10px 16px;
  border: 1px solid;
  border-color: ${({ isChecked }): string => (isChecked ? '#00905e' : '#d6dae0')};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s ease;

  &:hover {
    box-shadow: 0 0 3px #d6dae0;
  }
`
export const Header = styled.header`
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Subtitle = styled.h2`
  font-weight: 350;
  font-size: 17px;
  color: #68707a;
  width: 130px;
`

export const Title = styled.h1`
  font-weight: 700;
  font-size: 19px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  padding-bottom: 7px;
`

export const IconWrapper = styled.div`
  border: 2px solid #f5f6fa;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  width: 45px;
  height: 30px;
`
export const DateWrapper = styled.div`
  font-size: 15px;
  font-weight: 700;
`
