import { ApolloError } from '@apollo/client'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'

import {
  setNotificationViewStatusService,
  readNotificationService,
  deleteNotificationsService,
  sendCustomNotificationService,
} from 'services/notifications/notificationService'

export const useSetNotificationStats = (): {
  setNotificationViewStatus: (ids: string[], notificationBadgeRefetch: () => void) => Promise<void>
  error?: ApolloError
  loading: boolean
} => {
  const { setStatusMutation, error, loading } = setNotificationViewStatusService()

  const setNotificationViewStatus = async (
    ids: string[],
    notificationBadgeRefetch: () => void,
  ): Promise<void> => {
    const { errors } = await setStatusMutation({
      variables: {
        ids,
      },
    })

    if (errors && errors.length) {
    } else {
      notificationBadgeRefetch()
    }
  }

  return {
    setNotificationViewStatus,
    error,
    loading,
  }
}

export interface ICustomNotification {
  body: string
  subject: string
  companyIds?: string[]
  sendToAllCompanies?: boolean
  sendToAllRoles?: boolean
  sendAsEmail?: boolean
  sendAsPushNotification?: boolean
  roles?: string[]
  template?: string
}

export const useSendCustomNotification = (): {
  sendCustomNotification: (input: ICustomNotification) => Promise<void>
  error?: ApolloError
  loading: boolean
} => {
  const { setSnackbar } = useSnackbarAlert()
  const { error, loading, sendCustomNotificationMutation } = sendCustomNotificationService()

  const sendCustomNotification = async (input: ICustomNotification): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await sendCustomNotificationMutation({
      variables: { input },
    })

    if (errors && errors.length) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = `Success`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    error,
    loading,
    sendCustomNotification,
  }
}

export const userReadNotification = (): {
  readNotification: (
    ids: string[],
    action: string,
    fetchNotificationData: () => void,
  ) => Promise<void>
  error?: ApolloError
  loading: boolean
} => {
  const { setSnackbar } = useSnackbarAlert()
  const { error, loading, readNotificationMutation } = readNotificationService()

  const readNotification = async (
    ids: string[],
    action: string,
    fetchNotificationData: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await readNotificationMutation({
      variables: { ids, action },
    })

    if (errors && errors.length) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = `Success`
      variant = 'success'

      fetchNotificationData()
    }

    setSnackbar({ message, variant })
  }

  return {
    error,
    loading,
    readNotification,
  }
}

export const useDeleteNotifications = (): {
  deleteNotifications: (
    ids: string[],
    action: string,
    fetchNotificationData: () => void,
  ) => Promise<void>
  error?: ApolloError
  loading: boolean
} => {
  const { setSnackbar } = useSnackbarAlert()
  const { deleteNotificationsMutation, error, loading } = deleteNotificationsService()

  const deleteNotifications = async (
    ids: string[],
    action: string,
    fetchNotificationData: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await deleteNotificationsMutation({
      variables: {
        ids,
        action,
      },
    })

    if (errors && errors.length) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = `Success`
      variant = 'success'

      fetchNotificationData()
    }

    setSnackbar({ message, variant })
  }

  return {
    deleteNotifications,
    error,
    loading,
  }
}
