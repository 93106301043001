import React from 'react'

const Activate = (): React.JSX.Element => {
  return (
    <div>
      <h1>Activate Route</h1>TODO: Put Loader
    </div>
  )
}

export default Activate
