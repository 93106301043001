import styled, { CSSProperties } from 'styled-components'

// CoursesTab

export const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 15px 20px;
`
export const ToolbarItemWrapper = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
  width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`
export const Title = styled.h2`
  font-size: 22px;
  font-weight: 500;
`

export const columnFormFieldStyles: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
}
