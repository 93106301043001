import styled from 'styled-components'

// Styled component for the whiteboard
export const Whiteboard = styled.div`
  background-color: #ffffff;
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
  border: 1px solid #e7e9ed;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Grid = styled.div`
  display: grid;
  grid-gap: 20px;

  @media (min-width: 1900px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: 1900px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1525px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

//styled Button
export const Button = styled.button`
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: #d2364b;
  color: #fff;
  cursor: pointer;
  margin-left: 15px;
  font-size: 16px;
  font-weight: 600;
  transition: background 0.3s ease, border 0.3s ease;
  box-shadow: 1px 1px 1px rgb(0 0 0 / 38%);

  /* &:hover {
  background: #ff3a52;
  border: 1px solid transparent;
} */
`

// styled title
export const Title = styled.div`
  font-size: 24px;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 20px;
  font-weight: 700;
`

// Styled component for the text in the middle
export const CenteredText = styled.div`
  font-weight: 800;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  gap: 8px;

  h1 {
    font-size: 18px;
    font-weight: 800;
  }

  h4 {
    color: #68707a;
    font-size: 16px;
    font-weight: 500;
  }
`

export const ToolbarStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  marginLeft: 'auto',
}

export const ToolbarPaddingStyles = {
  padding: '0 0 20px 0',
}

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`
export const ContentWrapper = styled.div<{ $isGrid: boolean }>`
  margin-top: 30px;

  ${({ $isGrid }): string =>
    !$isGrid
      ? `
      .card {
          grid-template-columns:none;
      }
    `
      : `
      .card {
        display: grid;
        grid-template-columns: repeat(4, 1fr);


        @media (max-width: 1450px) {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    `}
`
export const GridTableWrapper = styled.div`
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  margin-top: 20px;
  padding: 16px;

  th:last-child {
    width: 10px;
  }
`
export const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 0 20px 0 0;
  text-transform: capitalize;
`
