/* eslint-disable */

/**
 * Create a new object with all properties of the input object except for the __typename property
 *
 * @param obj - The input object to filter
 * @returns  A new object with all properties of the input object except for the __typename property
 */
const removeTypeNameFromObject = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(v => removeTypeNameFromObject(v))
  } else if (obj !== null && typeof obj === 'object') {
    const newObj: any = {}
    Object.keys(obj).forEach(key => {
      if (key !== '__typename') {
        newObj[key] = removeTypeNameFromObject(obj[key])
      }
    })
    return newObj
  }
  return obj
}

export default removeTypeNameFromObject
