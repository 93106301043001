import React from 'react'
import Grid from 'components/common/GridV2/Grid'
import PaginationV2 from 'components/common/PaginationV2'
import PerPageDropdown from 'components/common/PerPageDropDown/PerPageDropDown'
import MyLoader from 'loading/loading'
import { PaginationContainer, CenterParagraph, Container } from './styled-components'
import useCourseStudents from 'hooks/analytics/useCourseStudents'

const CourseStudents: React.FC<{
  courseId: string
  groupId: string | undefined
  groupIds?: string[] | undefined
}> = ({ courseId, groupId, groupIds }) => {
  const {
    t,
    loading,
    students,
    gridConfig,
    collapseConfig,
    getCollapsedData,
    getNestedCollapsedData,
    nestedCollapseConfig,
    rowsPerPage,
    handleRowsPerPageChange,
    currentPage,
    handlePageChange,
    totalPages,
  } = useCourseStudents({ courseId, groupId, groupIds })

  if (loading) return <MyLoader width={250} speed={0.8} />

  return (
    <Container>
      {students ? (
        <>
          <Grid
            data={students}
            config={gridConfig}
            collapseConfig={collapseConfig}
            getCollapsedData={getCollapsedData}
            nestedCollapseConfig={nestedCollapseConfig}
            getNestedCollapsedData={getNestedCollapsedData}
          />
          {students?.length > 0 && (
            <PaginationContainer>
              <PerPageDropdown
                value={rowsPerPage}
                onChange={(value): void =>
                  handleRowsPerPageChange({
                    target: { value: value.toString() },
                  } as React.ChangeEvent<HTMLSelectElement>)
                }
                options={[10, 20, 50]}
              />
              <PaginationV2
                currentPage={currentPage}
                totalPages={totalPages}
                handleChange={handlePageChange}
              />
            </PaginationContainer>
          )}
        </>
      ) : (
        <CenterParagraph>{t('general.specific_data')}</CenterParagraph>
      )}
    </Container>
  )
}

export default CourseStudents
